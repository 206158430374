import supportedLanguages from "@app-backend/languages.json";
import xlfSource from "@app-backend/Locales/qportal.xlf";
import usei18n from "@app-i18n/index";
import { datesDictionary, importXlf, LanguageIso, numericDictionary, validationDictionary } from "@app-i18n/statics";
import useCompanyStore from "@app-store/company";
import store from "@app-store/index";
import useRestUtilsStore from "@app-store/rest-utils";
import useUserStore from "@app-store/user";
import resolveAny from "@app-utils/resolve-any";
import { useValidationI18n } from "@qamf/lighthouse";
import { ITranslation } from "@qamf/xliff-loader";
import Numeral from "numeral";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";
type SupportedLanguagesMap = typeof supportedLanguages;

@Module class LangModuleFactory extends VuexModule {
	readonly defaultIsoCode: LanguageIso = "en-US";
	isoCode: LanguageIso | null = null;
	language: SupportedLanguagesMap[LanguageIso] | null = null;
	languages: SupportedLanguagesMap = supportedLanguages;
	libLangMap = new Map<string, string>();
	libDictionary = new Map<string, any>();
	private downloadXlf(): Promise<ITranslation> {
		return new Promise(async resolve => {
			const langUtils = usei18n();
			langUtils.xlfObjectSource.value = xlfSource;
			const exitResolve = (xlf: ITranslation) => {
				langUtils.xlfObject.value = xlf;
				resolve(xlf);
			};

			const lang = await importXlf(this.isoCode ?? this.defaultIsoCode);
			exitResolve(lang.default);
		});
	}

	private async veeValidateLanguage() {
		// eslint-disable-next-line no-new
		new Promise<void>(async resolve => {
			const langUtils = usei18n();
			const langToSet = this.isoCode ?? this.defaultIsoCode;
			const parts = langToSet.split("-");
			const current = this.libLangMap.get("vee-validate");

			if (current === parts[0]) return resolve();

			let lang = parts[0] + "";
			if (!Object.keys(validationDictionary).find(vd => vd.startsWith(lang)))
				lang = "en";

			const [dictionary] = await resolveAny(validationDictionary[langToSet]());
			(dictionary as any).messages.required = langUtils.translateKey("com_error_required");
			(dictionary as any).messages.email = langUtils.translateKey("com_error_invalid_format");
			const { configure, localize, setLocale } = useValidationI18n();
			configure({
				generateMessage: localize(lang, { messages: dictionary.messages })
			});
			setLocale(lang);
			this.libLangMap.set("vee-validate", lang);

			resolve();
		});
	}

	private async dateFnsLanguage() {
		// eslint-disable-next-line no-new
		new Promise<void>(async resolve => {
			const langToSet = this.isoCode ?? this.defaultIsoCode;
			const parts = langToSet.split("-");
			const current = this.libLangMap.get("date-fns");

			if (current === parts[0]) return resolve();

			let localeToSet = this.isoCode ?? this.defaultIsoCode;
			if (current === localeToSet) return resolve();
			if (!(localeToSet in datesDictionary))
				localeToSet = "en-US";
			const [dictionary] = await resolveAny(datesDictionary[localeToSet]());
			this.libLangMap.set("date-fns", localeToSet);
			this.libDictionary.set("date-fns", (dictionary as any).default);

			resolve();
		});
	}

	private async numeralLanguage() {
		// eslint-disable-next-line no-new
		new Promise<void>(async resolve => {
			const langToSet = String(this.isoCode ?? this.defaultIsoCode);
			const parts = langToSet.split("-");
			const current = this.libLangMap.get("numeral");

			if (current === parts[0]) return resolve();

			let localeToSet = String(this.isoCode ?? this.defaultIsoCode);
			if (current === localeToSet) return resolve();
			if (!(localeToSet in numericDictionary))
				localeToSet = "en-US";
			let localesKey = await numericDictionary[localeToSet]();
			if (!localesKey)
				localesKey = await numericDictionary["en-US"]();
			this.libLangMap.set("numeral", localeToSet);
			this.libDictionary.set("numeral", localesKey);
			Numeral.locale(localesKey);

			resolve();
		});
	}

	@Mutation setCurrentIsoLang(isoLang: LanguageIso) {
		this.isoCode = isoLang;
		const restUtilsStore = useRestUtilsStore();
		restUtilsStore.setAcceptLanguage(isoLang);
	}

	@Mutation setCurrentLangData(data: SupportedLanguagesMap[LanguageIso] | null) {
		this.language = data;
	}

	@Action async ensureLanguage() {
		const userStore = useUserStore();
		const companyStore = useCompanyStore();
		const language = (userStore.settings?.Language && userStore.settings.Language !== ""
			? userStore.settings.Language
			: companyStore.company?.Language && companyStore.company.Language !== ""
				? companyStore.company.Language
				: this.defaultIsoCode) as LanguageIso;

		if (this.isoCode && this.isoCode === language)
			return;

		await this.setLanguage(language);
	}

	@Action async setLanguage(targetIsoCode: LanguageIso) {
		let langToSet = String(this.defaultIsoCode) as LanguageIso;
		if (targetIsoCode.toLowerCase() !== this.defaultIsoCode.toLowerCase()) {
			const availableLangs = this.languages ? Object.keys(this.languages) : [];
			const hasLang = availableLangs.includes(targetIsoCode);

			if (hasLang)
				langToSet = targetIsoCode;
			else {
				const langParts = targetIsoCode.split("-");
				const matched = availableLangs.find(l => l.split("-")[0] === langParts[0]) as LanguageIso | undefined;
				langToSet = matched ?? this.defaultIsoCode;
			}
		}
		if (langToSet && this.languages && this.languages[langToSet]) {
			this.setCurrentIsoLang(langToSet);
			await this.downloadXlf();
			await Promise.all([
				this.veeValidateLanguage(),
				this.dateFnsLanguage(),
				this.numeralLanguage()
			]);
			const langData = this.languages[langToSet];
			const html = document.documentElement;
			html.setAttribute("lang", langToSet);
			html.setAttribute("dir", langData.textDirection);
			this.setCurrentLangData(langData);
		}
	}
}

let LangModule: LangModuleFactory | undefined;
function useLangStore() {
	if (LangModule) return LangModule;
	const mod = LangModule = new LangModuleFactory({ store, name: "lang" });
	return mod;
}
export default useLangStore;
