import useRestUtilsStore from "@app-store/rest-utils";
import EventsManager from "@app-utils/events-manager";
import { type IResponse, RestClient } from "scarlett";

import type { IResponseError } from "./common.interfaces";

export default class PortalRestClientFactory extends RestClient<any, IResponseError<"SessionExpired">> {
	private readonly map = new Map<string, Promise<any>>();
	constructor() {
		super({
			basePath: "/api",
			throw: true,
			headers: new Headers({
				Pragma: "no-cache",
				"Cache-Control": "no-cache, no-store",
				"Content-Type": "application/json"
			}),
			credentials: "include",
			responseType: (req, res) => {
				if (res?.status === 500)
					return "text";

				return "json";
			},
			onRequest: function(req) {
				if (!req.options.headers)
					req.options.headers = new Headers();
				const headers = req.options.headers;
				const rest = useRestUtilsStore();
				headers.set("Accept-Language", rest.acceptLanguage);
			},
			onResponse(response) {
				if (!response.error) return;
				if (response.error.data?.Error.Code === "SessionExpired")
					EventsManager.emitError(response.error);
			},
			throwExcluding: [
				{ errorCode: "BodyParse" },
				error => error.data?.Error.Code === "SessionExpired"
			]
		});
	}

	getPromise<TResponse = any, TError = any>(key: string): Promise<IResponse<TResponse, TError>> | undefined {
		return this.map.get(key);
	}

	setPromise(key: string, prom: Promise<any> | null) {
		if (prom === null)
			this.map.delete(key);
		else this.map.set(key, prom);
	}
}
