<template>
	<div
		v-click-away="onClickOutside"
		class="q-form-field q-form-field-select"
		:class="classProp"
	>
		<QPopover
			ref="popover"
			v-model="isPopoverVisible"
			manual
			no-flip
			:placement="placement"
			:teleport-to="teleportTo"
			:reference="inputElement"
			:delay="0"
			:offset="0"
			:custom-class="`q-form-field-select-wrapper ${optionsClass}`"
			:class="{
				'd-none': disabled
			}"
			@show="onShowSelectOptions"
		>
			<template #target>
				<slot />
				<QFormFieldWrapper
					v-model="modelValue"
					:name="name"
					:size="size"
					:rules="rules"
					:error-message="errorMessage"
					:hide-validation="hideValidation"
					:hide-validation-messages="hideValidationMessages"
					:show-clearer="showClearer"
					@click.stop="onClickWrapper"
					@click-field-clearer="onClearerClick"
				>
					<template #default="defaultSlotProps">
						<input
							ref="inputElement"
							v-bind="defaultSlotProps.field"
							:value="activeOption"
							class="form-select"
							:class="[
								{
									['form-control-' + size]: true,
									'is-valid': defaultSlotProps.state === true,
									'is-invalid': defaultSlotProps.state === false
								},
								controlClasses
							]"
							:disabled="disabled"
							readonly
							@copy.prevent
							@paste.prevent
						>
					</template>
					<template #appendIcon>
						<i
							class="bi bi-chevron-down"
							:class="{'disabled': disabled }"
							aria-hidden="true"
							@click="onIconClick"
						/>
					</template>
				</QFormFieldWrapper>
			</template>
			<div
				class="select-body"
				@click.stop
			>
				<QOverlay
					:show="helpers.loading || loading"
					:size="spinnerSize"
				>
					<template v-if="helpers.groupedOptions.length">
						<div
							v-for="(groupedOptions, index) in helpers.groupedOptions"
							:key="`groupedOptions-${index}`"
							class="wrap-option-group"
						>
							<slot name="optionGroupLabel">
								<p class="label">
									{{ groupedOptions.id }}
								</p>
							</slot>
							<div
								v-for="(option, indexOpt) in groupedOptions.options"
								:key="`options-${indexOpt}`"
								class="option"
								:class="{ 'disabled': isOptionDisabled(option) }"
								@click.stop="onOptionClick(option)"
							>
								<slot
									name="option"
									:option="option"
								>
									<div class="option-element">
										{{ textField in option ? option[textField] : "" }}
									</div>
								</slot>
							</div>
						</div>
					</template>
					<template v-else>
						<div
							v-for="(option, index) in helpers.flatOptions"
							:key="index"
							class="option"
							:class="{ 'disabled': isOptionDisabled(option) || readonly }"
							@click.stop="onOptionClick(option)"
						>
							<slot
								name="option"
								:option="option"
							>
								<div
									class="option-element"
									:data-test="`option-element-${index}`"
								>
									{{ textField in option ? option[textField] : "" }}
								</div>
							</slot>
						</div>
					</template>
				</QOverlay>
			</div>
		</QPopover>
	</div>
</template>
<script lang="ts" src="./q-form-field-select.ts"></script>
<style lang="scss" src="./q-form-field-select.scss"></style>
