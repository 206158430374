import AccessDenied from "@app-components/access-denied/access-denied.vue";
import { QRouteMeta } from "@app-router/index";
import useGlobalStore from "@app-store/global";
import useUserStore from "@app-store/user";
import { isAccessGranted, isVisible } from "@app-utils/router";
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "ViewWrapper",
	components: {
		AccessDenied
	},
	props: {
		loading: { type: Boolean, default: false },
		onAccessGranted: { type: Function, required: true }
	},
	setup(props) {
		const globalStore = useGlobalStore();
		const userStore = useUserStore();
		const route = useRoute();
		const routeName = computed(() => {
			return route.name ?? "";
		});
		const loadingFunctionalities = computed(() => {
			return (
				userStore.loadingCompanyFunctionalities
				|| userStore.loadingSystemFunctionalities
			);
		});
		const helpers = reactive({
			isAuthorized: null as boolean | null,
			isVisible: null as boolean | null
		});
		const onAccessDenied = () => {
			globalStore.setRouteLoading(false);
		};
		const checkAccessGranted = () => {
			if (loadingFunctionalities.value) return;
			const meta = route.meta as QRouteMeta;
			helpers.isAuthorized = isAccessGranted(meta);
			helpers.isVisible = isVisible(meta);
			if (helpers.isAuthorized && helpers.isVisible)
				return props.onAccessGranted();
			onAccessDenied();
		};
		watch(
			() => routeName.value,
			() => checkAccessGranted()
		);
		const loadingFunctionalitiesChanged = (loading: boolean) => {
			if (loading) return;
			checkAccessGranted();
		};
		watch(
			() => loadingFunctionalities.value,
			(loading: boolean) => loadingFunctionalitiesChanged(loading)
		);
		onMounted(() => {
			checkAccessGranted();
		});
		return {
			loadingFunctionalities,
			helpers
		};
	}
});
