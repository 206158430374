import { useSecurityUtilities } from "@app-composables/security";
import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";
import useExternalPosStore from "@app-store/system/externalPos";

export const posSolution = "pos-solution";
export const posSolutionIntegration = "pos-solution-integration";
export const posSolutionIntegrationTutorial = "pos-solution-integration-tutorial";
export const posSolutionItemLibrary = "pos-solution-item-library";
export const posSolutionItemLibraryDetails = "pos-solution-item-library-details";
export const posSolutionAuthorization = "pos-solution-authorization";
export const pairSquareTerminals = "pos-solution-pair-square-terminals";
export const terminalDetails = "pos-solution-terminal-details";

export default [
	{
		path: "/:centerId(\\d{4,})/pos-solution",
		name: posSolution,
		component: () => import("@app-views/pos-solution/pos-solution.vue"),
		meta: {
			titleKey: "posSolutionTitle",
			childOf: "center-home",
			showIf: () => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.AccessToSquare]),
			accessIf: () => useSecurityUtilities().hasAtLeastOneFunctionalityEnabled([
				Functionalities.SquarePayment,
				Functionalities.ManageSquareConnection,
				Functionalities.ManageSquarePosIntegration,
				Functionalities.ManageSquareItemLibrary
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/integration",
		name: posSolutionIntegration,
		component: () => import("@app-views/pos-solution/integration/integration.vue"),
		meta: {
			titleKey: "pos_solution_integration_title",
			childOf: posSolution,
			inheritShowAccessIfs: false,
			accessIf: () => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.ManageSquarePosIntegration])
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/integration/tutorial",
		name: posSolutionIntegrationTutorial,
		component: () => import("@app-views/pos-solution/integration/tutorial/tutorial.vue"),
		meta: {
			titleKey: "pos_solution_integration_tutorial_title",
			childOf: posSolutionIntegration
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/item-library",
		name: posSolutionItemLibrary,
		component: () => import("@app-views/pos-solution/item-library/item-library.vue"),
		meta: {
			titleKey: "pos_solution_item_library_title",
			childOf: posSolution,
			inheritShowAccessIfs: false,
			showIf: () => useExternalPosStore().squareSyncStatus !== "NotExists",
			accessIf: () => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.ManageSquareItemLibrary])
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/item-library/:id(\\d+)",
		name: posSolutionItemLibraryDetails,
		component: () => import("@app-views/pos-solution/item-library/details/details.vue"),
		meta: {
			childOf: posSolutionItemLibrary
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/pair-square-terminals",
		name: pairSquareTerminals,
		component: () => import("@app-views/pos-solution/pair-square-terminals/pair-square-terminals.vue"),
		meta: {
			titleKey: "pos_solution_pair_square_terminals",
			childOf: posSolution,
			inheritShowAccessIfs: false,
			showIf: () => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.SquarePayment]),
			accessIf: () => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.SquarePayment])
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/pair-square-terminals/:id(\\d+)",
		name: terminalDetails,
		component: () => import("@app-views/pos-solution/pair-square-terminals/terminal-details/terminal-details.vue"),
		meta: {
			childOf: pairSquareTerminals
		}
	},
	{
		path: "/:centerId(\\d{4,})/pos-solution/connection-to-square",
		name: posSolutionAuthorization,
		component: () => import("@app-views/pos-solution/authorization/authorization.vue"),
		meta: {
			titleKey: "pos_solution_authorization_title",
			childOf: posSolution,
			groupName: "authorization",
			accessIf: () => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.ManageSquareConnection])
		}
	}
] as QRoute[];
