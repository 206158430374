import type { AccessHandler, QRouteMeta } from "@app-router/index";
import Router from "@app-router/index";
import SecurityPlugin from "@app-utils/security";
import { ref } from "vue";
import { type RouteLocationNormalized, RouteLocationRaw } from "vue-router";

export async function backToParentView() {
	const router = ref(Router);
	const route = router.value.currentRoute;
	const metaView = route.meta as QRouteMeta;
	const parentView = metaView.childOf;
	await router.value.push({
		name: parentView,
		params: route.params,
		query: route.query
	}).catch(() => {});
}
export function isVisible(meta: QRouteMeta): boolean {
	if (!meta.showIf) return true;

	const checkVisibility = (check: AccessHandler) => {
		if (typeof check !== "function")
			return SecurityPlugin.hasFunctionalities([check]);
		return check();
	};
	if (Array.isArray(meta.showIf)) {
		const results = meta.showIf.map(check => checkVisibility(check));
		const failed = results.find(r => !r);
		const areAllTrue = typeof failed === "undefined";
		return areAllTrue;
	} else return checkVisibility(meta.showIf);
}

export function isAccessGranted(meta: QRouteMeta): boolean {
	if (!meta.accessIf) return true;

	const checkAccess = (check: AccessHandler) => {
		if (typeof check !== "function")
			return SecurityPlugin.hasFunctionalitiesEnabled([check]);
		return check();
	};
	if (Array.isArray(meta.accessIf)) {
		const results = meta.accessIf.map(check => checkAccess(check));
		const failed = results.find(r => !r);
		const areAllTrue = typeof failed === "undefined";
		return areAllTrue;
	} else return checkAccess(meta.accessIf);
}

export function removeURLQueryString(to: RouteLocationNormalized): RouteLocationRaw | false {
	if (Object.keys(to.query).length)
		return { name: to.name, params: to.params, query: {} } as RouteLocationRaw;
	return false;
}
