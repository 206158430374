import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/.";
import SecurityPlugin from "@app-utils/security";

import { settingsReservations } from ".";
export const bookableDaysCalendarBookForLater = "bookable-days-calendar-book-for-later";
export const bookableDaysCalendarPlayNow = "bookable-days-calendar-play-now";
export const bookableDaysCalendarWaitingList = "bookable-days-calendar-waiting-list";
export const settingsReservationsWebOffers = "web-offers";
export const settingsReservationsShoesSocks = "shoes-and-socks";
export const settingsReservationsBowlerTypes = "bowler-types";
export const settingsReservationsOptions = "reservation-options";
export const settingsReservationsRulesPreferencesBookForLater = "rules-and-preferences-book-for-later";
export const settingsReservationsRulesPreferencesPlayNow = "rules-and-preferences-play-now";
const settingsReservationsRulesPreferencesCheckIn = "rules-and-preferences-check-in";
const settingsReservationsRulesPreferencesWaitingList = "rules-and-preferences-waiting-list";
export const settingsPlayingModeDetails = "playing-mode";
export const settingsReservationsBookableDaysBookForLater = "bookable-days-book-for-later";
export const settingsReservationsBookableDaysPlayNow = "bookable-days-play-now";
export const settingsReservationsBookableDaysWaitingList = "bookable-days-waiting-list";
export default [
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers",
		name: settingsReservationsWebOffers,
		component: () => import("@app-views/settings/reservations/web-offers/web-offers.vue"),
		meta: {
			titleKey: "label_web_offers",
			childOf: settingsReservations,
			groupName: "primary",
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineWebOffers,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes",
		name: settingsReservationsShoesSocks,
		component: () => import("@app-views/settings/reservations/rental-shoes/rental-shoes.vue"),
		meta: {
			titleKey: "settings_web_rental_shoes",
			childOf: settingsReservations,
			groupName: "primary",
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineShoesAndSocks,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bowler-types",
		name: settingsReservationsBowlerTypes,
		component: () => import("@app-views/settings/reservations/bowler-types/bowler-types.vue"),
		meta: {
			titleKey: "settings_bowler_types",
			childOf: settingsReservations,
			groupName: "primary",
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineBowlerTypes,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/options",
		name: settingsReservationsOptions,
		component: () => import("@app-views/settings/reservations/options/options.vue"),
		meta: {
			titleKey: "label_options",
			childOf: settingsReservations,
			groupName: "primary",
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineReservationOptions,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rules-and-preferences-book-for-later",
		name: settingsReservationsRulesPreferencesBookForLater,
		component: () => import("@app-views/settings/reservations/rules-and-preferences-book-for-later/rules-and-preferences-book-for-later.vue"),
		meta: {
			titleKey: "settings_web_rulespreferences",
			childOf: settingsReservations,
			groupName: "BookForLater",
			groupTitleKey: "label_book_for_later",
			showIf: () => SecurityPlugin.hasFunctionalities([Functionalities.WebReservations])
				|| SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.Kiosks]),
			accessIf: [Functionalities.DefineRulesAndPreferences]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bookable-days-book-for-later",
		name: settingsReservationsBookableDaysBookForLater,
		component: () => import("@app-views/settings/reservations/bookable-days/bookable-days.vue"),
		meta: {
			titleKey: "settings_web_bookabledayshours",
			childOf: settingsReservations,
			groupName: "BookForLater",
			groupTitleKey: "label_book_for_later",
			showIf: [Functionalities.WebReservations],
			accessIf: [Functionalities.DefineBookableDaysHours]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bookable-days-book-for-later/:idCalendar",
		name: bookableDaysCalendarBookForLater,
		component: () => import("@app-views/settings/reservations/bookable-days/calendar-view/calendar-view.vue"),
		meta: {
			childOf: settingsReservationsBookableDaysBookForLater,
			accessIf: [Functionalities.DefineBookableDaysHours]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rules-and-preferences-play-now",
		name: settingsReservationsRulesPreferencesPlayNow,
		component: () => import("@app-views/settings/reservations/rules-and-preferences-play-now/rules-and-preferences-play-now.vue"),
		meta: {
			titleKey: "settings_web_rulespreferences",
			childOf: settingsReservations,
			groupName: "PlayNow",
			groupTitleKey: "label_play_now",
			showIf: () => (SecurityPlugin.hasFunctionalities([Functionalities.WebReservations])
				|| SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.Kiosks]))
				&& SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.DefinePlayNowRulesAndPreferences]),
			accessIf: [Functionalities.DefinePlayNowRulesAndPreferences]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bookable-days-play-now",
		name: settingsReservationsBookableDaysPlayNow,
		component: () => import("@app-views/settings/reservations/bookable-days/bookable-days.vue"),
		meta: {
			titleKey: "settings_web_bookabledayshours",
			childOf: settingsReservations,
			groupName: "PlayNow",
			groupTitleKey: "label_play_now",
			showIf: [Functionalities.Kiosks],
			accessIf: [Functionalities.DefineBookableDaysHours]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bookable-days-play-now/:idCalendar",
		name: bookableDaysCalendarPlayNow,
		component: () => import("@app-views/settings/reservations/bookable-days/calendar-view/calendar-view.vue"),
		meta: {
			childOf: settingsReservationsBookableDaysPlayNow,
			accessIf: [Functionalities.DefineBookableDaysHours]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rules-and-preferences-check-in",
		name: settingsReservationsRulesPreferencesCheckIn,
		component: () => import("@app-views/settings/reservations/rules-and-preferences-check-in/rules-and-preferences-check-in.vue"),
		meta: {
			titleKey: "settings_web_rulespreferences",
			childOf: settingsReservations,
			groupName: "CheckIn",
			groupTitleKey: "label_check_in",
			showIf: [Functionalities.CheckInManagement],
			accessIf: [Functionalities.CheckInManagement]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rules-and-preferences-waiting-list",
		name: settingsReservationsRulesPreferencesWaitingList,
		component: () => import("@app-views/settings/reservations/rules-and-preferences-waiting-list/rules-and-preferences-waiting-list.vue"),
		meta: {
			titleKey: "settings_web_rulespreferences",
			childOf: settingsReservations,
			groupName: "WaitingList",
			groupTitleKey: "label_bowling_waiting_list",
			showIf: [Functionalities.KioskBowlingWaitingList, Functionalities.DefineKioskWaitingList],
			accessIf: [Functionalities.DefineRulesAndPreferences]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bookable-days-waiting-list",
		name: settingsReservationsBookableDaysWaitingList,
		component: () => import("@app-views/settings/reservations/bookable-days/bookable-days.vue"),
		meta: {
			titleKey: "settings_web_bookabledayshours",
			childOf: settingsReservations,
			groupName: "WaitingList",
			groupTitleKey: "label_bowling_waiting_list",
			showIf: [Functionalities.KioskBowlingWaitingList, Functionalities.DefineKioskWaitingList],
			accessIf: [Functionalities.DefineBookableDaysHours]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/bookable-days-waiting-list/:idCalendar",
		name: bookableDaysCalendarWaitingList,
		component: () => import("@app-views/settings/reservations/bookable-days/calendar-view/calendar-view.vue"),
		meta: {
			childOf: settingsReservationsBookableDaysWaitingList,
			accessIf: [Functionalities.DefineBookableDaysHours]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rules-and-preferences-waiting-list/:playingMode",
		name: settingsPlayingModeDetails,
		component: () => import("@app-views/settings/reservations/rules-and-preferences-waiting-list/playing-mode/playing-mode.vue"),
		meta: {
			childOf: settingsReservationsRulesPreferencesWaitingList,
			showIf: [Functionalities.KioskBowlingWaitingList, Functionalities.DefineKioskWaitingList],
			accessIf: [Functionalities.DefineRulesAndPreferences]
		}
	}
] as QRoute[];
