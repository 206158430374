<template>
	<b-card class="access-denied text-center">
		<template #header>
			<CardHeader :title="$t(titleKey)" />
		</template>
		<p>{{ $t(subtitleKey) }}</p>
		<p
			v-if="hintTextKey"
			class="mt-3"
		>
			{{ $t(hintTextKey) }}
		</p>
		<q-button
			v-if="showBackButton"
			:variant="backButtonVariant"
			class="cancel mt-4 d-inline d-lg-none"
			size="sm"
			@click="handleBackButton()"
		>
			<i
				class="far fa-fw fa-angle-left"
				aria-hidden="true"
			/> {{ $t("label_go_back") }}
		</q-button>
	</b-card>
</template>
<script lang="ts" src="./access-denied.ts"></script>
