/* eslint-disable simple-import-sort/imports */
import store from "@app-store/index";
import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { flow, slice, uniq } from "lodash-es";

import { getAppStorage, writeAppStorage } from "@app-utils/app-module-storage";
import useLangStore from "@app-store/lang";

import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";

import RestSystemFactory from "@app-rest/system";
import RestCompanyFactory from "@app-rest/company";
import RestUserFactory from "@app-rest/user";
import type { IUserInfo, IUserSettings } from "@app-rest/user.interfaces";
import type { ISystemBase } from "@app-rest/system/interfaces";
import type { CompanyUserRole, IFunctionalities } from "@app-rest/company/interfaces";

let _companyRestRest: RestCompanyFactory | null;
function ensureCompanyRestClient() {
	if (_companyRestRest) return _companyRestRest;
	const restClient = _companyRestRest = new RestCompanyFactory();
	return restClient;
}

let _systemRest: RestSystemFactory | null;
function ensureSystemRestClient() {
	if (_systemRest) return _systemRest;
	const restClient = _systemRest = new RestSystemFactory();
	return restClient;
}

let _userRest: RestUserFactory | null;
function ensureUserRestClient() {
	if (_userRest) return _userRest;
	const restClient = _userRest = new RestUserFactory();
	return restClient;
}

interface IUser {
	accountPageLink: string;
	info: IUserInfo;
	settings: IUserSettings;
	centerFunctionalities: string[];
	companyFunctionalities: string[];
	centers: ISystemBase[];
	systemIdsHistory: number[];
}
const userData = getAppStorage<object>("user") as IUser;
const appStore = useAppStore();

const systemsFunctionalities = new Map<number, IFunctionalities>();
const companiesFunctionalities = new Map<number, IFunctionalities>();

@Module class UserModuleFactory extends VuexModule {
	accountPageLink = "";
	info: IUserInfo | null = null;
	settings: IUserSettings | null = null;
	systems: ISystemBase[] = [];
	systemIdsHistory: number[] = userData?.systemIdsHistory ?? [];
	companyRole: CompanyUserRole | null = null;
	loadingCompanyFunctionalities = false;
	loadingSystemFunctionalities = false;
	get systemFunctionalities() {
		return (id?: number) => {
			if (!id) id = useSystemStore().info?.Id;
			if (!id) return;
			return systemsFunctionalities.get(id);
		};
	}

	get companyFunctionalities() {
		return (id?: number) => {
			if (!id) id = useCompanyStore().company?.Id;
			if (!id) return;
			return companiesFunctionalities.get(id);
		};
	}

	get isAuthenticated() {
		return this.info ? Boolean(this.info.Email) : null;
	}

	get isStaffUser() {
		return Boolean(this.info && this.info.Email && (this.info.Email as string).toLowerCase().indexOf("@qubicaamf.") > 0);
	}

	get accountUri() {
		return `${this.accountPageLink}?lang=${useLangStore().isoCode}`;
	}

	private writeData(userStore: any) {
		const store = getAppStorage();
		store.user = userStore;
		writeAppStorage(store);
	}

	@Mutation setSystemIdsHistory(ids: number[]) {
		this.systemIdsHistory = ids;
		const env = appStore.env;
		this.writeData({ env, ids });
	}

	@Mutation setAccountPageLink(pageLink: string) {
		this.accountPageLink = pageLink;
	}

	@Mutation setInfo(ui: IUserInfo) {
		this.info = ui;
	}

	@Mutation setSettings(us: IUserSettings) {
		this.settings = us;
	}

	@Mutation setSystems(systems: ISystemBase[]) {
		this.systems = systems;
	}

	@Mutation setLoadingCompanyFunctionalities(state: boolean) {
		this.loadingCompanyFunctionalities = state;
	}

	@Mutation setLoadingSystemFunctionalities(state: boolean) {
		this.loadingSystemFunctionalities = state;
	}

	@Mutation setCompanyRole(state: CompanyUserRole | null) {
		this.companyRole = state;
	}

	@Action addSystemIdToHistory(id: number) {
		if (!id) return;
		if (typeof id !== "number") {
			id = +id;
			if (isNaN(id)) return;
		}
		const history = (this.systemIdsHistory as number[]);
		const fixedHistory = flow(
			(centerIds: number[]) => {
				centerIds.unshift(id);
				return centerIds;
			},
			uniq,
			(centerIds: number[]) => slice(centerIds, 0, 4)
		)(history) as number[];
		this.setSystemIdsHistory(fixedHistory);
	}

	@Action async getInfo() {
		const response = await ensureUserRestClient().info();
		if (response.data) {
			this.setAccountPageLink(response.data.AccountPageLink);
			this.setInfo(response.data.Info);
		}
		return response;
	}

	@Action async syncSettings() {
		const response = await ensureUserRestClient().settings();
		if (response.data)
			this.setSettings(response.data);
		return response;
	}

	@Action updateSettings(userData: IUserSettings) {
		if (!userData) return;
		return ensureUserRestClient().settingsUpdate(userData);
	}

	@Action async ensureSystems() {
		if (this.systems && this.systems.length) return;
		const response = await ensureSystemRestClient().list();
		this.setSystems(response.data ?? []);
	}

	// #region sync functionalities
	// FIXME: used only in payments > web providers, waiting to be implemented via socket
	@Action async syncSystemFunctionalities(systemId: number) {
		if (typeof systemId !== "number") {
			systemId = +systemId;
			if (isNaN(systemId)) throw new Error("SystemId is not a number - syncSystemFunctionalities userStore");
		}
		this.setLoadingSystemFunctionalities(true);
		const response = await ensureSystemRestClient().functionalities(systemId);
		if (response.data)
			systemsFunctionalities.set(+systemId, response.data);
		this.setLoadingSystemFunctionalities(false);
	}

	@Action async syncCompanyFunctionalities(companyId: number) {
		if (typeof companyId !== "number") {
			companyId = +companyId;
			if (isNaN(companyId)) throw new Error("CompanyId is not a number - syncCompanyFunctionalities userStore");
		}
		this.setLoadingCompanyFunctionalities(true);
		const response = await ensureCompanyRestClient().functionalities(companyId);
		if (response.data)
			companiesFunctionalities.set(+companyId, response.data);
		const role = (response.headers?.get("X-Company-Role") as CompanyUserRole | undefined) ?? null;
		this.setCompanyRole(role);
		this.setLoadingCompanyFunctionalities(false);
	}
	// #endregion

	@Action async ensureSystemFunctionalities(systemId: number) {
		if (typeof systemId !== "number") {
			systemId = +systemId;
			if (isNaN(systemId)) throw new Error("SystemId is not a number - ensureSystemFunctionalities userStore");
		}
		this.setLoadingSystemFunctionalities(true);
		const functionalities = this.systemFunctionalities(systemId);
		if (!functionalities) {
			const response = await ensureSystemRestClient().functionalities(systemId);
			if (response.data)
				systemsFunctionalities.set(+systemId, response.data);
		}
		this.setLoadingSystemFunctionalities(false);
	}

	@Action async ensureCompanyFunctionalities(companyId: number) {
		if (typeof companyId !== "number") {
			companyId = +companyId;
			if (isNaN(companyId)) throw new Error("CompanyId is not a number - ensureCompanyFunctionalities userStore");
		}
		this.setLoadingCompanyFunctionalities(true);
		const functionalities = this.companyFunctionalities(companyId);
		if (!functionalities) {
			const response = await ensureCompanyRestClient().functionalities(companyId);
			if (response.data)
				companiesFunctionalities.set(+companyId, response.data);
			const role = (response.headers?.get("X-Company-Role") as CompanyUserRole | undefined) ?? null;
			this.setCompanyRole(role);
		}
		this.setLoadingCompanyFunctionalities(false);
	}

	@Action getToken() {
		const response = ensureUserRestClient().getToken();
		return response;
	}

	@Action getClaims() {
		const response = ensureUserRestClient().getClaims();
		return response;
	}

	@Action refreshToken() {
		const response = ensureUserRestClient().refreshToken();
		return response;
	}

	@Action getActivationUserData(data: { companyId: number, userId: number, activationCode: string }) {
		const response = ensureUserRestClient().activationUser(data.companyId, data.userId, data.activationCode);
		return response;
	}

	@Action finalizeActivationUserData(data: { companyId: number, userId: number, activationCode: string }) {
		const response = ensureUserRestClient().finalizeActivationUser(data.companyId, data.userId, data.activationCode);
		return response;
	}
}

const moduleName = "user";
let UserStore: UserModuleFactory | null;
function useUserStore() {
	if (UserStore) return UserStore;
	const mod = UserStore = new UserModuleFactory({ name: moduleName, store });
	return mod;
}

export default useUserStore;

const env = appStore.env;
const localAppData = getAppStorage("user");
if (localAppData && localAppData.ids && localAppData.env === env)
	useUserStore().setSystemIdsHistory(localAppData.ids);
