const HANDLER = "_vue_clickaway_handler";

function onmounted(el) {
	if (!el) return;
	document.documentElement.removeEventListener("click", el[HANDLER], false);
	delete el[HANDLER];
}

function mounted(el, binding) {
	onmounted(el);

	const callback = binding.value;
	let initialMacrotaskEnded = false;
	setTimeout(function() {
		initialMacrotaskEnded = true;
	}, 0);

	el[HANDLER] = function(ev) {
		if (initialMacrotaskEnded && !el.contains(ev.target))
			return callback(ev);
	};

	document.documentElement.addEventListener("click", el[HANDLER], false);
}

export default {
	mounted,
	onmounted,
	updated(el, binding) {
		if (binding.value === binding.oldValue) return;
		mounted(el, binding);
	}
};
