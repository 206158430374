import { Functionalities } from "@app-rest/company/interfaces";
import Router, { type QRoute } from "@app-router/.";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";

export default [
	{
		path: "/companies/:companyId(\\d+)",
		name: "company",
		beforeEnter: (to) => {
			const Company = useCompanyStore().company;
			const centerId
				= Company && Company.Centers && Company.Centers.length
					? useSystemStore().info?.Id
					: null;
			if (centerId) {
				Router.push({
					name: "center-home",
					params: { centerId: centerId + "" }
				});
			} else Router.push({ name: "select-center", params: to.params });
		},
		meta: {
			title: "Company"
		}
	},
	{
		path: "/companies/:companyId(\\d+)/users",
		name: "company-users",
		component: () => import("@app-views/companies/users/users.vue"),
		meta: {
			titleKey: "label_users",
			childOf: "company",
			accessIf: [Functionalities.AccessToUserSetup],
			helpSiteAnchor: "management-configurations/users"
		}
	},
	{
		path: "/companies/:companyId(\\d+)/users/:userId",
		name: "company-user-card",
		component: () => import("@app-views/companies/user-card/user-card.vue"),
		meta: {
			childOf: "company-users",
			helpSiteAnchor: "management-configurations/users"
		}
	},
	{
		path: "/public/user-activations/:companyId(\\d+)/:userId/:activationCode",
		name: "company-user-activation",
		component: () =>
			import("@app-views/companies/user-activation/user-activation.vue"),
		meta: {
			titleKey: "cua_account_route_title",
			allowAnonymous: true
		}
	},
	{
		path: "/companies/:companyId(\\d+)/roles-privileges",
		name: "roles-privileges",
		component: () =>
			import("@app-views/companies/role-setup/role-setup.vue"),
		meta: {
			titleKey: "rolesPrivilegesTitle",
			childOf: "company",
			accessIf: [Functionalities.AccessToRolesAndPrivileges],
			helpSiteAnchor: "management-configurations/roles-privileges"
		}
	},
	{
		path: "/companies/:companyId(\\d+)/eula",
		name: "eula",
		component: () => import("@app-views/eula/eula.vue"),
		meta: {
			titleKey: "termsTitle",
			accessIf: [Functionalities.AccessToTermsAndConditions]
		}
	}
] as QRoute[];
