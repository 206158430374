import { useRouteUtilities } from "@app-composables/route-utilities";
import { useSecurityUtilities } from "@app-composables/security";
import usei18n from "@app-i18n/index";
import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";
import useAppStore from "@app-store/app";
import useGlobalStore from "@app-store/global";
import useUserStore from "@app-store/user";
import EventsManager from "@app-utils/events-manager";
import { QLink, QNavbar } from "@qamf/lighthouse";
import { computed, defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import Sidebar from "./sidebar/sidebar.vue";

export default defineComponent({
	name: "Navbar",
	components: {
		QLink,
		QNavbar,
		Sidebar
	},
	props: {},
	setup() {
		const appStore = useAppStore();
		const globalStore = useGlobalStore();
		const userStore = useUserStore();
		const router = useRouter();
		const Routes = computed(() => globalStore.routes);
		const IsStaffUser = computed(() => userStore.isStaffUser);
		const userIsAuthenticated = computed(() => userStore.isAuthenticated);
		const userAccountUri = computed(() => userStore.accountUri);
		const userData = computed(() => userStore.info);
		const UserSettings = computed(() => userStore.settings);
		const env = computed(() => appStore.env);
		const version = computed(() => appStore.version);
		const ch = computed(() => appStore.channel);
		const routeData = computed(() => router.currentRoute.value.matched[0]);
		const canManageRemoteAssistance = computed(() => useSecurityUtilities().hasFunctionalitiesEnabled([Functionalities.ManageRemoteAssistance]));
		const showActions = ref(false);
		const { routeName, routeParams } = useRouteUtilities();
		const helpLinkClick = () => {
			EventsManager.emitHelpLinkClicked();
		};
		const getTitle = (route: QRoute) => {
			return route.meta.title || usei18n().getTranslatedRouteTitle(route);
		};
		const language = computed(() => UserSettings.value?.Language);
		const showAppInfo = computed(() => env.value === "Staging" || env.value === "Develop");
		const breadcrumb = computed(() => {
			if (!routeData.value || !routeData.value.meta || !Routes.value) return [];
			const bc: any[] = [];
			let route = routeData.value;
			let father;
			do {
				bc.unshift(route);
				father = Routes.value.find(
					(r) =>
						route.meta && route.meta.childOf && r.name === route.meta.childOf
				);
				if (father) route = father;
			} while (father);
			return bc;
		});
		const showActionsTooltip = (state: boolean) => {
			showActions.value = state;
		};
		const breadcrumbRoot = computed(() => breadcrumb.value[0]);
		const breadcrumbNoRoot = computed(() => breadcrumb.value.slice(1));
		return {
			showAppInfo,
			env,
			version,
			ch,
			userIsAuthenticated,
			Routes,
			IsStaffUser,
			userAccountUri,
			userData,
			UserSettings,
			routeData,
			showActions,
			language,
			breadcrumb,
			breadcrumbRoot,
			breadcrumbNoRoot,
			routeName,
			routeParams,
			canManageRemoteAssistance,
			helpLinkClick,
			getTitle,
			showActionsTooltip
		};
	}
});
