import type { IResponseError } from "@app-rest/common.interfaces";
import type { IFunctionalities } from "@app-rest/company/interfaces";
import PortalRestClientFactory from "@app-rest/index";

import type { ICurrencyDefinitionModel, ISystemBase, ISystemUploadPolicy } from "./interfaces";

export default class RestSystemFactory extends PortalRestClientFactory {
	list() {
		return this.get<ISystemBase[]>("/centers");
	}

	center(systemId: number) {
		return this.get<ISystemBase, IResponseError<string>>(`/centers/${systemId}`, {
			// FIXME: Check throwExcluding without Error Codes - TechStory 42933
			throwExcluding: [
				{ statusCode: 401 },
				{ statusCode: 404 }
			]
		});
	}

	uploadPolicy(systemId: number) {
		return this.get<ISystemUploadPolicy>(`/centers/${systemId}/policy`);
	}

	uploadPolicySave(systemId: number, model: ISystemUploadPolicy) {
		return this.put<ISystemUploadPolicy>(`/centers/${systemId}/policy`, { body: model });
	}

	mainCurrency(systemId: number) {
		return this.get<ICurrencyDefinitionModel, IResponseError<string>>(`/${systemId}/main-currency`, {
			// FIXME: Check throwExcluding without Error Codes - TechStory 42933
			throwExcluding: [
				{ statusCode: 404 }
			]
		});
	}

	functionalities(systemId: number) {
		return this.get<IFunctionalities>(`/${systemId}/functionalities`);
	}
}
