import type { IResponseError } from "@app-rest/common.interfaces";
import PortalRestClientFactory from "@app-rest/index";
import { dateTimeKey } from "@app-utils/dates";

import type { IReservation, IReservationAvailabilityPayload, IReservationParameters, IReservationParams, IReservationType } from "./reservations.interfaces";

const ReservationsAllErrors = ["QResourceNotAvailable", "ConquerorOffline"] as const;
export type ReservationsErrorCodes = typeof ReservationsAllErrors[number];

export class RestReservations extends PortalRestClientFactory {
	constructor(systemId?: number) {
		super();
		this.options.set("basePath", `/api/${systemId}`);
	}

	paramsToQs(params: IReservationParams) {
		const qs = new URLSearchParams();
		qs.append("dateFrom", dateTimeKey(params.dateFrom));
		qs.append("dateTo", dateTimeKey(params.dateTo));
		if (params.resourceClass)
			params.resourceClass.forEach(v => qs.append("resourceClass", v));
		if (params.status)
			params.status.forEach(v => qs.append("status", v));
		return qs;
	}

	parameters() {
		return this.get<IReservationParameters, IResponseError<string>>("/reservation-parameters", {
			// FIXME: Check throwExcluding without Error Codes - TechStory 42933
			throwExcluding: [{ statusCode: 404 }]
		});
	}

	list(params: IReservationParams) {
		const qs = this.paramsToQs(params);
		return this.get<IReservation[]>("/reservations?" + qs.toString());
	}

	reservationTypes() {
		return this.get<IReservationType[]>("/reservation-types");
	}

	resourceTypes() {
		return this.get<any>("/resource-types");
	}

	bookServiceReservation(data: IReservationAvailabilityPayload) {
		return this.post<any, IResponseError<ReservationsErrorCodes>>("/reservations/service-request", {
			body: data,
			throwExcluding: [error => {
				return ((error.statusCode === 409 || error.statusCode === 503)
					&& typeof error.data?.Error.Code === "string"
					&& ReservationsAllErrors.includes(error.data.Error.Code));
			}]
		});
	}

	bookTemporaryServiceReservation(data: IReservationAvailabilityPayload) {
		return this.post<any, IResponseError<ReservationsErrorCodes>>("/reservations/temporary-service-request", {
			body: data,
			throwExcluding: [error => {
				return ((error.statusCode === 409 || error.statusCode === 503)
					&& typeof error.data?.Error.Code === "string"
					&& ReservationsAllErrors.includes(error.data.Error.Code));
			}]
		});
	}

	confirmTemporaryReservation(resId: string) {
		return this.put<any>(`/reservations/${resId}`, {
			body: { Status: "Confirmed" }
		});
	}

	rejectTemporaryReservation(resId: string) {
		return this.delete<any>(`/reservations/${resId}`);
	}
}
