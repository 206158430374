import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/.";
import useCompanyStore from "@app-store/company";

import { settingsAdminSettings } from ".";

export const privacyAndTerms = "privacy-and-terms";
export const termsOfUse = "terms-of-use";
export const privacyPolicy = "privacy-policy";
export const privacyPolicyAllCenters = "privacy-policy-all-centers";
export const termsConditionsWebBook = "terms-and-conditions-web-booking";
export const termsConditionsKioskPlayNow = "terms-and-conditions-kiosk-play-now";

export type policyTermType = typeof privacyAndTerms
	| typeof termsOfUse
	| typeof privacyPolicy
	| typeof privacyPolicyAllCenters
	| typeof termsConditionsWebBook
	| typeof termsConditionsKioskPlayNow;

export default [
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/privacy-and-terms",
		name: privacyAndTerms,
		component: () => import("@app-views/settings/administrative-settings/privacy-and-terms/privacy-and-terms.vue"),
		meta: {
			titleKey: "settings_privacy_terms",
			childOf: settingsAdminSettings,
			groupName: "privacy",
			accessIf: [Functionalities.DefinePrivacyAndTerms]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/privacy-and-terms/privacy-policy",
		name: privacyPolicy,
		component: () => import("@app-views/settings/administrative-settings/privacy-and-terms/policy-term/policy-term.vue"),
		meta: {
			titleKey: "settings_privacy_terms_privacy_policy",
			childOf: privacyAndTerms,
			showIf: () => !useCompanyStore().isMultiSystem
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/privacy-and-terms/privacy-policy",
		name: privacyPolicyAllCenters,
		component: () => import("@app-views/settings/administrative-settings/privacy-and-terms/policy-term/policy-term.vue"),
		meta: {
			titleKey: "settings_privacy_terms_privacy_policy_all_centers",
			childOf: privacyAndTerms,
			showIf: () => useCompanyStore().isMultiSystem
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/privacy-and-terms/terms-of-use",
		name: termsOfUse,
		component: () => import("@app-views/settings/administrative-settings/privacy-and-terms/policy-term/policy-term.vue"),
		meta: {
			titleKey: "settings_privacy_terms_terms_of_use",
			childOf: privacyAndTerms,
			showIf: [Functionalities.WebReservations]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/privacy-and-terms/terms-and-conditions-web-booking",
		name: termsConditionsWebBook,
		component: () => import("@app-views/settings/administrative-settings/privacy-and-terms/policy-term/policy-term.vue"),
		meta: {
			titleKey: "settings_privacy_terms_terms_conditions_book_for_later",
			childOf: privacyAndTerms,
			showIf: [Functionalities.WebReservations]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/privacy-and-terms/terms-and-conditions-kiosk-play-now",
		name: termsConditionsKioskPlayNow,
		component: () => import("@app-views/settings/administrative-settings/privacy-and-terms/policy-term/policy-term.vue"),
		meta: {
			titleKey: "settings_privacy_terms_terms_conditions_play_now",
			childOf: privacyAndTerms,
			showIf: [Functionalities.Kiosks]
		}
	}
] as QRoute[];
