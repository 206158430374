import Router from "@app-router/index";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import useUserStore from "@app-store/user";
import { ApplicationInsights, IConfig, IConfiguration, ITraceTelemetry } from "@microsoft/applicationinsights-web";
import { RestError } from "scarlett";
import { ref } from "vue";

import { IErrorEventHandler } from "./events.definitions";

export type TrackedException = {
	exception: Error | DOMException;
	properties: Record<string, any>;
}

export enum EventAI {
	// Technical Events
	AppReady = "ready",
	FatalError = "fatal-error",
	CenterChanged = "center-changed",
	GoToHelpSite = "help-site",
	StartRemoteAssistance = "remote-assistance-start",
	StopRemoteAssistance = "remote-assistance-stop",
	// Menu Order Updates
	UpdateOrderMenu = "update-order-menu",
	FnBUpdatesDeleteOrder = "fnb-delete-order",
	FnBUpdatesBackFromOrderDetail = "fnb-back-from-order-detail",
	FnBUpdatesSaveOrderDetail = "fnb-save-order-detail",
	FnBUpdatesRestoreOrderDetail = "fnb-restore-order-detail",
	// Section: Dashboards
	BusinessResultsPrint = "business-results-print-dashboard",
	GameStatsExportExcel = "game-statistics-export-excel",
	GameStatsPrint = "game-statistics-print-dashboard",
	PerformanceMonitoringExportExcel = "performance-monitoring-export-excel",
	PerformanceMonitoringPrint = "performance-monitoring-print-dashboard",
	TipsExportExcel = "tips-reporting-export-excel",
	TipsPrint = "tips-reporting-print-dashboard",
	BumperStatsPrint = "bumper-statistics-print-dashboard",
	// Section: Kiosk Utilities
	KioskUtilsWakeUp = "kiosks-wake-up",
	KioskUtilsShutDown = "kiosks-shut-down",
	KioskUtilsRestart = "kiosks-restart",
	// Section: Cloud data
	CloudDataSelection = "cloud-upload-data-updated",
	// Section: Program & Licenses
		// core
		CmpExtendErrorPriceTemporaryInvalid = "cmp-extend-error-price-temporary-invalid",
		CmpExtendErrorCannotProceedToPayment = "cmp-extend-error-cannot-proceed-to-payment",
		CmpLoadErrorCannotGetSystemData = "cmp-payment-load-error-cannot-get-system-data",
		CmpLoadErrorCannotGetCompanySystemData = "cmp-payment-load-error-cannot-get-company-system-data",
		CmpPaymentLoadErrorCannotProceedToPayment = "cmp-payment-load-error-cannot-proceed-to-payment",
		CmpPaymentLoadErrorCannotGetPaymentData = "cmp-payment-load-error-cannot-get-payment-data",
		CmpPaymentLoadErrorUnsupportedCurrency = "cmp-payment-load-error-wrong-currency",
		CmpPaymentLoadErrorNoValidPrices = "cmp-payment-load-no-valid-prices",
		CmpPaymentLoadErrorPriceTemporaryInvalid = "cmp-payment-load-error-price-temporary-invalid",
		CmpExtendPayNow = "cmp-extend-pay",
		CmpExtendPayNowSubscription = "cmp-extend-pay-subscription",
		CmpPaymentFlowCreate = "cmp-payment-flow-create",
		CmpPaymentPopupBlocked = "cmp-payment-popup-blocked",
		CmpPaymentSuccess = "cmp-license-extended",
		CmpPaymentError = "cmp-payment-error",
		CmpPaymentErrorPayPal = "cmp-payment-error-paypal",
		CmpPaymentErrorPriceNotAvailable = "cmp-payment-error-price-not-available",
		// requests
		CmpRequestExtendLicense = "cmp-request-extend-license",
		CmpRequestDisableAutomaticRenewal = "cmp-request-disable-automatic-renewal",
		CmpRequestDisableService = "cmp-request-disable-service",
		CmpReactivateAutomaticRenewal = "cmp-reactivation-automatic-renewal",
		CmpReactivateService = "cmp-reactivation-service",
		// extra
		CmpAskQuestion = "cmp-ask-question-sent",
		CmpInvoiceError = "cmp-invoice-error",
		CmpInvoiceDownloaded = "cmp-invoice-downloaded",
	// Section: Roles & Privileges
	UsersRoleCreated = "role-created",
	UsersRoleDeleted = "role-deleted",
	UsersRoleEdited = "role-edited",
	// Section: Users
	UsersInvitation = "user-invitation-sent",
	// Section: Online backup
	BackupDownloadStarted = "backup-download-started",
	BackupDownloadFinished = "backup-download-finished",
	// Section: Settings
	WebOfferCreated = "web-offer-created",
	WebOfferCoreSettingsEdited = "web-offer-core-settings-edited",
	WebOfferDeleted = "web-offer-deleted",
	WebOfferPriceKeyEdited = "web-offer-pricekey-edited",
	WebOfferDuplicated = "web-offer-duplicated",
	// Section: Terminals
	TerminalsPairAdded = "terminals-pair-added",
	TerminalsPairDeleted = "terminals-pair-deleted",
	TerminalsPairInit = "terminal-pair-init",
	TerminalsPairSuccess = "terminals-pair-success",
	TerminalsPairInvalidCode = "terminals-pair-invalid-code",
	TerminalsPairExpiredCode = "terminals-pair-expired-code",
	// Section: Square Authorization
	ExternalPosAuthorizationConnect = "external-pos-authorization-connect",
	ExternalPosAuthorizationRevoke = "external-pos-authorization-revoke",
	ExternalPosAuthorizationDeletePairing = "external-pos-authorization-delete-pairing",
	ExternalPosAuthorizationSavePairing = "external-pos-authorization-save-pairing",
	ExternalPosAuthorizationTokenSaved = "external-pos-authorization-token-saved",
	ExternalPosAuthorizationTokenError = "external-pos-authorization-token-error",
	// Section: Square Integration
	SquareIntegrationTutorialSkip = "square-integration-tutorial-skip",
	SquareIntegrationTutorialDismiss = "square-integration-tutorial-dismiss",
	SquareIntegrationStarted = "square-integration-started",
	SquareIntegrationProgress = "square-integration-progress",
	SquareIntegrationFinished = "square-integration-finished",
	SquareIntegrationPageAbandoned = "square-integration-page-abandoned",
	SquareIntegrationSynchronizationFailed = "square-integration-synchronization-failed",
	SquareIntegrationRequirementsFailed = "square-integration-requirements-failed"
}

let AppInsights: ApplicationInsights | undefined;

export function setupAppInsights(config: IConfiguration & IConfig) {
	if (AppInsights) return;
	AppInsights = new ApplicationInsights({ config });
	AppInsights.loadAppInsights();
}
export function syncTrackingUser() {
	const appStore = useAppStore();
	if (!AppInsights) return;

	if (appStore.currentUser)
		AppInsights.setAuthenticatedUserContext(appStore.currentUser, undefined, true);
	else
		AppInsights.clearAuthenticatedUserContext();
}
export function trackView(name: string, action: "start" | "end", path: string) {
	if (!AppInsights) return;

	switch (action) {
		case "start": AppInsights.startTrackPage(name); break;
		case "end": AppInsights.stopTrackPage(name, path); break;
	}
}
export function trackTrace(trace: ITraceTelemetry, extraProps?: Record<string, any>, flush = false) {
	if (!AppInsights) return;
	const properties = Object.assign(
		{},
		extraProps ?? {}
	);
	AppInsights.trackTrace({ ...trace, properties });
	if (flush) AppInsights.flush();
}
export async function trackUserEvent(name: EventAI, extraProps?: Record<string, any>) {
	if (!AppInsights) return;
	const userStore = useUserStore();
	const companyStore = useCompanyStore();
	const systemStore = useSystemStore();
	const companyFunctionalities = userStore.companyFunctionalities();
	const systemFunctionalities = userStore.systemFunctionalities();
	const router = ref(Router);
	const route = router.value.currentRoute;
	const properties = Object.assign(
		{},
		extraProps || {},
		userStore && userStore.info ? userStore.info : {},
		userStore && userStore.settings ? userStore.settings : {},
		{ Language: useLangStore().isoCode },
		{
			path: route.fullPath,
			routeName: route.name
		},
		{
			companyId: companyStore.company?.Id ?? 0,
			systemId: systemStore.info?.Id ?? 0
		},
		{
			...(companyFunctionalities && { companyFunctionalities }),
			...(systemFunctionalities && { systemFunctionalities })
		}
	);
	AppInsights.trackEvent({ name, properties });
}
export async function trackException(error: Parameters<IErrorEventHandler>[0], extras?: Parameters<IErrorEventHandler>[1]): Promise<TrackedException | undefined> {
	if (!AppInsights) return;

	const tracked: TrackedException = { exception: error, properties: (extras ?? {}) };

	if (!extras)
		extras = {};

	if (tracked.exception instanceof RestError) {
		let rawBody = "";
		try {
			rawBody = await tracked.exception.fetchResponse?.text() ?? "";
		} catch (e) {}

		extras.instanceOf = "RestError";
		const userStore = useUserStore();
		const api = {
			url: tracked.exception.request?.url.toString(),
			method: tracked.exception.request?.method,
			params: tracked.exception.request?.options.query,
			payload: tracked.exception.request?.body,
			headers_request: tracked.exception.request?.options.headers,
			headers_response: tracked.exception.fetchResponse?.headers,
			status_code: tracked.exception.statusCode,
			error_code: tracked.exception.code,
			response: tracked.exception.data,
			rawBody
		};
		tracked.properties = { api, extras, instanceOf: "RestError", user: userStore.info };
		const isTimeout = (tracked.exception.statusCode + "").toLowerCase() === "timeout";
		if (tracked.exception.statusCode && tracked.exception.request?.method && (isTimeout || !isNaN(+tracked.exception.statusCode)))
			tracked.exception.message = `[${tracked.exception.request.method.toUpperCase()}-${api.status_code}] ${tracked.exception.request?.url.pathname}`;
	} else if (error) {
		tracked.properties.code = error.name;
		const exceptionTypes = [
			DOMException,
			EvalError,
			RangeError,
			ReferenceError,
			SyntaxError,
			TypeError,
			URIError,
			AggregateError
		];
		for (let i = 0; i < exceptionTypes.length; i++) {
			if (error instanceof exceptionTypes[i]) {
				tracked.properties.instanceOf = exceptionTypes[i].name;
				break;
			}
		}
	}
	AppInsights.trackException(tracked);
	AppInsights.flush();
	return tracked;
}
