<template>
	<div
		class="view-wrapper"
		:class="{ 'access-denied': !helpers.isAuthorized }"
	>
		<QSpinner v-if="loadingFunctionalities || loading" />
		<AccessDenied v-else-if="helpers.isAuthorized === false" />
		<slot v-else-if="helpers.isAuthorized" />
	</div>
</template>

<script lang="ts" src="./view-wrapper.ts"></script>
