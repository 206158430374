<template>
	<div class="settings-card-header">
		<i
			v-if="showBackArrow"
			class="far fa-arrow-left-long back-arrow"
			data-test="button-back"
			@click="handleBackArrow"
		/>
		<b-card-title title-tag="h3">
			{{ title }}
		</b-card-title>
		<b-card-subtitle>
			{{ subtitle }}
		</b-card-subtitle>
		<slot />
	</div>
</template>

<script lang="ts" src="./settings-card-header.ts"></script>
<style lang="scss" src="./settings-card-header.scss"></style>
