import usei18n from "@app-i18n/index";
import useLangStore from "@app-store/lang";
import useUserStore from "@app-store/user";
import EventsManager from "@app-utils/events-manager";
import { QFormFieldRadio, QModal } from "@qamf/lighthouse";
import { computed, defineComponent, ref } from "vue";

interface ILanguagesModalOption {
	text: string;
	value: boolean;
}

export default defineComponent({
	name: "ModalPreferences",
	components: {
		QModal,
		QFormFieldRadio
	},
	props: {
		id: { required: true, type: String }
	},
	setup() {
		const userStore = useUserStore();
		const { translateKey } = usei18n();
		const modalState = ref(false);
		const languages = computed(() => {
			const langStore = useLangStore();
			if (!langStore.languages) return;
			const langSorted = Object.keys(langStore.languages).map((k) => {
				return { code: k, label: langStore.languages[k].label };
			});
			return langSorted.sort((a, b) =>
				a.label > b.label ? 1 : b.label > a.label ? -1 : 0
			);
		});
		const userData = computed(() => {
			return userStore.info;
		});
		const userSettings = computed(() => {
			return userStore.settings;
		});
		const modalUserSettings = ref<InstanceType<typeof QModal>>();
		const showLanguagesList = ref(false);
		const selectedLang = ref("");
		const isLoading = ref(false);
		const showLanguagesOptions = ref<ILanguagesModalOption[]>([
			{ text: translateKey("com_use_center_lang"), value: false },
			{ text: translateKey("com_use_different_lang"), value: true }
		]);
		const language = computed(() => {
			return userSettings.value?.Language ? userSettings.value.Language : "";
		});
		const selectLang = (lang: string) => {
			selectedLang.value = lang;
		};
		const scrollActive = (e) => {
			if (showLanguagesList.value) {
				const wrapElement = e.target.querySelector(".wrap-languages");
				const activeElement = wrapElement.querySelector(".active");
				(wrapElement as Element).scrollTop = activeElement.offsetTop;
			}
			isLoading.value = false;
		};
		const setModalState = (state: boolean) => {
			modalState.value = state;
		};
		const saveLanguage = async() => {
			if (!userData.value) {
				EventsManager.emitError(new TypeError(), { message: "Modal preferences: userData is null or undefined" });
				return;
			}
			if (isLoading.value) return;
			isLoading.value = true;
			const userDataLanguage = {
				Language: showLanguagesList.value ? selectedLang.value : "",
				Username: userData.value.Username
			};
			await userStore.updateSettings(userDataLanguage);
			isLoading.value = false;
			setModalState(false);
			location.reload();
		};
		const onModalOpen = () => {
			isLoading.value = true;
			selectedLang.value = language.value;
			showLanguagesList.value = !!selectedLang.value;
			isLoading.value = false;
			setModalState(true);
		};
		return {
			languages,
			showLanguagesList,
			selectedLang,
			isLoading,
			showLanguagesOptions,
			modalState,
			modalUserSettings,
			selectLang,
			scrollActive,
			saveLanguage,
			onModalOpen
		};
	}
});
