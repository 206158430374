<template>
	<QModal
		:id="id"
		v-model="modalState"
		size="lg"
		centered
		hide-footer
		no-close-on-backdrop
		modal-class="modal-remote-assistance"
		:scrollable="false"
		:title="$t('rmt_ass_title')"
		:hide-header-close="isLoading"
		@show="onShow"
	>
		<QSpinner
			v-if="isLoading"
		/>

		<!-- OFF support -->
		<template v-else-if="!supportStatus">
			<q-row class="wrap-description">
				<q-col cols="12">
					<p>{{ $t('rmt_ass_off_enable_assistance') }}</p>
					<p>{{ $t('rmt_ass_off_proceed_you_need_remote_assistance') }}</p>
					<br>
					<p>{{ $t('rmt_ass_off_notes') }}</p>
					<ul class="my-3 mx-auto ps-4">
						<li>{{ $t('rmt_ass_off_notes_1') }}</li>
						<li>{{ $t('rmt_ass_off_notes_2') }}</li>
						<li>{{ $t('rmt_ass_off_notes_3') }}</li>
					</ul>
				</q-col>
			</q-row>
			<q-row class="footer-buttons mt-4">
				<q-col
					sm="6"
					cols="12"
				>
					<q-button
						variant="outline-secondary"
						@click="setModalState(false)"
					>
						{{ $t("button_cancel") }}
					</q-button>
				</q-col>
				<q-col
					sm="6"
					cols="12"
					class="text-sm-end"
				>
					<q-button
						:disabled="isLoading"
						@click="turnOn"
					>
						{{ $t("button_authorize") }}
					</q-button>
				</q-col>
			</q-row>
		</template>

		<!-- ON support -->
		<template v-else-if="supportStatus">
			<div class="wrap-header">
				<p>{{ $t("rmt_ass_qamf_canview_notmodify") }}</p>
				<p>{{ $t("rmt_ass_qamf_permissions_revoke") }}</p>
			</div>
			<legend class="col-form-label mt-4 mb-2">
				{{ $t('label_expire_permission') }}
			</legend>
			<q-row>
				<q-col
					sm="5"
					cols="12"
				>
					<QFormFieldDate
						id="supportExpireDate"
						v-model="helpers.supportEndsAt"
						name="supportExpireDate"
						show-controls-header
						format="PP"
						:min="helpers.supportMin"
						:max="helpers.supportMax"
						:disabled="!supportStatus"
						rules="required"
					/>
				</q-col>
				<q-col
					sm="5"
					cols="12"
				>
					<QFormFieldTime
						id="supportExpireTime"
						v-model="helpers.supportEndsAt"
						name="supportExpireTime"
						:disabled="!supportStatus"
						:rules="supportStatus ? 'required' : ''"
						:base-date="helpers.supportEndsAt"
						:min="supportMinTime"
						:max="supportMaxTime"
						:granularity="granularity"
						hide-validation
						max-date-included
					/>
				</q-col>
			</q-row>
			<q-row class="footer-buttons footer-on mt-4">
				<q-col
					sm="4"
					cols="12"
				>
					<q-button
						variant="outline-secondary"
						@click="setModalState(false)"
					>
						{{ $t("button_cancel") }}
					</q-button>
				</q-col>
				<q-col
					sm="8"
					cols="12"
					class="text-sm-end"
				>
					<q-button
						variant="outline-danger"
						:disabled="isLoading || !meta.valid"
						@click="save(false)"
					>
						{{ $t("button_revoke") }}
					</q-button>
					<q-button
						:disabled="isLoading || !meta.valid"
						class="ms-2"
						@click="save(true)"
					>
						{{ $t("button_save") }}
					</q-button>
				</q-col>
			</q-row>
		</template>
	</QModal>
</template>
<script lang="ts" src="./remote-assistance.ts"></script>
<style lang="scss" src="./remote-assistance.scss"></style>
