import useLangStore from "@app-store/lang";
import {
	addMinutes,
	endOfWeek as dateFnsEndOfWeek,
	format as dateFnsFormat,
	formatDuration as dateFnsFormatDuration,
	getMinutes,
	Locale,
	parse,
	startOfWeek as dateFnsStartOfWeek
} from "date-fns";

export interface IMonthOption {
	name: string
	value: number
}

export function stringToDateUTC(datetime: string) {
	function toGroups(matches: IterableIterator<RegExpMatchArray>) {
		let groups: string[] = [];
		for (const match of matches)
			groups = [...match];

		return groups;
	}
	function getMonthIndex(n: string) {
		const ni = +n;
		return ni ? ni - 1 : 0;
	}
	const regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})?/g;
	const groups = toGroups(datetime.matchAll(regex));
	if (groups.length) {
		const utc = new Date(
			+groups[1],
			getMonthIndex(groups[2]),
			+groups[3],
			+groups[4],
			+groups[5],
			+groups[6]
		);
		return utc;
	}
	const dateOnlyRegex = /(\d{4})-(\d{2})-(\d{2})?/g;
	const dateOnlyGroups = toGroups(datetime.matchAll(dateOnlyRegex));
	if (dateOnlyGroups.length) {
		const utc = new Date(
			+dateOnlyGroups[1],
			getMonthIndex(dateOnlyGroups[2]),
			+dateOnlyGroups[3]
		);
		return utc;
	}
}
export function format(date: Date, format: string) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dateFnsFormat(date, format, {
		locale: dictionary
	});
}
export function startOfWeek(date: Date) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dateFnsStartOfWeek(date, {
		locale: dictionary
	});
}
export function endOfWeek(date: Date) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dateFnsEndOfWeek(date, {
		locale: dictionary
	});
}
export function firstDayOfWeek(): number {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	return dictionary?.options?.weekStartsOn ?? 0;
}
export function stringToDate(datetime: string, referenceDate = new Date()): Date | undefined {
	const dateUtc = stringToDateUTC(datetime);
	if (dateUtc)
		return dateUtc;

	if (/(\d{2}):(\d{2})/g.test(datetime))
		return parse(datetime, "HH:mm", referenceDate);
}
export function formatDuration(duration: Duration, opt?: {
	format?: string[]
	zero?: boolean
	delimiter?: string
	locale?: Locale
}) {
	const dictionary: Locale | undefined = useLangStore().libDictionary.get("date-fns");
	const locale = opt?.locale ? opt?.locale : dictionary;
	const options = {
		...opt,
		locale
	};
	return dateFnsFormatDuration(duration, options);
}
export function formatSecondsToHHMMSS(seconds: number): string {
	const langStore = useLangStore();
	const timeSep = langStore.language?.timeSep;

	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	const formattedHours = hours.toString().padStart(2, "0");
	const formattedMinutes = minutes.toString().padStart(2, "0");
	const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

	return formattedHours + timeSep + formattedMinutes + timeSep + formattedSeconds;
}
export function formatSecondsToHHMM(seconds: number): string {
	const langStore = useLangStore();
	const timeSep = langStore.language?.timeSep;

	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);

	const formattedHours = hours.toString().padStart(2, "0");
	const formattedMinutes = minutes.toString().padStart(2, "0");

	return formattedHours + timeSep + formattedMinutes;
}
export function dateKey(date: Date) {
	return dateFnsFormat(date, "yyyy-MM-dd");
}
export function timeKey(date: Date) {
	return dateFnsFormat(date, "HH:mm");
}
export function timeKeyLocalized(date: Date) {
	const langStore = useLangStore();
	const is24h = langStore.language?.is24h;
	const minutesSep = langStore.language?.timeSep;
	const formatType = is24h
		? "H" + minutesSep + "mm"
		: "h" + minutesSep + "mm a";
	return format(date, formatType);
}
export function dateTimeKey(date: Date) {
	return dateFnsFormat(date, "yyyy-MM-dd'T'HH:mm:ss");
}
export function dateTimezoneKey(date: Date) {
	return dateFnsFormat(date, "yyyy-MM-dd'T'HH:mm:ssxxxxx");
}
export function monthDayAndTimeKey(date: Date) {
	return dateFnsFormat(date, "MM/dd' - 'hh:mm a'");
}
export function getDateRoundedUp(date: Date, granularity = 15) {
	let dateUp = addMinutes(date, granularity);
	if (getMinutes(dateUp) % granularity !== 0) {
		const ms = 1000 * 60 * granularity;
		dateUp = new Date(Math.ceil(dateUp.getTime() / ms) * ms);
	}
	dateUp.setSeconds(0);
	dateUp.setMilliseconds(0);
	return dateUp;
}
