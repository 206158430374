import { defineComponent } from "vue";

export default defineComponent({
	name: "QSpinner",
	props: {
		size: {
			type: String,
			required: false,
			default: "md",
			validator: (value: string) => ["xs", "sm", "md", "lg", "xl"].indexOf(value) >= 0
		},
		inline: { type: Boolean, required: false, default: false },
		theme: { type: String, required: false, default: "default" },
		disabled: { type: Boolean, required: false, default: false }
	},
	setup() {
		return {};
	}
});
