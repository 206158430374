<template>
	<overlay
		:show="show"
		variant="white"
		v-bind="attributes"
		:opacity="opacity"
		no-fade
	>
		<template #overlay>
			<span v-if="hideSpinner" />
			<Spinner
				v-else
				:size="spinnerSize"
			/>
		</template>
		<slot />
	</overlay>
</template>

<script lang="ts" src="./q-overlay.ts"></script>
