import useConnector from "@app-connector";
import useAppStore, { IAppInstance } from "@app-store/app";
import syncSettingsFromBE from "@app-utils/api-app";

async function resolveAppContext(env: string, ch: string) {
	const { initAppSettings } = useConnector();

	await initAppSettings({
		launchModeResolver() {
			return Promise.resolve(__LAUNCH_MODE__);
		},
		versionResolver() {
			return Promise.resolve(__VERSION__);
		},
		async settingsResolver() {
			const environment = env === "Develop" || env === "Local"
				? "development"
				: (env).toLocaleLowerCase();
			const channel = (ch).toLocaleLowerCase();

			const contextFileUrl = __LAUNCH_MODE__ === "Standard"
				? "/context.json"
				: `/settings/${environment}-${channel}.json`;
			const response = await fetch(contextFileUrl, { method: "GET", cache: "no-cache" })
				.then((data) => data.json())
				.catch((error) => {
					console.error(error);
				});

			return response;
		}
	});
}

export default async function boot() {
	await syncSettingsFromBE();

	const appStore = useAppStore();
	await resolveAppContext(appStore.env, appStore.channel);

	const { useAppSettings } = useConnector();
	const appSettings = useAppSettings();
	const mergedData = { ...appStore, ...appSettings } as IAppInstance;
	await appStore.updateData(mergedData);
}
