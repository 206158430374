import type { IResponseError } from "@app-rest/common.interfaces";
import PortalRestClientFactory from "@app-rest/index";

export type SquareOAuthEnvironment = "Sandbox" | "Production";

export interface SquareOAuthCodeRequest {
	Code: string;
	ResponseType: string;
	State: string;
	Environment: SquareOAuthEnvironment;
}

export interface SquareOAuthUrlResponse {
    Url: string;
}

export class SquareOAuthRestFactory extends PortalRestClientFactory {
	constructor(companyId?: number) {
		super();
		this.options.set("basePath", `/api/square-oauth/${companyId}`);
	}

	saveToken(body: SquareOAuthCodeRequest) {
		return this.post("/token", {
			body,
			headers: new Headers({
				"Content-Type": "application/json-patch+json"
			}),
			throwExcluding: [error => (error.statusCode ?? 500) >= 400 && typeof error.data?.Error?.Code === "string"]
		});
	}

	revokeToken(environment: SquareOAuthEnvironment) {
		return this.delete("/revoke", {
			query: {
				squareEnvironment: environment
			},
			headers: new Headers({
				"Content-Type": "application/json-patch+json"
			}),
			throwExcluding: [error => (error.statusCode === 400 || error.statusCode === 404) && typeof error.data?.Error?.Code === "string"]
		});
	}

	tokenExists(environment: SquareOAuthEnvironment) {
		return this.get<boolean, IResponseError>("/token-exists", {
			query: {
				environment
			}
		});
	}

	getConnectUrl(environment: SquareOAuthEnvironment) {
		return this.get<SquareOAuthUrlResponse, IResponseError>("/url", {
			query: {
				environment
			}
		});
	}
}
