import usei18n from "@app-i18n/index";
import { MediaResourceFileType } from "@app-rest/company/media.interfaces";
import { BModal, BModalProps, ColorVariant, useModalController } from "bootstrap-vue-next";
import { Component, defineComponent, h, VNode } from "vue";

export type ModalController = ReturnType<typeof useModalController>

let _modalController: ModalController | null = null;

export function initModalUtil(controller: ModalController) {
	_modalController = controller;
}

function getDefaultOptions(): BModalProps {
	const langUtils = usei18n();
	return {
		okTitle: langUtils.translateKey("button_ok"),
		cancelTitle: langUtils.translateKey("button_cancel"),
		cancelVariant: "outline-primary",
		okVariant: "primary",
		noCloseOnBackdrop: true,
		noCloseOnEsc: true,
		scrollable: true,
		centered: true,
		modalClass: "modal-utility",
		dialogClass: "",
		bodyClass: "",
		footerClass: "",
		modelValue: true
	};
}

function createModal(message: string | VNode[], localOptions: BModalProps) {
	return defineComponent({
		setup() {
			return () => h(
				BModal,
				{
					...localOptions
				},
				{
					default: () => message
				}
			);
		}
	});
}

async function launchModal(modalTemplate: Readonly<Component>) {
	if (_modalController === null) return;
	const modal = await _modalController.confirm?.({
		component: modalTemplate
	});
	return modal;
}

export function modalConfirm(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const confirmOptions = {
		cancelVariant: "outline-secondary",
		okVariant: "secondary",
		hideHeaderClose: false
	};
	const localOptions = Object.assign({}, defOpts, confirmOptions, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-confirm";
	return launchModal(createModal(message, localOptions));
}

export function modalConfirmModifyTheme() {
	const langUtils = usei18n();
	return modalConfirm(
		langUtils.translateKey("settings_web_graphical_you_are_about_to_modify_an_active_theme"),
		{
			title: langUtils.translateKey("settings_web_graphical_modify_active_theme"),
			cancelVariant: "outline-secondary",
			okVariant: "secondary",
			okTitle: langUtils.translateKey("button_confirm")
		}
	);
}

export function modalConfirmDelete(message: string | VNode[], overrides?: BModalProps) {
	const langUtils = usei18n();
	const defOpts = getDefaultOptions();
	const confirmOptions = {
		title: langUtils.translateKey("modal_delete_title"),
		okTitle: langUtils.translateKey("label_yes"),
		cancelTitle: langUtils.translateKey("label_no"),
		headerBgVariant: "danger" as ColorVariant,
		cancelVariant: "outline-secondary",
		okVariant: "secondary",
		hideHeaderClose: false
	};
	const localOptions = Object.assign({}, defOpts, confirmOptions, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-confirm-delete";
	return launchModal(createModal(message, localOptions));
}

export function modalConfirmDuplicate(message: string | VNode[], overrides?: BModalProps) {
	const langUtils = usei18n();
	const defOpts = getDefaultOptions();
	const confirmOptions = {
		title: langUtils.translateKey("modal_duplicate_title"),
		okTitle: langUtils.translateKey("label_yes"),
		cancelTitle: langUtils.translateKey("label_no"),
		cancelVariant: "outline-secondary",
		okVariant: "secondary",
		hideHeaderClose: false
	};
	const localOptions = Object.assign({}, defOpts, confirmOptions, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-confirm-duplicate";
	return launchModal(createModal(message, localOptions));
}

export function modalConfirmDeactivateServices(message: string | VNode[], overrides?: BModalProps) {
	const langUtils = usei18n();
	const defOpts = getDefaultOptions();
	const confirmOptions = {
		title: langUtils.translateKey("button_confirm"),
		okTitle: langUtils.translateKey("button_ok"),
		cancelTitle: langUtils.translateKey("button_cancel"),
		cancelVariant: "outline-secondary",
		okVariant: "secondary",
		hideHeaderClose: false
	};
	const localOptions = Object.assign({}, defOpts, confirmOptions, (overrides ?? {}));
	return modalConfirm(message, localOptions);
}

export function modalOk(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-ok";
	localOptions.hideHeaderClose = true;
	localOptions.okOnly = true;
	return launchModal(createModal(message, localOptions));
}

export function modalWarning(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return launchModal(createModal(message, localOptions));
}

export function modalError(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.headerBgVariant = "danger" as ColorVariant;
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return launchModal(createModal(message, localOptions));
}

export function modalErrorWithButton(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-error";
	localOptions.headerBgVariant = "danger" as ColorVariant;
	localOptions.okVariant = "secondary";
	localOptions.footerClass = "bg-align-right";
	return launchModal(createModal(message, localOptions));
}

export function modalSuccess(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	const tempId = "tmp-success";
	localOptions.modalClass = localOptions.modalClass + " modal-success";
	localOptions.headerBgVariant = "success" as ColorVariant;
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = true;
	localOptions.id = tempId;
	localOptions.title = "Success";
	localOptions.size = "md";

	const successModal = createModal(message, localOptions);
	launchModal(successModal);
	setTimeout(() => { _modalController?.hide() }, 1300);
}

export function modalInfo(message: string | VNode[], overrides?: BModalProps) {
	const defOpts = getDefaultOptions();
	const localOptions = Object.assign({}, defOpts, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-info";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return launchModal(createModal(message, localOptions));
}

export function modalPermissionsError(message?: string | VNode[], overrides?: BModalProps) {
	const langUtils = usei18n();
	const defOpts = getDefaultOptions();
	const content = message ?? langUtils.translateKey("com_error_permission_viewdata");
	const accessPermissionsOptions = {
		title: langUtils.translateKey("com_error_occurred")
	};
	const localOptions = Object.assign({}, defOpts, accessPermissionsOptions, (overrides ?? {}));
	localOptions.modalClass = localOptions.modalClass + " modal-info modal-permissions";
	localOptions.footerClass = "d-none";
	localOptions.hideHeaderClose = false;
	return launchModal(createModal(content, localOptions));
}

export function modalPopupBlock() {
	const langUtils = usei18n();
	return modalWarning([
		h("div", { class: "py-4" }, langUtils.translateKey("common_error_popup_blocked_text_generic"))
	], {
		title: langUtils.translateKey("common_error_popup_blocked_title")
	});
}

export function modalConfirmExitOnUnsavedState() {
	const langUtils = usei18n();
	return modalConfirm(
		langUtils.translateKey("com_you_some_unsaved_changes"),
		{
			title: langUtils.translateKey("com_unsaved_changes"),
			cancelVariant: "outline-secondary",
			okVariant: "secondary",
			okTitle: langUtils.translateKey("button_confirm")
		}
	);
}

export function modalErrorUnsupportedFile() {
	const langUtils = usei18n();
	const message = [
		h("p", langUtils.translateKey("settings_picture_upload_not_supported")),
		h("br"),
		h("p", langUtils.translateKey("settings_picture_check_item_try_again"))
	];
	return modalError(message, {
		title: langUtils.translateKey("settings_picture_unable_to_upload_file_title")
	});
}

export function modalErrorExceededFileSize(limitFile: number) {
	const langUtils = usei18n();
	const message = [
		h("p", langUtils.translateKey("settings_picture_upload_exceeds_limit", { limit: limitFile })),
		h("br"),
		h("p", langUtils.translateKey("settings_picture_check_size_item_try_again"))
	];
	return modalError(message, {
		title: langUtils.translateKey("settings_picture_unable_to_upload_file_title")
	});
}

export function modalErrorUnsupportedResolution(fileType: MediaResourceFileType, resolution?: string) {
	const langUtils = usei18n();
	const message = [
		h("p", langUtils.translateKey("settings_media_library_file_check_resolution")),
		h("br"),
		h("p", fileType === "Video"
			? langUtils.translateKey("settings_media_library_file_video_resolution_supported_full_hd")
			: langUtils.translateKey("settings_media_library_file_image_resolution_supported", { imageResolution: resolution })
		)
	];
	return modalError(message, {
		title: langUtils.translateKey("settings_media_library_file_resolution_not_supported")
	});
}
export function modalConquerorInformationMissing() {
	const langUtils = usei18n();
	return modalError(
		langUtils.translateKey("settings_rules_and_preferences_error_cq"),
		{
			title: langUtils.translateKey("com_error_occurred")
		}
	);
}
