import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";

export default [
	{
		path: "/:centerId(\\d{4,})/reservations",
		name: "center-reservations",
		component: () => import("@app-views/reservations/grid-view.vue"),
		meta: {
			titleKey: "reservationsGridTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToReservationsGrid],
			accessIf: [Functionalities.AccessToReservationsGrid]
		}
	},
	{
		path: "/:centerId(\\d{4,})/service-reservations",
		name: "service-reservations",
		component: () => import("@app-views/reservations/service/service.vue"),
		meta: {
			titleKey: "serviceReservationsTitle",
			childOf: "center-home",
			showIf: [Functionalities.WebReservations],
			accessIf: [Functionalities.CreateServiceReservations]
		}
	}
] as QRoute[];
