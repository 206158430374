import { backToParentView } from "@app-utils/router";
import { BCardSubtitle, BCardTitle } from "bootstrap-vue-next";
import { defineComponent } from "vue";

export default defineComponent({
	name: "CardHeader",
	components: {
		BCardTitle,
		BCardSubtitle
	},
	props: {
		title: { type: String, default: "" },
		subtitle: { type: String, default: "" },
		hasBackIcon: { type: Boolean, default: false }
	},
	setup() {
		const handleBackArrow = async() => {
			await backToParentView();
		};
		return { handleBackArrow };
	}
});
