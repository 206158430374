import { useResponsiveUtilities } from "@app-composables/responsive";
import { type BaseButtonVariant } from "@qamf/lighthouse";
import { computed, defineComponent, onMounted, onUnmounted, PropType } from "vue";

export default defineComponent({
	name: "NavigationButton",
	props: {
		variant: { type: String as PropType<keyof BaseButtonVariant>, default: "navigation" }
	},
	emits: ["click"],
	setup(props, { attrs, emit, slots }) {
		const instanceAttrs = attrs;
		const { responsiveClasses, isSmall, init: initResponsiveUtils, destroy: destroyResponsiveUtils } = useResponsiveUtilities();
		const isUsingStatusSlot = computed(() => {
			const slot = slots.status?.();
			if (!slot || !slot[0]) return false;
			const slotContent = slot[0].children;
			const isSlotEmpty = Array.isArray(slotContent) || typeof slotContent === "string" ? slotContent.length : 0;
			return isSlotEmpty > 0 && !isSmall.value;
		});
		const onClickEvent = () => {
			emit("click");
		};
		onMounted(() => {
			initResponsiveUtils();
		});
		onUnmounted(() => {
			destroyResponsiveUtils();
		});
		return {
			instanceAttrs,
			responsiveClasses,
			isUsingStatusSlot,
			onClickEvent
		};
	}
});
