<template>
	<div
		class="app-wrapper"
		:class="{
			'loading': appLoading,
			'internal-loading': routeLoading
		}"
		:data-language="language"
	>
		<div
			v-if="appLoading"
			class="splash-screen"
		>
			<span class="splash-content">
				<img
					id="loaderLogo"
					class="img-fluid"
					src="/images/qubica_logo.png"
				>
				<div class="splash-spinner">
					<div class="bounce1" />
					<div class="bounce2" />
					<div class="bounce3" />
				</div>
			</span>
		</div>
		<template v-else>
			<q-overlay
				variant="dark"
				:show="isPopupOpen"
				z-index="999"
				class="popup-overlay-wrapper"
				@click="mainOverlayClicked"
			>
				<template #overlay>
					<span />
				</template>
				<Layer
					id="header"
					class="header"
				>
					<Navbar
						ref="navbar"
					/>
					<ModalPreferences
						id="modal-user-settings"
						ref="user"
					/>
					<RemoteAssistance
						v-if="canManageRemoteAssistance"
						id="modal-remote-assistance"
						ref="remote"
					/>
				</Layer>
				<Layer
					id="main"
					class="main"
					:env="env"
				>
					<router-view
						ref="currentView"
						class="view"
						:class="{
							'multi-system': isCompanyMultiSystem,
							'multi-nav-layout': isInsideSettingsSection,
							'child-of-settings': isChildOfSettings
						}"
					/>
				</Layer>
				<Layer
					id="footer"
					class="footer"
				>
					<QFooter />
				</Layer>

				<QModal
					v-if="router.currentRoute && !isErrorPage"
					ref="errorModal"
					v-model="showErrorModal"
					centered
					scrollable
					no-close-on-backdrop
					hide-footer
					modal-class="modal-error bg-transparent"
					header-bg-variant="danger"
					header-text-variant="light"
					:hide-header-close="!isDevelopSession"
					:title="titleErrorModal"
				>
					<template #modal-title>
						<h5 class="modal-title">
							<i class="far fa-circle-exclamation" />
							<span>{{ titleErrorModal }}</span>
						</h5>
					</template>
					<div v-if="errorIsRest">
						<div v-if="errorStatusCode >= 500">
							{{ $t("com_cannot_be_processed_network_issue") }}
							<br><br>
							{{ $t("com_click_reload_try_again") }}
							<br><br>
							{{ $t("com_apologize_inconvenience") }}
							<div class="d-flex justify-content-end">
								<q-button
									variant="outline-secondary"
									class="text-secondary"
									@click="reload"
								>
									{{ $t("button_reload") }}
								</q-button>
							</div>
						</div>
						<div v-else-if="errorRestCode == 'QCenterNotFound'">
							{{ $t("com_bowling_center_not_found") }}
							<br>
							<br>
							<span
								class="fake-link"
								@click="goToSystemSelection"
							>{{ $t("com_click_to_reload_page") }}</span>
						</div>
						<div v-else-if="errorStatusCode == 401">
							{{ $t("com_access_denied_missing_privileges") }}
							<br><br>
							{{ $t("missingPrivilegesLogout1") }}
							<div class="d-flex justify-content-end">
								<q-button
									variant="outline-secondary"
									href="/logout"
								>
									{{ $t("label_logout") }}
								</q-button>
							</div>
						</div>
						<div v-else-if="errorStatusCode == 404">
							{{ $t("com_resource_not_found") }}
							<br><br>
							<div class="d-flex justify-content-end">
								<q-button
									variant="outline-secondary"
									@click="showErrorModal=false"
								>
									{{ $t("button_ok") }}
								</q-button>
							</div>
						</div>
						<div v-else-if="errorStatusCode == 440">
							{{ $t("com_session_expired_short") }}
							<br><br>
							{{ $t("com_please_login") }}
							<div class="d-flex justify-content-end">
								<form
									action="/login"
									method="post"
									enctype="multipart/form-data"
								>
									<input
										type="hidden"
										name="redirectUri"
										:value="locationHref"
									>
									<q-button
										variant="outline-secondary"
										type="submit"
									>
										{{ $t("label_login") }}
									</q-button>
								</form>
							</div>
						</div>
						<div v-else>
							{{ $t("com_error_occurred") }}
							<div
								v-if="errorRestObject && errorRestObject.Error"
								class="backend-error-message"
							>
								{{ errorRestObject.Error.Message }}
							</div>
							<div
								v-else-if="errorFirstOccurrence"
								class="backend-error-message"
							>
								{{ errorFirstOccurrence.error?.message }}
							</div>
							<div
								v-else
								class="backend-error-message"
							>
								{{ errorRestObject }}
							</div>
							<div class="d-flex justify-content-end">
								<q-button
									variant="outline-secondary"
									@click="showErrorModal=false"
								>
									{{ $t("button_ok") }}
								</q-button>
							</div>
						</div>
					</div>
					<div v-else>
						<div
							v-if="errorRestObject && errorRestObject.Error"
							class="error-message"
						>
							{{ errorRestObject.Error.Message }}
						</div>
						<div v-else>
							{{ $t("com_development_team_automatically_notified") }}
							<br><br>
							{{ $t("com_click_reload_try_again") }}
							<br><br>
							{{ $t("com_apologize_inconvenience") }}
							<div class="d-flex justify-content-end">
								<q-button
									variant="outline-secondary"
									@click="reload"
								>
									{{ $t("button_reload") }}
								</q-button>
							</div>
						</div>
					</div>
				</QModal>
			</q-overlay>
		</template>
	</div>
	<BModalOrchestrator />
	<BToastOrchestrator />
</template>

<script lang="ts" src="./app.ts"></script>
