/* eslint-disable simple-import-sort/imports */
import store from "@app-store/index";
import { VuexModule, Module, Mutation } from "vuex-class-modules";
import { getAppStorage, writeAppStorage } from "@app-utils/app-module-storage";
import PortalRestClientFactory from "@app-rest/index";

type AppLaunchMode = "" | "DevelopSession" | "DevelopAndTestingSession" | "TestingSession";

// FIXME: Cleanup deprecated api-app settings and use settings from @qamf/modules-connector
// Tech Story 62621: [QPortal] Deprecate legacy app settings
export interface IAppInstance {
	launchMode: AppLaunchMode;
	controller: string;
	version: string;
	env: string;
	channel: string;
	currentUser: string;
	currentUserChameleonHash: string;
	helpSiteLink: string;
	bowlerWebSite: string;
	browserLanguage: string;
	connectionString: string;
	qdeskUrl: string;
}

let _restClient: PortalRestClientFactory | null;
function ensureRestClient() {
	if (_restClient) return _restClient;
	const restClient = _restClient = new PortalRestClientFactory();
	return restClient;
}

@Module class AppModuleFactory extends VuexModule implements IAppInstance {
	launchMode: AppLaunchMode = "";
	controller = "";
	version = "";
	env = "";
	channel = "";
	currentUser = ""; // << username from backend (reading from AccessToken)
	currentUserChameleonHash = "";
	helpSiteLink = "";
	bowlerWebSite = "";
	browserLanguage = "";
	connectionString = "";
	qdeskUrl = "";
	popupWindowIsOpen = false;
	get appData() {
		return {
			launchMode: this.launchMode,
			controller: this.controller,
			version: this.version,
			env: this.env,
			channel: this.channel,
			currentUser: this.currentUser,
			helpSiteLink: this.helpSiteLink,
			bowlerWebSite: this.bowlerWebSite,
			browserLanguage: this.browserLanguage,
			connectionString: this.connectionString,
			qdeskUrl: this.qdeskUrl
		} as IAppInstance;
	}

	get isDevelopSession() {
		return this.launchMode === "DevelopSession" || this.launchMode === "DevelopAndTestingSession";
	}

	get isTestingSession() {
		return this.launchMode === "TestingSession" || this.launchMode === "DevelopAndTestingSession";
	}

	private writeData(appData: IAppInstance) {
		const store = getAppStorage();
		store.app = appData;
		writeAppStorage(store);
	}

	@Mutation setPopupWindowIsOpen(state: boolean) {
		this.popupWindowIsOpen = state;
	}

	@Mutation setCurrentUser(user: string) {
		this.currentUser = user;
	}

	@Mutation updateData(appData: IAppInstance) {
		Object.keys(appData).forEach(prop => {
			this[prop] = appData[prop];
		});
		this.writeData(appData);
	}
}

const moduleName = "app.qportal";

let AppStore: AppModuleFactory | null;

function useAppStore() {
	if (AppStore) return AppStore;

	const mod = AppStore = new AppModuleFactory({ name: moduleName, store });

	return mod;
}

export default useAppStore;
