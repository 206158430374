import { Functionalities } from "@app-rest/company/interfaces";
import useWebOfferStore from "@app-store/system/webOffer";
import SecurityPlugin from "@app-utils/security";

import { webOfferCalendar } from ".";
import { settingsReservationsWebOffers } from "./reservation";

export default [
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/add-web-offer",
		name: "add-web-offer",
		component: () => import("@app-views/settings/reservations/web-offers/add-web-offer/add-web-offer.vue"),
		meta: {
			titleKey: "settings_add_web_offer",
			childOf: settingsReservationsWebOffers
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)",
		name: "web-offer-details",
		component: () => import("@app-views/settings/reservations/web-offers/details/details.vue"),
		meta: {
			childOf: settingsReservationsWebOffers
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/status",
		name: "web-offer-details-status",
		component: () => import("@app-views/settings/reservations/web-offers/details/status/status.vue"),
		meta: {
			titleKey: "label_status",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/services",
		name: "web-offer-details-services",
		component: () => import("@app-views/settings/reservations/web-offers/details/services/services.vue"),
		meta: {
			titleKey: "servicesTitle",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/core-settings",
		name: "web-offer-details-coresettings",
		component: () => import("@app-views/settings/reservations/web-offers/details/core-settings/core-settings.vue"),
		meta: {
			titleKey: "settings_web_offer_coresettings",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/appearance",
		name: "web-offer-details-appearance",
		component: () => import("@app-views/settings/reservations/web-offers/details/appearance/appearance.vue"),
		meta: {
			titleKey: "settings_web_offer_appearance",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/pricing",
		name: "web-offer-details-pricing",
		component: () => import("@app-views/settings/reservations/web-offers/details/pricing/pricing.vue"),
		meta: {
			titleKey: "label_pricing",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/deposit-fees",
		name: "web-offer-details-depositfees",
		component: () => import("@app-views/settings/reservations/web-offers/details/deposit-fees/deposit-fees.vue"),
		meta: {
			titleKey() {
				if (SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.RealIncomePlayNow]))
					return "settings_web_offer_advance_payments_fees";
				else return "settings_web_offer_depositfees";
			},
			childOf: "web-offer-details",
			showIf: () => {
				const webOfferStore = useWebOfferStore();
				const hasServices = Boolean(webOfferStore.SelectedWebOfferServices);

				if (!hasServices || !webOfferStore.selectedWebOffer) return false;

				const hasAtLeastOneExternalServiceActive = webOfferStore.SelectedWebOfferServices?.ExternalApiServices.IsBookForLaterActive
					|| webOfferStore.SelectedWebOfferServices?.ExternalApiServices.IsPlayNowActive;

				return SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
					Functionalities.WebReservations,
					Functionalities.Kiosks
				]) && !hasAtLeastOneExternalServiceActive;
			}
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/lane-groups",
		name: "web-offer-details-lanegroups",
		component: () => import("@app-views/settings/reservations/web-offers/details/lane-groups/lane-groups.vue"),
		meta: {
			titleKey: "settings_web_offer_lanegroups",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/availability",
		name: "web-offer-details-availability",
		component: () => import("@app-views/settings/reservations/web-offers/details/availability/availability.vue"),
		meta: {
			titleKey: "label_availability",
			childOf: "web-offer-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/availability/:idCalendar",
		name: webOfferCalendar,
		component: () => import("@app-views/settings/reservations/web-offers/details/availability/calendar-view/calendar-view.vue"),
		meta: {
			childOf: "web-offer-details-availability"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/web-offers/:webofferId(\\d+)/options",
		name: "web-offer-details-options",
		component: () => import("@app-views/settings/reservations/web-offers/details/options/options.vue"),
		meta: {
			titleKey: "settings_web_offer_options",
			childOf: "web-offer-details"
		}
	}
];
