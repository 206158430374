import clickAway from "@app-directives/click-away";
import type { IGroup, IOption } from "@app-views/settings/settings.interfaces";
import { PopoverPlacement, QFormFieldWrapper, QPopover } from "@qamf/lighthouse";
import { flow, groupBy, isPlainObject, map, startCase } from "lodash-es";
import {
	computed,
	defineComponent,
	nextTick,
	onBeforeMount,
	onMounted,
	onUnmounted,
	PropType,
	reactive,
	ref,
	watch
} from "vue";

export const toNiceOptions = (arr: string[], textMap?: { [key: string]: string; }) => map(arr, (value) => {
	const option: IOption = { value, text: textMap ? textMap[value] : "" };
	if (!option.text) option.text = startCase(value);
	return option;
});
export const toOptions = (arr: any[]) => map(arr, (value) => ({ value, text: value } as IOption));
export const mapToOptions = (keyVals: { [key: string]: string }) => map(
	Object.keys(keyVals),
	(key) => ({
		value: key,
		text: keyVals[key]
	} as IOption)
);

const selectProvider = new Map<string, typeof QPopover>();

export default defineComponent({
	name: "QFormFieldSelect",
	components: {
		QFormFieldWrapper,
		QPopover
	},
	directives: {
		clickAway
	},
	props: {
		modelValue: { type: [String, Number, Boolean, Array, Object], default: null },
		placeholder: { type: String, default: "" },
		size: {
			type: String,
			default: "md",
			validator: (value: string) => ["xs", "sm", "md", "lg", "xl"].indexOf(value) >= 0
		},
		prefix: { type: String, default: "" },
		spinnerSize: { type: String, default: "md" },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		options: { type: Array, default: () => [] },
		textField: { type: String, default: "text" },
		valueField: { type: String, default: "value" },
		groupField: { type: String, default: null },
		groupTextField: { type: String, default: null },
		disabledField: { type: String, default: "disabled" },
		optionsClass: { type: String, default: "" },
		name: { type: String, default: null, required: true },
		rules: { type: String, default: null },
		errorMessage: { type: String, default: "" },
		hideValidation: { type: Boolean, default: false },
		hideValidationMessages: { type: Boolean, default: false },
		class: { type: String, default: null },
		placement: { type: String as PropType<PopoverPlacement>, default: "bottom" },
		showClearer: { type: Boolean, default: false },
		teleportTo: { type: String, default: "body" }
	},
	emits: ["click-icon", "click-field-clearer", "update:modelValue", "change"],
	setup(props, { emit }) {
		const inputElement = ref<HTMLElement | undefined>();
		const selectWrapper = ref<typeof QFormFieldWrapper>();
		const popover = ref<typeof QPopover | null>();
		const helpers = reactive({
			loading: false,
			selected: null as any,
			flatOptions: [] as object[],
			groupedOptions: [] as IGroup[],
			pickerKey: "",
			overflowParent: null as Element | null
		});
		const isPopoverVisible = ref(false);
		const classProp = computed(() => {
			return props.class;
		});
		const areOptionsPrimitives = computed(() => {
			const firstOpt = props.options ? props.options[0] : null;
			if (firstOpt === null || typeof firstOpt === "undefined") return null;
			return !isPlainObject(firstOpt);
		});
		const controlClasses = computed(() => {
			return {
				disabled: props.disabled,
				"is-placeholder": !helpers.selected
			};
		});
		const clearerClasses = computed(() => {
			return selectWrapper.value?.clearerClasses();
		});
		const onIconClick = () => {
			emit("click-icon");
		};
		const onClearerClick = () => {
			emit("click-field-clearer");
		};
		const onClickOutside = () => {
			if (!popover.value) return;
			isPopoverVisible.value = false;
		};
		const activeOption = computed(() => {
			if (helpers.selected) {
				const prefix = props.prefix ? `${props.prefix} ` : "";
				return `${prefix}${helpers.selected?.[props.textField] || ""}`;
			} else
				return props.placeholder || "";
		});
		const setLocalValue = async(newValue: string | number | boolean | null | Record<string, any>) => {
			await nextTick();
			if (helpers.selected === newValue)
				return onClickOutside();

			if (!newValue && areOptionsPrimitives.value)
				helpers.selected = newValue;
			else if (areOptionsPrimitives.value) {
				if (!isPlainObject(newValue))
					newValue = { value: newValue, text: newValue } as IOption;
				if (helpers.selected === newValue) return onClickOutside();
				const selected = (helpers.flatOptions as IOption[]).find(
					(opt) => opt.value === (newValue as IOption).value
				);
				helpers.selected = selected;
			} else {
				if (!isPlainObject(newValue)) {
					newValue = helpers.flatOptions.find(
						(opt) => opt[props.valueField] === newValue
					) as IOption;
				}
				if (!newValue) helpers.selected = null;
				else {
					if (
						helpers.selected
						&& helpers.selected[props.valueField] === newValue[props.valueField]
					)
						return onClickOutside();
					const selected = helpers.flatOptions.find(
						(opt) => opt[props.valueField] === (newValue as IOption)[props.valueField]
					);
					helpers.selected = selected;
				}
			}
			return onClickOutside();
		};
		const optionValue = computed<string | number | boolean | Record<string, any> | null>({
			get: () => props.modelValue,
			set: (value) => {
				if (props.modelValue !== value)
					emit("change");

				emit("update:modelValue", value);
			}
		});
		const mapLocalOptions = () => {
			const firstOpt = props.options ? props.options[0] : null;
			if (!firstOpt) return (helpers.flatOptions = []);
			if (!isPlainObject(firstOpt))
				return (helpers.flatOptions = toOptions(props.options));
			helpers.groupedOptions = [];
			if (props.groupField && firstOpt[props.groupField]) {
				helpers.groupedOptions = flow(
					(options: object[]) => groupBy(options, props.groupField),
					(groupsMap) => {
						const groupedOptions: IGroup[] = map(
							Object.keys(groupsMap),
							(groupId) => {
								const groupOptions = groupsMap[groupId];
								return {
									id: groupId,
									options: groupOptions
								};
							}
						);
						return groupedOptions;
					}
				)(props.options as object[]);
			}
			helpers.flatOptions = props.options as object[];
		};
		const onClickWrapper = async() => {
			if (props.disabled) return;
			await nextTick();

			if (!selectProvider.has(helpers.pickerKey) && popover.value)
				selectProvider.set(helpers.pickerKey, popover.value);
			selectProvider.forEach((singlePopover: typeof QPopover) => {
				if (singlePopover !== popover.value)
					singlePopover.hide();
			});
			if (popover.value) isPopoverVisible.value = true;
		};
		const isOptionDisabled = (option: object) => {
			return props.disabledField in option
				? Boolean(option[props.disabledField as keyof typeof option])
				: false;
		};
		const onOptionClick = async(option: object) => {
			const disabled = isOptionDisabled(option);
			if (disabled || props.readonly) return;
			await nextTick();
			isPopoverVisible.value = false;
			optionValue.value = option[props.valueField];
		};
		const onShowSelectOptions = async() => {
			const inputElementWidth = inputElement.value?.offsetWidth ?? 0;
			document.documentElement.style.setProperty("--select-width", `${inputElementWidth}px`);
		};
		watch(
			() => props.options,
			() => mapLocalOptions(),
			{ deep: true }
		);
		watch(
			() => props.modelValue,
			(value) => setLocalValue(value)
		);
		onBeforeMount(async() => {
			mapLocalOptions();
			setLocalValue(optionValue.value);
		});
		onMounted(() => {
			helpers.pickerKey = +new Date() + "_" + Math.floor(Math.random() * 1000);
		});
		onUnmounted(() => {
			selectProvider.delete(helpers.pickerKey);
		});
		return {
			inputElement,
			popover,
			helpers,
			controlClasses,
			clearerClasses,
			selectWrapper,
			classProp,
			isPopoverVisible,
			activeOption,
			onIconClick,
			onClearerClick,
			onClickWrapper,
			isOptionDisabled,
			onClickOutside,
			onOptionClick,
			onShowSelectOptions
		};
	}
});
