/* eslint-disable @typescript-eslint/ban-types */
import languages from "@app-backend/languages.json";
import type { ITranslation } from "@qamf/xliff-loader";
export type LanguageIso = keyof typeof languages;

export function importXlf(isoCode: LanguageIso): Promise<{ default: ITranslation }> {
	let iso = Object.keys(languages ?? {}).find(k => k === isoCode) as LanguageIso | undefined;

	if (!iso) {
		const langs = Object.keys(languages ?? {}).map(k => { return { Code: k } });
		const langParts = isoCode.split("-");
		const firstMatch = langs.find(l => l.Code.indexOf(langParts[0] + "-") > -1);
		if (firstMatch && firstMatch.Code !== "en-US")
			iso = firstMatch.Code as LanguageIso;
	}

	switch (iso) {
		case "en-GB":
			return import("@qamf/qst-qportal/en-GB/qportal.xlf");
		case "de-DE":
			return import("@qamf/qst-qportal/de-DE/qportal.xlf");
		case "fr-FR":
			return import("@qamf/qst-qportal/fr-FR/qportal.xlf");
		case "fr-CA":
			return import("@qamf/qst-qportal/fr-CA/qportal.xlf");
		case "it-IT":
			return import("@qamf/qst-qportal/it-IT/qportal.xlf");
		case "es-ES":
		case "es-MX":
		case "es-NI":
			return import("@qamf/qst-qportal/es-ES/qportal.xlf");
		case "nb-NO":
			return import("@qamf/qst-qportal/nb-NO/qportal.xlf");
		case "sv-SE":
			return import("@qamf/qst-qportal/sv-SE/qportal.xlf");
		case "da-DK":
			return import("@qamf/qst-qportal/da-DK/qportal.xlf");
		case "fi-FI":
			return import("@qamf/qst-qportal/fi-FI/qportal.xlf");
		case "pl-PL":
			return import("@qamf/qst-qportal/pl-PL/qportal.xlf");
		case "cs-CZ":
			return import("@qamf/qst-qportal/cs-CZ/qportal.xlf");
		case "ru-RU":
			return import("@qamf/qst-qportal/ru-RU/qportal.xlf");
		case "vi-VN":
			return import("@qamf/qst-qportal/vi-VN/qportal.xlf");
		case "id-ID":
			return import("@qamf/qst-qportal/id-ID/qportal.xlf");
		case "hr-HR":
			return import("@qamf/qst-qportal/hr-HR/qportal.xlf");
		case "nl-NL":
			return import("@qamf/qst-qportal/nl-NL/qportal.xlf");
		case "ja-JP":
			return import("@qamf/qst-qportal/ja-JP/qportal.xlf");
		case "zh-CN":
			return import("@qamf/qst-qportal/zh-CN/qportal.xlf");
		default:
			return import("@qamf/qst-qportal/en-US/qportal.xlf");
	}
}

export const datesDictionary: Record<LanguageIso, () => Promise<Locale>> = {
	"en-US": () => import("date-fns/locale/en-US") as Promise<Locale>,
	"en-GB": () => import("date-fns/locale/en-GB") as Promise<Locale>,
	"de-DE": () => import("date-fns/locale/de") as Promise<Locale>,
	"fr-FR": () => import("date-fns/locale/fr") as Promise<Locale>,
	"fr-CA": () => import("date-fns/locale/fr-CA") as Promise<Locale>,
	"it-IT": () => import("date-fns/locale/it") as Promise<Locale>,
	"es-ES": () => import("date-fns/locale/es") as Promise<Locale>,
	"es-MX": () => import("@app-i18n/locale/es-MX") as Promise<Locale>,
	"es-NI": () => import("@app-i18n/locale/es-NI") as Promise<Locale>,
	"nb-NO": () => import("date-fns/locale/nb") as Promise<Locale>,
	"sv-SE": () => import("date-fns/locale/sv") as Promise<Locale>,
	"da-DK": () => import("date-fns/locale/da") as Promise<Locale>,
	"fi-FI": () => import("date-fns/locale/fi") as Promise<Locale>,
	"pl-PL": () => import("date-fns/locale/pl") as Promise<Locale>,
	"cs-CZ": () => import("date-fns/locale/cs") as Promise<Locale>,
	"ru-RU": () => import("date-fns/locale/ru") as Promise<Locale>,
	"vi-VN": () => import("date-fns/locale/vi") as Promise<Locale>,
	"id-ID": () => import("date-fns/locale/id") as Promise<Locale>,
	"hr-HR": () => import("date-fns/locale/hr") as Promise<Locale>,
	"nl-NL": () => import("date-fns/locale/nl") as Promise<Locale>,
	"ja-JP": () => import("date-fns/locale/ja") as Promise<Locale>,
	"zh-CN": () => import("date-fns/locale/zh-CN") as Promise<Locale>
};

export const validationDictionary: Record<LanguageIso, () => Promise<Object>> = {
	"en-US": () => import("@vee-validate/i18n/dist/locale/en.json") as Promise<Object>,
	"en-GB": () => import("@vee-validate/i18n/dist/locale/en.json") as Promise<Object>,
	"de-DE": () => import("@vee-validate/i18n/dist/locale/de.json") as Promise<Object>,
	"fr-FR": () => import("@vee-validate/i18n/dist/locale/fr.json") as Promise<Object>,
	"fr-CA": () => import("@vee-validate/i18n/dist/locale/fr.json") as Promise<Object>,
	"it-IT": () => import("@vee-validate/i18n/dist/locale/it.json") as Promise<Object>,
	"es-ES": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<Object>,
	"es-MX": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<Object>,
	"es-NI": () => import("@vee-validate/i18n/dist/locale/es.json") as Promise<Object>,
	"nb-NO": () => import("@vee-validate/i18n/dist/locale/nb_NO.json") as Promise<Object>,
	"sv-SE": () => import("@vee-validate/i18n/dist/locale/sv.json") as Promise<Object>,
	"da-DK": () => import("@vee-validate/i18n/dist/locale/da.json") as Promise<Object>,
	"fi-FI": () => import("@vee-validate/i18n/dist/locale/fi.json") as Promise<Object>,
	"pl-PL": () => import("@vee-validate/i18n/dist/locale/pl.json") as Promise<Object>,
	"cs-CZ": () => import("@vee-validate/i18n/dist/locale/cs.json") as Promise<Object>,
	"ru-RU": () => import("@vee-validate/i18n/dist/locale/ru.json") as Promise<Object>,
	"vi-VN": () => import("@vee-validate/i18n/dist/locale/vi.json") as Promise<Object>,
	"id-ID": () => import("@vee-validate/i18n/dist/locale/id.json") as Promise<Object>,
	"hr-HR": () => import("@vee-validate/i18n/dist/locale/hr.json") as Promise<Object>,
	"nl-NL": () => import("@vee-validate/i18n/dist/locale/nl.json") as Promise<Object>,
	"ja-JP": () => import("@vee-validate/i18n/dist/locale/ja.json") as Promise<Object>,
	"zh-CN": () => import("@vee-validate/i18n/dist/locale/zh_CN.json") as Promise<Object>
};

export const numericDictionary: Record<LanguageIso, () => Promise<string | null>> = {
	"en-US": () => Promise.resolve(null),
	"en-GB": async() => {
		await import("numeral/locales/en-gb");
		return "en-gb";
	},
	"de-DE": async() => {
		await import("numeral/locales/de");
		return "de";
	},
	"fr-FR": async() => {
		await import("numeral/locales/fr");
		return "fr";
	},
	"fr-CA": async() => {
		await import("numeral/locales/fr-ca");
		return "fr-ca";
	},
	"it-IT": async() => {
		await import("numeral/locales/it");
		return "it";
	},
	"es-ES": async() => {
		await import("numeral/locales/es");
		return "es";
	},
	"es-MX": async() => {
		await import("numeral/locales/es");
		return "es";
	},
	"es-NI": async() => {
		await import("numeral/locales/es");
		return "es";
	},
	"nb-NO": async() => {
		await import("numeral/locales/no");
		return "no";
	},
	"sv-SE": () => Promise.resolve(null),
	"da-DK": async() => {
		await import("numeral/locales/da-dk");
		return "da-dk";
	},
	"fi-FI": async() => {
		await import("numeral/locales/fi");
		return "fi";
	},
	"pl-PL": async() => {
		await import("numeral/locales/pl");
		return "pl";
	},
	"cs-CZ": async() => {
		await import("numeral/locales/cs");
		return "cs";
	},
	"ru-RU": async() => {
		await import("numeral/locales/ru");
		return "ru";
	},
	"vi-VN": async() => {
		await import("numeral/locales/vi");
		return "vi";
	},
	"id-ID": () => Promise.resolve(null),
	"hr-HR": () => Promise.resolve(null),
	"nl-NL": async() => {
		await import("numeral/locales/nl-nl");
		return "nl-nl";
	},
	"ja-JP": async() => {
		await import("numeral/locales/ja");
		return "ja";
	},
	"zh-CN": async() => {
		await import("numeral/locales/chs");
		return "chs";
	}
};
