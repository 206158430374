import usei18n from "@app-i18n/index";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useGlobalStore from "@app-store/global";
import useLangStore from "@app-store/lang";
import useMenuItemsStore from "@app-store/menu-items";
import useSystemStore from "@app-store/system";
import useExternalPosStore from "@app-store/system/externalPos";
import useWebOfferStore from "@app-store/system/webOffer";
import useUserStore from "@app-store/user";
import syncSettingsFromBE from "@app-utils/api-app";
import { syncTrackingUser, trackTrace, trackView } from "@app-utils/app-insights";
import { isAccessGranted, isVisible } from "@app-utils/router";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import {
	exitToErrorPage,
	type QRoute,
	QRouteMeta,
	Routes,
	setErrorObject,
	setRouteTitle,
	setWasTryingToGo
} from ".";
import { posSolution } from "./routes/pos-solution";

function exitToAccessDenied(to: RouteLocationNormalized, next?: NavigationGuardNext) {
	setWasTryingToGo(to);
	if (next) next({ name: "access-denied" });
}
async function ensureUserInfo(next: NavigationGuardNext) {
	const appStore = useAppStore();
	const userStore = useUserStore();

	if (!userStore.info || !userStore.info.Email || appStore.currentUser.toLowerCase() !== userStore.info.Username.toLowerCase()) {
		const responses = await Promise.all([
			userStore.getInfo(),
			userStore.syncSettings()
		]);
		if (!userStore.info || !userStore.settings) {
			appStore.setCurrentUser("");
			const infoError = responses[0].error;
			const settingsError = responses[1].error;
			const theError = infoError || settingsError || new Error("");
			setErrorObject(theError);
			exitToErrorPage(next, theError);
			return false;
		}
	}
	return true;
}
async function ensureSystemInfo(to: RouteLocationNormalized, next: NavigationGuardNext, systemId: number) {
	const systemStore = useSystemStore();
	const appStore = useAppStore();
	await systemStore.ensureInfo(systemId);

	const system = systemStore.info;
	const statusCode = systemStore.infoStatusCode;

	if (statusCode === 401) {
		exitToAccessDenied(to, next);
		return null;
	}

	if (!system || statusCode === 404) {
		next({ name: "not-found" });
		return null;
	}

	const currentEnv = appStore.env.toLowerCase();
	const centerCh = system.Channel.toLowerCase();
	if (currentEnv !== "develop" && currentEnv !== "local" && centerCh !== appStore.channel.toLowerCase()) {
		const envPart = currentEnv === "production" ? "" : `-${currentEnv}`;
		const channelPart = centerCh === "stable" ? "" : `-${centerCh}`;
		const portalUrl = `https://qportal${envPart}${channelPart}.qubicaamf.com`;
		const redirectUrl = new URL(to.fullPath, portalUrl);

		location.href = redirectUrl.href;
	}

	trackTrace({
		message: "Prompt: System info loaded",
		severityLevel: SeverityLevel.Information
	});
}
async function getUserFunctionalitiesOnSystem(systemId: number) {
	if (!systemId) return;
	const userStore = useUserStore();

	await userStore.ensureSystemFunctionalities(systemId);
}
async function getCompanyInfo(companyId: number) {
	if (!companyId) return;
	const companyStore = useCompanyStore();
	await companyStore.ensureCompanyInfo(companyId);
	trackTrace({
		message: "Prompt: Company info loaded",
		severityLevel: SeverityLevel.Information
	});
}
async function getUserFunctionalitiesOnCompany(companyId: number) {
	if (!companyId) return;
	const userStore = useUserStore();

	await userStore.ensureCompanyFunctionalities(companyId);
}
async function ensurePosSolutionImportStatus(to: RouteLocationNormalized) {
	if (to.meta?.childOf === posSolution || to.name?.toString().startsWith(posSolution)) {
		const posStore = useExternalPosStore();
		await Promise.all([
			posStore.ensureCenterPairing(),
			posStore.ensureSquareSyncStatus()
		]);
	}
}

export default async(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
	const langStore = useLangStore();
	const systemStore = useSystemStore();
	const globalStore = useGlobalStore();
	const menuItemsStore = useMenuItemsStore();
	const appStore = useAppStore();
	const userStore = useUserStore();
	const companyStore = useCompanyStore();

	if (appStore.popupWindowIsOpen)
		return;

	if (to.name !== from.name)
		globalStore.setRouteLoading(true);

	// FIXME: #50429 remove after upgrade Vue3
	await syncSettingsFromBE();

	const isPrivateView = !to.path.startsWith("/public");
	const loggedInUser = Boolean(appStore.currentUser);

	if (loggedInUser) {
		const success = await ensureUserInfo(next);
		if (!success) return;

		if (to.name !== "root" && to.path === from.path)
			return next();

		if (to.name === "root") {
			const system = systemStore.info;
			if (system && system.Id)
				return next({ name: "center-home", params: { centerId: system.Id + "" } });
			else {
				const lastSystemFromHistory = userStore.systemIdsHistory && userStore.systemIdsHistory[0] ? userStore.systemIdsHistory[0] : null;
				if (lastSystemFromHistory)
					return next({ name: "center-home", params: { centerId: lastSystemFromHistory + "" } });

				return next({ name: "select-center" });
			}
		}

		if (isPrivateView) {
			const centerIdInRoute = "centerId" in to.params;
			const companyIdInRoute = "companyId" in to.params;
			const webOfferIdInRoute = "webofferId" in to.params;
			const needCentersList = userStore && !userStore.systems.length;

			if (needCentersList) {
				await userStore.ensureSystems();
				trackTrace({
					message: "Prompt: List systems loaded",
					severityLevel: SeverityLevel.Information
				});
			}

			// Center based view
			if (centerIdInRoute) {
				const params = (to as any).params;
				await Promise.all([
					ensureSystemInfo(to, next, params.centerId),
					getUserFunctionalitiesOnSystem(params.centerId)
				]);

				if (!systemStore.info) return;

				const centerRoute = Routes.find(r => r.name === "center-home");
				const centerName = centerRoute?.meta.title;
				if (systemStore.info.Name !== centerName)
					setRouteTitle("center-home", systemStore.info.Name ?? "");

				await getCompanyInfo(systemStore.info.CompanyId);
				await getUserFunctionalitiesOnCompany(systemStore.info.CompanyId);

				if (webOfferIdInRoute && isVisible(to.meta) && isAccessGranted(to.meta)) {
					const webOfferStore = useWebOfferStore();
					await Promise.all([
						webOfferStore.syncWebOffers(),
						webOfferStore.syncWebOffer(Number(params.webofferId)),
						webOfferStore.syncSelectedWebOfferServices(Number(params.webofferId))
					]);
					setRouteTitle("web-offer-details", webOfferStore.selectedWebOffer?.Name ?? "");
				}
			} else if (companyIdInRoute) { // Company based view
				const companyId = +to.params.companyId;
				await Promise.all([
					getCompanyInfo(companyId),
					getUserFunctionalitiesOnCompany(companyId)
				]);
				if (companyStore.company) {
					setRouteTitle("company", companyStore.company.Name);
					if (!useSystemStore().info?.Id) {
						const masterCenterId = companyStore.company.MasterCenterId;
						const masterIsInCompanyCenters = companyStore.company.Centers.find(c => c.Id === masterCenterId);
						const systemId = masterCenterId && masterIsInCompanyCenters ? masterCenterId : companyStore.company.Centers[0].Id;
						await Promise.all([
							ensureSystemInfo(to, next, systemId),
							getUserFunctionalitiesOnSystem(systemId)
						]);
					}
				}
			}

			await ensurePosSolutionImportStatus(to);
			await langStore.ensureLanguage();

			if (to.name === "select-center" && userStore.systems && userStore.systems.length === 1) {
				return next({
					name: "center-home",
					params: { centerId: userStore.systems[0].Id + "" }
				});
			}

			menuItemsStore.initMenu();
		} else
			await langStore.ensureLanguage();
	} else if (isPrivateView)
		return location.href = `/login?redirectUri=${location.href}`;
	else if (!to.meta?.allowAnonymous) {
		await langStore.ensureLanguage();
		return exitToAccessDenied(to, next);
	} else
		await langStore.ensureLanguage();

	syncTrackingUser();

	const toRoute = to as unknown as QRoute;
	const toRouteMeta = "meta" in to ? to.matched[0].meta as QRouteMeta : undefined;
	const routeTitle = toRouteMeta?.titleKey ? usei18n().getTranslatedRouteTitle(toRoute) : toRouteMeta?.title ?? "";
	setRouteTitle(toRoute, routeTitle);

	if (to.name)
		trackView(to.name.toString(), "start", to.fullPath);
	next();
};
