import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";

export const fbOrderUpdates = "fnb-order-updates";
export const fbOrderUpdatesDetails = "fnb-order-updates-details";

export default [
	{
		path: "/:centerId(\\d{4,})",
		name: "center-home",
		beforeEnter: (to, from, next) => {
			if (!to.params.centerId)
				return next({ name: "select-center" });
			next();
		},
		component: () => import("@app-views/center-home/center-home.vue"),
		meta: {}
	},
	{
		path: "/:centerId(\\d{4,})/performance-monitoring",
		name: "performance-monitoring",
		component: () => import("@app-views/performance-monitoring/performance-monitoring.vue"),
		meta: {
			titleKey: "performanceMonitoringTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToPerformanceMonitoring],
			accessIf: [Functionalities.AccessToPerformanceMonitoring],
			helpSiteAnchor: "dashboards-reporting/performance-monitoring"
		}
	},
	{
		path: "/:centerId(\\d{4,})/game-statistics",
		name: "game-statistics",
		component: () => import("@app-views/game-statistics/game-statistics.vue"),
		meta: {
			titleKey: "gameStatisticsTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToGameStatistics],
			accessIf: [Functionalities.AccessToGameStatistics],
			helpSiteAnchor: "dashboards-reporting/game-statistics"
		}
	},
	{
		path: "/:centerId(\\d{4,})/business-results",
		name: "business-results",
		component: () => import("@app-views/business/business.vue"),
		meta: {
			titleKey: "businessResultsTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToBusinessDashboard],
			accessIf: [Functionalities.AccessToBusinessDashboard],
			helpSiteAnchor: "dashboards-reporting/business-results"
		}
	},
	{
		path: "/:centerId(\\d{4,})/cloud-data-selection",
		name: "cloud-data-selection",
		component: () => import("@app-views/services-activation/services-activation.vue"),
		meta: {
			titleKey: "cloudDataSelectionTitle",
			childOf: "center-home",
			accessIf: [Functionalities.AccessToDataAndServiceSetup],
			helpSiteAnchor: "management-configurations/cloud-data-section"
		}
	},
	{
		path: "/:centerId(\\d{4,})/customer-accounts",
		name: "customer-accounts",
		component: () => import("@app-views/customer-accounts/customer-accounts.vue"),
		meta: {
			titleKey: "customerAccountsTitle",
			childOf: "center-home",
			helpSiteAnchor: "tools-features/customer-accounts",
			showIf: [Functionalities.AccessToCustomerAccounts],
			accessIf: [Functionalities.AccessToCustomerAccounts]
		}
	},
	{
		path: "/:centerId(\\d{4,})/customer-accounts/:id",
		name: "customer-accounts-details",
		component: () => import("@app-views/customer-accounts/customer-details/customer-details.vue"),
		meta: {
			childOf: "customer-accounts",
			helpSiteAnchor: "tools-features/customer-account-details"
		}
	},
	{
		path: "/:centerId(\\d{4,})/kiosk-utilities",
		name: "kiosk-utilities",
		component: () => import("@app-views/kiosk-utilities/kiosk-utilities.vue"),
		meta: {
			titleKey: "kioskUtilities",
			childOf: "center-home",
			showIf: [Functionalities.Kiosks],
			accessIf: [Functionalities.ManageKioskUtilities],
			helpSiteAnchor: "tools-features/online-backups"
		}
	},
	{
		path: "/:centerId(\\d{4,})/online-backups",
		name: "online-backups",
		component: () => import("@app-views/online-backups/online-backups.vue"),
		meta: {
			titleKey: "onlineBackupTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToOnlineBackup],
			accessIf: [Functionalities.AccessToOnlineBackup],
			helpSiteAnchor: "tools-features/online-backups"
		}
	},
	{
		path: "/:centerId(\\d{4,})/bumper-statistics",
		name: "bumper-statistics",
		component: () => import("@app-views/bumper/bumper.vue"),
		meta: {
			titleKey: "bumperStatisticsTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToBumperProtectionStatistics],
			accessIf: [Functionalities.AccessToBumperProtectionStatistics],
			helpSiteAnchor: "dashboards-reporting/bumper-protection-statistics"
		}
	},
	{
		path: "/:centerId(\\d{4,})/tips",
		name: "tips-reporting",
		component: () => import("@app-views/tips/tips.vue"),
		meta: {
			titleKey: "tipsReportingTitle",
			childOf: "center-home",
			showIf: [Functionalities.AccessToTipsReporting],
			accessIf: [Functionalities.AccessToTipsReporting]
		}
	},
	{
		path: "/:centerId(\\d{4,})/transactions",
		name: "web-transactions",
		component: () => import("@app-views/web-transactions/web-transactions.vue"),
		meta: {
			titleKey: "web_transactions_title",
			childOf: "center-home",
			showIf: [Functionalities.SquarePayment],
			accessIf: [Functionalities.SquarePayment]
		}
	},
	{
		path: "/:centerId(\\d{4,})/fnb-order-updates",
		name: fbOrderUpdates,
		component: () => import("@app-views/fnb-order-updates/fnb-order-updates.vue"),
		meta: {
			titleKey: "fnb_order_updates",
			childOf: "center-home",
			showIf: [Functionalities.ManageFBOrdersUpdates],
			accessIf: [Functionalities.ManageFBOrdersUpdates]
		}
	},
	{
		path: "/:centerId(\\d{4,})/fnb-order-updates/:id",
		name: fbOrderUpdatesDetails,
		component: () => import("@app-views/fnb-order-updates/order-updates-detail/order-updates-detail.vue"),
		meta: {
			titleKey: "label_order_details",
			childOf: "fnb-order-updates"
		}
	}
] as QRoute[];
