import { IPaymentCurrency } from "@app-rest/common.interfaces";
import { RestGlobalFactory } from "@app-rest/global";
import type { QRoute } from "@app-router/index";
import store from "@app-store/index";
import EventsManager from "@app-utils/events-manager";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

@Module class GlobalModuleFactory extends VuexModule {
	restClient = new RestGlobalFactory();
	appLoading = true;
	routeLoading = true;
	viewLoading = false;
	routes: QRoute[] = [];
	currencies: IPaymentCurrency[] | null = null;

	@Mutation setAppLoading(state: boolean) {
		this.appLoading = state;
	}

	@Mutation setRouteLoading(state: boolean) {
		this.routeLoading = state;
	}

	@Mutation setViewLoading(state: boolean) {
		this.viewLoading = state;
	}

	@Mutation setRoutes(routes: QRoute[]) {
		this.routes = routes;
	}

	@Mutation setRouteTitle(obj: { routeName: string; title: string }) {
		const route = this.routes.find(r => r.name === obj.routeName);
		if (!route || route.meta.title === obj.title) return;
		route.meta.title = obj.title;
		// FIXME: Tech Story 64112: [QPortal] Rework Navbar's breadcrumb
		this.routes = [...this.routes];
	}

	@Mutation setCurrencies(currencies: IPaymentCurrency[] | null) {
		this.currencies = currencies;
	}

	@Action async syncCurrencies() {
		const response = await this.restClient.currencies();
		this.setCurrencies(response?.data ?? null);
	}

	@Action stopRouteLoadingDelayed() {
		return new Promise<void>((resolve) => {
			setTimeout(() => {
				this.setRouteLoading(false);
				resolve();
			}, 550);
		});
	}
}

const moduleName = "global";

let GlobalStore: GlobalModuleFactory | null;
function useGlobalStore() {
	if (GlobalStore) return GlobalStore;
	const mod = GlobalStore = new GlobalModuleFactory({ name: moduleName, store });
	EventsManager.onError(() => {
		mod.setViewLoading(false);
		mod.setRouteLoading(false);
	});
	return mod;
}

export default useGlobalStore;
