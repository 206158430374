import type { IResponseError, IRestPatchSortData, IRestResponse } from "@app-rest/common.interfaces";
import PortalRestClientFactory from "@app-rest/index";

import type { IAppearance, ITariffAvailableQuantities, ITariffsPatchData, IWebOffer, IWebOfferActivationStatus, IWebOfferAlwaysAvailableStatus, IWebOfferCore, IWebOfferDepositFees, IWebOfferDuplicateData, IWebOfferLanesLinkedStatus, IWebOfferLanesList, IWebOfferLanesPatch, IWebOfferLanesRecap, IWebOfferLanesStatus, IWebOfferLanesStatusLinkedParamsGet, IWebOfferList, IWebOfferOptions, IWebOfferOrder, IWebOfferServices } from "./offers.interfaces";
import type { IReservationExperience, IReservationOfferPriceKey, IReservationType, OpenType } from "./reservations.interfaces";
import type { IPutCustomDayByDayData, IPutCustomEveryDayData, IPutDayByDayData, IPutEveryDayData, ISystemCalendarDetails, ISystemCalendars } from "./system.calendars.interfaces";

const WebOffersAllErrors = ["ExperienceNotValid", "WebOfferConflict"] as const;
const SingleWebOfferAllErrors = ["WebOfferConflict", "SingleWebOfferArgument", "WebOfferViolateBookableRules", "WebOfferNotActivable", "WebOfferIdNotFound", "WebOfferNotActivableException", "ExperiencePriceKeyNotCompatible", "ExperiencePriceKeyIdNotFound", "WebOfferCannotBeActivated"] as const;
const WebOffersCalendarAllErrors = ["InvalidDayConflict", "InvalidPeriodConflict", "CalendarConflict", "PeriodNotFound"] as const;
type WebOffersErrorCodes = typeof WebOffersAllErrors[number];
type SingleWebOfferErrorCodes = typeof SingleWebOfferAllErrors[number];
export type WebOfferActivationErrorCodes = "ExperiencePriceKeysPriceValueMustBeZero" | "";
export type WebOffersCalendarErrorCodes = typeof WebOffersCalendarAllErrors[number];

export class WebOffersRestFactory extends PortalRestClientFactory {
	constructor(systemId?: number) {
		super();
		this.options.set("basePath", `/api/${systemId}`);
	}

	offers() {
		return this.get<IWebOfferList>("/weboffers/list");
	}

	offer(id: number) {
		return this.get<IWebOffer>(`/weboffers/${id}`);
	}

	changeOrder(obj: IWebOfferOrder[]) {
		return this.patch("/weboffers/sort", {
			body: {
				SortWebOffers: obj
			}
		});
	}

	webOffersQuantities(type: OpenType) {
		return this.get<ITariffAvailableQuantities>(`/weboffers/available-quantities?OpenType=${type}`);
	}

	addWebOffer(data: IWebOfferCore) {
		return this.post<IWebOffer, IResponseError<WebOffersErrorCodes>>("/weboffers", {
			body: data,
			throwExcluding: [error => {
				return error.statusCode === 409
					&& typeof error.data?.Error.Code === "string"
					&& WebOffersAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	duplicateWebOffer(webOfferId: number) {
		return this.post<IWebOfferDuplicateData>(`/weboffers/${webOfferId}/duplicate`);
	}

	updateWebOfferCoreSettings(id: number, data: IWebOfferCore) {
		return this.patch<null, IResponseError<SingleWebOfferErrorCodes>>(`/weboffers/${id}/core`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			throwExcluding: [error => {
				return error.statusCode === 409
					&& typeof error.data?.Error.Code === "string"
					&& SingleWebOfferAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	updateWebOfferAppearance(id: number, data: IAppearance) {
		return this.patch(`/weboffers/${id}/appearance`, {
			body: data
		});
	}

	updateWebOfferCoreTariffs(id: number, data: Partial<ITariffsPatchData>) {
		return this.patch(`/weboffers/${id}/tariffs`, {
			body: data
		});
	}

	updateWebOfferActivation(id: number, data: IWebOfferActivationStatus) {
		return this.patch<IWebOffer, IResponseError<SingleWebOfferErrorCodes, WebOfferActivationErrorCodes[]>>(`/weboffers/${id}/activation`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			// FIXME: Check throwExcluding without Error Codes - TechStory 42933
			// not all error codes are exposed
			throwExcluding: [error => {
				return ((error.statusCode === 409 || error.statusCode === 404)
					&& typeof error.data?.Error.Code === "string"
					&& SingleWebOfferAllErrors.includes(error.data.Error.Code));
			}]
		});
	}

	webOfferServices(id: number) {
		return this.get<IWebOfferServices>(`/weboffers/${id}/services`);
	}

	updateWebOffersServices(id: number, data: IWebOfferServices) {
		return this.put(`/weboffers/${id}/services`, {
			body: data
		});
	}

	updateWebOfferEconomicalSettings(id: number, data: Partial<IWebOfferDepositFees>) {
		return this.patch(`/weboffers/${id}/economical-settings`, {
			body: data
		});
	}

	webOfferOptions(webOfferId: number) {
		return this.get<IWebOfferOptions>(`/weboffers/${webOfferId}/options`);
	}

	updateWebOfferOptions(webOfferId: number, data: IWebOfferOptions) {
		return this.patch<null, IResponseError<string, string[]>>(`/weboffers/${webOfferId}/options`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			// FIXME: Check throwExcluding without Error Codes - TechStory 42933
			// not all error codes are exposed
			throwExcluding: [{ statusCode: 409 }]
		});
	}

	deleteWebOffer(webOfferId: number, force: boolean) {
		return this.delete<null>(`/weboffers/${webOfferId}?force=${force}`, {
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			// FIXME: Check throwExcluding without Error Codes - TechStory 42933
			throwExcluding: [{ statusCode: 400 }]
		});
	}

	reservationTypes() {
		return this.get<IReservationType[]>("/reservation-types");
	}

	experiences() {
		return this.get<IReservationExperience[]>("/experiences");
	}

	experiencesPriceKeysByOpenType(id: number, openType: OpenType) {
		return this.get<ITariffAvailableQuantities>(`/experiences/${id}/PriceKeysByQuantity?openType=${openType}`);
	}

	setOfferPriceKey(id: number) {
		return this.put<IReservationOfferPriceKey>(`/offers/${id}/tariffs`);
	}

	setAlwaysAvailable(webOfferId: number, data: IWebOfferAlwaysAvailableStatus) {
		return this.patch<IWebOffer>(`/weboffers/${webOfferId}/alwaysavailable`, {
			body: data
		});
	}

	calendars(webOfferId: number) {
		return this.get<ISystemCalendars>(`/${webOfferId}/webofferscalendarperiod`);
	}

	calendar(webOfferId: number, title: string) {
		return this.get<ISystemCalendarDetails>(`/${webOfferId}/webofferscalendarperiod/detail?title=${title}`);
	}

	checkIfCalendarExist(webOfferId: number, title: string) {
		return this.get<null, IResponseError<WebOffersCalendarErrorCodes>>(`/${webOfferId}/webofferscalendarperiod/exists?title=${title}`, {
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			throwExcluding: [error => {
				return error.statusCode === 404
					&& typeof error.data?.Error.Code === "string"
					&& WebOffersCalendarAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	defaultDayByDay(webOfferId: number, data: IPutDayByDayData) {
		return this.put<null, IResponseError<WebOffersCalendarErrorCodes>>(`/${webOfferId}/webofferscalendarperiod/default/daybyday`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			throwExcluding: [error => {
				return error.statusCode === 409
					&& typeof error.data?.Error.Code === "string"
					&& WebOffersCalendarAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	defaultEveryDay(webOfferId: number, data: IPutEveryDayData) {
		return this.put<null, IResponseError<WebOffersCalendarErrorCodes>>(`/${webOfferId}/webofferscalendarperiod/default/everyday`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			throwExcluding: [error => {
				return error.statusCode === 409
					&& typeof error.data?.Error.Code === "string"
					&& WebOffersCalendarAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	customDayByDay(webOfferId: number, data: IPutCustomDayByDayData, title: string) {
		return this.put<null, IResponseError<WebOffersCalendarErrorCodes>>(`/${webOfferId}/webofferscalendarperiod/custom/daybyday?title=${title}`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			throwExcluding: [error => {
				return error.statusCode === 409
					&& typeof error.data?.Error.Code === "string"
					&& WebOffersCalendarAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	customEveryDay(webOfferId: number, data: IPutCustomEveryDayData, title: string) {
		return this.put<null, IResponseError<WebOffersCalendarErrorCodes>>(`/${webOfferId}/webofferscalendarperiod/custom/everyday?title=${title}`, {
			body: data,
			responseType: (req, res) => {
				if (res?.status === 200)
					return "text";
				return "json";
			},
			throwExcluding: [error => {
				return error.statusCode === 409
					&& typeof error.data?.Error.Code === "string"
					&& WebOffersCalendarAllErrors.includes(error.data.Error.Code);
			}]
		});
	}

	deleteCalendar(webOfferId: number, title: string) {
		return this.delete(`/${webOfferId}/webofferscalendarperiod?title=${title}`);
	}

	lanesGroupList(webOfferId: number) {
		return this.get<IWebOfferLanesList>(`/WebOfferLaneGroups/${webOfferId}/List`);
	}

	lanesGroupStatus(webOfferId: number) {
		return this.get<IWebOfferLanesStatus>(`/WebOfferLaneGroups/${webOfferId}/Status`);
	}

	lanesGroupLinkedStatus(query: IWebOfferLanesStatusLinkedParamsGet) {
		return this.get<IRestResponse<IWebOfferLanesLinkedStatus>>(`/WebOfferLaneGroups/${query.WebOfferId}/Linked/Status`, { query });
	}

	lanesGroupLanes(webOfferId: number) {
		return this.get<IWebOfferLanesRecap>(`/WebOfferLaneGroups/${webOfferId}/Lanes`);
	}

	patchLanesGroup(webOfferId: number, data: IWebOfferLanesPatch) {
		return this.patch(`/WebOfferLaneGroups/${webOfferId}`, {
			body: data
		});
	}

	deleteLanesGroup(webOfferId: number, laneGroups: string[]) {
		return this.delete(`/WebOfferLaneGroups/${webOfferId}`, {
			query: {
				laneGroups: laneGroups.toString()
			}
		});
	}

	patchSortLanesGroup(webOfferId: number, data: IRestPatchSortData) {
		return this.patch(`/WebOfferLaneGroups/${webOfferId}/Sort`, {
			body: data
		});
	}

	earliestAvailability() {
		return this.get<string[]>("WebOffers/earliest-availability-types");
	}
}
