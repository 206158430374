import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/.";

import { settingsWebsite } from ".";

export const genericPageWebOffers = "text-and-images-web-offers";
export const genericPageShoesSocks = "text-and-images-shoes-and-socks";
export const genericPageExtras = "text-and-images-games-and-extras";
export const genericPageFood = "text-and-images-food-and-drink";
export const genericPageCart = "text-and-images-cart";
export const genericPageCompanyRegistration = "text-and-images-company-registration";
export const genericPageReservationConfirmed = "text-and-images-reservation-confirmed";
export const graphicalThemes = "graphical-themes";
export const graphicalThemesDefine = "graphical-themes-define-themes";
export const graphicalThemesApply = "graphical-themes-apply-themes";
export const graphicalThemesDetail = "graphical-themes-define-themes-theme";
export const graphicalThemesTitle = "graphical-themes-define-themes-title";
export const graphicalThemesFonts = "graphical-themes-define-themes-fonts";
export const graphicalThemesWebsite = "graphical-themes-define-themes-website";
export const graphicalThemesEmail = "graphical-themes-define-themes-email";
export const graphicalThemesPDF = "graphical-themes-define-themes-confirmation-pdf";

export default [
	{
		path: "/:centerId(\\d{4,})/settings/website/website-menu",
		name: "website-menu",
		component: () => import("@app-views/settings/website/header-menu/website-menu.vue"),
		meta: {
			titleKey: "settings_website_header_menu",
			childOf: settingsWebsite,
			groupName: "graphics",
			accessIf: [Functionalities.DefineWebsiteMenu]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images",
		name: "text-and-images",
		component: () => import("@app-views/settings/website/text-and-images/text-and-images.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_texts_images",
			childOf: settingsWebsite,
			groupName: "graphics",
			accessIf: [Functionalities.DefineTextAndImages]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/maintenance-mode",
		name: "maintenance-mode",
		component: () => import("@app-views/settings/website/maintenance-mode/maintenance-mode.vue"),
		meta: {
			titleKey: "settings_web_maintenance_mode",
			childOf: settingsWebsite,
			groupName: "maintenance",
			accessIf: [Functionalities.DefineWebsiteMaintenance]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/common-parts",
		name: "text-and-images-common-parts",
		component: () => import("@app-views/settings/website/text-and-images/common-parts/common-parts.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_common_parts",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/home-page",
		name: "text-and-images-home-page",
		component: () => import("@app-views/settings/website/text-and-images/homepage/homepage.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_home_page",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/web-offers",
		name: genericPageWebOffers,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_web_offers",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/shoes-and-socks",
		name: genericPageShoesSocks,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_shoes_socks",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/games-and-extras",
		name: genericPageExtras,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_games_extras",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/food-and-drink",
		name: genericPageFood,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_food_beverage",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/cart",
		name: genericPageCart,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "com_your_order",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/company-registration",
		name: genericPageCompanyRegistration,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "com_company_registration",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/reservation-confirmed",
		name: genericPageReservationConfirmed,
		component: () => import("@app-views/settings/website/text-and-images/generic/generic.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_reservation_confirmed",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/thankyou",
		name: "text-and-images-thankyou",
		component: () => import("@app-views/settings/website/text-and-images/thankyou/thankyou.vue"),
		meta: {
			titleKey: "com_thank_you",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/order-details-pdf",
		name: "text-and-images-order-details-pdf",
		component: () => import("@app-views/settings/website/text-and-images/pdf/pdf.vue"),
		meta: {
			groupName: "email-pdf",
			titleKey: "settings_web_lookfeel_text_images_order_details_pdf",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/text-and-images/confirmation_email",
		name: "text-and-images-confirmation_email",
		component: () => import("@app-views/settings/website/text-and-images/email/email.vue"),
		meta: {
			groupName: "email-pdf",
			titleKey: "settings_web_lookfeel_text_images_confirmation_email",
			childOf: "text-and-images"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website/website-menu/:id",
		name: "website-menu-item",
		component: () => import("@app-views/settings/website/header-menu/menu-item.vue"),
		meta: {
			childOf: "website-menu"
		}
	}
] as QRoute[];
