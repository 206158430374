<template>
	<q-button
		class="navigation-button"
		:variant="variant"
		v-bind="instanceAttrs"
		:class="responsiveClasses"
		data-test="button-navigation"
		@click="onClickEvent"
	>
		<span
			class="content-wrapper"
			:class="{ 'with-status': isUsingStatusSlot }"
		>
			<slot name="before-content" />
			<span class="content"><slot /></span>
			<span class="additional-info"><slot name="additional-info" /></span>
			<span class="status"><slot name="status" /></span>
			<slot name="icon-arrow"><i class="icon arrow fal fa-chevron-right" /></slot>
		</span>
	</q-button>
</template>

<script lang="ts" src="./navigation-button.ts"></script>
<style lang="scss" src="./navigation-button.scss"></style>
