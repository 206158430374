import type { Functionality } from "@app-rest/company/interfaces";
import Guard from "@app-router/guard";
import Agent4DRoutes from "@app-router/routes/agent4D";
import BaseRoutes from "@app-router/routes/base";
import CmpRoutes from "@app-router/routes/cmp";
import CompaniesRoutes from "@app-router/routes/companies";
import PosSolutionRoutes from "@app-router/routes/pos-solution";
import ReservationsRoutes from "@app-router/routes/reservations";
import SettingsRoutes from "@app-router/routes/settings";
import SettingsGraphicThemesRoutes from "@app-router/routes/settings/graphic-themes";
import SettingsKiosksRoutes from "@app-router/routes/settings/kiosks";
import SettingsReservationRoutes from "@app-router/routes/settings/reservation";
import SettingsFoodRoutes from "@app-router/routes/settings/reservation.food";
import SettingsOffersRoutes from "@app-router/routes/settings/reservation.offers";
import SettingsPrivacyTerms from "@app-router/routes/settings/reservation.privacy-and-terms";
import SettingsShoesRoutes from "@app-router/routes/settings/reservation.shoes";
import SettingsWebSiteRoutes from "@app-router/routes/settings/website";
import CentersRoutes from "@app-router/routes/systems";
import useGlobalStore from "@app-store/global";
import { EventAI, trackUserEvent, trackView } from "@app-utils/app-insights";
import { identifyChameleonUser } from "@app-utils/chameleon";
import { union } from "lodash-es";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

type MetaPropertyGetter<K> = {(): K};
export type AccessHandler = Functionality | {():boolean}
export interface QRouteMeta {
	childOf?: string;
	title?: string;
	titleKey?: string | MetaPropertyGetter<string>;
	disabled?: boolean;
	description?: string;
	statusLabel?: string;
	groupName?: string;
	groupTitleKey?: string;
	helpSiteAnchor?: string;
	allowAnonymous?: boolean;
	showIf?: AccessHandler | AccessHandler[];
	accessIf?: AccessHandler | AccessHandler[];
	inheritShowAccessIfs?: boolean;
}
export type QRoute = RouteRecordRaw & {
	meta: QRouteMeta
}

function buildRoutes() {
	const baseRoutes: QRoute[] = [
		...BaseRoutes,
		...CentersRoutes,
		...CmpRoutes,
		...CompaniesRoutes,
		...SettingsRoutes,
		...SettingsReservationRoutes,
		...SettingsFoodRoutes,
		...SettingsPrivacyTerms,
		...SettingsOffersRoutes,
		...SettingsShoesRoutes,
		...SettingsKiosksRoutes,
		...ReservationsRoutes,
		...SettingsWebSiteRoutes,
		...SettingsGraphicThemesRoutes,
		...PosSolutionRoutes,
		...Agent4DRoutes
	];
	const mergeSecurity = (routeAccess: AccessHandler | AccessHandler[], parentAccess: AccessHandler | AccessHandler[]) => {
		const route = !Array.isArray(routeAccess) ? [routeAccess] : routeAccess;
		const parent = !Array.isArray(parentAccess) ? [parentAccess] : parentAccess;
		return union(route, parent);
	};
	const routes = baseRoutes.map(route => {
		if (route.meta && route.meta.childOf && route.meta.childOf !== "center-home" && typeof route.meta.inheritShowAccessIfs === "undefined")
			route.meta.inheritShowAccessIfs = true;

		if (route.meta && !route.meta.title && !route.meta.titleKey)
			route.meta.title = "";

		if (route.meta && route.meta.inheritShowAccessIfs) {
			const parent = baseRoutes.find(pr => pr.name === route.meta.childOf);
			if (parent) {
				if (parent.meta.showIf)
					route.meta.showIf = mergeSecurity(route.meta.showIf ?? [], parent.meta.showIf);
				if (parent.meta.accessIf)
					route.meta.accessIf = mergeSecurity(route.meta.accessIf ?? [], parent.meta.accessIf);
			}
		}
		return route;
	});
	return routes;
}
export const Routes: QRoute[] = buildRoutes();

const Router = createRouter({
	history: createWebHistory(),
	linkActiveClass: "active",
	routes: Routes
});

export function setRouteTitle(route: QRoute | string, title: string) {
	const globalStore = useGlobalStore();
	if (typeof route === "string")
		route = globalStore.routes.find(r => r.name === route) as QRoute;
	globalStore.setRouteTitle({ routeName: route.name?.toString() ?? "", title });
}
export function setWasTryingToGo(to) {
	(window as any).wasTryingToGo = to;
}
export function getWasTryingToGo(): QRoute {
	const wasTryingToGo = (window as any).wasTryingToGo;
	delete (window as any).wasTryingToGo;
	return wasTryingToGo;
}
export function exitToErrorPage(next, err) {
	next({ name: "error-page", params: { err } });
}
export function setErrorObject(err) {
	(window as any).errorObject = err;
}
export function getErrorObject(): Error {
	const errObj = (window as any).errorObject;
	delete (window as any).errorObject;
	return errObj;
}

Router.beforeEach((to, from, next) => Guard(to, from, next));
Router.afterEach(to => {
	const globalStore = useGlobalStore();
	if (globalStore.appLoading) {
		globalStore.setAppLoading(false);
		trackUserEvent(EventAI.AppReady);
	}

	if (to.name)
		trackView(to.name.toString(), "end", to.fullPath);

	identifyChameleonUser();
});

export default Router;
