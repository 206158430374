import type { IRestPatchSortData, IRestResponse } from "@app-rest/common.interfaces";
import { WebOffersRestFactory } from "@app-rest/system/offers";
import type { IAppearance, ITariffAvailableQuantity, ITariffsPatchData, IWebOffer, IWebOfferActivationStatus, IWebOfferAlwaysAvailableStatus, IWebOfferCore, IWebOfferDepositFees, IWebOfferLanesLinkedStatus, IWebOfferLanesPatch, IWebOfferLanesStatusLinkedParamsGet, IWebOfferListElement, IWebOfferOptions, IWebOfferOrder, IWebOfferServices } from "@app-rest/system/offers.interfaces";
import { RestReservations } from "@app-rest/system/reservations";
import { type IReservationExperience, type IReservationType, OpenType } from "@app-rest/system/reservations.interfaces";
import type { IPutCustomDayByDayData, IPutCustomEveryDayData, IPutDayByDayData, IPutEveryDayData, ISystemCalendars } from "@app-rest/system/system.calendars.interfaces";
import store from "@app-store/index";
import useSystemStore from "@app-store/system";
import EventsManager from "@app-utils/events-manager";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

@Module class WebOfferModuleFactory extends VuexModule {
	rest: WebOffersRestFactory | null = null;
	webOffers: IWebOfferListElement[] = [];
	reservationTypes: IReservationType[] = [];
	experiences: IReservationExperience[] = [];
	timeOptions: ITariffAvailableQuantity[] = [];
	gameOptions: ITariffAvailableQuantity[] = [];
	unlimitedOptions: ITariffAvailableQuantity[] = [];
	SelectedWebOfferServices: IWebOfferServices | null = null;
	selectedWebOffer: IWebOffer | null = null;
	calendars: ISystemCalendars | null = null;
	lanesGroups: IWebOfferLanesLinkedStatus[] | null = null;
	hasMoreLanesGroups = false;
	restReservations: RestReservations | null = null;
	@Mutation setRestClient(data: WebOffersRestFactory) {
		this.rest = data;
	}

	@Mutation setRestReservation(restClient: RestReservations | null) {
		this.restReservations = restClient;
	}

	@Action ensureRestClient() {
		if (!this.rest)
			this.setRestClient(new WebOffersRestFactory(useSystemStore().info?.Id));
		return Promise.resolve(this.rest as unknown as WebOffersRestFactory);
	}

	@Mutation clearData() {
		this.webOffers = [];
		this.reservationTypes = [];
		this.experiences = [];
		this.timeOptions = [];
		this.gameOptions = [];
		this.unlimitedOptions = [];
		this.selectedWebOffer = null;
		this.SelectedWebOfferServices = null;
		this.calendars = null;
		this.lanesGroups = null;
		this.hasMoreLanesGroups = false;
		this.rest = null;
		this.restReservations = null;
	}

	@Mutation setWebOffers(data: IWebOfferListElement[]) {
		this.webOffers = data;
	}

	@Mutation setSelectedWebOffer(data: IWebOffer | null) {
		this.selectedWebOffer = data;
	}

	@Mutation setTitleSelectedWebOffer(data: string) {
		if (this.selectedWebOffer)
			this.selectedWebOffer.Title = data;
	}

	@Mutation setReservationTypes(data: IReservationType[]) {
		this.reservationTypes = data;
	}

	@Mutation setSelectedWebOfferServices(data: IWebOfferServices | null) {
		this.SelectedWebOfferServices = data;
	}

	@Mutation setExperiences(data: IReservationExperience[]) {
		this.experiences = data;
	}

	@Mutation setTimeOptions(data: ITariffAvailableQuantity[]) {
		this.timeOptions = data;
	}

	@Mutation setGameOptions(data: ITariffAvailableQuantity[]) {
		this.gameOptions = data;
	}

	@Mutation setUnlimitedOptions(data: ITariffAvailableQuantity[]) {
		this.gameOptions = data;
	}

	// #region calendar web offer
	@Mutation setCalendars(data: ISystemCalendars | null) {
		this.calendars = data;
	}

	@Mutation setLanesGroups(data: IRestResponse<IWebOfferLanesLinkedStatus> | null) {
		this.lanesGroups = data?.Elements ?? null;
		this.hasMoreLanesGroups = data?.HasMoreElements ?? false;
	}

	@Mutation appendLanesGroups(data: IRestResponse<IWebOfferLanesLinkedStatus>) {
		const currentLanesGroups = this.lanesGroups ?? [];
		this.lanesGroups = [...currentLanesGroups, ...data.Elements];
		this.hasMoreLanesGroups = data.HasMoreElements ?? false;
	}

	// #endregion
	@Action async syncWebOffers() {
		const restClient = await this.ensureRestClient();
		const response = await restClient.offers();
		this.setWebOffers(response?.data?.WebOffers ?? []);
	}

	@Action async syncWebOffer(id: number) {
		const restClient = await this.ensureRestClient();
		const response = await restClient.offer(id);
		this.setSelectedWebOffer(response?.data ?? null);
	}

	@Action async changeWebOfferOrder(data: IWebOfferOrder[]) {
		const restClient = await this.ensureRestClient();
		return restClient.changeOrder(data);
	}

	@Action async syncTimeOptions() {
		const restClient = await this.ensureRestClient();
		const response = await restClient.webOffersQuantities(OpenType.Time);
		if (response && response.data) this.setTimeOptions(response.data.Quantities);
	}

	@Action async syncGameOptions() {
		const restClient = await this.ensureRestClient();
		const response = await restClient.webOffersQuantities(OpenType.Game);
		if (response && response.data) this.setGameOptions(response.data.Quantities);
	}

	@Action async syncUnlimitedOptions() {
		const restClient = await this.ensureRestClient();
		const response = await restClient.webOffersQuantities(OpenType.Unlimited);
		if (response && response.data) this.setUnlimitedOptions(response.data.Quantities);
	}

	@Action async syncReservationTypes() {
		const restClient = await this.ensureRestClient();
		const response = await restClient.reservationTypes();
		if (!response || !response.data) return;
		const notServiceReservation = response.data.filter(el => !el.IsService);
		this.setReservationTypes(notServiceReservation);
	}

	@Action async syncSelectedWebOfferServices(id?: number) {
		const webOfferId = id || this.selectedWebOffer?.WebOfferId;

		if (!webOfferId) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.webOfferServices(webOfferId);

		if (!response || !response.data) return;
		this.setSelectedWebOfferServices(response.data);
	}

	@Action async updateSelectedWebofferServices(data: IWebOfferServices) {
		if (!this.selectedWebOffer) return;
		const restClient = await this.ensureRestClient();
		return restClient.updateWebOffersServices(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async syncExperiences(type: OpenType) {
		const restClient = await this.ensureRestClient();
		const response = await restClient.experiences();
		if (!response || !response.data) return;
		const typeFilteredExperiences = response.data.filter(el => el.OpenTypes.includes(type));
		this.setExperiences(typeFilteredExperiences);
	}

	@Action async getExperiencePriceKeys(data: Partial<IWebOfferCore>) {
		const restClient = await this.ensureRestClient();
		if (data.ExperienceId && data.OpenType)
			return restClient.experiencesPriceKeysByOpenType(data.ExperienceId, data.OpenType);
	}

	@Action async addWebOffer(data: IWebOfferCore) {
		const restClient = await this.ensureRestClient();
		return await restClient.addWebOffer(data);
	}

	@Action async duplicateWebOffer(webOfferId: number) {
		const restClient = await this.ensureRestClient();
		const response = await restClient.duplicateWebOffer(webOfferId);

		return response;
	}

	@Action async updateWebOfferCoreSettings(data: IWebOfferCore) {
		if (!this.selectedWebOffer) return;
		const restClient = await this.ensureRestClient();
		return restClient.updateWebOfferCoreSettings(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async updateWebOfferAppearance(data: IAppearance) {
		if (!this.selectedWebOffer) return;
		const restClient = await this.ensureRestClient();
		return restClient.updateWebOfferAppearance(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async updateWebOfferTariffs(data: Partial<ITariffsPatchData>) {
		if (!this.selectedWebOffer) return;
		const restClient = await this.ensureRestClient();
		return restClient.updateWebOfferCoreTariffs(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async updateWebOfferEconomicalSettings(data: Partial<IWebOfferDepositFees>) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;
		return restClient.updateWebOfferEconomicalSettings(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async deleteWebOffer(force: boolean) {
		if (!this.selectedWebOffer) return;
		const restClient = await this.ensureRestClient();
		const newWebOfferList = this.webOffers.filter(el => el.WebOfferId !== this.selectedWebOffer?.WebOfferId);
		this.setWebOffers(newWebOfferList);
		return restClient.deleteWebOffer(this.selectedWebOffer.WebOfferId, force);
	}

	@Action async activateWebOffer(data: IWebOfferActivationStatus) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;
		const response = await restClient.updateWebOfferActivation(this.selectedWebOffer.WebOfferId, data);
		if (response.data) {
			const offers = [...this.webOffers];
			const index = offers.findIndex(offer => offer.WebOfferId === response.data?.WebOfferId);
			offers[index] = response.data;
			this.setWebOffers(offers);
			this.setSelectedWebOffer(response.data);
			this.setSelectedWebOfferServices(null);
		}
		return response;
	}

	@Action async setAvailabilityStatus(data: IWebOfferAlwaysAvailableStatus) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;
		const response = await restClient.setAlwaysAvailable(this.selectedWebOffer.WebOfferId, data);

		if (response) {
			this.setSelectedWebOffer(response.data);
			this.setSelectedWebOfferServices(null);
		}
	}

	// #region calendar web offer
	@Action async syncWebOfferCalendars() {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.calendars(this.selectedWebOffer.WebOfferId);
		this.setCalendars(response?.data ?? null);
	}

	@Action async getWebOfferCalendarDetails(title: string) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.calendar(this.selectedWebOffer.WebOfferId, title);
		return response;
	}

	@Action async checkIfCalendarExist(title: string) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.checkIfCalendarExist(this.selectedWebOffer.WebOfferId, title);
		if (response?.status === 200) return true;
		if (response?.status === 400) return false;

		return false;
	}

	@Action async saveDefaultDayByDay(data: IPutDayByDayData) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.defaultDayByDay(this.selectedWebOffer.WebOfferId, data);

		return response;
	}

	@Action async saveDefaultEveryDay(data: IPutEveryDayData) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.defaultEveryDay(this.selectedWebOffer.WebOfferId, data);

		return response;
	}

	@Action async saveCustomDayByDay({ data, title }: {data: IPutCustomDayByDayData, title: string}) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.customDayByDay(this.selectedWebOffer.WebOfferId, data, title);

		return response;
	}

	@Action async saveCustomEveryDay({ data, title }: { data: IPutCustomEveryDayData, title: string}) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		const response = await restClient.customEveryDay(this.selectedWebOffer.WebOfferId, data, title);

		return response;
	}

	@Action async deleteCalendar(title: string) {
		const restClient = await this.ensureRestClient();
		const result = await useSystemStore().ensureReservationParams();
		if (!result.Success || !this.selectedWebOffer) return null;

		await restClient.deleteCalendar(this.selectedWebOffer.WebOfferId, title);
	}

	// #endregion
	@Action async getWebOfferOptions() {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return null;

		const response = await restClient.webOfferOptions(this.selectedWebOffer.WebOfferId);

		if (!response) return null;
		return response.data;
	}

	@Action async updateWebOfferOptions(data: IWebOfferOptions) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;
		return restClient.updateWebOfferOptions(this.selectedWebOffer.WebOfferId, data);
	}

	// #region lane groups
	@Action async getLanesGroupLanes() {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return null;

		const response = await restClient.lanesGroupLanes(this.selectedWebOffer.WebOfferId);
		if (!response) return null;
		return response.data;
	}

	@Action async getLanesGroupList() {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;

		const response = await restClient.lanesGroupList(this.selectedWebOffer.WebOfferId);
		if (!response) return null;
		return response.data;
	}

	@Action async getLanesGroupStatus() {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return null;

		const response = await restClient.lanesGroupStatus(this.selectedWebOffer.WebOfferId);
		if (!response) return null;
		return response.data;
	}

	@Action async getLanesGroupLinkedStatus({ Page, ItemsPerPage }: {Page: number, ItemsPerPage: number}) {
		const restClient = await this.ensureRestClient();
		const systemId = useSystemStore().info?.Id;
		if (!this.selectedWebOffer || !systemId) return null;

		const params: IWebOfferLanesStatusLinkedParamsGet = {
			Page,
			ItemsPerPage,
			WebOfferId: this.selectedWebOffer.WebOfferId,
			CenterId: systemId
		};

		const response = await restClient.lanesGroupLinkedStatus(params);
		if (!response) return null;

		if (Page === 1)
			this.setLanesGroups(response.data);
		else if (response.data)
			this.appendLanesGroups(response.data);

		return response.data;
	}

	@Action async patchLanesGroup(data: IWebOfferLanesPatch) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return null;

		await restClient.patchLanesGroup(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async deleteLanesGroup(data: string[]) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return null;

		await restClient.deleteLanesGroup(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async patchLanesGroupSort(data: IRestPatchSortData) {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;

		await restClient.patchSortLanesGroup(this.selectedWebOffer.WebOfferId, data);
	}

	@Action async getEarliestAvailabilityOptions() {
		const restClient = await this.ensureRestClient();
		if (!this.selectedWebOffer) return;

		const response = await restClient.earliestAvailability();
		if (!response) return null;

		return response.data;
	}
	// #endregion
}

const moduleName = "system.webOffer";
let WebOfferStore: WebOfferModuleFactory | null;
function useWebOfferStore() {
	if (WebOfferStore) return WebOfferStore;
	const mod = WebOfferStore = new WebOfferModuleFactory({ name: moduleName, store });
	EventsManager.onSystemChanged(id => {
		mod.clearData();
		mod.setRestClient(new WebOffersRestFactory(id));
		mod.setRestReservation(new RestReservations(id));
	});
	return mod;
}

export default useWebOfferStore;
