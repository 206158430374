import PortalRestClientFactory from "@app-rest/index";
import { ICmpCenterDetailModel } from "@app-rest/system/cmp.interfaces";

import type { ICompanyCmpListModel, ICompanyInfoModel, IFunctionalities, IInvoice, IPrivilegeCategoryModel } from "./interfaces";

export default class RestCompanyFactory extends PortalRestClientFactory {
	company(companyId: number) {
		return this.get<ICompanyInfoModel>(`/companies/${companyId}`);
	}

	privilegesTree(companyId: number) {
		return this.get<IPrivilegeCategoryModel[]>(`/companies/${companyId}/privilegestree`);
	}

	companyCmp(companyId: number) {
		return this.get<ICompanyCmpListModel>(`/companies/${companyId}/cmp`);
	}

	companyCenterCmp(companyId: number, centerId: number) {
		return this.get<ICmpCenterDetailModel>(`/companies/${companyId}/cmp/${centerId}`);
	}

	cmpInvoices(companyId: number) {
		return this.get<IInvoice[]>(`/companies/${companyId}/invoices`);
	}

	cmpInvoice(companyId: number, centerId: number, invoiceId: number) {
		return this.get<Blob>(`/companies/${companyId}/invoices/${invoiceId}/center/${centerId}`, { responseType: "blob" });
	}

	functionalities(companyId: number) {
		return this.get<IFunctionalities>(`/companies/${companyId}/functionalities`);
	}
}
