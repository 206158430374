import useAppStore from "@app-store/app";

import QPortalApp from "@/main";

import usei18n from "./index";

const i18nPlugin = {
	install() {
		const { translateKey } = usei18n();
		const appStore = useAppStore();
		QPortalApp.config.globalProperties.$t = translateKey;

		if (process.env.LAUNCH_MODE === "DevelopSession" && appStore.env === "Develop") {
			import("@app-backend/Locales/qportal.xlf").then(xlf => {
				console.info(`[i18n] XLF Source file loaded, keys found: ${Object.keys(xlf.default.source).length}`);
			});
		}

		QPortalApp.directive("t", {
			mounted: (element, binding) => {
				const key = Object.keys(binding.modifiers)[0];
				const translatedString = translateKey(key, binding.value);
				element.innerHTML = translatedString;
			},
			updated: (element, binding) => {
				const key = Object.keys(binding.modifiers)[0];
				const translatedString = translateKey(key, binding.value);
				element.innerHTML = translatedString;
			}
		});
	}
};

export default i18nPlugin;
