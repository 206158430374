import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";
import SecurityPlugin from "@app-utils/security";
export const agent4DPlaylists = "playlists";
export const agent4DBillboards = "billboards";
export const agent4DTemplates = "billboards-templates";
export const agent4DTemplateDetails = "billboards-template-details";

export default [
	{
		path: "/:centerId(\\d{4,})/neoverse",
		name: "agent4D",
		component: () => import("@app-views/agent4D/agent4D.vue"),
		meta: {
			titleKey: "label_agent4D",
			childOf: "center-home",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.Agent4DExperimentalFeature,
				Functionalities.AccessNeoverse
			]),
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.Agent4DExperimentalFeature,
				Functionalities.AccessNeoverse
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/neoverse/playlists",
		name: agent4DPlaylists,
		component: () => import("@app-views/agent4D/playlists/playlists.vue"),
		meta: {
			titleKey: "agent4D_playlists",
			childOf: "agent4D"
		}
	},
	{
		path: "/:centerId(\\d{4,})/neoverse/playlists/:id",
		name: "agent4D-playlist-details",
		component: () => import("@app-views/agent4D/playlists/playlist-details/playlist-details.vue"),
		meta: {
			childOf: agent4DPlaylists
		}
	},
	{
		path: "/:centerId(\\d{4,})/neoverse/billboards",
		name: agent4DBillboards,
		component: () => import("@app-views/agent4D/billboards/billboards.vue"),
		meta: {
			titleKey: "agent4D_billboards",
			childOf: "agent4D"
		}
	},
	{
		path: "/:centerId(\\d{4,})/neoverse/billboards/:wallId/templates",
		name: agent4DTemplates,
		component: () => import("@app-views/agent4D/billboards/templates/templates.vue"),
		meta: {
			titleKey: "label_templates",
			childOf: agent4DBillboards
		}
	},
	{
		path: "/:centerId(\\d{4,})/neoverse/billboards/:wallId/templates/:templateId",
		name: agent4DTemplateDetails,
		component: () => import("@app-views/agent4D/billboards/templates/template-details/template-details.vue"),
		meta: {
			childOf: agent4DTemplates
		}
	}
] as QRoute[];
