import { RestRemoteAssistance } from "@app-rest/system/remoteAssistance";
import store from "@app-store/index";
import useSystemStore from "@app-store/system";
import { EventAI, trackUserEvent } from "@app-utils/app-insights";
import EventsManager from "@app-utils/events-manager";
import { formatISO } from "date-fns";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

@Module class RemoteAssistanceModuleFactory extends VuexModule {
	rest: RestRemoteAssistance | null = null;
	expireAtIso: string | null = null;

	@Mutation clearData() {
		this.rest = null;
		this.expireAtIso = null;
	}

	@Mutation setRest(restClient: RestRemoteAssistance) {
		this.rest = restClient;
	}

	@Action ensureRestClient() {
		if (!this.rest)
			this.setRest(new RestRemoteAssistance(useSystemStore().info?.Id));
		return Promise.resolve(this.rest as unknown as RestRemoteAssistance);
	}

	@Mutation setExpireAtIso(date: string | null) {
		this.expireAtIso = date;
	}

	@Action async syncAssistanceStatus() {
		const restClient = await this.ensureRestClient();
		const response = await restClient.getAssistanceExpiration();
		if (response && response.data)
			this.setExpireAtIso(response.data?.Exp);
	}

	@Action async startRemoteAssistance(date: Date) {
		const restClient = await this.ensureRestClient();
		await restClient.setAssistanceExpiration(date);
		this.setExpireAtIso(formatISO(date));
		trackUserEvent(EventAI.StartRemoteAssistance);
	}

	@Action async stopRemoteAssistance() {
		const restClient = await this.ensureRestClient();
		await restClient.setAssistanceExpiration(null);
		this.setExpireAtIso(null);
		trackUserEvent(EventAI.StopRemoteAssistance);
	}
}

const moduleName = "system.remoteAssistance";
let RemoteAssistanceStore: RemoteAssistanceModuleFactory | null;
function useRemoteAssistanceStore() {
	if (RemoteAssistanceStore) return RemoteAssistanceStore;
	const mod = RemoteAssistanceStore = new RemoteAssistanceModuleFactory({ name: moduleName, store });
	EventsManager.onSystemChanged(systemId => {
		mod.clearData();
		mod.setRest(new RestRemoteAssistance(systemId));
	});
	return mod;
}

export default useRemoteAssistanceStore;
