<xliff xmlns="urn:oasis:names:tc:xliff:document:2.0" version="2.0" srcLang="en-US">
	<file id="qportal">
		<group id="general">
			<unit id="welcomeTitle">
				<segment>
					<source>Welcome to</source>
				</segment>
			</unit>
			<unit id="businessDashboardTitle">
				<segment>
					<source>Business Dashboard</source>
				</segment>
			</unit>
			<unit id="gameStatisticsTitle">
				<segment>
					<source>Game Statistics</source>
				</segment>
			</unit>
			<unit id="bumperStatisticsTitle">
				<segment>
					<source>Bumper Protection Statistics</source>
				</segment>
			</unit>
			<unit id="tipsReportingTitle">
				<segment>
					<source>Tips Reporting</source>
				</segment>
			</unit>
			<unit id="performanceMonitoringTitle">
				<segment>
					<source>Performance Monitoring</source>
				</segment>
			</unit>
			<unit id="customerAccountsTitle">
				<segment>
					<source>Customer Accounts</source>
				</segment>
			</unit>
			<unit id="onlineBackupTitle">
				<segment>
					<source>Online Backups</source>
				</segment>
			</unit>
			<unit id="posSolutionTitle">
				<segment>
					<source>Square</source>
				</segment>
			</unit>
			<unit id="technicalResourcesTitle">
				<segment>
					<source>Technical Resources</source>
				</segment>
			</unit>
			<unit id="eShopTitle">
				<segment>
					<source>e-Shop</source>
				</segment>
			</unit>
			<unit id="trainingResourcesTitle">
				<segment>
					<source>Training Resources</source>
				</segment>
			</unit>
			<unit id="servicesTitle">
				<segment>
					<source>Services</source>
				</segment>
			</unit>
			<unit id="ManagementTitle">
				<segment>
					<source>Management</source>
				</segment>
			</unit>
			<unit id="configutationTitle">
				<segment>
					<source>Configuration</source>
				</segment>
			</unit>
			<unit id="settingsTitle">
				<segment>
					<source>Settings</source>
				</segment>
			</unit>
			<unit id="activeServicesTitle">
				<segment>
					<source>Active Services</source>
				</segment>
			</unit>
			<unit id="usersAndPrivsTitle">
				<segment>
					<source>Users &amp; Privileges</source>
				</segment>
			</unit>
			<unit id="roleSetupTitle">
				<segment>
					<source>Role Setup</source>
				</segment>
			</unit>
			<unit id="openid_token_page">
				<segment>
					<source>Session Info</source>
				</segment>
			</unit>
			<unit id="web_transactions_title">
				<segment>
					<source>Transactions</source>
				</segment>
			</unit>
			<unit id="remote_assistance_title">
				<segment>
					<source>Remote Assistance</source>
				</segment>
			</unit>
			<unit id="termsTitle">
				<segment>
					<source>Terms &amp; Conditions</source>
				</segment>
			</unit>
			<unit id="com_click_to_reload_page">
				<segment>
					<source>Click here to reload the page.</source>
				</segment>
			</unit>
			<unit id="reservationsTitle">
				<segment>
					<source>Reservations</source>
				</segment>
			</unit>
			<unit id="serviceReservationsTitle">
				<segment>
					<source>Service Reservations</source>
				</segment>
			</unit>
			<unit id="cmpProgramTitle">
				<segment>
					<source>Program Details</source>
				</segment>
			</unit>
			<unit id="cmpProgramsServicesTitle">
				<segment>
					<source>Programs &amp; Services Details</source>
				</segment>
			</unit>
			<unit id="hiTitle">
				<segment>
					<source>Hi</source>
				</segment>
			</unit>
			<unit id="dashboardsReportingTitle">
				<segment>
					<source>Dashboards &amp; Reporting</source>
				</segment>
			</unit>
			<unit id="servicesResourcesTitle">
				<segment>
					<source>Services &amp; Resources</source>
				</segment>
			</unit>
			<unit id="toolsFeaturesTitle">
				<segment>
					<source>Tools &amp; Features</source>
				</segment>
			</unit>
			<unit id="liveVirtualTrainingTitle">
				<segment>
					<source>Live Virtual Training</source>
				</segment>
			</unit>
			<unit id="marketingResourcesTitle">
				<segment>
					<source>Marketing Resources</source>
				</segment>
			</unit>
			<unit id="maxTrainingPlusTitle">
				<segment>
					<source>MaxTraining Plus</source>
				</segment>
			</unit>
			<unit id="dassleWebTitle">
				<segment>
					<source>Dassle Web</source>
				</segment>
			</unit>
			<unit id="requestServicesTitle">
				<segment>
					<source>Request Services</source>
				</segment>
			</unit>
			<unit id="serviceReservationTitle">
				<segment>
					<source>Service Reservation</source>
				</segment>
			</unit>
			<unit id="ManagementConfigurationTitle">
				<segment>
					<source>Management &amp; Configuration</source>
				</segment>
			</unit>
			<unit id="cloudDataSelectionTitle">
				<segment>
					<source>Cloud Data Selection</source>
				</segment>
			</unit>
			<unit id="programsLicensesTitle">
				<segment>
					<source>Programs &amp; Licenses</source>
				</segment>
			</unit>
			<unit id="rolesPrivilegesTitle">
				<segment>
					<source>Roles &amp; Privileges</source>
				</segment>
			</unit>
			<unit id="usersTitle">
				<segment>
					<source>Users</source>
				</segment>
			</unit>
			<unit id="payPalDemoTitle">
				<segment>
					<source>PayPal Demo</source>
				</segment>
			</unit>
			<unit id="reservationsGridTitle">
				<segment>
					<source>Reservations Grid</source>
				</segment>
			</unit>
			<unit id="kioskUtilities">
				<segment>
					<source>Kiosk Utilities</source>
				</segment>
			</unit>
			<unit id="webSiteTitle">
				<segment>
					<source>WebSite</source>
				</segment>
			</unit>
			<unit id="webReservationSite">
				<segment>
					<source>Web Reservations</source>
				</segment>
			</unit>
			<unit id="businessResultsTitle">
				<segment>
					<source>Business Results</source>
				</segment>
			</unit>
			<unit id="styleguideTitle">
				<segment>
					<source>Style Guide</source>
				</segment>
			</unit>
			<unit id="date_and_time">
				<segment>
					<source>{{date}} at {{time}}</source>
				</segment>
			</unit>
		</group>
		<group id="general_errors">
			<unit id="com_cannot_be_processed_network_issue">
				<segment>
					<source>
						Your request cannot be processed at this time due to a network issue or a problem in our system configuration.
					</source>
				</segment>
			</unit>
			<unit id="com_development_team_automatically_notified">
				<segment>
					<source>
						Our development team has automatically been notified of this issue and will investigate.
					</source>
				</segment>
			</unit>
			<unit id="com_click_reload_try_again">
				<segment>
					<source>
						You can click the reload button or try again later.
					</source>
				</segment>
			</unit>
			<unit id="com_apologize_inconvenience">
				<segment>
					<source>
						We apologize for the inconvenience.
					</source>
				</segment>
			</unit>
			<unit id="com_service_unavailable">
				<segment>
					<source>Service Unavailable</source>
				</segment>
			</unit>
			<unit id="com_center_not_found">
				<segment>
					<source>Center not found</source>
				</segment>
			</unit>
			<unit id="com_bowling_center_not_found">
				<segment>
					<source>The Bowling Center you are looking for is not found</source>
				</segment>
			</unit>
			<unit id="com_resource_not_found">
				<segment>
					<source>Resource not found</source>
				</segment>
			</unit>
			<unit id="com_unauthorized_request">
				<segment>
					<source>Unauthorized request</source>
				</segment>
			</unit>
			<unit id="com_unauthorized_access">
				<segment>
					<source>Unauthorized access</source>
				</segment>
			</unit>
			<unit id="com_access_denied_missing_privileges">
				<segment>
					<source>Access is denied as you do not have sufficient privileges.</source>
				</segment>
			</unit>
			<unit id="com_session_expired_title">
				<segment>
					<source>Session Expired</source>
				</segment>
			</unit>
			<unit id="com_session_expired">
				<segment>
					<source>Unfortunately your session has expired and we require to login again.</source>
				</segment>
			</unit>
			<unit id="com_session_expired_short">
				<segment>
					<source>Unfortunately your session has expired.</source>
				</segment>
			</unit>
			<unit id="com_please_login">
				<segment>
					<source>Please login again.</source>
				</segment>
			</unit>
			<unit id="com_error_occurred">
				<segment>
					<source>An error occurred</source>
				</segment>
			</unit>
			<unit id="com_saving_failed">
				<segment>
					<source>Saving failed</source>
				</segment>
			</unit>
			<unit id="com_error_required">
				<segment>
					<source>Mandatory field</source>
				</segment>
			</unit>
			<unit id="com_error_invalid_email">
				<segment>
					<source>Invalid email</source>
				</segment>
			</unit>
			<unit id="com_error_invalid_field">
				<segment>
					<source>Invalid field</source>
				</segment>
			</unit>
			<unit id="com_error_text_to_long">
				<segment>
					<source>Text too long</source>
				</segment>
			</unit>
			<unit id="com_error_invalid_format">
				<segment>
					<source>Invalid format</source>
				</segment>
			</unit>
			<unit id="com_error_invalid_range">
				<segment>
					<source>Invalid range</source>
				</segment>
			</unit>
			<unit id="com_error_invalid_range_maxdays">
				<segment>
					<source>The date range is limited to {{days}} days</source>
				</segment>
			</unit>
			<unit id="com_error_duplicate_email">
				<segment>
					<source>Duplicate Email</source>
				</segment>
			</unit>
			<unit id="com_error_limit_reached">
				<segment>
					<source>Limit {{el}} Reached</source>
				</segment>
			</unit>
			<unit id="com_remote_auth_failure">
				<segment>
					<source>Login credentials are valid but we are experiencing some trouble in logging you in with your current browser version, please update it or use another browser.</source>
				</segment>
			</unit>
			<unit id="error_services_activation">
				<segment>
					<source>The system configuration is not yet complete, therefore some services may not be available. If the problem persists, please contact our Tech Support.</source>
				</segment>
			</unit>
			<unit id="com_error_permission_viewdata">
				<segment>
					<source>You don't have permissions to view this data.</source>
				</segment>
			</unit>
			<unit id="com_error_invalid_hexcode">
				<segment>
					<source>Invalid hex code</source>
				</segment>
			</unit>
			<unit id="com_error_duplicate_title">
				<segment>
					<source>This title already exists</source>
				</segment>
			</unit>
			<unit id="com_error_at_least_one_active_service">
				<segment>
					<source>At least one active service</source>
				</segment>
			</unit>
			<unit id="com_error_no_links">
				<segment>
					<source>Links are not allowed</source>
				</segment>
			</unit>
		</group>
		<group id="common">
			<unit id="com_your_order">
				<segment>
					<source>Your Order</source>
				</segment>
			</unit>
			<unit id="com_your_reservation">
				<segment>
					<source>Your Reservation</source>
				</segment>
			</unit>
			<unit id="com_find_reservation">
				<segment>
					<source>Find Reservation</source>
				</segment>
			</unit>
			<unit id="com_reservation_details">
				<segment>
					<source>Reservation Details</source>
				</segment>
			</unit>
			<unit id="com_thank_you">
				<segment>
					<source>Thank You</source>
				</segment>
			</unit>
			<unit id="com_show_as_website">
				<segment>
					<source>Show logo as website</source>
				</segment>
			</unit>
			<unit id="com_company_registration">
				<segment>
					<source>Company Registration</source>
				</segment>
			</unit>
			<unit id="com_unsaved_changes">
				<segment>
					<source>Unsaved changes</source>
				</segment>
			</unit>
			<unit id="com_you_some_unsaved_changes">
				<segment>
					<source>You have some unsaved changes. If you leave before saving, your changes will be lost. Do you want to continue?</source>
				</segment>
			</unit>
			<unit id="com_proceed_question">
				<segment>
					<source>Are you sure you want to proceed?</source>
				</segment>
			</unit>
			<unit id="com_continue_question">
				<segment>
					<source>Are you sure you want to continue?</source>
				</segment>
			</unit>
			<unit id="com_try_different_credentials">
				<segment>
					<source>Please try different credentials.</source>
				</segment>
			</unit>
			<unit id="com_contact_support">
				<segment>
					<source>Please contact our Tech Support.</source>
				</segment>
			</unit>
			<unit id="com_generic_error">
				<segment>
					<source>Something went wrong</source>
				</segment>
			</unit>
			<unit id="com_no_records">
				<segment>
					<source>No records found</source>
				</segment>
			</unit>
			<unit id="com_min_label">
				<segment>
					<source>min</source>
				</segment>
			</unit>
			<unit id="com_max_label">
				<segment>
					<source>max</source>
				</segment>
			</unit>
			<unit id="com_min_label_capitalize">
				<segment>
					<source>Min</source>
				</segment>
			</unit>
			<unit id="com_max_label_capitalize">
				<segment>
					<source>Max</source>
				</segment>
			</unit>
			<unit id="com_min_dotted">
				<segment>
					<source>Min:</source>
				</segment>
			</unit>
			<unit id="com_max_dotted">
				<segment>
					<source>Max:</source>
				</segment>
			</unit>
			<unit id="com_fromtime_label">
				<segment>
					<source>From {{timeFrom}}</source>
				</segment>
			</unit>
			<unit id="com_totime_label">
				<segment>
					<source>To {{timeTo}}</source>
				</segment>
			</unit>
			<unit id="com_lanenr_label">
				<segment>
					<source>Lane {{laneNr}}</source>
				</segment>
			</unit>
			<unit id="com_number_range">
				<segment>
					<source>{{fromNumber}} to {{toNumber}}</source>
				</segment>
			</unit>
			<unit id="com_daterange_value">
				<segment>
					<source>{{dateFrom}} to {{dateTo}}</source>
				</segment>
			</unit>
			<unit id="com_timerange_value">
				<segment>
					<source>{{timeFrom}} to {{timeTo}}</source>
				</segment>
			</unit>
			<unit id="com_paired">
				<segment>
					<source>Paired</source>
				</segment>
			</unit>
			<unit id="com_not_paired">
				<segment>
					<source>Not paired</source>
				</segment>
			</unit>
			<unit id="com_select_center">
				<segment>
					<source>Select Center</source>
				</segment>
			</unit>
			<unit id="com_all_center">
				<segment>
					<source>All Centers</source>
				</segment>
			</unit>
			<unit id="com_use_center_lang">
				<segment>
					<source>Use Center language</source>
				</segment>
			</unit>
			<unit id="com_use_different_lang">
				<segment>
					<source>Use a different language</source>
				</segment>
			</unit>
			<unit id="com_browser_unsupported_feature">
				<segment>
					<source>Your browser is not supporting this functionality, please update it or use another browser.</source>
				</segment>
			</unit>
			<unit id="com_sunday">
				<segment>
					<source>Sunday</source>
				</segment>
			</unit>
			<unit id="com_monday">
				<segment>
					<source>Monday</source>
				</segment>
			</unit>
			<unit id="com_tuesday">
				<segment>
					<source>Tuesday</source>
				</segment>
			</unit>
			<unit id="com_wednesday">
				<segment>
					<source>Wednesday</source>
				</segment>
			</unit>
			<unit id="com_thursday">
				<segment>
					<source>Thursday</source>
				</segment>
			</unit>
			<unit id="com_friday">
				<segment>
					<source>Friday</source>
				</segment>
			</unit>
			<unit id="com_saturday">
				<segment>
					<source>Saturday</source>
				</segment>
			</unit>
			<unit id="com_extra1">
				<segment>
					<source>Extra 1</source>
				</segment>
			</unit>
			<unit id="com_extra2">
				<segment>
					<source>Extra 2</source>
				</segment>
			</unit>
			<unit id="com_extra3">
				<segment>
					<source>Extra 3</source>
				</segment>
			</unit>
			<unit id="com_everyday">
				<segment>
					<source>Every day</source>
				</segment>
			</unit>
			<unit id="pday_previous_month">
				<segment>
					<source>Previous Month</source>
				</segment>
			</unit>
			<unit id="pday_next_month">
				<segment>
					<source>Next Month</source>
				</segment>
			</unit>
			<unit id="modal_updated_successfully">
				<segment>
					<source>{{subject}} updated successfully.</source>
				</segment>
			</unit>
			<unit id="com_repeats_every_year">
				<segment>
					<source>Repeats every year</source>
				</segment>
			</unit>
			<unit id="com_create">
				<segment>
					<source>Create</source>
				</segment>
			</unit>
			<unit id="com_slection_only_visible">
				<segment>
					<source>The selection works only on visibile items</source>
				</segment>
			</unit>
			<unit id="com_add_categories">
				<segment>
					<source>Add Categories</source>
				</segment>
			</unit>
			<unit id="com_create_category">
				<segment>
					<source>Create category</source>
				</segment>
			</unit>
			<unit id="com_area">
				<segment>
					<source>Area</source>
				</segment>
			</unit>
			<unit id="com_create_area">
				<segment>
					<source>Create area</source>
				</segment>
			</unit>
			<unit id="com_select_area">
				<segment>
					<source>Select area</source>
				</segment>
			</unit>
			<unit id="com_add_area">
				<segment>
					<source>Add area</source>
				</segment>
			</unit>
			<unit id="com_change_area">
				<segment>
					<source>Change area</source>
				</segment>
			</unit>
			<unit id="com_select_department">
				<segment>
					<source>Select department</source>
				</segment>
			</unit>
			<unit id="com_add_remove_categories">
				<segment>
					<source>Add / Remove Categories</source>
				</segment>
			</unit>
			<unit id="com_available_all_web_offers">
				<segment>
					<source>Available for every web offer</source>
				</segment>
			</unit>
			<unit id="com_confirm_delete">
				<segment>
					<source>Are you sure you want to delete the item '{{name}}'?</source>
				</segment>
			</unit>
			<unit id="com_remove_categories">
				<segment>
					<source>Remove Categories</source>
				</segment>
			</unit>
			<unit id="com_days">
				<segment>
					<source>days</source>
				</segment>
			</unit>
			<unit id="com_hours">
				<segment>
					<source>hours</source>
				</segment>
			</unit>
			<unit id="com_minutes">
				<segment>
					<source>minutes</source>
				</segment>
			</unit>
			<unit id="com_seconds">
				<segment>
					<source>seconds</source>
				</segment>
			</unit>
			<unit id="com_bowlers_per_lane">
				<segment>
					<source>Bowlers per lane</source>
				</segment>
			</unit>
			<unit id="com_bowler_info_logged">
				<segment>
					<source>Bowler Information - Logged user</source>
				</segment>
			</unit>
			<unit id="com_bowler_info_guest">
				<segment>
					<source>Bowler Information - Guest</source>
				</segment>
			</unit>
			<unit id="com_lanes_in_a_reservation">
				<segment>
					<source>Lanes in a reservation</source>
				</segment>
			</unit>
			<unit id="com_time_slot_15MinutesFromNow">
				<segment>
					<source>15 minutes from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_30MinutesFromNow">
				<segment>
					<source>30 minutes from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_1HourFromNow">
				<segment>
					<source>1 hour from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_2HoursFromNow">
				<segment>
					<source>2 hours from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_3HoursFromNow">
				<segment>
					<source>3 hours from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_4HoursFromNow">
				<segment>
					<source>4 hours from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_5HoursFromNow">
				<segment>
					<source>5 hours from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_8HoursFromNow">
				<segment>
					<source>8 hours from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_NextBookableDay">
				<segment>
					<source>Next Bookable Day</source>
				</segment>
			</unit>
			<unit id="com_time_slot_2DaysFromNow">
				<segment>
					<source>2 days from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_3DaysFromNow">
				<segment>
					<source>3 days from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_4DaysFromNow">
				<segment>
					<source>4 days from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_5DaysFromNow">
				<segment>
					<source>5 days from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_1WeekFromNow">
				<segment>
					<source>1 week from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_2WeekFromNow">
				<segment>
					<source>2 weeks from now</source>
				</segment>
			</unit>
			<unit id="com_time_slot_UseBookableRules">
				<segment>
					<source>Use Default</source>
				</segment>
			</unit>
			<unit id="com_maximum_lanes_in_reservation">
				<segment>
					<source>Maximum lanes in a reservation</source>
				</segment>
			</unit>
			<unit id="com_n_categories">
				<segment>
					<source>{{n}} categories</source>
				</segment>
			</unit>
			<unit id="com_coming_soon">
				<segment>
					<source>Coming soon</source>
				</segment>
			</unit>
			<unit id="com_do_you_want_proceed">
				<segment>
					<source>Do you want to proceed?</source>
				</segment>
			</unit>
			<unit id="com_add_new_item">
				<segment>
					<source>Add New Item</source>
				</segment>
			</unit>
			<unit id="common_error_popup_blocked_title">
				<segment>
					<source>Pop-ups blocked</source>
				</segment>
			</unit>
			<unit id="common_error_popup_blocked_text_generic">
				<segment>
					<source>Pop-ups were blocked on this page. Allow pop-ups in order to proceed.</source>
				</segment>
			</unit>
			<unit id="common_finalize_your_order">
				<segment>
					<source>Finalize your order</source>
				</segment>
			</unit>
			<unit id="common_welcome_to_our_center">
				<segment>
					<source>Welcome to our center</source>
				</segment>
			</unit>
			<unit id="common_what_do_you_want_do">
				<segment>
					<source>What do you want to do?</source>
				</segment>
			</unit>
			<unit id="common_there_are_no_items_yet">
				<segment>
					<source>There are no items yet</source>
				</segment>
			</unit>
			<unit id="common_request_successfully_submitted">
				<segment>
					<source>Your request has been successfully submitted</source>
				</segment>
			</unit>
			<unit id="label_synchronize">
				<segment>
					<source>Synchronize</source>
				</segment>
			</unit>
			<unit id="label_open">
				<segment>
					<source>Open</source>
				</segment>
			</unit>
			<unit id="label_competitive">
				<segment>
					<source>Competitive</source>
				</segment>
			</unit>
			<unit id="com_new_category">
				<segment>
					<source>New Category</source>
				</segment>
			</unit>
			<unit id="com_select_category">
				<segment>
					<source>Select Category</source>
				</segment>
			</unit>
			<unit id="com_progress">
				<segment>
					<source>Progress</source>
				</segment>
			</unit>
		</group>
		<group id="labels">
			<unit id="label_warning">
				<segment>
					<source>Warning</source>
				</segment>
			</unit>
			<unit id="label_warnings">
				<segment>
					<source>Warnings</source>
				</segment>
			</unit>
			<unit id="label_uncategorized">
				<segment>
					<source>Uncategorized</source>
				</segment>
			</unit>
			<unit id="label_personal_data">
				<segment>
					<source>Personal data</source>
				</segment>
			</unit>
			<unit id="label_preferences">
				<segment>
					<source>Preferences</source>
				</segment>
			</unit>
			<unit id="label_settings">
				<segment>
					<source>Settings</source>
				</segment>
			</unit>
			<unit id="label_kiosk">
				<segment>
					<source>Kiosk</source>
				</segment>
			</unit>
			<unit id="label_agent4D">
				<segment>
					<source>Neoverse</source>
				</segment>
			</unit>
			<unit id="label_login">
				<segment>
					<source>Login</source>
				</segment>
			</unit>
			<unit id="label_logout">
				<segment>
					<source>Logout</source>
				</segment>
			</unit>
			<unit id="label_from">
				<segment>
					<source>From</source>
				</segment>
			</unit>
			<unit id="label_to">
				<segment>
					<source>To</source>
				</segment>
			</unit>
			<unit id="label_from_date_to_date">
				<segment>
					<source>From {{dateFrom}} To {{dateTo}}</source>
				</segment>
			</unit>
			<unit id="label_bowlcenter">
				<segment>
					<source>Bowling Center</source>
				</segment>
			</unit>
			<unit id="label_company">
				<segment>
					<source>Company</source>
				</segment>
			</unit>
			<unit id="label_users">
				<segment>
					<source>Users</source>
				</segment>
			</unit>
			<unit id="label_filter">
				<segment>
					<source>Filter</source>
				</segment>
			</unit>
			<unit id="label_no_filter">
				<segment>
					<source>No filter</source>
				</segment>
			</unit>
			<unit id="label_prefix">
				<segment>
					<source>Prefix</source>
				</segment>
			</unit>
			<unit id="label_yes">
				<segment>
					<source>Yes</source>
				</segment>
			</unit>
			<unit id="label_no">
				<segment>
					<source>No</source>
				</segment>
			</unit>
			<unit id="label_all">
				<segment>
					<source>All</source>
				</segment>
			</unit>
			<unit id="label_start">
				<segment>
					<source>Start</source>
				</segment>
			</unit>
			<unit id="label_end">
				<segment>
					<source>End</source>
				</segment>
			</unit>
			<unit id="label_groupby">
				<segment>
					<source>Group by</source>
				</segment>
			</unit>
			<unit id="label_checkall">
				<segment>
					<source>Check All</source>
				</segment>
			</unit>
			<unit id="label_uncheckall">
				<segment>
					<source>Uncheck All</source>
				</segment>
			</unit>
			<unit id="label_success">
				<segment>
					<source>Success</source>
				</segment>
			</unit>
			<unit id="label_firstname">
				<segment>
					<source>First Name</source>
				</segment>
			</unit>
			<unit id="label_lastname">
				<segment>
					<source>Last Name</source>
				</segment>
			</unit>
			<unit id="label_id">
				<segment>
					<source>Id</source>
				</segment>
			</unit>
			<unit id="label_mobilephone">
				<segment>
					<source>Mobile Phone</source>
				</segment>
			</unit>
			<unit id="label_phone">
				<segment>
					<source>Phone</source>
				</segment>
			</unit>
			<unit id="label_email">
				<segment>
					<source>Email</source>
				</segment>
			</unit>
			<unit id="label_username">
				<segment>
					<source>Username</source>
				</segment>
			</unit>
			<unit id="label_status">
				<segment>
					<source>Status</source>
				</segment>
			</unit>
			<unit id="label_total">
				<segment>
					<source>Total</source>
				</segment>
			</unit>
			<unit id="label_totals">
				<segment>
					<source>Totals</source>
				</segment>
			</unit>
			<unit id="label_limit">
				<segment>
					<source>Limit</source>
				</segment>
			</unit>
			<unit id="label_duration">
				<segment>
					<source>Duration</source>
				</segment>
			</unit>
			<unit id="label_expire_date">
				<segment>
					<source>Expire Date</source>
				</segment>
			</unit>
			<unit id="label_created_on">
				<segment>
					<source>Created on</source>
				</segment>
			</unit>
			<unit id="label_expire_permission">
				<segment>
					<source>Read-only access permission will expire on:</source>
				</segment>
			</unit>
			<unit id="label_owner">
				<segment>
					<source>Owner</source>
				</segment>
			</unit>
			<unit id="label_admin">
				<segment>
					<source>Admin</source>
				</segment>
			</unit>
			<unit id="label_legend">
				<segment>
					<source>Legend</source>
				</segment>
			</unit>
			<unit id="label_usage">
				<segment>
					<source>Usage</source>
				</segment>
			</unit>
			<unit id="label_game">
				<segment>
					<source>Game</source>
				</segment>
			</unit>
			<unit id="label_games">
				<segment>
					<source>Games</source>
				</segment>
			</unit>
			<unit id="label_game_one">
				<segment>
					<source>1 Game</source>
				</segment>
			</unit>
			<unit id="label_games_params">
				<segment>
					<source>{{games}} Games</source>
				</segment>
			</unit>
			<unit id="label_unlimited">
				<segment>
					<source>Unlimited</source>
				</segment>
			</unit>
			<unit id="label_sessions">
				<segment>
					<source>Sessions</source>
				</segment>
			</unit>
			<unit id="label_env">
				<segment>
					<source>Environment</source>
				</segment>
			</unit>
			<unit id="label_mixed">
				<segment>
					<source>Mixed</source>
				</segment>
			</unit>
			<unit id="label_date">
				<segment>
					<source>Date</source>
				</segment>
			</unit>
			<unit id="label_time">
				<segment>
					<source>Time</source>
				</segment>
			</unit>
			<unit id="label_range">
				<segment>
					<source>Range</source>
				</segment>
			</unit>
			<unit id="label_dates">
				<segment>
					<source>Date Range</source>
				</segment>
			</unit>
			<unit id="label_lane">
				<segment>
					<source>Lane</source>
				</segment>
			</unit>
			<unit id="label_lane_params">
				<segment>
					<source>{{lanes}} Lane</source>
				</segment>
			</unit>
			<unit id="label_lanes_params">
				<segment>
					<source>{{lanes}} Lanes</source>
				</segment>
			</unit>
			<unit id="label_lanerange">
				<segment>
					<source>Lane Range</source>
				</segment>
			</unit>
			<unit id="label_type">
				<segment>
					<source>Type</source>
				</segment>
			</unit>
			<unit id="label_alllanes">
				<segment>
					<source>All Lanes</source>
				</segment>
			</unit>
			<unit id="label_timerange">
				<segment>
					<source>Time Range</source>
				</segment>
			</unit>
			<unit id="label_total_includes">
				<segment>
					<source>Total Includes</source>
				</segment>
			</unit>
			<unit id="label_overview">
				<segment>
					<source>Overview</source>
				</segment>
			</unit>
			<unit id="label_details">
				<segment>
					<source>Details</source>
				</segment>
			</unit>
			<unit id="label_playing_sessions">
				<segment>
					<source>Playing sessions</source>
				</segment>
			</unit>
			<unit id="label_playing_time">
				<segment>
					<source>Playing time</source>
				</segment>
			</unit>
			<unit id="label_playing_game">
				<segment>
					<source>Number of Games</source>
				</segment>
			</unit>
			<unit id="label_playing_unlimited">
				<segment>
					<source>Playing unlimited</source>
				</segment>
			</unit>
			<unit id="label_search">
				<segment>
					<source>Search</source>
				</segment>
			</unit>
			<unit id="label_language">
				<segment>
					<source>Language</source>
				</segment>
			</unit>
			<unit id="label_change_language">
				<segment>
					<source>Change language</source>
				</segment>
			</unit>
			<unit id="label_pinspotter">
				<segment>
					<source>Pinspotter, calls generated by</source>
				</segment>
			</unit>
			<unit id="label_other_devices">
				<segment>
					<source>Other devices, calls generated by</source>
				</segment>
			</unit>
			<unit id="label_calls">
				<segment>
					<source>Calls inserted manually by</source>
				</segment>
			</unit>
			<unit id="label_options">
				<segment>
					<source>Options</source>
				</segment>
			</unit>
			<unit id="label_date_from">
				<segment>
					<source>Date from</source>
				</segment>
			</unit>
			<unit id="label_date_to">
				<segment>
					<source>Date to</source>
				</segment>
			</unit>
			<unit id="label_time_from">
				<segment>
					<source>Time from</source>
				</segment>
			</unit>
			<unit id="label_time_to">
				<segment>
					<source>Time to</source>
				</segment>
			</unit>
			<unit id="label_filters">
				<segment>
					<source>Filters</source>
				</segment>
			</unit>
			<unit id="label_games_duration">
				<segment>
					<source>Games duration</source>
				</segment>
			</unit>
			<unit id="label_lost_time">
				<segment>
					<source>Lost Time</source>
				</segment>
			</unit>
			<unit id="label_single">
				<segment>
					<source>Single</source>
				</segment>
			</unit>
			<unit id="label_description">
				<segment>
					<source>Description</source>
				</segment>
			</unit>
			<unit id="label_guidelines">
				<segment>
					<source>Guidelines</source>
				</segment>
			</unit>
			<unit id="label_recap">
				<segment>
					<source>Recap</source>
				</segment>
			</unit>
			<unit id="label_down_time">
				<segment>
					<source>Down time</source>
				</segment>
			</unit>
			<unit id="label_back">
				<segment>
					<source>Back</source>
				</segment>
			</unit>
			<unit id="label_sync">
				<segment>
					<source>Sync</source>
				</segment>
			</unit>
			<unit id="label_sync_now">
				<segment>
					<source>Sync Now</source>
				</segment>
			</unit>
			<unit id="label_sync_again">
				<segment>
					<source>Sync Again</source>
				</segment>
			</unit>
			<unit id="label_try_again">
				<segment>
					<source>Try Again</source>
				</segment>
			</unit>
			<unit id="label_remember">
				<segment>
					<source>Remember</source>
				</segment>
			</unit>
			<unit id="label_number">
				<segment>
					<source>Number</source>
				</segment>
			</unit>
			<unit id="label_stop">
				<segment>
					<source>Stop</source>
				</segment>
			</unit>
			<unit id="label_months">
				<segment>
					<source>Months</source>
				</segment>
			</unit>
			<unit id="label_year">
				<segment>
					<source>Year</source>
				</segment>
			</unit>
			<unit id="label_years">
				<segment>
					<source>Years</source>
				</segment>
			</unit>
			<unit id="label_name">
				<segment>
					<source>Name</source>
				</segment>
			</unit>
			<unit id="label_ledwall_preview_mode">
				<segment>
					<source>Ledwall preview mode</source>
				</segment>
			</unit>
			<unit id="label_hour_one">
				<segment>
					<source>1 Hour</source>
				</segment>
			</unit>
			<unit id="label_hours_params">
				<segment>
					<source>{{hours}} Hours</source>
				</segment>
			</unit>
			<unit id="label_minute_one">
				<segment>
					<source>1 Minute</source>
				</segment>
			</unit>
			<unit id="label_minutes_params">
				<segment>
					<source>{{minutes}} Minutes</source>
				</segment>
			</unit>
			<unit id="label_perlane">
				<segment>
					<source>Per Lane</source>
				</segment>
			</unit>
			<unit id="label_experience">
				<segment>
					<source>Experience</source>
				</segment>
			</unit>
			<unit id="label_title">
				<segment>
					<source>Title</source>
				</segment>
			</unit>
			<unit id="label_picture">
				<segment>
					<source>Picture</source>
				</segment>
			</unit>
			<unit id="label_upload">
				<segment>
					<source>Upload</source>
				</segment>
			</unit>
			<unit id="label_select">
				<segment>
					<source>Select</source>
				</segment>
			</unit>
			<unit id="label_selectpicture">
				<segment>
					<source>Select Picture</source>
				</segment>
			</unit>
			<unit id="label_remove">
				<segment>
					<source>Remove</source>
				</segment>
			</unit>
			<unit id="label_removepicture">
				<segment>
					<source>Remove Picture</source>
				</segment>
			</unit>
			<unit id="label_activation">
				<segment>
					<source>Activation</source>
				</segment>
			</unit>
			<unit id="label_attention">
				<segment>
					<source>Attention!</source>
				</segment>
			</unit>
			<unit id="label_price_options">
				<segment>
					<source>Price Options</source>
				</segment>
			</unit>
			<unit id="label_price_option">
				<segment>
					<source>1 Price Option</source>
				</segment>
			</unit>
			<unit id="label_price_options_params">
				<segment>
					<source>{{count}} Price Options</source>
				</segment>
			</unit>
			<unit id="label_select_option">
				<segment>
					<source>-- Please select an option --</source>
				</segment>
			</unit>
			<unit id="label_amount">
				<segment>
					<source>Amount</source>
				</segment>
			</unit>
			<unit id="label_more_results">
				<segment>
					<source>More results</source>
				</segment>
			</unit>
			<unit id="label_more_information">
				<segment>
					<source>More information</source>
				</segment>
			</unit>
			<unit id="label_select_all">
				<segment>
					<source>Select All</source>
				</segment>
			</unit>
			<unit id="label_deselect_all">
				<segment>
					<source>Deselect All</source>
				</segment>
			</unit>
			<unit id="label_all_items">
				<segment>
					<source>All items</source>
				</segment>
			</unit>
			<unit id="label_item">
				<segment>
					<source>Item</source>
				</segment>
			</unit>
			<unit id="label_n_item">
				<segment>
					<source>{{number}} Item</source>
				</segment>
			</unit>
			<unit id="label_none">
				<segment>
					<source>None</source>
				</segment>
			</unit>
			<unit id="label_none_selected">
				<segment>
					<source>None Selected</source>
				</segment>
			</unit>
			<unit id="label_n_selected">
				<segment>
					<source>{{selected}} Selected</source>
				</segment>
			</unit>
			<unit id="label_n_exported">
				<segment>
					<source>{{numberOfExported}} Exported</source>
				</segment>
			</unit>
			<unit id="label_none_available">
				<segment>
					<source>None Available</source>
				</segment>
			</unit>
			<unit id="label_web_book_for_later">
				<segment>
					<source>Web - Book for Later</source>
				</segment>
			</unit>
			<unit id="label_kiosk_book_for_later">
				<segment>
					<source>Kiosk - Book for Later</source>
				</segment>
			</unit>
			<unit id="label_kiosk_play_now">
				<segment>
					<source>Kiosk - Play Now</source>
				</segment>
			</unit>
			<unit id="label_category">
				<segment>
					<source>Category</source>
				</segment>
			</unit>
			<unit id="label_categories">
				<segment>
					<source>Categories</source>
				</segment>
			</unit>
			<unit id="label_price_key">
				<segment>
					<source>Price Key</source>
				</segment>
			</unit>
			<unit id="label_folder_new">
				<segment>
					<source>New Folder</source>
				</segment>
			</unit>
			<unit id="label_folder_new_params">
				<segment>
					<source>New Folder({{count}})</source>
				</segment>
			</unit>
			<unit id="label_pricing">
				<segment>
					<source>Pricing</source>
				</segment>
			</unit>
			<unit id="label_players">
				<segment>
					<source>Players</source>
				</segment>
			</unit>
			<unit id="label_per_player">
				<segment>
					<source>Per Player</source>
				</segment>
			</unit>
			<unit id="label_image">
				<segment>
					<source>Image</source>
				</segment>
			</unit>
			<unit id="label_content">
				<segment>
					<source>Content</source>
				</segment>
			</unit>
			<unit id="label_contents">
				<segment>
					<source>Contents</source>
				</segment>
			</unit>
			<unit id="label_items">
				<segment>
					<source>Items</source>
				</segment>
			</unit>
			<unit id="label_n_items">
				<segment>
					<source>{{number}} Items</source>
				</segment>
			</unit>
			<unit id="label_move_to">
				<segment>
					<source>Move To</source>
				</segment>
			</unit>
			<unit id="label_payments">
				<segment>
					<source>Payments</source>
				</segment>
			</unit>
			<unit id="label_period">
				<segment>
					<source>Period</source>
				</segment>
			</unit>
			<unit id="label_image_details">
				<segment>
					<source>Image details</source>
				</segment>
			</unit>
			<unit id="label_rotate_left">
				<segment>
					<source>Rotate Left</source>
				</segment>
			</unit>
			<unit id="label_rotate_right">
				<segment>
					<source>Rotate Right</source>
				</segment>
			</unit>
			<unit id="label_reset">
				<segment>
					<source>Reset</source>
				</segment>
			</unit>
			<unit id="label_zoom_in">
				<segment>
					<source>Zoom In</source>
				</segment>
			</unit>
			<unit id="label_zoom_out">
				<segment>
					<source>Zoom Out</source>
				</segment>
			</unit>
			<unit id="label_discard">
				<segment>
					<source>Discard</source>
				</segment>
			</unit>
			<unit id="label_closingtime">
				<segment>
					<source>Closing Time</source>
				</segment>
			</unit>
			<unit id="label_infobox">
				<segment>
					<source>Infobox</source>
				</segment>
			</unit>
			<unit id="label_additional_information">
				<segment>
					<source>Additional Information</source>
				</segment>
			</unit>
			<unit id="label_logo_url">
				<segment>
					<source>Logo Url</source>
				</segment>
			</unit>
			<unit id="label_signature">
				<segment>
					<source>Signature</source>
				</segment>
			</unit>
			<unit id="label_see_you_later">
				<segment>
					<source>See you later</source>
				</segment>
			</unit>
			<unit id="label_search_direction">
				<segment>
					<source>Search Direction</source>
				</segment>
			</unit>
			<unit id="label_lane_range">
				<segment>
					<source>Lane Range</source>
				</segment>
			</unit>
			<unit id="label_left_to_right">
				<segment>
					<source>Left to right</source>
				</segment>
			</unit>
			<unit id="label_right_to_left">
				<segment>
					<source>Right to left</source>
				</segment>
			</unit>
			<unit id="label_availability">
				<segment>
					<source>Availability</source>
				</segment>
			</unit>
			<unit id="label_earliest_availability">
				<segment>
					<source>Earliest availability</source>
				</segment>
			</unit>
			<unit id="label_main">
				<segment>
					<source>Main</source>
				</segment>
			</unit>
			<unit id="label_text">
				<segment>
					<source>Text</source>
				</segment>
			</unit>
			<unit id="label_label">
				<segment>
					<source>Label</source>
				</segment>
			</unit>
			<unit id="label_link">
				<segment>
					<source>Link</source>
				</segment>
			</unit>
			<unit id="label_header">
				<segment>
					<source>Header</source>
				</segment>
			</unit>
			<unit id="label_header_image">
				<segment>
					<source>Header image</source>
				</segment>
			</unit>
			<unit id="label_background">
				<segment>
					<source>Background</source>
				</segment>
			</unit>
			<unit id="label_mainpage">
				<segment>
					<source>Main Page</source>
				</segment>
			</unit>
			<unit id="label_homepage_body">
				<segment>
					<source>Homepage Body</source>
				</segment>
			</unit>
			<unit id="label_web_offer_buttons">
				<segment>
					<source>Web Offer Buttons</source>
				</segment>
			</unit>
			<unit id="label_confirmation_buttons">
				<segment>
					<source>Confirmation Buttons</source>
				</segment>
			</unit>
			<unit id="label_navigation_arrows">
				<segment>
					<source>Navigation Arrows</source>
				</segment>
			</unit>
			<unit id="label_price_tag_and_categories">
				<segment>
					<source>Price Tag and Categories</source>
				</segment>
			</unit>
			<unit id="label_confirmation_pdf">
				<segment>
					<source>Confirmation PDF</source>
				</segment>
			</unit>
			<unit id="label_use_default">
				<segment>
					<source>Use Default</source>
				</segment>
			</unit>
			<unit id="label_icons_and_text">
				<segment>
					<source>Text and Icons</source>
				</segment>
			</unit>
			<unit id="label_copy">
				<segment>
					<source>Copy</source>
				</segment>
			</unit>
			<unit id="label_unassigned">
				<segment>
					<source>Unassigned</source>
				</segment>
			</unit>
			<unit id="label_deviceid">
				<segment>
					<source>Device ID</source>
				</segment>
			</unit>
			<unit id="label_unlink">
				<segment>
					<source>Unlink</source>
				</segment>
			</unit>
			<unit id="label_devicetype">
				<segment>
					<source>Device Type</source>
				</segment>
			</unit>
			<unit id="label_pinpad">
				<segment>
					<source>PIN Pad</source>
				</segment>
			</unit>
			<unit id="label_pinpad_freedompay_identifier">
				<segment>
					<source>Pin Pad {{identifier}}</source>
				</segment>
			</unit>
			<unit id="label_caption">
				<segment>
					<source>Caption</source>
				</segment>
			</unit>
			<unit id="label_transaction_id">
				<segment>
					<source>Transaction ID</source>
				</segment>
			</unit>
			<unit id="label_made_by">
				<segment>
					<source>Made By</source>
				</segment>
			</unit>
			<unit id="label_user_name">
				<segment>
					<source>User Name</source>
				</segment>
			</unit>
			<unit id="label_full_name">
				<segment>
					<source>Full Name</source>
				</segment>
			</unit>
			<unit id="label_user">
				<segment>
					<source>User</source>
				</segment>
			</unit>
			<unit id="label_action_type">
				<segment>
					<source>Action Type</source>
				</segment>
			</unit>
			<unit id="label_cq_terminals">
				<segment>
					<source>CQ Terminals</source>
				</segment>
			</unit>
			<unit id="label_action">
				<segment>
					<source>Action</source>
				</segment>
			</unit>
			<unit id="label_user_from">
				<segment>
					<source>User From</source>
				</segment>
			</unit>
			<unit id="label_user_to">
				<segment>
					<source>User To</source>
				</segment>
			</unit>
			<unit id="label_user_from_to">
				<segment>
					<source>User From / To</source>
				</segment>
			</unit>
			<unit id="label_transfer">
				<segment>
					<source>Transfer</source>
				</segment>
			</unit>
			<unit id="label_na">
				<segment>
					<source>N.A.</source>
				</segment>
			</unit>
			<unit id="label_all_users">
				<segment>
					<source>All Users</source>
				</segment>
			</unit>
			<unit id="label_not_specified">
				<segment>
					<source>Not Specified</source>
				</segment>
			</unit>
			<unit id="label_void">
				<segment>
					<source>Void</source>
				</segment>
			</unit>
			<unit id="label_pay_out">
				<segment>
					<source>Pay Out</source>
				</segment>
			</unit>
			<unit id="label_go_back">
				<segment>
					<source>Go back</source>
				</segment>
			</unit>
			<unit id="label_service">
				<segment>
					<source>Service</source>
				</segment>
			</unit>
			<unit id="label_import">
				<segment>
					<source>Import</source>
				</segment>
			</unit>
			<unit id="label_play_now">
				<segment>
					<source>Play Now</source>
				</segment>
			</unit>
			<unit id="label_book_for_later">
				<segment>
					<source>Book for Later</source>
				</segment>
			</unit>
			<unit id="label_check_in">
				<segment>
					<source>Check In</source>
				</segment>
			</unit>
			<unit id="label_fb_pickup">
				<segment>
					<source>Food and Beverage - Pickup</source>
				</segment>
			</unit>
			<unit id="label_fb_table_service">
				<segment>
					<source>Food and Beverage - Table service</source>
				</segment>
			</unit>
			<unit id="label_bowling_waiting_list">
				<segment>
					<source>Bowling Waiting List</source>
				</segment>
			</unit>
			<unit id="label_menu">
				<segment>
					<source>Menu</source>
				</segment>
			</unit>
			<unit id="label_pickup">
				<segment>
					<source>Pickup</source>
				</segment>
			</unit>
			<unit id="label_table_service_pickup">
				<segment>
					<source>Table service/Pickup</source>
				</segment>
			</unit>
			<unit id="label_table_service_areas_tables">
				<segment>
					<source>Table service - Areas/Tables</source>
				</segment>
			</unit>
			<unit id="label_categories_and_items">
				<segment>
					<source>Categories and Items</source>
				</segment>
			</unit>
			<unit id="label_review_order">
				<segment>
					<source>Review order</source>
				</segment>
			</unit>
			<unit id="label_thank_you_pickup">
				<segment>
					<source>Thank you (Pickup)</source>
				</segment>
			</unit>
			<unit id="label_thank_you_table_service">
				<segment>
					<source>Thank you (Table service)</source>
				</segment>
			</unit>
			<unit id="label_pickup_order_update">
				<segment>
					<source>Pickup - Order update</source>
				</segment>
			</unit>
			<unit id="label_table_service_order_update">
				<segment>
					<source>Table service - Order update</source>
				</segment>
			</unit>
			<unit id="label_playing_options">
				<segment>
					<source>Playing Options</source>
				</segment>
			</unit>
			<unit id="label_credit_card">
				<segment>
					<source>Credit Card</source>
				</segment>
			</unit>
			<unit id="label_print_receipt">
				<segment>
					<source>Print Receipt</source>
				</segment>
			</unit>
			<unit id="label_print_never">
				<segment>
					<source>Never print</source>
				</segment>
			</unit>
			<unit id="label_print_always">
				<segment>
					<source>Always print</source>
				</segment>
			</unit>
			<unit id="label_common_questions">
				<segment>
					<source>Common questions</source>
				</segment>
			</unit>
			<unit id="label_get_in_touch">
				<segment>
					<source>Get in touch with us</source>
				</segment>
			</unit>
			<unit id="label_all_web_offers">
				<segment>
					<source>All Web Offers</source>
				</segment>
			</unit>
			<unit id="label_all_web_offers_lowercase">
				<segment>
					<source>All web offers</source>
				</segment>
			</unit>
			<unit id="label_web_offers">
				<segment>
					<source>Web Offers</source>
				</segment>
			</unit>
			<unit id="label_one_web_offer">
				<segment>
					<source>1 web offer</source>
				</segment>
			</unit>
			<unit id="label_number_web_offers">
				<segment>
					<source>{{number}} web offers</source>
				</segment>
			</unit>
			<unit id="label_offers">
				<segment>
					<source>Offers</source>
				</segment>
			</unit>
			<unit id="label_socks">
				<segment>
					<source>Socks</source>
				</segment>
			</unit>
			<unit id="label_logo">
				<segment>
					<source>Logo</source>
				</segment>
			</unit>
			<unit id="label_background_picture">
				<segment>
					<source>Background Picture</source>
				</segment>
			</unit>
			<unit id="label_side_area_text">
				<segment>
					<source>Side Area Text</source>
				</segment>
			</unit>
			<unit id="label_body">
				<segment>
					<source>Body</source>
				</segment>
			</unit>
			<unit id="label_footer">
				<segment>
					<source>Footer</source>
				</segment>
			</unit>
			<unit id="label_footer_image">
				<segment>
					<source>Footer image</source>
				</segment>
			</unit>
			<unit id="label_left_footer_title">
				<segment>
					<source>Left Footer Title</source>
				</segment>
			</unit>
			<unit id="label_left_footer_content">
				<segment>
					<source>Left Footer Content</source>
				</segment>
			</unit>
			<unit id="label_right_footer_title">
				<segment>
					<source>Right Footer Title</source>
				</segment>
			</unit>
			<unit id="label_right_footer_content">
				<segment>
					<source>Right Footer Content</source>
				</segment>
			</unit>
			<unit id="label_business_day">
				<segment>
					<source>Business Day</source>
				</segment>
			</unit>
			<unit id="label_applies_to">
				<segment>
					<source>Applies to</source>
				</segment>
			</unit>
			<unit id="label_modifiers_groups">
				<segment>
					<source>Modifiers Groups</source>
				</segment>
			</unit>
			<unit id="label_modifiers">
				<segment>
					<source>Modifiers</source>
				</segment>
			</unit>
			<unit id="label_up_to">
				<segment>
					<source>Up to</source>
				</segment>
			</unit>
			<unit id="label_at_least">
				<segment>
					<source>At least</source>
				</segment>
			</unit>
			<unit id="label_delivery_lanes">
				<segment>
					<source>Lanes</source>
				</segment>
			</unit>
			<unit id="label_delivery_tables">
				<segment>
					<source>Tables</source>
				</segment>
			</unit>
			<unit id="label_unavailable">
				<segment>
					<source>Unavailable</source>
				</segment>
			</unit>
			<unit id="label_off">
				<segment>
					<source>Off</source>
				</segment>
			</unit>
			<unit id="label_contact_info">
				<segment>
					<source>Contact info</source>
				</segment>
			</unit>
			<unit id="label_device_type">
				<segment>
					<source>Device Type</source>
				</segment>
			</unit>
			<unit id="label_device">
				<segment>
					<source>Device</source>
				</segment>
			</unit>
			<unit id="label_controls">
				<segment>
					<source>Controls</source>
				</segment>
			</unit>
			<unit id="label_id_value">
				<segment>
					<source>ID {{value}}</source>
				</segment>
			</unit>
			<unit id="label_skip">
				<segment>
					<source>Skip</source>
				</segment>
			</unit>
			<unit id="label_next">
				<segment>
					<source>Next</source>
				</segment>
			</unit>
			<unit id="label_prev">
				<segment>
					<source>Prev</source>
				</segment>
			</unit>
			<unit id="label_not_show_again">
				<segment>
					<source>Not show again</source>
				</segment>
			</unit>
			<unit id="label_price">
				<segment>
					<source>Price</source>
				</segment>
			</unit>
		</group>
		<group id="modal">
			<unit id="modal_delete_title">
				<segment>
					<source>Confirm deletion</source>
				</segment>
			</unit>
			<unit id="modal_duplicate_title">
				<segment>
					<source>Duplicate Web Offer</source>
				</segment>
			</unit>
			<unit id="modal_cannot_save_title">
				<segment>
					<source>Unable to save</source>
				</segment>
			</unit>
			<unit id="modal_cannot_continue">
				<segment>
					<source>Unable to continue</source>
				</segment>
			</unit>
			<unit id="modal_cannot_save_changes_title">
				<segment>
					<source>Unable to save changes</source>
				</segment>
			</unit>
			<unit id="modal_save_changes_title">
				<segment>
					<source>Save changes</source>
				</segment>
			</unit>
			<unit id="modal_cannot_activate_title">
				<segment>
					<source>Unable to Activate</source>
				</segment>
			</unit>
			<unit id="modal_tip_perecentages_value_must_be_coherent">
				<segment>
					<source>Tip Percentages values must be coherent with the value definition (Lower {{firstSign}} Middle {{secondSign}} Upper)</source>
				</segment>
			</unit>
			<unit id="modal_no_playing_mode_title">
				<segment>
					<source>Playing mode cannot be disabled</source>
				</segment>
			</unit>
			<unit id="modal_no_playing_mode_body">
				<segment>
					<source>This playing mode cannot be disabled. At least one playing mode must be enabled for the Waiting List service.</source>
				</segment>
			</unit>
			<unit id="label_contacts">
				<segment>
					<source>Contacts</source>
				</segment>
			</unit>
			<unit id="label_fonts">
				<segment>
					<source>Fonts</source>
				</segment>
			</unit>
			<unit id="label_colors_website">
				<segment>
					<source>Colors - Website</source>
				</segment>
			</unit>
			<unit id="label_colors_email">
				<segment>
					<source>Colors - Email</source>
				</segment>
			</unit>
			<unit id="label_colors_confirmation_pdf">
				<segment>
					<source>Colors - Confirmation PDF</source>
				</segment>
			</unit>
			<unit id="label_page_title">
				<segment>
					<source>Page Title</source>
				</segment>
			</unit>
			<unit id="label_apis">
				<segment>
					<source>APIs</source>
				</segment>
			</unit>
			<unit id="label_server_address">
				<segment>
					<source>Server Address</source>
				</segment>
			</unit>
			<unit id="label_server_port">
				<segment>
					<source>Server Port</source>
				</segment>
			</unit>
			<unit id="label_address">
				<segment>
					<source>Address</source>
				</segment>
			</unit>
			<unit id="label_port">
				<segment>
					<source>Port</source>
				</segment>
			</unit>
			<unit id="label_enabled">
				<segment>
					<source>Enabled</source>
				</segment>
			</unit>
			<unit id="label_disabled">
				<segment>
					<source>Disabled</source>
				</segment>
			</unit>
			<unit id="label_wakeup">
				<segment>
					<source>Wake Up</source>
				</segment>
			</unit>
			<unit id="label_shutdown">
				<segment>
					<source>Shut Down</source>
				</segment>
			</unit>
			<unit id="label_restart">
				<segment>
					<source>Restart</source>
				</segment>
			</unit>
			<unit id="label_pickup_only">
				<segment>
					<source>Pickup only</source>
				</segment>
			</unit>
			<unit id="label_tiles">
				<segment>
					<source>Tiles</source>
				</segment>
			</unit>
			<unit id="label_lower">
				<segment>
					<source>Lower</source>
				</segment>
			</unit>
			<unit id="label_middle">
				<segment>
					<source>Middle</source>
				</segment>
			</unit>
			<unit id="label_upper">
				<segment>
					<source>Upper</source>
				</segment>
			</unit>
			<unit id="label_tips_percentage">
				<segment>
					<source>Tips Percentage</source>
				</segment>
			</unit>
			<unit id="label_default_tip_value">
				<segment>
					<source>Default Tip value</source>
				</segment>
			</unit>
			<unit id="changing_company_privacy_policy">
				<segment>
					<source>Changing Company Privacy Policy</source>
				</segment>
			</unit>
			<unit id="sure_save_changes">
				<segment>
					<source>Are you sure you want to save your changes?</source>
				</segment>
			</unit>
			<unit id="all_changes_made_applied_to_all_centers">
				<segment>
					<source>Please note that all changes made will be applied to the Privacy Policy of all centers belonging to this Company</source>
				</segment>
			</unit>
			<unit id="label_colors">
				<segment>
					<source>Colors</source>
				</segment>
			</unit>
			<unit id="label_animation_set">
				<segment>
					<source>Animation Set</source>
				</segment>
			</unit>
			<unit id="label_facade">
				<segment>
					<source>Facade</source>
				</segment>
			</unit>
			<unit id="label_unable_to_add">
				<segment>
					<source>Unable to add</source>
				</segment>
			</unit>
			<unit id="label_pair">
				<segment>
					<source>Pair</source>
				</segment>
			</unit>
		</group>
		<group id="components">
			<unit id="texteditor_action_undo">
				<segment>
					<source>Undo</source>
				</segment>
			</unit>
			<unit id="texteditor_action_redo">
				<segment>
					<source>Redo</source>
				</segment>
			</unit>
			<unit id="texteditor_action_bold">
				<segment>
					<source>Bold</source>
				</segment>
			</unit>
			<unit id="texteditor_action_italic">
				<segment>
					<source>Italic</source>
				</segment>
			</unit>
			<unit id="texteditor_action_underline">
				<segment>
					<source>Underline</source>
				</segment>
			</unit>
			<unit id="texteditor_action_ul">
				<segment>
					<source>"Bullet list</source>
				</segment>
			</unit>
			<unit id="texteditor_action_ol">
				<segment>
					<source>Ordered list</source>
				</segment>
			</unit>
			<unit id="texteditor_action_code">
				<segment>
					<source>HTML Code</source>
				</segment>
			</unit>
			<unit id="texteditor_action_editor">
				<segment>
					<source>Back to editor</source>
				</segment>
			</unit>
			<unit id="imageeditor_edit">
				<segment>
					<source>Edit image</source>
				</segment>
			</unit>
			<unit id="imageeditor_replace">
				<segment>
					<source>Replace image</source>
				</segment>
			</unit>
		</group>
		<group id="buttons">
			<unit id="button_execute">
				<segment>
					<source>Execute</source>
				</segment>
			</unit>
			<unit id="button_export">
				<segment>
					<source>Export</source>
				</segment>
			</unit>
			<unit id="button_print">
				<segment>
					<source>Print</source>
				</segment>
			</unit>
			<unit id="button_apply">
				<segment>
					<source>Apply</source>
				</segment>
			</unit>
			<unit id="button_add">
				<segment>
					<source>Add</source>
				</segment>
			</unit>
			<unit id="button_add_now">
				<segment>
					<source>Add now</source>
				</segment>
			</unit>
			<unit id="button_cancel">
				<segment>
					<source>Cancel</source>
				</segment>
			</unit>
			<unit id="button_ok">
				<segment>
					<source>Ok</source>
				</segment>
			</unit>
			<unit id="button_save">
				<segment>
					<source>Save</source>
				</segment>
			</unit>
			<unit id="button_continue">
				<segment>
					<source>Continue</source>
				</segment>
			</unit>
			<unit id="button_delete">
				<segment>
					<source>Delete</source>
				</segment>
			</unit>
			<unit id="button_reload">
				<segment>
					<source>Reload</source>
				</segment>
			</unit>
			<unit id="button_authorize">
				<segment>
					<source>Authorize</source>
				</segment>
			</unit>
			<unit id="button_revoke">
				<segment>
					<source>Revoke</source>
				</segment>
			</unit>
			<unit id="button_download">
				<segment>
					<source>Download</source>
				</segment>
			</unit>
			<unit id="button_close">
				<segment>
					<source>Close</source>
				</segment>
			</unit>
			<unit id="button_send">
				<segment>
					<source>Send</source>
				</segment>
			</unit>
			<unit id="button_send_request">
				<segment>
					<source>Send Request</source>
				</segment>
			</unit>
			<unit id="button_back">
				<segment>
					<source>Back</source>
				</segment>
			</unit>
			<unit id="button_confirm">
				<segment>
					<source>Confirm</source>
				</segment>
			</unit>
			<unit id="button_preview">
				<segment>
					<source>Preview</source>
				</segment>
			</unit>
			<unit id="button_start_preview">
				<segment>
					<source>Start Preview</source>
				</segment>
			</unit>
			<unit id="button_duplicate">
				<segment>
					<source>Duplicate</source>
				</segment>
			</unit>
		</group>
		<group id="privileges">
			<unit id="RefreshPrivileges">
				<segment>
					<source>Refresh Privileges</source>
				</segment>
			</unit>
			<unit id="OnlineServices">
				<segment>
					<source>Online Services</source>
				</segment>
			</unit>
			<unit id="DashboardsAndReporting">
				<segment>
					<source>Dashboards &amp; Reporting</source>
				</segment>
			</unit>
			<unit id="AccessToBusinessDashboard">
				<segment>
					<source>Access to Business Results</source>
				</segment>
			</unit>
			<unit id="AccessToGameStatistics">
				<segment>
					<source>Access to Game Statistics</source>
				</segment>
			</unit>
			<unit id="AccessToPerformanceMonitoring">
				<segment>
					<source>Access to Performance Monitoring</source>
				</segment>
			</unit>
			<unit id="AccessToBumperProtectionStatistics">
				<segment>
					<source>Access to Bumper Protection Statistics</source>
				</segment>
			</unit>
			<unit id="AccessToReservationsGrid">
				<segment>
					<source>Access to Reservation Grid</source>
				</segment>
			</unit>
			<unit id="ToolsAndFeatures">
				<segment>
					<source>Tools &amp; Features</source>
				</segment>
			</unit>
			<unit id="AccessToOnlineBackup">
				<segment>
					<source>Access to Online Backup</source>
				</segment>
			</unit>
			<unit id="CreateServiceReservations">
				<segment>
					<source>Create Service Reservations</source>
				</segment>
			</unit>
			<unit id="AccessToCustomerAccounts">
				<segment>
					<source>Access to Customer Accounts</source>
				</segment>
			</unit>
			<unit id="AccessNeoverseModule">
				<segment>
					<source>Access Neoverse Web App</source>
				</segment>
			</unit>
			<unit id="AccessNeoverse">
				<segment>
					<source>Access Neoverse setup</source>
				</segment>
			</unit>
			<unit id="ManageCustomerAccounts">
				<segment>
					<source>Manage Customer Accounts</source>
				</segment>
			</unit>
			<unit id="ManageKioskUtilities">
				<segment>
					<source>Manage Kiosk Utilities</source>
				</segment>
			</unit>
			<unit id="MobileServices">
				<segment>
					<source>Mobile Services</source>
				</segment>
			</unit>
			<unit id="AccessToTechWizardApp">
				<segment>
					<source>Access TechWizard App</source>
				</segment>
			</unit>
			<unit id="AccessToTipsReporting">
				<segment>
					<source>Access to Tips Reporting</source>
				</segment>
			</unit>
			<unit id="ManagementAndConfiguration">
				<segment>
					<source>Management &amp; Configuration</source>
				</segment>
			</unit>
			<unit id="Management">
				<segment>
					<source>Management</source>
				</segment>
			</unit>
			<unit id="AccessToDataAndServiceSetup">
				<segment>
					<source>Access to Cloud Data Selection</source>
				</segment>
			</unit>
			<unit id="ActivateDeactivateServices">
				<segment>
					<source>Select Categories of Data and Services to Enable</source>
				</segment>
			</unit>
			<unit id="AccessToCmp">
				<segment>
					<source>Access Programs &amp; Licenses</source>
				</segment>
			</unit>
			<unit id="ManageCmpSubscription">
				<segment>
					<source>Manage Renewals</source>
				</segment>
			</unit>
			<unit id="AccessToInvoices">
				<segment>
					<source>Access to Invoices</source>
				</segment>
			</unit>
			<unit id="RolesAndPrivileges">
				<segment>
					<source>Roles &amp; Privileges</source>
				</segment>
			</unit>
			<unit id="AccessToRolesAndPrivileges">
				<segment>
					<source>Access to Roles and Privileges</source>
				</segment>
			</unit>
			<unit id="AssignRolesToUsers">
				<segment>
					<source>Assign Roles to Users</source>
				</segment>
			</unit>
			<unit id="DefineReservationOptions">
				<segment>
					<source>Define Options</source>
				</segment>
			</unit>
			<unit id="DefineRolesAndPrivileges">
				<segment>
					<source>Define Roles and Privileges</source>
				</segment>
			</unit>
			<unit id="Users">
				<segment>
					<source>Users</source>
				</segment>
			</unit>
			<unit id="AccessToUserSetup">
				<segment>
					<source>Access to User Setup</source>
				</segment>
			</unit>
			<unit id="AddUser">
				<segment>
					<source>Add User</source>
				</segment>
			</unit>
			<unit id="BanAndDeleteUsers">
				<segment>
					<source>Ban and Delete Users</source>
				</segment>
			</unit>
			<unit id="Settings">
				<segment>
					<source>Settings</source>
				</segment>
			</unit>
			<unit id="WebReservations">
				<segment>
					<source>Web Reservations</source>
				</segment>
			</unit>
			<unit id="DefineRulesAndPreferences">
				<segment>
					<source>Define Rules and Preferences</source>
				</segment>
			</unit>
			<unit id="DefineBookableDaysHours">
				<segment>
					<source>Define Bookable Days/Hours</source>
				</segment>
			</unit>
			<unit id="DefineBowlerTypes">
				<segment>
					<source>Define Bowler Types</source>
				</segment>
			</unit>
			<unit id="DefineWebOffers">
				<segment>
					<source>Define Web Offers</source>
				</segment>
			</unit>
			<unit id="DefineShoesAndSocks">
				<segment>
					<source>Define Shoes and Socks</source>
				</segment>
			</unit>
			<unit id="DefineFoodAndBeverage">
				<segment>
					<source>Define Food and Beverage</source>
				</segment>
			</unit>
			<unit id="DefineExtras">
				<segment>
					<source>Define Extras</source>
				</segment>
			</unit>
			<unit id="DefineTextAndImages">
				<segment>
					<source>Manage Texts and Images</source>
				</segment>
			</unit>
			<unit id="DefineWebsiteMaintenance">
				<segment>
					<source>Manage Maintenance Mode</source>
				</segment>
			</unit>
			<unit id="DefineWebTheme">
				<segment>
					<source>Define Web Theme</source>
				</segment>
			</unit>
			<unit id="DefineEmailAddresses">
				<segment>
					<source>Define Email Adresses</source>
				</segment>
			</unit>
			<unit id="DefinePrivacyAndTerms">
				<segment>
					<source>Define Privacy And Terms</source>
				</segment>
			</unit>
			<unit id="Miscellaneous">
				<segment>
					<source>Miscellaneous</source>
				</segment>
			</unit>
			<unit id="DefineLaneGroups">
				<segment>
					<source>Define Lane Groups</source>
				</segment>
			</unit>
			<unit id="LaneOptionsSets">
				<segment>
					<source>Lane Options Sets</source>
				</segment>
			</unit>
			<unit id="DefineLaneOptionsSets">
				<segment>
					<source>Define Lane Options Sets</source>
				</segment>
			</unit>
			<unit id="DefinePayments">
				<segment>
					<source>Define Payments</source>
				</segment>
			</unit>
			<unit id="DefineGraphicResources">
				<segment>
					<source>Define Media Library</source>
				</segment>
			</unit>
			<unit id="DefineGraphicalThemes">
				<segment>
					<source>Define Graphic Themes</source>
				</segment>
			</unit>
			<unit id="DefineWebsiteMenu">
				<segment>
					<source>Define Header Menu</source>
				</segment>
			</unit>
			<unit id="Square">
				<segment>
					<source>Square</source>
				</segment>
			</unit>
			<unit id="SquarePayment">
				<segment>
					<source>Allow to Pair Square Terminals</source>
				</segment>
			</unit>
			<unit id="ManageSquarePosIntegration">
				<segment>
					<source>Manage Catalog Synchronization</source>
				</segment>
			</unit>
			<unit id="ManageSquareItemLibrary">
				<segment>
					<source>Access and manage the Item Library</source>
				</segment>
			</unit>
			<unit id="ManageSquareConnection">
				<segment>
					<source>Access to Connection to Square</source>
				</segment>
			</unit>
			<unit id="RemoteAssistance">
				<segment>
					<source>Remote Assistance</source>
				</segment>
			</unit>
			<unit id="ManageRemoteAssistance">
				<segment>
					<source>Allow Remote Assistance</source>
				</segment>
			</unit>
			<unit id="Reservations">
				<segment>
					<source>Reservations</source>
				</segment>
			</unit>
			<unit id="Payments">
				<segment>
					<source>Payments</source>
				</segment>
			</unit>
			<unit id="LaneGroups">
				<segment>
					<source>Lane Groups</source>
				</segment>
			</unit>
			<unit id="LaneOptionsSet">
				<segment>
					<source>Lane Options Set</source>
				</segment>
			</unit>
			<unit id="FoodOrdering">
				<segment>
					<source>Food Ordering</source>
				</segment>
			</unit>
			<unit id="FoodAndBeverage">
				<segment>
					<source>Food and Beverage</source>
				</segment>
			</unit>
			<unit id="Extras">
				<segment>
					<source>Extras</source>
				</segment>
			</unit>
			<unit id="AdministrativeSettings">
				<segment>
					<source>Administrative Settings</source>
				</segment>
			</unit>
			<unit id="MediaLibrary">
				<segment>
					<source>Media Library</source>
				</segment>
			</unit>
			<unit id="Website">
				<segment>
					<source>Website</source>
				</segment>
			</unit>
			<unit id="Kiosks">
				<segment>
					<source>Kiosks</source>
				</segment>
			</unit>
			<unit id="AccessToKiosks">
				<segment>
					<source>Access to Kiosks</source>
				</segment>
			</unit>
			<unit id="DefineKioskStations">
				<segment>
					<source>Define Kiosk Stations</source>
				</segment>
			</unit>
			<unit id="DefineKioskTextAndImages">
				<segment>
					<source>Define Kiosk Text and Images</source>
				</segment>
			</unit>
			<unit id="ManageKioskPlayLists">
				<segment>
					<source>Define Kiosk Playlists</source>
				</segment>
			</unit>
			<unit id="DefineAPIsSettings">
				<segment>
					<source>Define APIs settings</source>
				</segment>
			</unit>
			<unit id="APIs">
				<segment>
					<source>APIs</source>
				</segment>
			</unit>
			<unit id="RealTimeScoringEvents">
				<segment>
					<source>Real-time Scoring Events</source>
				</segment>
			</unit>
			<unit id="EventQueueLaneStatus">
				<segment>
					<source>Event queue - Lane status</source>
				</segment>
			</unit>
			<unit id="EventQueueScoringEvents">
				<segment>
					<source>Event queue - Scoring events</source>
				</segment>
			</unit>
			<unit id="EventQueueLocalReservationEvents">
				<segment>
					<source>Event Queue - Local Reservation events</source>
				</segment>
			</unit>
			<unit id="ManageFBOrdersUpdates">
				<segment>
					<source>Manage F&amp;B orders updates</source>
				</segment>
			</unit>
			<unit id="GraphicThemes">
				<segment>
					<source>Graphic Themes</source>
				</segment>
			</unit>
			<unit id="Terminals">
				<segment>
					<source>Terminals</source>
				</segment>
			</unit>
			<unit id="DefineTerminals">
				<segment>
					<source>Define Terminals</source>
				</segment>
			</unit>
			<unit id="ManageBowlingReservationApi">
				<segment>
					<source>Manage Bowling Reservation Api</source>
				</segment>
			</unit>
		</group>
		<group id="access-denied">
			<unit id="accessDeniedTitle">
				<segment>
					<source>Access Denied</source>
				</segment>
			</unit>
			<unit id="errorPageTitle">
				<segment>
					<source>Ops...</source>
				</segment>
			</unit>
			<unit id="missing_auth">
				<segment>
					<source>Sorry but you do not have the authorization to access this section.</source>
				</segment>
			</unit>
			<unit id="request_auth">
				<segment>
					<source>If you need to use this feature, please contact a manager to request access.</source>
				</segment>
			</unit>
			<unit id="missingPrivileges">
				<segment>
					<source>You do not have the privileges to access this page.</source>
				</segment>
			</unit>
			<unit id="missingPrivilegesLogout1">
				<segment>
					<source>Please logout and try again using different credentials.</source>
				</segment>
			</unit>
		</group>
		<group id="business-dashboard">
			<unit id="bd_f_taxes">
				<segment>
					<source>Taxes</source>
				</segment>
			</unit>
			<unit id="bd_f_addedTaxes">
				<segment>
					<source>Added Taxes</source>
				</segment>
			</unit>
			<unit id="bd_f_includedTaxes">
				<segment>
					<source>Included Taxes</source>
				</segment>
			</unit>
			<unit id="bd_table_totalIncludesAll">
				<segment>
					<source>All</source>
				</segment>
			</unit>
			<unit id="bd_f_trust">
				<segment>
					<source>Trust</source>
				</segment>
			</unit>
			<unit id="bd_f_rounding">
				<segment>
					<source>Rounding</source>
				</segment>
			</unit>
			<unit id="bd_f_deposits">
				<segment>
					<source>Deposits</source>
				</segment>
			</unit>
			<unit id="bd_f_expenses">
				<segment>
					<source>Expenses</source>
				</segment>
			</unit>
			<unit id="bd_f_bysector">
				<segment>
					<source>By sector</source>
				</segment>
			</unit>
			<unit id="bd_f_bycounter">
				<segment>
					<source>By counter</source>
				</segment>
			</unit>
			<unit id="bd_f_byoperator">
				<segment>
					<source>By operator</source>
				</segment>
			</unit>
			<unit id="bd_f_bymaindep">
				<segment>
					<source>By main department</source>
				</segment>
			</unit>
			<unit id="bd_f_byday">
				<segment>
					<source>By day</source>
				</segment>
			</unit>
			<unit id="bd_f_byhour">
				<segment>
					<source>By hour</source>
				</segment>
			</unit>
			<unit id="bd_chart_negative">
				<segment>
					<source>Negative</source>
				</segment>
			</unit>
			<unit id="bd_chart_positive">
				<segment>
					<source>Positive</source>
				</segment>
			</unit>
			<unit id="bd_label_department">
				<segment>
					<source>Department</source>
				</segment>
			</unit>
			<unit id="bd_transacts_title">
				<segment>
					<source>Transaction details</source>
				</segment>
			</unit>
			<unit id="bd_transacts_operator">
				<segment>
					<source>Operator</source>
				</segment>
			</unit>
			<unit id="bd_transacts_price_key">
				<segment>
					<source>Price Key</source>
				</segment>
			</unit>
			<unit id="bd_transacts_quantity">
				<segment>
					<source>Quantity</source>
				</segment>
			</unit>
		</group>
		<group id="role-setup">
			<unit id="rs_rolesTitle">
				<segment>
					<source>Roles</source>
				</segment>
			</unit>
			<unit id="rs_new_role">
				<segment>
					<source>New Role</source>
				</segment>
			</unit>
			<unit id="rs_availablePrivilegesTitle">
				<segment>
					<source>Available Privileges</source>
				</segment>
			</unit>
			<unit id="rs_rolesNotFound">
				<segment>
					<source>No roles found for this Company</source>
				</segment>
			</unit>
			<unit id="rs_roleNamePlaceholder">
				<segment>
					<source>Role name here</source>
				</segment>
			</unit>
			<unit id="rs_term_privilege">
				<segment>
					<source>Privilege</source>
				</segment>
			</unit>
			<unit id="rs_term_privilegePlural">
				<segment>
					<source>Privileges</source>
				</segment>
			</unit>
			<unit id="rs_privileges_rules">
				<segment>
					<source>Select at least one privilege.</source>
				</segment>
			</unit>
			<unit id="rs_deleteErrorTitle">
				<segment>
					<source>Role Deletion not allowed</source>
				</segment>
			</unit>
			<unit id="rs_deleteErrorMessage">
				<segment>
					<source>It is not possible to delete roles that are associated with users.</source>
				</segment>
			</unit>
		</group>
		<group id="user-activation">
			<unit id="cua_account_route_title">
				<segment>
					<source>User Activation Process</source>
				</segment>
			</unit>
			<unit id="cua_account_title">
				<segment>
					<source>Account activation</source>
				</segment>
			</unit>
			<unit id="cua_account_completed_title">
				<segment>
					<source>Account activation completed</source>
				</segment>
			</unit>
			<unit id="cua_user_welcome">
				<segment>
					<source>Welcome {{name}}!</source>
				</segment>
			</unit>
			<unit id="cua_user_welcome_message">
				<segment>
					<source>Your account has been successfully activated</source>
				</segment>
			</unit>
			<unit id="cua_user_can_login">
				<segment>
					<source>You can now login to QPortal</source>
				</segment>
			</unit>
			<unit id="cua_start_qportal">
				<segment>
					<source>Start using QPortal</source>
				</segment>
			</unit>
			<unit id="cua_error_possible_causes">
				<segment>
					<source>Possible causes: activation expired or not found.</source>
				</segment>
			</unit>
			<unit id="cua_users_found">
				<segment>
					<source>There are already users associated with the email</source>
				</segment>
			</unit>
			<unit id="cua_user_found">
				<segment>
					<source>There is already a user associated with the email</source>
				</segment>
			</unit>
			<unit id="cua_login_to_complete">
				<segment>
					<source>To complete the activation process, please login using your QubicaAMF credentials</source>
				</segment>
			</unit>
			<unit id="cua_footernotes">
				<segment>
					<source>Note: in case the credentials above do not belong to you but to another user in your organization, you can create a new user (associated with the same email) by clicking the following link</source>
				</segment>
			</unit>
			<unit id="cua_userNotMatching">
				<segment>
					<source>{{name}}, another user is already logged in</source>
				</segment>
			</unit>
			<unit id="cua_clickLoginToContinue1">
				<segment>
					<source>To proceed with the activation, the current user must first log out. Please, use the following logout link and then click Continue:</source>
				</segment>
			</unit>
		</group>
		<group id="kioskUtilities">
			<unit id="wake_up_kiosk_stations">
				<segment>
					<source>Wake Up Kiosk Stations</source>
				</segment>
			</unit>
			<unit id="shutdonw_kiosk_stations">
				<segment>
					<source>Shutdown Kiosk Stations</source>
				</segment>
			</unit>
			<unit id="you_are_shutting_down_the_following_kiosk_stations">
				<segment>
					<source>You are shutting down the following Kiosk stations:</source>
				</segment>
			</unit>
			<unit id="please_note_that_kiosk_will_not_shut_down_when_in_use">
				<segment>
					<source>Please note that Kiosks will not shut down when in use</source>
				</segment>
			</unit>
			<unit id="restart_kiosk_stations">
				<segment>
					<source>Restart Kiosk Stations</source>
				</segment>
			</unit>
			<unit id="you_are_restarting_the_following_kiosk_stations">
				<segment>
					<source>You are restarting the following Kiosk stations:</source>
				</segment>
			</unit>
			<unit id="please_note_that_kiosk_will_not_restart_when_in_use">
				<segment>
					<source>Please note that Kiosks will not restart when in use</source>
				</segment>
			</unit>
			<unit id="do_you_wish_to_proceed">
				<segment>
					<source>Do you wish to proceed?</source>
				</segment>
			</unit>
			<unit id="your_request_has_been_submitted">
				<segment>
					<source>Your Request has been submitted</source>
				</segment>
			</unit>
			<unit id="please_check_that_all_kiosk_stations_shut_down_correctly">
				<segment>
					<source>Please check that all Kiosk stations shut down correctly.</source>
				</segment>
			</unit>
			<unit id="please_check_that_all_kiosk_stations_are_running">
				<segment>
					<source>Please check that all Kiosk stations are running.</source>
				</segment>
			</unit>
			<unit id="please_check_that_all_kiosk_stations_restarted_correctly">
				<segment>
					<source>Please check that all Kiosk stations restarted correctly.</source>
				</segment>
			</unit>
			<unit id="sorry_an_error_occurred_please_try_again">
				<segment>
					<source>Sorry an error occurred. Please try again.</source>
				</segment>
			</unit>
		</group>
		<group id="users">
			<unit id="cuserlist_add_user">
				<segment>
					<source>Add User</source>
				</segment>
			</unit>
			<unit id="cuserlist_send_email">
				<segment>
					<source>Send Email Invitation</source>
				</segment>
			</unit>
			<unit id="cuserlist_email_sent">
				<segment>
					<source>Email invitation sent successfully.</source>
				</segment>
			</unit>
		</group>
		<group id="user-card">
			<unit id="usrc_allcenters">
				<segment>
					<source>All Company's Bowling Centers</source>
				</segment>
			</unit>
			<unit id="usrc_allprivileges">
				<segment>
					<source>(all privileges enabled)</source>
				</segment>
			</unit>
			<unit id="usrc_new_user">
				<segment>
					<source>New User</source>
				</segment>
			</unit>
			<unit id="usrc_base_info_title">
				<segment>
					<source>Base Info</source>
				</segment>
			</unit>
			<unit id="usrc_roles_title">
				<segment>
					<source>Roles</source>
				</segment>
			</unit>
			<unit id="usrc_label_banned">
				<segment>
					<source>Banned</source>
				</segment>
			</unit>
			<unit id="usrc_label_ban">
				<segment>
					<source>Ban User</source>
				</segment>
			</unit>
			<unit id="usrc_label_unban">
				<segment>
					<source>Unban User</source>
				</segment>
			</unit>
			<unit id="usrc_no_roles_found">
				<segment>
					<source>No roles associated with this user.</source>
				</segment>
			</unit>
			<unit id="usrc_valid_on">
				<segment>
					<source>Valid on</source>
				</segment>
			</unit>
			<unit id="usrc_assigned_roles">
				<segment>
					<source>Assigned roles</source>
				</segment>
			</unit>
			<unit id="usrc_sure_to_delete">
				<segment>
					<source>Are you sure you want to delete '{{firstname}} {{lastname}}'?</source>
				</segment>
			</unit>
			<unit id="usrc_sure_to_delete_rule">
				<segment>
					<source>Do you want to delete this association?</source>
				</segment>
			</unit>
			<unit id="usrc_role_assign_modal">
				<segment>
					<source>Role Assignment</source>
				</segment>
			</unit>
			<unit id="usrc_selected_roles">
				<segment>
					<source>Select roles:</source>
				</segment>
			</unit>
			<unit id="usrc_privileges_preview">
				<segment>
					<source>Privileges Preview</source>
				</segment>
			</unit>
			<unit id="usrc_no_company_roles">
				<segment>
					<source>No roles defined on this Company.</source>
				</segment>
			</unit>
			<unit id="usrc_no_company_roles_advice">
				<segment>
					<source>To assign a role to a user, you must first create one.</source>
				</segment>
			</unit>
			<unit id="usrc_userinvitation_sent">
				<segment>
					<source>User added, the registration email has been sent.</source>
				</segment>
			</unit>
			<unit id="usrc_roles_rules">
				<segment>
					<source>Select at least one role.</source>
				</segment>
			</unit>
			<unit id="usrc_square_team_member">
				<segment>
					<source>Square Team Member</source>
				</segment>
			</unit>
			<unit id="usrc_pair_square_team_member">
				<segment>
					<source>Pair Team Member</source>
				</segment>
			</unit>
			<unit id="usrc_unpair_square_team_member">
				<segment>
					<source>Remove pair</source>
				</segment>
			</unit>
			<unit id="usrc_square_team_member_details">
				<segment>
					<source>Square User Details</source>
				</segment>
			</unit>
			<unit id="usrc_square_team_member_pair_modal">
				<segment>
					<source>Are you sure you want to pair '{{firstname}} {{lastname}}' to this user?</source>
				</segment>
			</unit>
			<unit id="usrc_square_team_member_unpair_modal">
				<segment>
					<source>Are you sure you want to remove pair of '{{firstname}} {{lastname}}' to this user?</source>
				</segment>
			</unit>
		</group>
		<group id="game-statistics">
			<unit id="gstats_playing_time">
				<segment>
					<source>{{name}}'s playing time</source>
				</segment>
			</unit>
			<unit id="gstats_playing_hours">
				<segment>
					<source>Playing Hours</source>
				</segment>
			</unit>
			<unit id="gstats_f_eventtype">
				<segment>
					<source>Event Type</source>
				</segment>
			</unit>
			<unit id="gstats_f_eventtypes">
				<segment>
					<source>Event Types</source>
				</segment>
			</unit>
			<unit id="gstats_f_all_eventtypes">
				<segment>
					<source>All Event Types</source>
				</segment>
			</unit>
			<unit id="gstats_f_playing_time_only">
				<segment>
					<source>Playing Time Only</source>
				</segment>
			</unit>
			<unit id="gstats_f_total_open">
				<segment>
					<source>Total Opening Time</source>
				</segment>
			</unit>
			<unit id="gstats_game_dur">
				<segment>
					<source>Game Duration</source>
				</segment>
			</unit>
			<unit id="gstats_incomplete_games">
				<segment>
					<source>Incomplete Games</source>
				</segment>
			</unit>
			<unit id="gstats_not_played">
				<segment>
					<source>Not Played Time</source>
				</segment>
			</unit>
			<unit id="gstats_env_only">
				<segment>
					<source>{{name}} only</source>
				</segment>
			</unit>
			<unit id="gstats_event_type_open">
				<segment>
					<source>Open</source>
				</segment>
			</unit>
			<unit id="gstats_event_type_tournament">
				<segment>
					<source>Tournament</source>
				</segment>
			</unit>
			<unit id="gstats_event_type_league">
				<segment>
					<source>League</source>
				</segment>
			</unit>
			<unit id="gstats_hours">
				<segment>
					<source>hours</source>
				</segment>
			</unit>
			<unit id="gstats_minutes">
				<segment>
					<source>minutes</source>
				</segment>
			</unit>
		</group>
		<group id="bumper-statistics">
			<unit id="tab_title_byevent">
				<segment>
					<source>By Event</source>
				</segment>
			</unit>
			<unit id="tab_title_bylane">
				<segment>
					<source>By Lane</source>
				</segment>
			</unit>
			<unit id="tab_title_bysession">
				<segment>
					<source>By Session</source>
				</segment>
			</unit>
			<unit id="table_th_events">
				<segment>
					<source>Events</source>
				</segment>
			</unit>
			<unit id="table_th_occurrences">
				<segment>
					<source>Occurrences</source>
				</segment>
			</unit>
			<unit id="table_th_tiltswarnings">
				<segment>
					<source>Tilts and Warnings</source>
				</segment>
			</unit>
			<unit id="table_th_tilts">
				<segment>
					<source>Tilts</source>
				</segment>
			</unit>
			<unit id="table_th_fouls">
				<segment>
					<source>Fouls</source>
				</segment>
			</unit>
			<unit id="table_th_allfouls">
				<segment>
					<source>All Fouls</source>
				</segment>
			</unit>
			<unit id="table_th_totals">
				<segment>
					<source>Totals</source>
				</segment>
			</unit>
			<unit id="table_th_sessions">
				<segment>
					<source>Sessions</source>
				</segment>
			</unit>
			<unit id="table_th_people">
				<segment>
					<source>People</source>
				</segment>
			</unit>
			<unit id="table_th_datetime">
				<segment>
					<source>Date/Time</source>
				</segment>
			</unit>
			<unit id="table_th_protectionlevel">
				<segment>
					<source>Protection Level</source>
				</segment>
			</unit>
			<unit id="table_th_player">
				<segment>
					<source>Player</source>
				</segment>
			</unit>
			<unit id="protection_level_low">
				<segment>
					<source>Low</source>
				</segment>
			</unit>
			<unit id="protection_level_medium">
				<segment>
					<source>Medium</source>
				</segment>
			</unit>
			<unit id="protection_level_high">
				<segment>
					<source>High</source>
				</segment>
			</unit>
			<unit id="foul_kind_close_hit">
				<segment>
					<source>Close Hit Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_lofting">
				<segment>
					<source>Ball Lofting Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_multiple">
				<segment>
					<source>Multiple Ball Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_speed">
				<segment>
					<source>Excessive Speed Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_rough_play">
				<segment>
					<source>Rough Play Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_extreme_speed">
				<segment>
					<source>Extreme Speed Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_triple_hit">
				<segment>
					<source>Triple Hit Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_unexpected">
				<segment>
					<source>Unexpected Ball Foul</source>
				</segment>
			</unit>
			<unit id="foul_kind_line_crossed">
				<segment>
					<source>Line Crossed Foul</source>
				</segment>
			</unit>
			<unit id="subtype_triple_hit">
				<segment>
					<source>Triple hit foul with high speed</source>
				</segment>
			</unit>
			<unit id="subtype_in_a_row_among_all_players">
				<segment>
					<source>3 consecutive fouls</source>
				</segment>
			</unit>
			<unit id="subtype_multiple_balls_twice">
				<segment>
					<source>2 Multiple ball fouls</source>
				</segment>
			</unit>
			<unit id="subtype_in_a_row_for_a_player">
				<segment>
					<source>3 consecutive fouls by one player</source>
				</segment>
			</unit>
			<unit id="subtype_tilt_after_warning">
				<segment>
					<source>Foul after warning</source>
				</segment>
			</unit>
			<unit id="subtype_tilt_extreme_speed">
				<segment>
					<source>Extreme Speed</source>
				</segment>
			</unit>
			<unit id="subtype_overall_for_a_player">
				<segment>
					<source>4 fouls out of 5 balls by one player</source>
				</segment>
			</unit>
			<unit id="event_type_tilt">
				<segment>
					<source>Tilt</source>
				</segment>
			</unit>
			<unit id="event_type_warning_tilt">
				<segment>
					<source>Warning Tilt</source>
				</segment>
			</unit>
			<unit id="event_type_foul">
				<segment>
					<source>Foul</source>
				</segment>
			</unit>
			<unit id="event_type_reset_tilt">
				<segment>
					<source>Tilt Cleared</source>
				</segment>
			</unit>
			<unit id="event_type_reset_tilt_operator">
				<segment>
					<source>Tilt Cleared (by {{operator}})</source>
				</segment>
			</unit>
		</group>
		<group id="performance-monitoring">
			<unit id="tcs_bylane">
				<segment>
					<source>By Lane</source>
				</segment>
			</unit>
			<unit id="tcs_bytype">
				<segment>
					<source>By Type</source>
				</segment>
			</unit>
			<unit id="tcs_details">
				<segment>
					<source>Details</source>
				</segment>
			</unit>
			<unit id="tcs_pinspotter">
				<segment>
					<source>Pinspotter</source>
				</segment>
			</unit>
			<unit id="tcs_manual">
				<segment>
					<source>Manually Inserted</source>
				</segment>
			</unit>
			<unit id="tcs_manual_short">
				<segment>
					<source>Manual</source>
				</segment>
			</unit>
			<unit id="tcs_redempt">
				<segment>
					<source>Redemption</source>
				</segment>
			</unit>
			<unit id="tcs_machine">
				<segment>
					<source>Machine (pins, motors, etc...)</source>
				</segment>
			</unit>
			<unit id="tcs_machine_label">
				<segment>
					<source>Machine</source>
				</segment>
			</unit>
			<unit id="tcs_chassis">
				<segment>
					<source>Chassis</source>
				</segment>
			</unit>
			<unit id="tcs_ball_lift">
				<segment>
					<source>Ball Lift</source>
				</segment>
			</unit>
			<unit id="tcs_ball_return">
				<segment>
					<source>Ball Return</source>
				</segment>
			</unit>
			<unit id="tcs_ticket_dispenser">
				<segment>
					<source>Ticket Dispenser</source>
				</segment>
			</unit>
			<unit id="tcs_front_desk_operator">
				<segment>
					<source>Front desk operators</source>
				</segment>
			</unit>
			<unit id="tcs_front_desk_operator_label">
				<segment>
					<source>Operators</source>
				</segment>
			</unit>
			<unit id="tcs_mechanic_through_their_phone">
				<segment>
					<source>Mechanics through their phone</source>
				</segment>
			</unit>
			<unit id="tcs_mechanic_through_their_phone_label">
				<segment>
					<source>Mechanics - phone</source>
				</segment>
			</unit>
			<unit id="tcs_mechanic_through_the_chassis">
				<segment>
					<source>Mechanics through the chassis</source>
				</segment>
			</unit>
			<unit id="tcs_mechanic_through_the_chassis_label">
				<segment>
					<source>Mechanics - chassis</source>
				</segment>
			</unit>
			<unit id="tcs_bowler">
				<segment>
					<source>Bowlers through the bowler console</source>
				</segment>
			</unit>
			<unit id="tcs_bowler_label">
				<segment>
					<source>Bowlers</source>
				</segment>
			</unit>
			<unit id="tcs_status_complete">
				<segment>
					<source>Complete</source>
				</segment>
			</unit>
			<unit id="tcs_status_complete_label">
				<segment>
					<source>Completed Calls</source>
				</segment>
			</unit>
			<unit id="tcs_status_cancel">
				<segment>
					<source>Cancel</source>
				</segment>
			</unit>
			<unit id="tcs_status_cancel_label">
				<segment>
					<source>Canceled Calls</source>
				</segment>
			</unit>
			<unit id="tcs_event_types_open">
				<segment>
					<source>Open</source>
				</segment>
			</unit>
			<unit id="tcs_event_types_open_label">
				<segment>
					<source>Lane Open</source>
				</segment>
			</unit>
			<unit id="tcs_event_types_closed">
				<segment>
					<source>Closed</source>
				</segment>
			</unit>
			<unit id="tcs_event_types_closed_label">
				<segment>
					<source>Lane Closed</source>
				</segment>
			</unit>
			<unit id="tcs_calls_lane_status">
				<segment>
					<source>Calls occurring when the lane is</source>
				</segment>
			</unit>
			<unit id="tcs_calls_status">
				<segment>
					<source>Call Status</source>
				</segment>
			</unit>
			<unit id="tcs_edge_string_lanes">
				<segment>
					<source>EDGE String Lanes</source>
				</segment>
			</unit>
			<unit id="tcs_casual_play">
				<segment>
					<source>Casual Play</source>
				</segment>
			</unit>
			<unit id="tcs_USBC_certified">
				<segment>
					<source>USBC Certified</source>
				</segment>
			</unit>
			<unit id="tcs_all_others">
				<segment>
					<source>All others</source>
				</segment>
			</unit>
			<unit id="tcs_label_generatedby">
				<segment>
					<source>Generated by</source>
				</segment>
			</unit>
			<unit id="tcs_label_source">
				<segment>
					<source>Source</source>
				</segment>
			</unit>
			<unit id="tcs_label_sources">
				<segment>
					<source>Sources</source>
				</segment>
			</unit>
			<unit id="tcs_label_filter">
				<segment>
					<source>Filter</source>
				</segment>
			</unit>
			<unit id="tcs_label_select_options">
				<segment>
					<source>Select Options</source>
				</segment>
			</unit>
			<unit id="tcs_label_allsources">
				<segment>
					<source>All Sources</source>
				</segment>
			</unit>
			<unit id="tcs_table_playedframes">
				<segment>
					<source>Played Frames</source>
				</segment>
			</unit>
			<unit id="tcs_table_stop_occurrences">
				<segment>
					<source>Stop Occurrences</source>
				</segment>
			</unit>
			<unit id="tcs_table_frames">
				<segment>
					<source>Frames/Stop (Avg)</source>
				</segment>
			</unit>
			<unit id="tcs_table_downtime">
				<segment>
					<source>Downtime</source>
				</segment>
			</unit>
			<unit id="tcs_table_worktime">
				<segment>
					<source>Work time</source>
				</segment>
			</unit>
			<unit id="tcs_table_downavg">
				<segment>
					<source>Downtime Average</source>
				</segment>
			</unit>
			<unit id="tcs_table_openmode">
				<segment>
					<source>Opening Mode</source>
				</segment>
			</unit>
			<unit id="tcs_table_mecharrival">
				<segment>
					<source>Mechanical Arrival</source>
				</segment>
			</unit>
			<unit id="tcs_table_responsetime">
				<segment>
					<source>Response time</source>
				</segment>
			</unit>
			<unit id="tcs_table_mechanic">
				<segment>
					<source>Mechanic</source>
				</segment>
			</unit>
			<unit id="tcs_table_comments">
				<segment>
					<source>Comments</source>
				</segment>
			</unit>
			<unit id="tcs_noexport_message">
				<segment>
					<source>No data to export, check the filters please.</source>
				</segment>
			</unit>
			<unit id="tcs_no_stops">
				<segment>
					<source>No stops</source>
				</segment>
			</unit>
		</group>
		<group id="select-center">
			<unit id="selectc_select_req">
				<segment>
					<source>Please select a Bowling Center.</source>
				</segment>
			</unit>
		</group>
		<group id="not-found">
			<unit id="not_found_title">
				<segment>
					<source>Not Found</source>
				</segment>
			</unit>
			<unit id="not_found_page_title">
				<segment>
					<source>Page not found</source>
				</segment>
			</unit>
			<unit id="not_found_sorry">
				<segment>
					<source>Sorry, we couldn't find the page you were looking for.</source>
				</segment>
			</unit>
			<unit id="not_found_suggest_main_section">
				<segment>
					<source>We suggest that you return to main sections.</source>
				</segment>
			</unit>
			<unit id="not_found_button_main_section">
				<segment>
					<source>Go to the main page</source>
				</segment>
			</unit>
		</group>
		<group id="online-backup">
			<unit id="obck_title">
				<segment>
					<source>Conqueror Cloud Online Backups</source>
				</segment>
			</unit>
			<unit id="obck_definition">
				<segment>
					<source>The Online Backup Service safely stores copies of the last seven backups created by Conqueror.</source>
				</segment>
			</unit>
			<unit id="obck_security">
				<segment>
					<source>For security reasons, Conqueror can only upload backups to the cloud but cannot download or delete them.</source>
				</segment>
			</unit>
			<unit id="obck_contact">
				<segment>
					<source>In case the online copy of a backup is needed, please contact the QubicaAMF Support team.</source>
				</segment>
			</unit>
			<unit id="obck_t_date">
				<segment>
					<source>Backup Date</source>
				</segment>
			</unit>
			<unit id="obck_t_state">
				<segment>
					<source>State</source>
				</segment>
			</unit>
			<unit id="obck_t_size">
				<segment>
					<source>Size</source>
				</segment>
			</unit>
			<unit id="obck_t_files">
				<segment>
					<source>Files</source>
				</segment>
			</unit>
			<unit id="obck_t_available">
				<segment>
					<source>Available</source>
				</segment>
			</unit>
			<unit id="obck_t_inprogress">
				<segment>
					<source>In Progress</source>
				</segment>
			</unit>
			<unit id="obck_m_download_C_backup">
				<segment>
					<source>Download Conqueror backup</source>
				</segment>
			</unit>
			<unit id="obck_m_dowloading">
				<segment>
					<source>Downloading</source>
				</segment>
			</unit>
			<unit id="obck_m_download_recent">
				<segment>
					<source>Download the most recent backup</source>
				</segment>
			</unit>
			<unit id="obck_m_download_older">
				<segment>
					<source>Download an older backup</source>
				</segment>
			</unit>
			<unit id="obck_m_download_not_leave_this_website">
				<segment>
					<source>Please do not leave this website while the download is in progress.</source>
				</segment>
			</unit>
			<unit id="obck_progessbar_downloaded">
				<segment>
					<source>Downloded {{count}} of {{totals}}</source>
				</segment>
			</unit>
		</group>
		<group id="pos_solution">
			<unit id="pos_solution_item_library_title">
				<segment>
					<source>Item Library</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_title">
				<segment>
					<source>Catalog Synchronization</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_add_custom_attribute_title">
				<segment>
					<source>Add the Area custom attribute to the items in Square</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_add_custom_attribute_desc">
				<segment>
					<source>Add to Square the values of the Area custom attribute to assign an area to your items.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_custom_attribute_added">
				<segment>
					<source>Items in Square have the Area custom attribute</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_confirm_title">
				<segment>
					<source>Start the Square catalog synchronization</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_confirm_part_1">
				<segment>
					<source>Starting the Square catalog synchronization, all items, modifiers, groups, departments, categories and experiences created in Conqueror will be permanently deleted and replaced by the items in the Square catalog.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_confirm_part_2">
				<segment>
					<source>The current configuration of items, web offers, extras and rental shoes  for the Web and Kiosk services will be permanently deleted.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_not_active_title">
				<segment>
					<source>Initialize the Square catalog synchronization</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_not_active_desc">
				<segment>
					<source>Start the Square catalog synchronization to import the catalog of the {{squareLocationName}} Square location to the catalog of the QubicaAMF {{centerName}} Center.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_syncing_title">
				<segment>
					<source>Syncing with Square</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_syncing_desc">
				<segment>
					<source>Conqueror Server must be up and running until the synchronization is completed</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_requirements_check_title">
				<segment>
					<source>Checking requirements</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_requirements_check_desc">
				<segment>
					<source>Square catalog synchronization will start soon</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_requirements_failed_title">
				<segment>
					<source>Square catalog synchronization failed</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_requirements_failed_desc_part_1">
				<segment>
					<source>One or more requirements were not met. Please check the list below and try again.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_requirements_failed_desc_part_2">
				<segment>
					<source>Square catalog is not syncing.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_failed_title">
				<segment>
					<source>Square catalog synchronization failed</source>
				</segment>
			</unit>
			<unit id="pos_solution_error_code">
				<segment>
					<source>ERROR CODE: {{ErrorCode}}</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_warning_part_1">
				<segment>
					<source>Unable to communicate with the Conqueror Server. Please check that the Conqueror Server is running and connected to the network. Contact the QubicaAMF Tech Support to get help and restore the backup.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_warning_part_2">
				<segment>
					<source>In case of a temporary network error, the Square catalog synchronization will resume as soon as the Conqueror Server goes back online.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_warning_part_3">
				<segment>
					<source>Conqueror terminals and Kiosk devices can't be reactivated; the web reservation service can't go back online.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_failed_desc_part_1">
				<segment>
					<source>An error occurred and the Square catalog synchronization could not be completed.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_failed_desc_part_2">
				<segment>
					<source>Contact the QubicaAMF Tech Support to get help; follow the instructions they'll give you before restarting the synchronization process.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_synchronization_failed_desc_part_3">
				<segment>
					<source>Conqueror terminals and kiosk devices can't be reactivated; the web reservation service can't go back online.</source>
				</segment>
			</unit>
			<unit id="pos_solution_catalog_import_failed_desc">
				<segment>
					<source>An error occurred and the Square catalog synchronization could not be completed. Try syncing again; if the problem persists, please contact the QubicaAMF Tech Support to get help. Conqueror terminals and Kiosk devices cannot be reactivated; the web reservation service cannot go back online.</source>
				</segment>
			</unit>
			<unit id="pos_solution_synchronization_temporary_unavailable_desc_part_1">
				<segment>
					<source>An error occurred and the Square catalog synchronization is temporarily unavailable. Changes made from the Square Dashboard won't be automatically imported to Conqueror.</source>
				</segment>
			</unit>
			<unit id="pos_solution_synchronization_temporary_unavailable_desc_part_2">
				<segment>
					<source>Contact the QubicaAMF Tech Support to get help; follow the instructions they'll give you before trying again the synchronization process.</source>
				</segment>
			</unit>
			<unit id="pos_solution_synchronization_temporary_unavailable_desc_part_3">
				<segment>
					<source>Conqueror terminals, Kiosk devices and the web reservation service are working properly.</source>
				</segment>
			</unit>
			<unit id="pos_solution_unable_to_add_custom_attribute_part_1">
				<segment>
					<source>Unable to add custom Area attribute to items in Square.</source>
				</segment>
			</unit>
			<unit id="pos_solution_unable_to_add_custom_attribute_part_2">
				<segment>
					<source>If the problem persists, please contact the QubicaAMF Tech Support to get help.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_completed_title">
				<segment>
					<source>Synchronization completed</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_completed_desc_part_1">
				<segment>
					<source>The Square catalog was imported successfully and the automatic synchronization is on.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_completed_desc_part_2">
				<segment>
					<source>All future changes made from the Square Dashboard will be automatically imported to Conqueror.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_requirements_title">
				<segment>
					<source>Synchronization requirements</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_conqueror_updated">
				<segment>
					<source>Conqueror is updated to a version equal or higher than {{conquerorVersion}}</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_conqueror_connected">
				<segment>
					<source>Conqueror Server is running and connected to the network</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_main_currency_paired">
				<segment>
					<source>The main currency of Conqueror matches the currency of the Square Location</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_conqueror_terminal_unused">
				<segment>
					<source>All the Conqueror terminals in your center are not being used</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_kiosk_devices_unused">
				<segment>
					<source>All the Kiosk stations of your center are inactive</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_web_reservation_unused">
				<segment>
					<source>The maintenance mode is enabled for your web reservation service</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_import_title">
				<segment>
					<source>Importing Square catalog</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_web_offers_cleaned_up">
				<segment>
					<source>Deleting web offers, food and beverage items, extras and rental shoes items for the web reservation and kiosk services</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_departments_cleaned_up">
				<segment>
					<source>Deleting departments, items, modifiers and modifiers groups</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_catalog_imported">
				<segment>
					<source>Importing Square catalog</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_conqueror_aligned">
				<segment>
					<source>Downloading catalog to Conqueror</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_synced_items_title">
				<segment>
					<source>Synced items</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_tutorial_title">
				<segment>
					<source>Tutorial</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_title">
				<segment>
					<source>Connection to Square</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_disconnected_title">
				<segment>
					<source>Connect QubicaAMF and Square Accounts</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_disconnected_desc">
				<segment>
					<source>Sign in to the Square Owner account of your business to connect it with your QubicaAMF company</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_connected_title">
				<segment>
					<source>Square Connected!</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_connected_desc">
				<segment>
					<source>Your company is connected to Square.</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_connect_button">
				<segment>
					<source>Connect to Square</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_revoke_button">
				<segment>
					<source>Disconnect Square</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_empty_select">
				<segment>
					<source>Select Square location</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_sandbox_switch">
				<segment>
					<source>Connect a Sandbox account</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_revoke_modal_title">
				<segment>
					<source>Revoke permissions</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_revoke_modal_description">
				<segment>
					<source>Do you want to revoke permissions?</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_delete_pairing_modal_title">
				<segment>
					<source>Remove center pairing for {{centerName}}</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_delete_pairing_modal_description">
				<segment>
					<source>Are you sure you want to disconnect the Square Location?</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_pairing_replace_modal_title">
				<segment>
					<source>Replace current pairing</source>
				</segment>
			</unit>
			<unit id="pos_solution_authorization_pairing_replace_modal_description">
				<segment>
					<source>Are you sure you want to replace paired Square Location for the following centers?</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_no_department_type">
				<segment>
					<source>No Department Type</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_GameFBT">
				<segment>
					<source>FB Games</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_GameBowling">
				<segment>
					<source>Game Bowling</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_TimeBowling">
				<segment>
					<source>Time Bowling</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_UnlimitedBowling">
				<segment>
					<source>Unlimited Bowling</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_LeagueBowling">
				<segment>
					<source>League Bowling</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_TournamentBowling">
				<segment>
					<source>Tournament Bowling</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_ShoesSocks">
				<segment>
					<source>Shoes and Socks</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_ExtraFrames">
				<segment>
					<source>Extra Frames</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_Income">
				<segment>
					<source>Income</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_Expense">
				<segment>
					<source>Expenses</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_FoodAndBeverage">
				<segment>
					<source>Food and Beverage</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_ProShop">
				<segment>
					<source>Pro Shop</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_Lockers">
				<segment>
					<source>Lockers</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_TimeGamesPlay">
				<segment>
					<source>Time Games</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_TimeGamesTool">
				<segment>
					<source>Time Game Tools</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_QCash">
				<segment>
					<source>QCash</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_Trust">
				<segment>
					<source>Trust</source>
				</segment>
			</unit>
			<unit id="pos_solution_department_Unknown">
				<segment>
					<source>Miscellaneous</source>
				</segment>
			</unit>
			<unit id="pos_solution_pair_square_terminals">
				<segment>
					<source>Pair Square Terminals</source>
				</segment>
			</unit>
			<unit id="pos_solution_there_is_no_square_terminal_yet">
				<segment>
					<source>There is no Square Terminal yet.</source>
				</segment>
			</unit>
			<unit id="pos_solution_add_a_square_terminal_to_assign_it_to_one_of_your_stations">
				<segment>
					<source>Add a Square Terminal to assign it to one of your stations.</source>
				</segment>
			</unit>
			<unit id="pos_solution_add_a_square_terminal">
				<segment>
					<source>Add a Square Terminal</source>
				</segment>
			</unit>
			<unit id="pos_solution_all_device_types">
				<segment>
					<source>All Device Types</source>
				</segment>
			</unit>
			<unit id="pos_solution_conqueror_terminal_or_qpad">
				<segment>
					<source>Conqueror Terminal or QPad</source>
				</segment>
			</unit>
			<unit id="pos_solution_terminal_linked_to_alias">
				<segment>
					<source>{{terminalName}} (linked to {{aliasName}})</source>
				</segment>
			</unit>
			<unit id="pos_solution_enter_the_device_code_below">
				<segment>
					<source>Enter the Device Code below on the Square Terminal</source>
				</segment>
			</unit>
			<unit id="pos_solution_create_square_device">
				<segment>
					<source>Create Square device</source>
				</segment>
			</unit>
			<unit id="pos_solution_no_device_code">
				<segment>
					<source>No Device Code</source>
				</segment>
			</unit>
			<unit id="pos_solution_pair_data_devicename">
				<segment>
					<source>Square Nickname</source>
				</segment>
			</unit>
			<unit id="pos_solution_pair_data_devicecode">
				<segment>
					<source>Device Code</source>
				</segment>
			</unit>
			<unit id="pos_solution_pair_data_expireddate">
				<segment>
					<source>Device Code expiration date</source>
				</segment>
			</unit>
			<unit id="pos_solution_pair_data_status">
				<segment>
					<source>Device Code status</source>
				</segment>
			</unit>
			<unit id="pos_solution_paired">
				<segment>
					<source>Paired</source>
				</segment>
			</unit>
			<unit id="pos_solution_unpaired">
				<segment>
					<source>Unpaired</source>
				</segment>
			</unit>
			<unit id="pos_solution_expired">
				<segment>
					<source>Expired</source>
				</segment>
			</unit>
			<unit id="pos_solution_missing">
				<segment>
					<source>Missing</source>
				</segment>
			</unit>
			<unit id="pos_solution_never_expires">
				<segment>
					<source>Never expires</source>
				</segment>
			</unit>
			<unit id="pos_solution_sign_in_to_the_square_terminal">
				<segment>
					<source>Sign in to the Square Terminal and enter the Device Code to pair the device with this station.</source>
				</segment>
			</unit>
			<unit id="pos_solution_reset_the_device_code">
				<segment>
					<source>Reset the Device Code from the Square dashboard to unpair the device from this station.</source>
				</segment>
			</unit>
			<unit id="pos_solution_deleting_device">
				<segment>
					<source>Deleting device</source>
				</segment>
			</unit>
			<unit id="pos_solution_are_you_sure_you_want_to_delete_device_name">
				<segment>
					<source>Are you sure you want to delete {{deviceName}}?</source>
				</segment>
			</unit>
			<unit id="pos_solution_device_should_also_be_deleted_from_square">
				<segment>
					<source>This device should also be deleted from Square, if present.</source>
				</segment>
			</unit>
			<unit id="pos_solution_go_to_square_dashboard_to_delete_device">
				<segment>
					<source>Go to the Device section of the Square dashboard to delete the device.</source>
				</segment>
			</unit>
			<unit id="pos_solution_alias_error_save_duplicate_message">
				<segment>
					<source>A device with this name already exists. Please enter a different name and try again.</source>
				</segment>
			</unit>
			<unit id="pos_solution_alias_error_save_station_assigned_message">
				<segment>
					<source>The selected station is already paired with a device. Please select a different station and try again.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_title_part_1">
				<segment>
					<source>Follow the synchronization procedure to import your Square catalog to Conqueror.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_title_part_2">
				<segment>
					<source>With the catalog synchronization, you'll use a single and shared catalog for your sales.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_title">
				<segment>
					<source>Synchronization Tips</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_1_part_1">
				<segment>
					<source>Assign an Area to your items in Square.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_1_part_2">
				<segment>
					<source>The Area custom attribute is needed to finalize the configuration of the items which do not belong to the Food and Beverage area.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_2">
				<segment>
					<source>Start the catalog synchronization when your bowling center is closed.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_3">
				<segment>
					<source>While the catalog synchronization is running, settings and sales areas are disabled.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_3_link">
				<segment>
					<source>If your Square catalog is empty, add items to it through the Square Dashboard.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_4">
				<segment>
					<source>Don't make changes to your Square catalog while the catalog synchronization is running.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_5">
				<segment>
					<source>After the first catalog synchronization is complete, finalize the bowling items configuration.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_6">
				<segment>
					<source>After the first catalog synchronization is complete, finalize your Conqueror, Web and Kiosk configuration.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_tips_point_7">
				<segment>
					<source>When all is set, you can go back to your sales activities.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_assign_item_type_title">
				<segment>
					<source>Assign the Area custom attribute to your items</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_assign_item_type_point_1">
				<segment>
					<source>Add to Square the values of the Area custom attribute to categorize your items. The Area custom attribute will be automatically added to the details page of your items in Square.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_assign_item_type_point_2">
				<segment>
					<source>The Area custom attribute values can be found in the Custom Attribute section of the details page of each item.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_assign_item_type_point_3">
				<segment>
					<source>Items can belong to the following areas:</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_assign_item_type_point_4">
				<segment>
					<source>Items with no area assigned will be automatically categorized as Food and Beverage.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_assign_item_type_point_5">
				<segment>
					<source>Remember to save your changes.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_title">
				<segment>
					<source>How to start the Square catalog synchronization</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_1">
				<segment>
					<source>Update Conqueror X to a version equal or higher than {{conquerorVersion}}.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_2">
				<segment>
					<source>Make sure that the Conqueror Server is running and connected to the network.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_3">
				<segment>
					<source>Make sure that the main currency of Conqueror matches the currency of the Square Location.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_4">
				<segment>
					<source>Make sure that all the Conqueror terminals in your center are not being used.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_5">
				<segment>
					<source>Deactivate all the Kiosk stations of your center.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_6">
				<segment>
					<source>Enable the maintenance mode for your web reservation service.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_how_to_start_point_7">
				<segment>
					<source>Start the catalog synchronization from this QPortal section.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_what_happen_title">
				<segment>
					<source>What the catalog synchronization procedure does</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_what_happen_point_1">
				<segment>
					<source>Web and Kiosk services are temporarily disabled while the synchronization is running.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_what_happen_point_2">
				<segment>
					<source>Conqueror terminals are disconnected (if in use) while the synchronization is running.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_what_happen_point_3">
				<segment>
					<source>All items, modifiers and departments of Conqueror are permanently deleted and replaced by the items in the Square catalog. All the reservations already placed will not be affected.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_what_happen_point_4">
				<segment>
					<source>Department colors are automatically assigned. Manual color management is no longer available.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_what_happen_point_5">
				<segment>
					<source>The current configuration of items, web offers, extras and rental shoes is permanently deleted. You'll need to configure them again once the synchronization is complete.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_title">
				<segment>
					<source>Finalize the synchronization and go back to business.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_1">
				<segment>
					<source>After the catalog synchronization is complete, Conqueror, Web and Kiosk devices can be used again.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_2">
				<segment>
					<source>Complete the bowling items configuration.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_1">
				<segment>
					<source>Complete the Conqueror setup configuration:</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_2">
				<segment>
					<source>Set up the Experiences configuration (SETUP > General > Experience Setup)</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_3">
				<segment>
					<source>Set up the default Price Key items in the Payments window (SETUP > General > Center Setup)</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_4">
				<segment>
					<source>Set up the default Price Key items for each Bowling Mode (SETUP > General > Bowling Modes > Bowling)</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_5">
				<segment>
					<source>Set up the Categories and Sub-categories configuration (SETUP > POS Setup > Categories)</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_6">
				<segment>
					<source>Set up the configuration of each Bar Group (SETUP > POS Setup > Lane Order Menus)</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_3_line_7">
				<segment>
					<source>Set up the configuration of each group (SETUP > Modules &amp; Options Setup > MMS Setup > Content Setup > Price list)</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_4">
				<segment>
					<source>Complete the Web and Kiosk services configuration.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_point_5">
				<segment>
					<source>Once the Web and Kiosk services configuration is complete, reactivate the Kiosk stations of your center and disable the maintenance mode for your web reservation service.</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_finalize_end">
				<segment>
					<source>When all is set, you can go back to your sales activities.</source>
				</segment>
			</unit>
			<unit id="pos_solution_integration_tutorial_end">
				<segment>
					<source>You're all set!</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_skip">
				<segment>
					<source>Skip Tutorial</source>
				</segment>
			</unit>
			<unit id="pos_solution_tutorial_does_not_show_again">
				<segment>
					<source>Don't show this again</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_permission_title">
				<segment>
					<source>Selling permissions</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_permission_1">
				<segment>
					<source>Authorization - Type 1</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_permission_2">
				<segment>
					<source>Authorization - Type 2</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_end">
				<segment>
					<source>When one or both types of authorization are enabled for an item, only users with the required authorizations can sell the item.</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_category">
				<segment>
					<source>Reporting Category</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_configuration_title">
				<segment>
					<source>Item configuration</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_pair_socks">
				<segment>
					<source>Pairs of shoes and socks</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_include_shoes">
				<segment>
					<source>Include shoes</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_time_proportional">
				<segment>
					<source>Flexible time-based pricing</source>
				</segment>
			</unit>
			<unit id="pos_solution_bowling_item_details_confirm_save_modal">
				<segment>
					<source>Options in Conqueror may have this item set as default. Saving changes will remove the default item from the options involved. To keep using those options, you will need to set a new default item for them.</source>
				</segment>
			</unit>
		</group>
		<group id="services-activation">
			<unit id="srvact_techlogs">
				<segment>
					<source>Technical Logs</source>
				</segment>
			</unit>
			<unit id="srvact_techlogs_troubleshooting">
				<segment>
					<source>Enhanced Troubleshooting and Assistance</source>
				</segment>
			</unit>
			<unit id="srvact_prod_improvs">
				<segment>
					<source>Product Improvements</source>
				</segment>
			</unit>
			<unit id="srvact_perf">
				<segment>
					<source>Product Performance</source>
				</segment>
			</unit>
			<unit id="srvact_perfmon">
				<segment>
					<source>Performance Monitoring Dashboard</source>
				</segment>
			</unit>
			<unit id="srvact_games">
				<segment>
					<source>Games</source>
				</segment>
			</unit>
			<unit id="srvact_games_stat_dashboard">
				<segment>
					<source>Game Statistic Dashboard</source>
				</segment>
			</unit>
			<unit id="srvact_online_scoring">
				<segment>
					<source>Online Scoring</source>
				</segment>
			</unit>
			<unit id="srvact_waiting_list_sms">
				<segment>
					<source>Waiting List SMS</source>
				</segment>
			</unit>
			<unit id="srvact_sore_api">
				<segment>
					<source>Score Information APIs</source>
				</segment>
			</unit>
			<unit id="srvact_waiting_list_api">
				<segment>
					<source>Waiting List APIs</source>
				</segment>
			</unit>
			<unit id="srvact_report_api">
				<segment>
					<source>Reporting APIs</source>
				</segment>
			</unit>
			<unit id="srvact_economic">
				<segment>
					<source>Reservations and Financial</source>
				</segment>
			</unit>
			<unit id="srvact_business_report">
				<segment>
					<source>Business Reporting Dashboard</source>
				</segment>
			</unit>
			<unit id="srvact_web_booking">
				<segment>
					<source>Web Booking System</source>
				</segment>
			</unit>
			<unit id="srvact_web_booking_api">
				<segment>
					<source>Web Booking APIs</source>
				</segment>
			</unit>
			<unit id="srvact_reporting">
				<segment>
					<source>Reporting APIs</source>
				</segment>
			</unit>
			<unit id="srvact_backup">
				<segment>
					<source>Secure Online Backups</source>
				</segment>
			</unit>
			<unit id="srvact_backup_disaster_rec">
				<segment>
					<source>Disaster Recovery</source>
				</segment>
			</unit>
			<unit id="srvact_backup_enhanced_tr">
				<segment>
					<source>Enhanced Troubleshooting</source>
				</segment>
			</unit>
			<unit id="srvact_page_title">
				<segment>
					<source>Conqueror Cloud Data Selection</source>
				</segment>
			</unit>
			<unit id="srvact_select_categories">
				<segment>
					<source>Select the categories of Conqueror data to be securely transmitted and stored within the Conqueror Cloud.</source>
				</segment>
			</unit>
			<unit id="srvact_important_notes">
				<segment>
					<source>Important Note: Disabling any of the below data categories will result in the interruption of the related connected components &amp; services and may adversely impact support, staff functions and customer-facing features.</source>
				</segment>
			</unit>
			<unit id="srvact_data_categories">
				<segment>
					<source>Data Categories</source>
				</segment>
			</unit>
			<unit id="srvact_data_category">
				<segment>
					<source>Data Category</source>
				</segment>
			</unit>
			<unit id="srvact_connected_features">
				<segment>
					<source>Connected Features and Services</source>
				</segment>
			</unit>
			<unit id="srvact_disable_services_title">
				<segment>
					<source>Confirm Disabling Services</source>
				</segment>
			</unit>
			<unit id="srvact_disable_services">
				<segment>
					<source>Disable Services</source>
				</segment>
			</unit>
			<unit id="srvact_disabled_categories">
				<segment>
					<source>You have selected to disable the following data categories:</source>
				</segment>
			</unit>
			<unit id="srvact_disabled_categories_footer">
				<segment>
					<source>This will result in the interruption of the connected Features &amp; Services.</source>
				</segment>
			</unit>
		</group>
		<group id="remote_assistance">
			<unit id="rmt_ass_title">
				<segment>
					<source>Remote Assistance</source>
				</segment>
			</unit>
			<unit id="rmt_ass_auth_qamf_view_data">
				<segment>
					<source>Authorize QubicaAMF Support team to view your data</source>
				</segment>
			</unit>
			<unit id="rmt_ass_qamf_canview_notmodify">
				<segment>
					<source>QubicaAMF Support team has been temporarily granted permission to view data stored in the cloud.</source>
				</segment>
			</unit>
			<unit id="rmt_ass_qamf_permissions_revoke">
				<segment>
					<source>You can revoke this permission at any time or modify the expiry date and time.</source>
				</segment>
			</unit>
			<unit id="rmt_ass_off_enable_assistance">
				<segment>
					<source>This function allows you to enable selected members of the QubicaAMF Support team to temporarily access your center data stored in the cloud for training or troubleshooting purposes.</source>
				</segment>
			</unit>
			<unit id="rmt_ass_off_proceed_you_need_remote_assistance">
				<segment>
					<source>Please proceed only in the case you need remote assistance and you already informed our team about it.</source>
				</segment>
			</unit>
			<unit id="rmt_ass_off_notes">
				<segment>
					<source>Notes:</source>
				</segment>
			</unit>
			<unit id="rmt_ass_off_notes_1">
				<segment>
					<source>Once authorized, selected QubicaAMF staff members will be able to view your data stored in the cloud, but not to modify it.</source>
				</segment>
			</unit>
			<unit id="rmt_ass_off_notes_2">
				<segment>
					<source>This authorization will automatically expire in two days.</source>
				</segment>
			</unit>
			<unit id="rmt_ass_off_notes_3">
				<segment>
					<source>You can revoke, reduce or extend this temporary permission at any time by accessing this window while the temporary authorization is active.</source>
				</segment>
			</unit>
		</group>
		<group id="cmp">
			<unit id="label_centername">
				<segment>
					<source>Center Name</source>
				</segment>
			</unit>
			<unit id="label_center_info">
				<segment>
					<source>Center Info</source>
				</segment>
			</unit>
			<unit id="label_conquerorx">
				<segment>
					<source>Conqueror X</source>
				</segment>
			</unit>
			<unit id="label_conquerorpro">
				<segment>
					<source>Conqueror Pro</source>
				</segment>
			</unit>
			<unit id="label_terminals">
				<segment>
					<source>Terminals</source>
				</segment>
			</unit>
			<unit id="label_terminal_params">
				<segment>
					<source>{{terminals}} Terminal</source>
				</segment>
			</unit>
			<unit id="label_terminals_params">
				<segment>
					<source>{{terminals}} Terminals</source>
				</segment>
			</unit>
			<unit id="label_license">
				<segment>
					<source>{{licenses}} License</source>
				</segment>
			</unit>
			<unit id="label_licenses">
				<segment>
					<source>{{licenses}} Licenses</source>
				</segment>
			</unit>
			<unit id="label_conquerortier">
				<segment>
					<source>Conqueror X Tier</source>
				</segment>
			</unit>
			<unit id="label_programstatus">
				<segment>
					<source>Program Status</source>
				</segment>
			</unit>
			<unit id="label_programdetails_manageplan">
				<segment>
					<source>Manage your plan</source>
				</segment>
			</unit>
			<unit id="label_cmpstartdate">
				<segment>
					<source>Start Date</source>
				</segment>
			</unit>
			<unit id="label_cmpexpiredate">
				<segment>
					<source>Expiration Date</source>
				</segment>
			</unit>
			<unit id="label_nexttoexpire">
				<segment>
					<source>Next To Expire</source>
				</segment>
			</unit>
			<unit id="label_cmp_kiosk_stations">
				<segment>
					<source>Kiosk Stations</source>
				</segment>
			</unit>
			<unit id="label_cmp_kiosk_station">
				<segment>
					<source>Kiosk Station</source>
				</segment>
			</unit>
			<unit id="label_cmp_neoverse_basic">
				<segment>
					<source>Neoverse</source>
				</segment>
			</unit>
			<unit id="label_cmp_neoverse_interactive">
				<segment>
					<source>Neoverse Interactive Edition</source>
				</segment>
			</unit>
			<unit id="label_rejoin">
				<segment>
					<source>Rejoin</source>
				</segment>
			</unit>
			<unit id="label_extend">
				<segment>
					<source>Extend</source>
				</segment>
			</unit>
			<unit id="label_active">
				<segment>
					<source>Active</source>
				</segment>
			</unit>
			<unit id="label_inactive">
				<segment>
					<source>Inactive</source>
				</segment>
			</unit>
			<unit id="label_activate">
				<segment>
					<source>Activate</source>
				</segment>
			</unit>
			<unit id="label_deactivate">
				<segment>
					<source>Deactivate</source>
				</segment>
			</unit>
			<unit id="label_display_name">
				<segment>
					<source>Display Name</source>
				</segment>
			</unit>
			<unit id="label_nothing_to_display">
				<segment>
					<source>Nothing to display</source>
				</segment>
			</unit>
			<unit id="label_conqueror_name">
				<segment>
					<source>Conqueror Name</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_activedaysleft">
				<segment>
					<source>Active, {{days}} days left</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_activedayleft">
				<segment>
					<source>Active, {{days}} day left</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_activerenewsindays">
				<segment>
					<source>Active, renews in {{days}} days</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_activerenewsinday">
				<segment>
					<source>Active, renews in {{days}} day</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_expireinday">
				<segment>
					<source>Expires in {{days}} day</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_expiresindays">
				<segment>
					<source>Expires in {{days}} days</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_expired">
				<segment>
					<source>Expired</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_expiredgraceperiodday">
				<segment>
					<source>Expired, grace period {{days}} day left</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_expiredgraceperioddays">
				<segment>
					<source>Expired, grace period {{days}} days left</source>
				</segment>
			</unit>
			<unit id="label_subscriptionstatus_notexpiring">
				<segment>
					<source>Active</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_maximization_program">
				<segment>
					<source>Conqueror Maximization Program</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_maximization_program_description">
				<segment>
					<source>Your complete center operations software and support maintenance plan – includes automatic updates, cloud services, technical support, training support, and marketing support. Required to use additional services.</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_web">
				<segment>
					<source>Conqueror Web</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_web_description">
				<segment>
					<source>Online booking system and sales tools. Create offers that feature bowling content and bundles. Showcase F&amp;B, activities, and other non-bowling extras. Customize to match your center’s unique look and feel. Requires CMP.</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_kiosk">
				<segment>
					<source>Conqueror Kiosk</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_kiosk_description">
				<segment>
					<source>Smart, convenient self-service for your guests and greater flexibility for your staff. Includes check-in for reservations, walk-in and play now, F&amp;B ordering, future booking, advertising, and more. Requires CMP.</source>
				</segment>
			</unit>
			<unit id="cmp_neoverse">
				<segment>
					<source>Neoverse</source>
				</segment>
			</unit>
			<unit id="cmp_neoverse_description">
				<segment>
					<source>Your immersive entertainment service. It features a center-wide LED video wall integrated with the scoring system and a complete and responsive lights, music and effects system.</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_maximization_program_short">
				<segment>
					<source>CMP</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_web_short">
				<segment>
					<source>Web</source>
				</segment>
			</unit>
			<unit id="cmp_conqueror_kiosk_short">
				<segment>
					<source>Kiosk</source>
				</segment>
			</unit>
			<unit id="cmp_modal_contact_title">
				<segment>
					<source>Request information on Conqueror Maximization Program</source>
				</segment>
			</unit>
			<unit id="cmp_modal_contact_text">
				<segment>
					<source>How can we help? Please type any specific questions or requests here and your sales representative will be in touch as soon as possible to discuss.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_contact_success">
				<segment>
					<source>Thank you! Your request has been submitted and one of your sales representatives will be in contact soon.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_extend_title">
				<segment>
					<source>Request to extend services</source>
				</segment>
			</unit>
			<unit id="cmp_modal_extend_text">
				<segment>
					<source>Thank you for your business and continued participation. Please click below to have your sales representative contact you with options to extend your services.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_extend_success">
				<segment>
					<source>Your request has been submitted, your sales representative will get back to you soon.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_disable_automatic_renewal_title">
				<segment>
					<source>Disable the automatic renewal</source>
				</segment>
			</unit>
			<unit id="cmp_modal_disable_automatic_renewal_text">
				<segment>
					<source>Are you sure that you want to disable your automatic renewal? If confirmed, you must extend your services through this website or with your sales representative before their expiration date to avoid any interruption of your services.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_unsubscribe_success">
				<segment>
					<source>Thank you. Your request to disable automatic renewal is confirmed and your services will not be extended automatically at expiration. A sales representative will be in touch to discuss other renewal options.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_disable_service_title">
				<segment>
					<source>Disable {{service}}</source>
				</segment>
			</unit>
			<unit id="cmp_modal_disable_service_text">
				<segment>
					<source>Are you sure you want to cancel your {{service}} service? If you proceed, this service will not be extended at expiration and all included features and functions will stop at the expiration date.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_disable_service_success">
				<segment>
					<source>Thank you. Your request to disable {{service}} is confirmed. A sales representative will be in touch with you to discuss other available options.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_undo_disable_service_title">
				<segment>
					<source>Cancel request to disable {{service}} service</source>
				</segment>
			</unit>
			<unit id="cmp_modal_undo_disable_service_text">
				<segment>
					<source>You have previous requested to disable the {{service}} service. Do you want to cancel your request and keep the service active?</source>
				</segment>
			</unit>
			<unit id="cmp_modal_undo_disable_service_text_grace_period">
				<segment>
					<source>As you are past your expiration date, if you proceed your {{service}} service will be immediately renewed for one additional year from that date.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_undo_disable_service_success">
				<segment>
					<source>Thank you. Your request has been confirmed. The {{service}} service will be available for extension at expiration according to the terms defined for your center.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_undo_disable_service_success_grace_period">
				<segment>
					<source>Thank you. Your request has been confirmed. The {{service}} service will be immediately renewed.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_undo_disable_service_success_grace_period_invoice">
				<segment>
					<source>We will generate an invoice on the next business day that you will find within the QPortal, and payment will be based on your current terms and the details specified on the invoice.</source>
				</segment>
			</unit>
			<unit id="cmp_list_tab_program">
				<segment>
					<source>Program</source>
				</segment>
			</unit>
			<unit id="cmp_list_tab_invoices">
				<segment>
					<source>Invoices</source>
				</segment>
			</unit>
			<unit id="cmp_label_invoice_number">
				<segment>
					<source>Invoice Number</source>
				</segment>
			</unit>
			<unit id="cmp_label_invoice_date">
				<segment>
					<source>Invoice Date</source>
				</segment>
			</unit>
			<unit id="cmp_no_invoices">
				<segment>
					<source>No invoices found</source>
				</segment>
			</unit>
			<unit id="cmp_detail_intro_title">
				<segment>
					<source>Cloud services included in your Conqueror Maximization Program</source>
				</segment>
			</unit>
			<unit id="cmp_detail_intro_text">
				<segment>
					<source>The Conqueror Maximization Program is your complete center operations software and services maintenance plan that provides everything you need to get the most out of your investment in Conqueror X and your entire technology product ecosystem. Get the peace of mind that comes with frequent updates, access to experts and avoidance of unexpected expenses for software and support.</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_title">
				<segment>
					<source>Your program includes:</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_title_1">
				<segment>
					<source>Automatic Updates</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_text_1">
				<segment>
					<source>Automatic and continuous updates for scoring, management system, POS, marketing tools and more.</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_title_2">
				<segment>
					<source>Conqueror Cloud</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_text_2">
				<segment>
					<source>Conqueror Cloud services including business dashboards, secure backup and the platform for marketing, web and mobile features.</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_title_3">
				<segment>
					<source>Technical Support</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_text_3">
				<segment>
					<source>Technical support services and unlimited access to QubicaAMF experts just a phone call or email away.</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_title_4">
				<segment>
					<source>Training and Marketing</source>
				</segment>
			</unit>
			<unit id="cmp_detail_includes_text_4">
				<segment>
					<source>Training and marketing support services including an extensive resource library, access to virtual classrooms, turn-key marketing programs and more.</source>
				</segment>
			</unit>
			<unit id="cmp_payment_thankyou_title">
				<segment>
					<source>Extension Successful, Thank You!</source>
				</segment>
			</unit>
			<unit id="cmp_payment_thankyou_text">
				<segment>
					<source>We truly appreciate your business and continued participation in the Conqueror Maximization Program!</source>
				</segment>
			</unit>
			<unit id="cmp_payment_thankyou_text_after">
				<segment>
					<source>Please do not hesitate to contact any member of our team with any questions on the program or how we can help you get the most out of the features and services to maximize your business. We look forward to the continued opportunity to serve you!</source>
				</segment>
			</unit>
			<unit id="cmp_payment_thankyou_extended">
				<segment>
					<source>Your services have been extended through {{date}}.</source>
				</segment>
			</unit>
			<unit id="cmp_payment_error_title">
				<segment>
					<source>Sorry something went wrong</source>
				</segment>
			</unit>
			<unit id="cmp_payment_error_text">
				<segment>
					<source>We apologize for the inconvenience but we are not able to process your PayPal payment at this time. Please try again later or use another payment method. If your PayPal payment keeps being declined, please contact PayPal customer support for assistance.</source>
				</segment>
			</unit>
			<unit id="cmp_payment_error_contact">
				<segment>
					<source>Would you prefer to be contacted direcly by a sales representative to discuss renewal and payment options?</source>
				</segment>
			</unit>
			<unit id="cmp_payment_error_contactlink">
				<segment>
					<source>Click here</source>
				</segment>
			</unit>
			<unit id="cmp_payment_paypal_error_message">
				<segment>
					<source>PayPal Error Message</source>
				</segment>
			</unit>
			<unit id="cmp_appropriate_taxes_will_be_added">
				<segment>
					<source>The appropriate sales taxes will be added to your order total where applicable, in accordance with state and local sales tax laws.</source>
				</segment>
			</unit>
			<unit id="cmp_service_will_be_disabled">
				<segment>
					<source>Service will be disabled</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_service_title">
				<segment>
					<source>Extend your {{service}}</source>
				</segment>
			</unit>
			<unit id="cmp_rejoin_your_service_title">
				<segment>
					<source>Rejoin your {{service}}</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_services_title">
				<segment>
					<source>Extend your {{services}} and {{lastService}}</source>
				</segment>
			</unit>
			<unit id="cmp_rejoin_your_services_title">
				<segment>
					<source>Rejoin your {{services}} and {{lastService}}</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_services_expiration_day">
				<segment>
					<source>Your services will expire on {{date}}, 1 day left</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_services_expiration_days">
				<segment>
					<source>Your services will expire on {{date}}, {{days}} days left</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_service_expiration_day">
				<segment>
					<source>Your service will expire on {{date}}, 1 day left</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_service_expiration_days">
				<segment>
					<source>Your service will expire on {{date}}, {{days}} days left</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_service_expired">
				<segment>
					<source>Your service expired on {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_extend_your_services_expired">
				<segment>
					<source>Your services expired on {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_detail_disable_service">
				<segment>
					<source>Disable service</source>
				</segment>
			</unit>
			<unit id="cmp_detail_undo_disable_service">
				<segment>
					<source>Cancel your request to disable this service</source>
				</segment>
			</unit>
			<unit id="cmp_detail_faq_text">
				<segment>
					<source>If you want to know more about program details and inclusions, consult our frequently asked questions</source>
				</segment>
			</unit>
			<unit id="cmp_detail_faq_link">
				<segment>
					<source>Read the FAQs</source>
				</segment>
			</unit>
			<unit id="cmp_detail_faq_label">
				<segment>
					<source>FAQs</source>
				</segment>
			</unit>
			<unit id="cmp_detail_contact_text_get_in_touch">
				<segment>
					<source>If you have any questions about the Conqueror Maximization Program or want to explore additional extension and purchase options, get in touch with your local sales representative to learn more</source>
				</segment>
			</unit>
			<unit id="cmp_detail_contact_question">
				<segment>
					<source>Do you have any questions about the Conqueror Maximization Program or want to explore additional extension and purchase options?</source>
				</segment>
			</unit>
			<unit id="cmp_detail_contact_text">
				<segment>
					<source>Get in touch with your local sales representative to learn more by clicking the link below</source>
				</segment>
			</unit>
			<unit id="cmp_detail_contact_link">
				<segment>
					<source>Ask Us a Question</source>
				</segment>
			</unit>
			<unit id="cmp_detail_disable_automatic_renewal_intro">
				<segment>
					<source>This ensures your all of services (CMP and additional services) remain active and uninterrupted by automatically extending them for one year at your expiration date.</source>
				</segment>
			</unit>
			<unit id="cmp_detail_disable_automatic_renewal_text">
				<segment>
					<source>Invoices will be issued at each renewal and will be available here in the QPortal, and payment will be due based on your current terms and the details specified on the invoice. If disabled, you can manage extensions manually, including selecting monthly payment installments. Please click the link below to make changes to this option.</source>
				</segment>
			</unit>
			<unit id="cmp_detail_automatic_renewal">
				<segment>
					<source>Automatic Renewal</source>
				</segment>
			</unit>
			<unit id="cmp_detail_unsubscribe_link">
				<segment>
					<source>Request to disable automatic renewal</source>
				</segment>
			</unit>
			<unit id="cmp_detail_cancel_unsubscribe_button">
				<segment>
					<source>Cancel your request to disable automatic renewal</source>
				</segment>
			</unit>
			<unit id="cmp_modal_enable_renewal_title">
				<segment>
					<source>Enable automatic renewal</source>
				</segment>
			</unit>
			<unit id="cmp_modal_enable_automatic_renewal_success">
				<segment>
					<source>Thank you. Your request to reactivate automatic renewal is confirmed. Invoices will be issued at each renewal and will be available here in the QPortal, and payment will be due based on your current terms and the details specified on the invoice.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_enable_automatic_renewal_success_grace">
				<segment>
					<source>Your request to enable automatic renewal is confirmed. Your center has been renewed for an additional year. We will generate an invoice on the next business day that you will find within the QPortal, and payment will be due based on your current terms and the details specified on the invoice.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_enable_automatic_renewal_text">
				<segment>
					<source>You have previously requested to disable automatic renewal. Do you want to reactivate it? If you proceed your services will be automatically extended for one year at your expiration date.</source>
				</segment>
			</unit>
			<unit id="cmp_modal_enable_automatic_renewal_text_grace">
				<segment>
					<source>You have previously requested to disable automatic renewal. Do you want to reactivate it? As you are past your expiration date, If you proceed your program will be immediately renewed for one additional year from that date.</source>
				</segment>
			</unit>
			<unit id="cmp_payment_paybutton_title">
				<segment>
					<source>Pay Now</source>
				</segment>
			</unit>
			<unit id="cmp_payment_paybutton_subtitle">
				<segment>
					<source>with PayPal or by Credit Card</source>
				</segment>
			</unit>
			<unit id="cmp_payment_expiration_date">
				<segment>
					<source>New Expiration date</source>
				</segment>
			</unit>
			<unit id="cmp_payment_onepayment">
				<segment>
					<source>One payment</source>
				</segment>
			</unit>
			<unit id="cmp_payment_monthlypayment">
				<segment>
					<source>Monthly payment</source>
				</segment>
			</unit>
			<unit id="cmp_payment_taxes">
				<segment>
					<source>+ taxes</source>
				</segment>
			</unit>
			<unit id="cmp_payment_amount">
				<segment>
					<source>{{value}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_amount_monthly">
				<segment>
					<source>{{value}} / mo.</source>
				</segment>
			</unit>
			<unit id="cmp_payment_amount_duration">
				<segment>
					<source>(12 months)</source>
				</segment>
			</unit>
			<unit id="cmp_payment_amount_discount">
				<segment>
					<source>Save {{value}}</source>
				</segment>
			</unit>
			<unit id="cmpPaymentTitle">
				<segment>
					<source>Payment Window</source>
				</segment>
			</unit>
			<unit id="cmpPaymentServicesTitle">
				<segment>
					<source>Payment</source>
				</segment>
			</unit>
			<unit id="cmp_payment_services_expire_on">
				<segment>
					<source>Your services will expire on {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_service_expire_on">
				<segment>
					<source>Your service will expire on {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_services_expired_on">
				<segment>
					<source>Your services expired on {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_service_expired_on">
				<segment>
					<source>Your service expired on {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_add_one_year_expiring_services">
				<segment>
					<source>Add 1 year to your expiring services</source>
				</segment>
			</unit>
			<unit id="cmp_payment_add_one_year_expired_services">
				<segment>
					<source>Add 1 year to your expired services</source>
				</segment>
			</unit>
			<unit id="cmp_payment_add_one_year_expiring_service">
				<segment>
					<source>Add 1 year to your expiring service</source>
				</segment>
			</unit>
			<unit id="cmp_payment_add_one_year_expired_service">
				<segment>
					<source>Add 1 year to your expired service</source>
				</segment>
			</unit>
			<unit id="cmp_payment_next_expiration_date">
				<segment>
					<source>The next expiration date will be {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_new_expiration_date">
				<segment>
					<source>The new expiration date will be {{date}}</source>
				</segment>
			</unit>
			<unit id="cmp_payment_single_payment">
				<segment>
					<source>Single Payment</source>
				</segment>
			</unit>
			<unit id="cmp_payment_twelve_installments">
				<segment>
					<source>12 Installments</source>
				</segment>
			</unit>
			<unit id="cmp_payment_extendprogram">
				<segment>
					<source>Extend Program</source>
				</segment>
			</unit>
			<unit id="cmp_payment_rejoinprogram">
				<segment>
					<source>Rejoin Program</source>
				</segment>
			</unit>
			<unit id="cmp_payment_pay_single_payment">
				<segment>
					<source>pay in a single payment</source>
				</segment>
			</unit>
			<unit id="cmp_payment_price_per_year">
				<segment>
					<source>{{value}} /y</source>
				</segment>
			</unit>
			<unit id="cmp_payment_temporary_unavailable">
				<segment>
					<source>Payment temporary unavailable</source>
				</segment>
			</unit>
			<unit id="cmp_payment_temporary_unavailable_text">
				<segment>
					<source>Service temporarily unavailable due to an update in progress.</source>
				</segment>
			</unit>
			<unit id="cmp_payment_temporary_unavailable_text_wait">
				<segment>
					<source>Please wait a few minutes and try again.</source>
				</segment>
			</unit>
		</group>
		<group id="service-reservations">
			<unit id="service_res_type">
				<segment>
					<source>Reservation Type</source>
				</segment>
			</unit>
			<unit id="service_res_title">
				<segment>
					<source>Title</source>
				</segment>
			</unit>
			<unit id="service_res_startingtime">
				<segment>
					<source>Start Time</source>
				</segment>
			</unit>
			<unit id="service_res_duration">
				<segment>
					<source>Duration</source>
				</segment>
			</unit>
			<unit id="service_res_booklanestitle">
				<segment>
					<source>Select the lanes to book</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_single">
				<segment>
					<source>Single Lane</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_pair">
				<segment>
					<source>Lane Pair</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_range">
				<segment>
					<source>Range of Lanes</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_system">
				<segment>
					<source>Let the System Select</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_singlelanenumber">
				<segment>
					<source>Lane Number</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_lanepair">
				<segment>
					<source>Pair</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_lanefrom">
				<segment>
					<source>Lane From</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_laneto">
				<segment>
					<source>Lane To</source>
				</segment>
			</unit>
			<unit id="service_res_booklanes_numberoflanes">
				<segment>
					<source>Number of Lanes</source>
				</segment>
			</unit>
			<unit id="service_res_book_btn">
				<segment>
					<source>Book</source>
				</segment>
			</unit>
			<unit id="service_res_modal_title">
				<segment>
					<source>Service Reservation</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_1">
				<segment>
					<source>Lane {{lane}} booked on {{dateFrom}} at {{timeFrom}} for {{duration}} minutes</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_2">
				<segment>
					<source>Lanes {{laneFrom}}-{{laneTo}} booked on {{dateFrom}} at {{timeFrom}} for {{duration}} minutes</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_3">
				<segment>
					<source>Lanes from {{laneFrom}} to {{laneTo}} booked on {{dateFrom}} at {{timeFrom}} for {{duration}} minutes</source>
				</segment>
			</unit>
			<unit id="service_res_please_book_between">
				<segment>
					<source>Please, select a time between {{from}} - {{to}}</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_1_err">
				<segment>
					<source>Lane {{lane}} is not available</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_2_err">
				<segment>
					<source>Lanes {{laneFrom}}-{{laneTo}} are not available</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_3a_err">
				<segment>
					<source>Lane {{lane}} is not available</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_3b_err">
				<segment>
					<source>Lanes from {{laneFrom}} to {{laneTo}} are not available</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_4_err_single">
				<segment>
					<source>There is no lane availability at the time and date requested</source>
				</segment>
			</unit>
			<unit id="service_res_modal_sel_4_err">
				<segment>
					<source>There are not {{laneNumbers}} lanes available at the time and date requested</source>
				</segment>
			</unit>
			<unit id="service_res_modal_confirm_single">
				<segment>
					<source>Do you want to book lane {{lane}} on {{date}} at {{time}} for {{duration}} minutes?</source>
				</segment>
			</unit>
			<unit id="service_res_modal_confirm">
				<segment>
					<source>Do you want to book lanes {{laneFrom}}-{{laneTo}} on {{date}} at {{time}} for {{duration}} minutes?</source>
				</segment>
			</unit>
			<unit id="service_res_invalid_number">
				<segment>
					<source>Invalid number (use 15-minute increments only).</source>
				</segment>
			</unit>
			<unit id="service_res_modal_title_failed">
				<segment>
					<source>Service Reservation Failed</source>
				</segment>
			</unit>
			<unit id="service_res_connection_error">
				<segment>
					<source>Sorry, a connection error occurred and your request could not be processed. Please check that the Conqueror Server is running correctly.</source>
				</segment>
			</unit>
		</group>
		<group id="reservations-grid">
			<unit id="res_grid_reservation">
				<segment>
					<source>Reservation</source>
				</segment>
			</unit>
			<unit id="res_grid_overbooking">
				<segment>
					<source>Overbooking</source>
				</segment>
			</unit>
		</group>
		<group id="customers">
			<unit id="customers_list_to_filter">
				<segment>
					<source>Perform a search to find the desired Accounts</source>
				</segment>
			</unit>
			<unit id="customers_list_empty">
				<segment>
					<source>No Accounts found</source>
				</segment>
			</unit>
		</group>
		<group id="settings">
			<unit id="settings_media_library">
				<segment>
					<source>Media Library</source>
				</segment>
			</unit>
			<unit id="settings_media_library_all_centers">
				<segment>
					<source>Media Library (all centers)</source>
				</segment>
			</unit>
			<unit id="settings_web_themes">
				<segment>
					<source>Web Themes</source>
				</segment>
			</unit>
			<unit id="settings_picture_library">
				<segment>
					<source>Picture Library</source>
				</segment>
			</unit>
			<unit id="settings_picture_library_default">
				<segment>
					<source>Default Library</source>
				</segment>
			</unit>
			<unit id="settings_picture_library_user">
				<segment>
					<source>Your Library</source>
				</segment>
			</unit>
			<unit id="settings_picture_libraries_modal_title">
				<segment>
					<source>Insert media</source>
				</segment>
			</unit>
			<unit id="settings_picture_libraries_image_detail">
				<segment>
					<source>Image Detail</source>
				</segment>
			</unit>
			<unit id="settings_picture_libraries_video_detail">
				<segment>
					<source>Video Detail</source>
				</segment>
			</unit>
			<unit id="settings_picture_libraries_folder_detail">
				<segment>
					<source>Folder Detail</source>
				</segment>
			</unit>
			<unit id="settings_web_customers">
				<segment>
					<source>Customers</source>
				</segment>
			</unit>
			<unit id="settings_picture_add_image_button_scr_only">
				<segment>
					<source>Insert media</source>
				</segment>
			</unit>
			<unit id="settings_picture_modify_image_modal_title">
				<segment>
					<source>{{imageTitle}} - Item {{currentImageIndex}} of {{imagesNumber}}</source>
				</segment>
			</unit>
			<unit id="settings_picture_error-image-name-already-exists">
				<segment>
					<source>An image with this name already exists</source>
				</segment>
			</unit>
			<unit id="settings_picture_error_item_name_already_exists">
				<segment>
					<source>An item with this name already exists</source>
				</segment>
			</unit>
			<unit id="settings_picture_error-folder-name-already-exists">
				<segment>
					<source>A folder with this name already exists</source>
				</segment>
			</unit>
			<unit id="settings_picture_unable_to_upload_file_title">
				<segment>
					<source>Unable to upload the selected item</source>
				</segment>
			</unit>
			<unit id="settings_picture_upload_exceeds_limit">
				<segment>
					<source>The item you are trying to upload exceeds the {{limit}} MB size limit.</source>
				</segment>
			</unit>
			<unit id="settings_picture_upload_not_supported">
				<segment>
					<source>The file you are trying to upload is not supported. It might be because of its format.</source>
				</segment>
			</unit>
			<unit id="settings_picture_check_size_item_try_again">
				<segment>
					<source>Please check the size of your items and try again.</source>
				</segment>
			</unit>
			<unit id="settings_picture_check_item_try_again">
				<segment>
					<source>Please check the item and try again.</source>
				</segment>
			</unit>
			<unit id="settings_media_library_file_resolution_not_supported">
				<segment>
					<source>Resolution not supported</source>
				</segment>
			</unit>
			<unit id="settings_media_library_file_check_resolution">
				<segment>
					<source>The file you are trying to upload is not supported. Please check the resolution of your file and try again.</source>
				</segment>
			</unit>
			<unit id="settings_media_library_file_video_resolution_supported_full_hd">
				<segment>
					<source>Supported video resolution: 1080x1920 Full HD</source>
				</segment>
			</unit>
			<unit id="settings_media_library_file_image_resolution_supported">
				<segment>
					<source>Supported image resolution: {{imageResolution}}</source>
				</segment>
			</unit>
			<unit id="settings_reservation">
				<segment>
					<source>Reservations</source>
				</segment>
			</unit>
			<unit id="settings_website">
				<segment>
					<source>Website</source>
				</segment>
			</unit>
			<unit id="settings_kiosks">
				<segment>
					<source>Kiosks</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_stations">
				<segment>
					<source>Kiosk Stations</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_number">
				<segment>
					<source>Kiosk {{number}}</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_number_with_pinpad">
				<segment>
					<source>Kiosk {{number}} - PIN Pad</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_services_activated">
				<segment>
					<source>Kiosk services activated</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_services">
				<segment>
					<source>Kiosk services</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_use_custom_texts">
				<segment>
					<source>Use custom texts</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_players_title">
				<segment>
					<source>Get ready to play</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_players_content">
				<segment>
					<source>How many guests are playing today?</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_players_info">
				<segment>
					<source>Please note that large groups reservations need to be made at the front desk</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_offers_title">
				<segment>
					<source>Choose your experience</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_shoes_title">
				<segment>
					<source>Add shoes and socks</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_shoes_content">
				<segment>
					<source>All players are required to wear bowling shoes for both safety and performance</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_games_title">
				<segment>
					<source>Enhance your visit with extras!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_food_title">
				<segment>
					<source>Add food to your order</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_order_title">
				<segment>
					<source>REVIEW YOUR ORDER</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_order_content">
				<segment>
					<source>Please review all items in your cart</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_almost_there">
				<segment>
					<source>Almost there, just a few more steps</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_thankyou_title">
				<segment>
					<source>You are all set!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_play_now_thankyou_reservation_content">
				<segment>
					<source>Thank you for your reservation. You will receive an email with your receipt and confirmation code soon.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_logged_content">
				<segment>
					<source>Please check your bowler information, provide the missing information for a better experience.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_guest_title">
				<segment>
					<source>Enter bowler information</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_guest_content">
				<segment>
					<source>Please check your reservation details, provide the missing information for a better experience.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_find_title">
				<segment>
					<source>Find your reservation</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_find_content">
				<segment>
					<source>Please choose how you would like to proceed</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_details_title">
				<segment>
					<source>Review your order</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_details_content">
				<segment>
					<source>Please check your reservation details</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_thankyou_title">
				<segment>
					<source>Thank you!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_checkin_thankyou_page_content">
				<segment>
					<source>Your check-in is now complete.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_delivery_pickup_title">
				<segment>
					<source>Choose how to get your food</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_delivery_pickup_content">
				<segment>
					<source>Please select your preferred choice</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_label_info">
				<segment>
					<source>Table service - Description</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_pickup_label_info">
				<segment>
					<source>Pickup - Description</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_info">
				<segment>
					<source>Your order will be delivered to you, at the table you'll select amoung those available.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_pickup_info">
				<segment>
					<source>You'll have to collect your order at the pickup point. We'll call your order number when ready.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_areas_tables_title">
				<segment>
					<source>Select your table</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_delivery_categories_items_title">
				<segment>
					<source>Choose your meal from the menu</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_review_order_title">
				<segment>
					<source>Review your order</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_review_order_content">
				<segment>
					<source>Please review all items in your cart</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_contact_info_title">
				<segment>
					<source>Receive updates about your order</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_contact_info_content">
				<segment>
					<source>Enter your phone number below so that we can text you updates about your order.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_tips_title">
				<segment>
					<source>Add a tip</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_tips_content">
				<segment>
					<source>If you would like to leave a tip please use the buttons below</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_thankyou_title">
				<segment>
					<source>You're on the waiting list!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_thankyou_content">
				<segment>
					<source>We will contact you to let you know when it's your turn</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_players_title">
				<segment>
					<source>Join the Bowling waiting list</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_players_content">
				<segment>
					<source>How many guests?</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_players_info">
				<segment>
					<source>Please note that large groups reservations need to be made at the front desk</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_playing_options_title">
				<segment>
					<source>Choose your experience</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_playing_options_content">
				<segment>
					<source>Pick the bowling playing option you want to play</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_contact_info_title">
				<segment>
					<source>Enter your information</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_contact_info_content">
				<segment>
					<source>We will contact you to let you know when it's your turn</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_waiting_list_instructions_content">
				<segment>
					<source>To know your position in the waiting list or if you want to leave it, please go to the front desk</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_review_order_pickup_info">
				<segment>
					<source>Please note that you'll need to collect your order at the designated pickup point</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_pickup_order_received_thank_you_title">
				<segment>
					<source>Order received!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_pickup_go_to_the_food_thank_you_content">
				<segment>
					<source>Please go to the food pickup area and wait for your order number to be called.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_delivery_order_received_thank_you_title">
				<segment>
					<source>Order received!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_thank_you_content">
				<segment>
					<source>When ready, our staff will bring your order to your table.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_thank_you_delivered_to_table">
				<segment>
					<source>When ready, your order will be delivered to your table.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_pickup_order_update_thank_you_title">
				<segment>
					<source>Order received!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_pickup_order_update_thank_you_content">
				<segment>
					<source>We'll text you to let you know when your order is ready to pickup.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_order_update_thank_you_title">
				<segment>
					<source>Order received!</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_menu_table_service_order_update_thank_you_content">
				<segment>
					<source>When ready, your order will be taken to your table. We'll text you to let you know when your order is on the way.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_thank_you">
				<segment>
					<source>Thank You</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_early_check_in">
				<segment>
					<source>Early Check In</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_early_check_in_content">
				<segment>
					<source>Please visit the front desk {{minutesBefore}} minutes before your reservation to receive your lane assignment.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_go_to_front_desk">
				<segment>
					<source>On time - Go straight to the front desk</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_go_to_front_desk_content">
				<segment>
					<source>Please visit the front desk to receive more information about your lane.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_go_to_lane">
				<segment>
					<source>On time - Go straight to the lane</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_go_to_lane_content">
				<segment>
					<source>Your lane is ready! Please go to your lane to get started.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_main_page_welcome_message">
				<segment>
					<source>Welcome message</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_main_page_tile_playnow">
				<segment>
					<source>Tile - Play Now</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_main_page_tile_checkin">
				<segment>
					<source>Tile - Check In</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_main_page_tile_menu">
				<segment>
					<source>Tile - Menu</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_main_page_tile_waiting_list">
				<segment>
					<source>Tile - Bowling Waiting List</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_texts_main_page_tile_picture">
				<segment>
					<source>Tile picture</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_section_title_texts_and_images">
				<segment>
					<source>Texts and Images</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_deactivate_services_message">
				<segment>
					<source>Do you want to save these changes? Confirm to disable all Kiosk's services</source>
				</segment>
			</unit>
			<unit id="settings_id_already_used_change_id">
				<segment>
					<source>ID value is already in use. Please, change ID value and try again.</source>
				</segment>
			</unit>
			<unit id="settings_id_already_used_change_descr">
				<segment>
					<source>Description value is already in use. Please, change description text and try again.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_title_services_cannot_be_enabled">
				<segment>
					<source>Kiosk services cannot be enabled</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_title_services_will_be_disabled">
				<segment>
					<source>Kiosk services will be disabled</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_device_id_unassigned_text">
				<segment>
					<source>Kiosk services cannot be enabled on the selected station because no Device ID has been assigned.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_device_id_unassigned_fix">
				<segment>
					<source>Assign a Device ID to the selected station to continue.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_max_licenses_reached_text">
				<segment>
					<source>Kiosk services cannot be enabled on the selected station.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_max_licenses_reached_fix">
				<segment>
					<source>All available licenses have been activated. To enable Kiosk services on the selected Kiosk station, please disable a different station, or contact our dealer(s) to get more licenses.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_services_will_be_disabled_text">
				<segment>
					<source>Kiosk services will be disabled on the selected station.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_services_will_be_disabled_behavior">
				<segment>
					<source>The selected Kiosk station will only be able to display promotional content. All Kiosk services related to reservations and sales will not be available for the selected Kiosk station.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_error_max_licenses_activated">
				<segment>
					<source>All available licenses have been activated. To enable Kiosk services on the selected Kiosk station, please disable a different station, or contact your dealer(s) to get more licenses.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_cpu">
				<segment>
					<source>Unlink CPU</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_instructions_title">
				<segment>
					<source>Unlink Instructions</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_instructions_part_1">
				<segment>
					<source>This process allows you to unlink a CPU (Device ID) assigned to your bowling center from the center itself.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_instructions_part_2">
				<segment>
					<source>Use this function if you need to replace a damaged CPU. You will be able to link a new CPU after the one that needs to be replaced has been unlinked.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_instructions_part_3">
				<segment>
					<source>Cannot find the CPU that needs to be replaced in the list? Uncouple the CPU from the Kiosk Station to which it is connected. Go to the Kiosk Station section, find and select the desired Kiosk Station, deactivate its services and then set “Unassigned” in the Device ID area.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_no_device_available">
				<segment>
					<source>No Device ID available</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_unlink_successfully_completed">
				<segment>
					<source>Unlink process successfully completed</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlists">
				<segment>
					<source>Playlists</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_manage_playlists">
				<segment>
					<source>Manage Playlists</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_assign_playlists">
				<segment>
					<source>Assign Playlists</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_add_playlist">
				<segment>
					<source>Add Playlist</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_add_media_items">
				<segment>
					<source>Add media items</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_media_items_count">
				<segment>
					<source>Media ({{itemsSelected}} of {{totalItemsPlaylist}} Items - {{sizeItemsSelected}} of {{totalSizePlaylist}} MB)</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_delete_title">
				<segment>
					<source>Deleting Playlist</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_delete_first_line">
				<segment>
					<source>You are deleting the item '{{playlistName}}'.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_delete_second_line">
				<segment>
					<source>If it is assigned to any kiosk, the '{{defaultPlaylistName}}' default playlist will be automatically assigned to those kiosks.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_media_count">
				<segment>
					<source>{{media}} media</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_empty">
				<segment>
					<source>This playlist is empty, add media items</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_displayname_already_exists">
				<segment>
					<source>A playlist with this name already exists. Please rename the playlist and try again.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_upload_error_limit_title">
				<segment>
					<source>Unable to add the selected item(s)</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_upload_error_limit">
				<segment>
					<source>The item you are trying to add exceed the maximum number or size allowed. Playlists can have up to {{limitItems}} items, and must not exceed the maximum size of {{limitItemsTotalSize}} MB.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_upload_video_error_not_supported">
				<segment>
					<source>The file you are trying to upload is not supported. It might be because of its format or size.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_playlist_upload_video_error_check_size">
				<segment>
					<source>Please check your file and remember that it must not exceed the {{limit}} MB size limit.</source>
				</segment>
			</unit>
			<unit id="settings_web_graphic_themes">
				<segment>
					<source>Graphic Themes</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_texts_images">
				<segment>
					<source>Texts and Images</source>
				</segment>
			</unit>
			<unit id="settings_web_maintenance_mode">
				<segment>
					<source>Maintenance Mode</source>
				</segment>
			</unit>
			<unit id="settings_web_maintenance_mode_enable_modal_title">
				<segment>
					<source>Enable Maintenance Mode</source>
				</segment>
			</unit>
			<unit id="settings_web_maintenance_mode_disable_modal_title">
				<segment>
					<source>Disable Maintenance Mode</source>
				</segment>
			</unit>
			<unit id="settings_web_maintenance_mode_enable_modal_body">
				<segment>
					<source>You are enabling the maintenance mode. Your online booking service will be unavailable for your customers until the maintenance mode will be disabled</source>
				</segment>
			</unit>
			<unit id="settings_web_maintenance_mode_disable_modal_body">
				<segment>
					<source>You are disabling the maintenance mode. This operation will make your online booking service available for your customers to make reservations.</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_common_parts">
				<segment>
					<source>Common Parts</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_home_page">
				<segment>
					<source>Home Page</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_web_offers">
				<segment>
					<source>Web Offers</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_shoes_socks">
				<segment>
					<source>Shoes and Socks</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_games_extras">
				<segment>
					<source>Games and Extras</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_food_beverage">
				<segment>
					<source>Food and Beverage</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_reservation_confirmed">
				<segment>
					<source>Reservation Confirmed</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_order_details_pdf">
				<segment>
					<source>Order Details PDF</source>
				</segment>
			</unit>
			<unit id="settings_web_lookfeel_text_images_confirmation_email">
				<segment>
					<source>Confirmation Email</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions">
				<segment>
					<source>Instructions</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions_go_frontdesk_now">
				<segment>
					<source>Go straight to the front desk</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions_go_frontdesk_later">
				<segment>
					<source>Go to the front desk later</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions_go_frontdesk_content">
				<segment>
					<source>Please visit the front desk to receive more information about your lane.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions_go_frontdesk_later_content">
				<segment>
					<source>Please visit the front desk {{frontDeskMinutes}} minutes before your reservation to receive your lane assignment.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions_go_straight_to_lane">
				<segment>
					<source>Go straight to the lane</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_play_now_instructions_go_straight_to_lane_content">
				<segment>
					<source>Your lane is ready! Please go to your lane to get started.</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_common_parts_messages_disabled">
				<segment>
					<source>Messages - Kiosk Disabled</source>
				</segment>
			</unit>
			<unit id="settings_kiosk_common_parts_header_logo_background">
				<segment>
					<source>Header - Logo - Background</source>
				</segment>
			</unit>
			<unit id="settings_website_header_menu">
				<segment>
					<source>Header Menu</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_themes_define_themes">
				<segment>
					<source>Define Themes</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_themes_define_themes_create_theme_modal_message">
				<segment>
					<source>Select the theme you want to clone from the dropdown menu and choose a new name</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_themes_apply_themes_to_centers">
				<segment>
					<source>Apply Themes to Centers</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_themes_custom_themes">
				<segment>
					<source>Custom Themes</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_themes_default_themes">
				<segment>
					<source>Default Themes</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_themes_apply_themes">
				<segment>
					<source>Apply Themes to Centers</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_no_custom_themes">
				<segment>
					<source>No custom themes configured</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_error_save_title">
				<segment>
					<source>Unable to create Theme</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_error_save_message">
				<segment>
					<source>A Theme with this name already exists. Please rename the Theme and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_error_change_name_title">
				<segment>
					<source>Unable to rename Theme</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_error_change_name_message">
				<segment>
					<source>A Theme with this name already exists. Please rename the Theme and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_active_and_one_more">
				<segment>
					<source>{{centerName}} and one more</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_active_and_n_more_centers">
				<segment>
					<source>{{centerName}} and {{numberOfCenters}} more centers</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_choose_center">
				<segment>
					<source>Choose center:</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_modify_active_theme">
				<segment>
					<source>Modify active theme</source>
				</segment>
			</unit>
			<unit id="settings_web_graphical_you_are_about_to_modify_an_active_theme">
				<segment>
					<source>You are about to modify an active theme. The changes will take effect when you click Confirm.</source>
				</segment>
			</unit>
			<unit id="settings_add_theme">
				<segment>
					<source>Add Theme</source>
				</segment>
			</unit>
			<unit id="settings_add_new_theme">
				<segment>
					<source>Add new theme</source>
				</segment>
			</unit>
			<unit id="settings_theme_to_clone">
				<segment>
					<source>Theme to clone</source>
				</segment>
			</unit>
			<unit id="settings_web_rulespreferences">
				<segment>
					<source>Rules and Preferences</source>
				</segment>
			</unit>
			<unit id="settings_web_bookabledays_custom_period">
				<segment>
					<source>Custom Period</source>
				</segment>
			</unit>
			<unit id="settings_web_bookabledayshours">
				<segment>
					<source>Bookable Days/Hours</source>
				</segment>
			</unit>
			<unit id="settings_bookable_default_calendar">
				<segment>
					<source>Default Calendar</source>
				</segment>
			</unit>
			<unit id="settings_bookable_default_calendar_day_by_day">
				<segment>
					<source>Day by day calendar</source>
				</segment>
			</unit>
			<unit id="settings_bookable_default_calendar_opening_times">
				<segment>
					<source>Opening times</source>
				</segment>
			</unit>
			<unit id="settings_bookable_conflict_periods_modal_title">
				<segment>
					<source>Custom period already exists</source>
				</segment>
			</unit>
			<unit id="settings_bookable_conflict_periods_modal_message">
				<segment>
					<source>This custom period conflicts with {{calendarName}} - {{startDate}} – {{endDate}}. Please enter another date range.</source>
				</segment>
			</unit>
			<unit id="settings_bookable_invalid_bookable_day_conflict_modal_message">
				<segment>
					<source>The opening times of {{day}} must be between {{minimumOpeningTime}} - {{maximumClosingTime}}</source>
				</segment>
			</unit>
			<unit id="settings_bookable_between_two_years_warning_modal_title">
				<segment>
					<source>Unable to create Custom Period</source>
				</segment>
			</unit>
			<unit id="settings_bookable_between_two_years_warning_modal">
				<segment>
					<source>You are about to create a custom yearly recurring period whose date range falls between two years. Do you want to proceed?</source>
				</segment>
			</unit>
			<unit id="settings_bookable_always_available_warning_message">
				<segment>
					<source>You cannot create Custom Periods when the Web Offer is set to "Always Available". To continue, disable the "Always Available" option in the Default Availability tab.</source>
				</segment>
			</unit>
			<unit id="settings_bookable_default_availability">
				<segment>
					<source>Default Availability</source>
				</segment>
			</unit>
			<unit id="settings_bookableday_not_bookable">
				<segment>
					<source>Not Bookable</source>
				</segment>
			</unit>
			<unit id="settings_web_bookable_error_save_message">
				<segment>
					<source>A Calendar with this name already exists. Please rename the Calendar and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_bookable_error_save_title">
				<segment>
					<source>Unable to create Calendar</source>
				</segment>
			</unit>
			<unit id="settings_web_bookable_web_offer_always_available">
				<segment>
					<source>Web offer always available</source>
				</segment>
			</unit>
			<unit id="settings_web_bookable_always_available">
				<segment>
					<source>Always available</source>
				</segment>
			</unit>
			<unit id="settings_bowler_types">
				<segment>
					<source>Bowler Types</source>
				</segment>
			</unit>
			<unit id="settings_bowler_types_number">
				<segment>
					<source>Number of Bowler Types</source>
				</segment>
			</unit>
			<unit id="settings_bowler_types_error_duplicate_text">
				<segment>
					<source>The Display Name must be different for each Type.</source>
				</segment>
			</unit>
			<unit id="settings_website_management_email_addresses">
				<segment>
					<source>Email Addresses</source>
				</segment>
			</unit>
			<unit id="settings_website_management_email_addresses_bcc">
				<segment>
					<source>Bcc addresses for confirmation emails</source>
				</segment>
			</unit>
			<unit id="settings_website_management_email_addresses_contacts">
				<segment>
					<source>Contacts for Technical Issues</source>
				</segment>
			</unit>
			<unit id="settings_website_management_maintenance_mode">
				<segment>
					<source>Maintenance Mode</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_add_new_laneGroups">
				<segment>
					<source>Add new Lane Groups</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_no_records">
				<segment>
					<source>No Lane Groups defined</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_error_save_title">
				<segment>
					<source>Unable to create Lane Group</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_error_link_title">
				<segment>
					<source>Unable to link Lane Group</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_error_link_weboffer">
				<segment>
					<source>No lane groups have been created yet. To link a lane group to a Web Offer, you must first create one in the Settings section > Lane Groups</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_error_save_message">
				<segment>
					<source>A Lane Group with this name already exists. Please rename the Lane Group and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_compatible_lanes">
				<segment>
					<source>The Web offer is available on all the compatible lanes ({{laneGroups}})</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_description_modify">
				<segment>
					<source>To modify the lane set, add lane groups or review the Experience setup in Conqueror X</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_delete">
				<segment>
					<source>Delete Lane Groups</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroup_description">
				<segment>
					<source>1 lane group: {{recap}}</source>
				</segment>
			</unit>
			<unit id="settings_web_lanegroups_description">
				<segment>
					<source>{{laneGroups}} lane groups: {{recap}}</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_status">
				<segment>
					<source>Status</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_shoes">
				<segment>
					<source>Shoes</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_socks">
				<segment>
					<source>Socks</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_new_socks">
				<segment>
					<source>New Socks</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_add_sock">
				<segment>
					<source>Add Sock</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_sock_error_save_title">
				<segment>
					<source>Unable to create Item</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_sock_no_pricekey_message">
				<segment>
					<source>Every possible Socks pricing has been configured. You can only delete a Socks pricing to create a new one, or edit an exiting one.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_sock_error_save_message">
				<segment>
					<source>A Sock with this name already exists. Please rename the Sock and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_new_sock">
				<segment>
					<source>Create new Sock</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_status_activation_alert">
				<segment>
					<source>This feature cannot be activated because one or more parameters are missing:</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_status_activation">
				<segment>
					<source>Are you sure you want to set the status to 'Active'?</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_status_activation_subtitle">
				<segment>
					<source>Active feature Shoes and Socks is visible on the website to customers and available for purchase.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_status_deactivation">
				<segment>
					<source>Are you sure you want to set the status to 'Inactive'?</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_status_deactivation_subtitle">
				<segment>
					<source>Inactive feature Shoes and Socks is not visible on the website to customers and not available for purchase.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_shoes_missing_display_name">
				<segment>
					<source>Display Name missing in {{shoe}}</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_shoes_missing_price_key">
				<segment>
					<source>PriceKey missing in {{shoe}}</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_socks_missing_display_name">
				<segment>
					<source>There is at least one sock without Display Name</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_socks_missing_price_key">
				<segment>
					<source>PriceKey missing in {{sock}}</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_category_empty">
				<segment>
					<source>This category cannot be activated because is empty.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_maximum_category_reached">
				<segment>
					<source>You have reached the maximum number of active categories. Switch off at least one category and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoessocks_unable_to_activate_category">
				<segment>
					<source>Unable To Activate Category</source>
				</segment>
			</unit>
			<unit id="settings_food_ordering">
				<segment>
					<source>Food Ordering</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_error_save_title">
				<segment>
					<source>Unable to save Category</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_error_save_message">
				<segment>
					<source>A Category with this name already exists. Please rename the Category and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_delete_message">
				<segment>
					<source>Are you sure you want to delete the category '{{name}}'?</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_kiosk_pickup">
				<segment>
					<source>Kiosk - Pickup</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_kiosk_table_service">
				<segment>
					<source>Kiosk - Table service</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_item_error_save_message">
				<segment>
					<source>An Item with this name already exists. Please rename the Item and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_add">
				<segment>
					<source>Add Food and Beverage</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_availability">
				<segment>
					<source>Availability - Services</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_add_item">
				<segment>
					<source>Add new item in {{category}}</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_filter_placeholder">
				<segment>
					<source>Filter by keyword</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_delete_modal">
				<segment>
					<source>Are you sure you want to delete the category: '{{category}}'?</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_add_web_offers">
				<segment>
					<source>Add Web Offers</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_category_add_web_offer_title_page">
				<segment>
					<source>{{categoryName}} - Add Web Offers</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_item_activation_error_modal_title">
				<segment>
					<source>The activation can't be completed</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_item_activation_error_modal_message">
				<segment>
					<source>The following items can't be activated because they miss one or more mandatory fields:</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_table_service">
				<segment>
					<source>Table service</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_table_service_areas">
				<segment>
					<source>Areas</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_table_service_tables">
				<segment>
					<source>Tables</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_delete_tables">
				<segment>
					<source>Delete tables</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_delete_tables_text">
				<segment>
					<source>Are you sure you want to delete the selected tables?</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_area_activation_alert_no_tables">
				<segment>
					<source>This area cannot be activated because it has no tables.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_area_error_save_message">
				<segment>
					<source>An Area with this name already exists. Please rename the Area and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_area_delete_message">
				<segment>
					<source>Are you sure you want to delete the area '{{name}}'?</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_there_are_no_areas_yet">
				<segment>
					<source>There are no areas yet</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_one_area_must_be_created">
				<segment>
					<source>At least one area must be created before adding any item.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_area_cannot_be_activated">
				<segment>
					<source>Sorry, this area cannot be activated</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_area_cannot_be_activated_text">
				<segment>
					<source>You can only have up to 16 active areas at the same time. Please deactivate one of your active areas to activate this one.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_new_set_tables">
				<segment>
					<source>New set of tables</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_change_area_selected_tables">
				<segment>
					<source>Change area to selected tables</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_table_error_save_message">
				<segment>
					<source>The display name you entered already exists. Please use a different one.</source>
				</segment>
			</unit>
			<unit id="settings_web_foodbeverage_last_table_delete_modal">
				<segment>
					<source>Do you want to save these changes? This operation makes the linked Area inactive.</source>
				</segment>
			</unit>
			<unit id="settings_extras">
				<segment>
					<source>Extras</source>
				</segment>
			</unit>
			<unit id="settings_add_new_extra">
				<segment>
					<source>Add new Extra</source>
				</segment>
			</unit>
			<unit id="settings_extra_cannot_be_saved_img_description">
				<segment>
					<source>{{extra}} cannot be saved. Active items can be saved only if both the description and the image are set.</source>
				</segment>
			</unit>
			<unit id="settings_extra_invalid_display_name">
				<segment>
					<source>Invalid Display Name</source>
				</segment>
			</unit>
			<unit id="settings_extra_display_name_already_exists">
				<segment>
					<source>This Display Name already exists. Please, choose another name.</source>
				</segment>
			</unit>
			<unit id="settings_extras_activation_error">
				<segment>
					<source>Extras can't be activated</source>
				</segment>
			</unit>
			<unit id="settings_extras_activation_error_details">
				<segment>
					<source>The following extras are missing one or more mandatory fields and can't be activated:</source>
				</segment>
			</unit>
			<unit id="settings_web_search_price_key">
				<segment>
					<source>Search price key</source>
				</segment>
			</unit>
			<unit id="settings_add_web_offer">
				<segment>
					<source>Add Web Offer</source>
				</segment>
			</unit>
			<unit id="settings_add_category">
				<segment>
					<source>Add Category</source>
				</segment>
			</unit>
			<unit id="settings_add_extra">
				<segment>
					<source>Add Extra</source>
				</segment>
			</unit>
			<unit id="settings_add_lane_group">
				<segment>
					<source>Add Lane Group</source>
				</segment>
			</unit>
			<unit id="settings_add_custom_bookable_period">
				<segment>
					<source>Add Custom Bookable Period</source>
				</segment>
			</unit>
			<unit id="settings_custom_periods">
				<segment>
					<source>Custom Periods</source>
				</segment>
			</unit>
			<unit id="settings_custom_period">
				<segment>
					<source>Custom Period</source>
				</segment>
			</unit>
			<unit id="settings_create_new_custom_periods">
				<segment>
					<source>Create new Custom Period</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_services_web_book_for_later">
				<segment>
					<source>Web - Book for later</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_services_kiosk_book_for_later">
				<segment>
					<source>Kiosk - Book for later</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_services_kiosk_play_now">
				<segment>
					<source>Kiosk - Play Now</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_services_external_web_book_for_later">
				<segment>
					<source>ExternalAPI - Book For Later</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_services_external_kiosk_play_now">
				<segment>
					<source>ExternalAPI - Play Now</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_coresettings">
				<segment>
					<source>Core Settings</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_appearance">
				<segment>
					<source>Appearance</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_appearance_summary">
				<segment>
					<source>Title, description and pictures</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_appearance_update_success">
				<segment>
					<source>Appearance successfully updated</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_availability_always_available_warning_message">
				<segment>
					<source>The option "Web Offer always available" cannot be enabled when there are custom periods already defined</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_availability_always_available_warning_title">
				<segment>
					<source>Cannot enable "Web Offer always available"</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options_display_shoes_rental">
				<segment>
					<source>Display Shoe Rental Page</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options_bowler_shoe_size">
				<segment>
					<source>Bowler details – ask for shoe size</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options_bowler_bumper">
				<segment>
					<source>Bowler details – ask for bumper preference</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options_extras">
				<segment>
					<source>Display Games and Extras Page</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options_food">
				<segment>
					<source>Display Food and Beverage Page</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options_rest_error_message">
				<segment>
					<source>System earliest availability is set to {{qty}}, please use a greater or equal value</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation_alert">
				<segment>
					<source>This offer cannot be activated because one or more parameters are missing:</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation_missing_pricekey_alert">
				<segment>
					<source>This offer cannot be activated because one or more items in the Pricing section no longer meet the Core Settings.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation_missing_pricekey_404_alert">
				<segment>
					<source>This offer cannot be activated because one or more items in the Pricing section are missing.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation_external_pricekeys_must_be_zero">
				<segment>
					<source>For Web Offer eXternal, it is not possible to use Experience with Price-Key having a price other than 0.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_options_error_modal_message">
				<segment>
					<source>There are some problems with Options, please change the option and retry activation</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation">
				<segment>
					<source>Are you sure you want to set the status to 'Active'?</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation_visible_to_customers">
				<segment>
					<source>Active web offers are visible to customers and available for purchase.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_deactivation">
				<segment>
					<source>Are you sure you want to set the status to 'Inactive'?</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_deactivation_visible_to_customers">
				<segment>
					<source>Inactive web offers are neither visible to customers, nor available for purchase.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_activation_activation_warning">
				<segment>
					<source>use this option to change the status of your web offer.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_status_pricekey_missing">
				<segment>
					<source>{{qty}} is not defined</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_pricing_summary">
				<segment>
					<source>Pricing per each playing option</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_pricing_multipliername">
				<segment>
					<source>{{multiplier}} x {{name}}</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees">
				<segment>
					<source>Deposit and Fees</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_advance_payments_fees">
				<segment>
					<source>Advance Payment and Fees</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_advance_payment">
				<segment>
					<source>Advance Payment</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_fixed">
				<segment>
					<source>Fixed Amount</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_percentage">
				<segment>
					<source>Percentage</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_deposit">
				<segment>
					<source>Deposit</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_nodeposit">
				<segment>
					<source>No Deposit</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_depositamount">
				<segment>
					<source>Deposit {{amount}}</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_depositpercentage">
				<segment>
					<source>Deposit {{perc}}%</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_bookingfee">
				<segment>
					<source>Booking Fee</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_nobookingfee">
				<segment>
					<source>No Booking Fee</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_bookingfeeamount">
				<segment>
					<source>Booking Fee {{amount}}</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_bookingfeepercentage">
				<segment>
					<source>Booking Fee {{perc}}%</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_minfee">
				<segment>
					<source>Min Fee</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_depositfees_maxfee">
				<segment>
					<source>Max Fee</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_lanegroups">
				<segment>
					<source>Lane Groups</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_options">
				<segment>
					<source>Options</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_error_adding_name">
				<segment>
					<source>A Web Offer with this name already exists. Please rename the Web Offer and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_error_title_adding_name">
				<segment>
					<source>Unable to save Web Offer</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_error_title_delete">
				<segment>
					<source>Unable to delete Web Offer</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_delete">
				<segment>
					<source>Delete Web Offer</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_error_text_delete">
				<segment>
					<source>This Web Offer cannot be deleted because it is active.</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_force_delete_message">
				<segment>
					<source>This item: '{{name}}' is active. Do you want to delete it?</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_duplicate_message">
				<segment>
					<source>Are you sure you want to duplicate the item '{{name}}'?</source>
				</segment>
			</unit>
			<unit id="settings_web_offer_save_alert_message">
				<segment>
					<source>Do you want to save these changes? This operation makes the Web Offer inactive.</source>
				</segment>
			</unit>
			<unit id="settings_label_playing_time_form_hint">
				<segment>
					<source>(1 to 3 options)</source>
				</segment>
			</unit>
			<unit id="settings_web_rules_and_preferences">
				<segment>
					<source>Rules and Preferences</source>
				</segment>
			</unit>
			<unit id="settings_add_remove_web_offers">
				<segment>
					<source>Add / Remove Web Offers</source>
				</segment>
			</unit>
			<unit id="settings_lanes_per_reservation">
				<segment>
					<source>Lanes per reservation</source>
				</segment>
			</unit>
			<unit id="settings_buffer_time">
				<segment>
					<source>Buffer time</source>
				</segment>
			</unit>
			<unit id="settings_lanes_availability_check_interval">
				<segment>
					<source>Lanes availability check interval</source>
				</segment>
			</unit>
			<unit id="settings_earliest_selectable_time_slot">
				<segment>
					<source>Earliest selectable time slot</source>
				</segment>
			</unit>
			<unit id="settings_enable_go_straight_to_the_lane">
				<segment>
					<source>Enable go straight to the lane</source>
				</segment>
			</unit>
			<unit id="settings_bookable_period_calendar_days">
				<segment>
					<source>Bookable period (calendar days)</source>
				</segment>
			</unit>
			<unit id="settings_enable_guest_reservation">
				<segment>
					<source>Enable guest reservation</source>
				</segment>
			</unit>
			<unit id="settings_next_bookable_day">
				<segment>
					<source>Next Bookable Day</source>
				</segment>
			</unit>
			<unit id="settings_item_cannot_be_activated_img_category">
				<segment>
					<source>{{item}} can't be activated if the image or the category are not set.</source>
				</segment>
			</unit>
			<unit id="settings_item_cannot_be_activated_cannot_sold_individually">
				<segment>
					<source>{{item}} can't be activated since it can't be sold individually but only as part of a package.</source>
				</segment>
			</unit>
			<unit id="settings_item_cannot_be_activated_includes_menu">
				<segment>
					<source>{{item}} can't be activated because it includes one or more Menu items.</source>
				</segment>
			</unit>
			<unit id="settings_payments">
				<segment>
					<source>Payments</source>
				</segment>
			</unit>
			<unit id="settings_payments_web_payments">
				<segment>
					<source>Web Payments</source>
				</segment>
			</unit>
			<unit id="settings_payments_web_payments_async_flow_active">
				<segment>
					<source>Asynchronous Flow Active</source>
				</segment>
			</unit>
			<unit id="settings_payments_web_payments_async_flow_inactive">
				<segment>
					<source>Asynchronous Flow Inactive</source>
				</segment>
			</unit>
			<unit id="settings_payments_kiosk_payments">
				<segment>
					<source>Kiosk Payments</source>
				</segment>
			</unit>
			<unit id="settings_payments_sales_receipt_kiosk">
				<segment>
					<source>Sales receipt - Kiosk</source>
				</segment>
			</unit>
			<unit id="settings_payments_use_the_sales_receipt">
				<segment>
					<source>Use the Sales receipt - Kiosk header</source>
				</segment>
			</unit>
			<unit id="settings_payments_credit_card_receipt_kiosk">
				<segment>
					<source>Credit card receipt - Kiosk</source>
				</segment>
			</unit>
			<unit id="settings_tips_payments">
				<segment>
					<source>Tips</source>
				</segment>
			</unit>
			<unit id="settings_tips_payments_kiosk_menu">
				<segment>
					<source>Kiosk Menu - Display Tip Page</source>
				</segment>
			</unit>
			<unit id="settings_tips_payments_kiosk_play_now">
				<segment>
					<source>Kiosk Play Now - Display Tip Page</source>
				</segment>
			</unit>
			<unit id="settings_no_payments_web_payments">
				<segment>
					<source>No web payments</source>
				</segment>
			</unit>
			<unit id="settings_payments_test_price_key">
				<segment>
					<source>Test Price Key</source>
				</segment>
			</unit>
			<unit id="settings_payments_payment_provider">
				<segment>
					<source>Payment provider</source>
				</segment>
			</unit>
			<unit id="settings_payments_payment_providers">
				<segment>
					<source>Payment providers</source>
				</segment>
			</unit>
			<unit id="settings_payments_fake_provider">
				<segment>
					<source>Fake provider</source>
				</segment>
			</unit>
			<unit id="settings_payments_connect_to_square_title">
				<segment>
					<source>Connect to Square</source>
				</segment>
			</unit>
			<unit id="settings_payments_connect_to_square_message">
				<segment>
					<source>You are setting Square as you payment provider for your web reservation service. To use Square as your payment provider, you must connect your Square Owner Account to your QubicaAMF company (QPortal > Management &amp; Configuration > Square > Connect to Square).</source>
				</segment>
			</unit>
			<unit id="settings_payments_kiosk_provider_enabled">
				<segment>
					<source>1 provider enabled</source>
				</segment>
			</unit>
			<unit id="settings_payments_kiosk_providers_enabled">
				<segment>
					<source>{{qty}} providers enabled</source>
				</segment>
			</unit>
			<unit id="settings_payments_kiosk_no_providers_enabled">
				<segment>
					<source>No providers enabled</source>
				</segment>
			</unit>
			<unit id="settings_payments_first_data">
				<segment>
					<source>First Data</source>
				</segment>
			</unit>
			<unit id="settings_payments_provider_settings">
				<segment>
					<source>{{provider}} settings</source>
				</segment>
			</unit>
			<unit id="settings_payments_select_test_price_key">
				<segment>
					<source>Select test Price Key</source>
				</segment>
			</unit>
			<unit id="settings_administative_settings">
				<segment>
					<source>Administrative Settings</source>
				</segment>
			</unit>
			<unit id="settings_rules_and_preferences_conflict_advice_user">
				<segment>
					<source>If you want your changes to be applied, you can delete or deactivate the web offer or change these web offer options.</source>
				</segment>
			</unit>
			<unit id="settings_rules_and_preferences_conflict_web_offer_message">
				<segment>
					<source>Your changes conflict with the “{{qty}}” Web Offer.</source>
				</segment>
			</unit>
			<unit id="settings_rules_and_preferences_option_value_conflict">
				<segment>
					<source>The “{{property}}” option is set to:</source>
				</segment>
			</unit>
			<unit id="settings_rules_and_preferences_error_cq">
				<segment>
					<source>There is some information from Conqueror missing. Please make sure you have the latest available version of Conqueror installed and that Conqueror is connected to the Cloud.</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms">
				<segment>
					<source>Privacy and Terms</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_terms_of_use">
				<segment>
					<source>Terms of Use</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_privacy_policy">
				<segment>
					<source>Privacy Policy</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_privacy_policy_all_centers">
				<segment>
					<source>Privacy Policy (all centers)</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_terms_conditions_book_for_later">
				<segment>
					<source>Terms and Conditions - Web Book for Later</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_terms_conditions_play_now">
				<segment>
					<source>Terms and Conditions - Kiosk Play Now</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_terms_conditions">
				<segment>
					<source>Terms and Conditions</source>
				</segment>
			</unit>
			<unit id="settings_privacy_terms_link_label">
				<segment>
					<source>Label of the link</source>
				</segment>
			</unit>
			<unit id="label_enable">
				<segment>
					<source>Enable</source>
				</segment>
			</unit>
			<unit id="label_disable">
				<segment>
					<source>Disable</source>
				</segment>
			</unit>
			<unit id="settings_website_menu_no_items">
				<segment>
					<source>No items configured</source>
				</segment>
			</unit>
			<unit id="settings_website_menu_error_duplicate_text">
				<segment>
					<source>The Label must be different for each item.</source>
				</segment>
			</unit>
			<unit id="settings_choose_center_to_preview">
				<segment>
					<source>Choose center to preview</source>
				</segment>
			</unit>
			<unit id="settings_modifier_groups">
				<segment>
					<source>Modifier Groups</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_number_of_total_active">
				<segment>
					<source>{{activeNumber}} of {{totalNumber}} Active</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_activating_the_modifier_group">
				<segment>
					<source>Activating the modifier group</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_are_you_sure_you_want_to_activate_the_modifier_group">
				<segment>
					<source>Are you sure you want to activate the {{groupName}} modifier group?
					</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_visible_multiple">
				<segment>
					<source>This action will make this modifier group visible for {{number}} items.
					</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_deactivating_the_modifier_group">
				<segment>
					<source>Deactivating the modifier group</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_are_you_sure_you_want_to_deactivate_the_modifier_group">
				<segment>
					<source>Are you sure you want to deactivate the {{groupName}} modifier group?
					</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_no_longer_visible_multiple">
				<segment>
					<source>This action will make this modifier group no longer visible for {{number}} items.
					</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_inactive_modifiers_are_not_visible">
				<segment>
					<source>Inactive modifiers and inactive modifiers groups are neither visible to customers, nor available for purchase.</source>
				</segment>
			</unit>
			<unit id="settings_modifiers_allow_customer_to_select">
				<segment>
					<source>Allow customer to select</source>
				</segment>
			</unit>
			<unit id="settings_order_updates">
				<segment>
					<source>Order Updates</source>
				</segment>
			</unit>
			<unit id="settings_order_updates_table_service">
				<segment>
					<source>Order Updates - Table Service</source>
				</segment>
			</unit>
			<unit id="settings_order_updates_pickup">
				<segment>
					<source>Order Updates - Pickup</source>
				</segment>
			</unit>
			<unit id="settings_order_updates_enable_for_pickup">
				<segment>
					<source>Enable order updates for pickup orders</source>
				</segment>
			</unit>
			<unit id="settings_order_updates_enable_for_table_service">
				<segment>
					<source>Enable order updates for table service orders</source>
				</segment>
			</unit>
			<unit id="settings_order_updates_text_message_content">
				<segment>
					<source>Text message content</source>
				</segment>
			</unit>
			<unit id="settings_apis_twilio_setup">
				<segment>
					<source>Twilio Setup</source>
				</segment>
			</unit>
			<unit id="settings_apis_twilio_setup_account_sid">
				<segment>
					<source>Account SID</source>
				</segment>
			</unit>
			<unit id="settings_apis_twilio_setup_auth_token">
				<segment>
					<source>Auth Token</source>
				</segment>
			</unit>
			<unit id="settings_apis_twilio_setup_default_international_code">
				<segment>
					<source>Default International Code</source>
				</segment>
			</unit>
			<unit id="settings_reservations_checkin_grace_period">
				<segment>
					<source>Add grace period to reservations</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes">
				<segment>
					<source>Graphic Themes</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_all_centers">
				<segment>
					<source>Graphic Themes (all centers)</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_manage">
				<segment>
					<source>Manage Graphic Themes</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_assign">
				<segment>
					<source>Assign Graphic Themes</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_website_preview">
				<segment>
					<source>Website Preview</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_info_message">
				<segment>
					<source>Info message</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_warning_message">
				<segment>
					<source>Warning message</source>
				</segment>
			</unit>
			<unit id="settings_graphic_themes_danger_message">
				<segment>
					<source>Danger message</source>
				</segment>
			</unit>
			<unit id="settings_lane_options_sets">
				<segment>
					<source>Lane Options Sets</source>
				</segment>
			</unit>
			<unit id="settings_lane_options_set_add">
				<segment>
					<source>Add Lane Options Set</source>
				</segment>
			</unit>
			<unit id="settings_lane_options_sets_empty">
				<segment>
					<source>There isn't any Lane Options set defined</source>
				</segment>
			</unit>
			<unit id="settings_lane_options_sets_add_error_add_title">
				<segment>
					<source>Unable to create Lane Options Set</source>
				</segment>
			</unit>
			<unit id="settings_lane_options_sets_add_error">
				<segment>
					<source>A Lane Options Set with this name already exists. Please rename the Lane Options Set and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_rental_shoes">
				<segment>
					<source>Rental Shoes</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_system">
				<segment>
					<source>Shoe Size System</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_chart">
				<segment>
					<source>Shoe Size Chart</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_unable_save_category">
				<segment>
					<source>A Category with this name already exists. Please rename the Category and try again.</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_add_range">
				<segment>
					<source>New Size Range</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_range">
				<segment>
					<source>Size range</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_single_size">
				<segment>
					<source>Single size</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size">
				<segment>
					<source>Size</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_delete_category">
				<segment>
					<source>Delete Category</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_want_to_delete_this_category">
				<segment>
					<source>Are you sure you want to delete this category?</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_want_to_deactivate_the_category">
				<segment>
					<source>Are you sure you want to deactivate the category {{categoryName}}?</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_want_to_deactivate_the_following_categories">
				<segment>
					<source>Are you sure you want to deactivate the following categories?</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_deactivate_category">
				<segment>
					<source>Deactivate Category</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_deactivate_categories">
				<segment>
					<source>Deactivate categories</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_delete_size">
				<segment>
					<source>Delete Size</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_delete_sizes">
				<segment>
					<source>Delete Sizes</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_want_to_delete_size">
				<segment>
					<source>Are you sure you want to delete the size {{categoryName}}?</source>
				</segment>
			</unit>
			<unit id="settings_web_shoe_size_want_to_delete_these_sizes">
				<segment>
					<source>Are you sure you want to delete these sizes?</source>
				</segment>
			</unit>
			<unit id="settings_terminals">
				<segment>
					<source>Terminals</source>
				</segment>
			</unit>
			<unit id="settings_terminals_no_terminal_to_be_configured">
				<segment>
					<source>No terminals to display.</source>
				</segment>
			</unit>
			<unit id="settings_terminals_add_terminal">
				<segment>
					<source>Add terminal</source>
				</segment>
			</unit>
			<unit id="settings_terminals_maximum_number_terminals_reached">
				<segment>
					<source>The maximum number of terminals has been already reached</source>
				</segment>
			</unit>
			<unit id="settings_terminals_terminal_number">
				<segment>
					<source>Terminal {{terminalNumber}}</source>
				</segment>
			</unit>
			<unit id="settings_terminals_delete_terminal">
				<segment>
					<source>Delete terminal</source>
				</segment>
			</unit>
			<unit id="settings_terminals_confirm_delete_terminal">
				<segment>
					<source>Are you sure you want to delete '{{terminalName}}'?</source>
				</segment>
			</unit>
			<unit id="settings_terminals_label_paired">
				<segment>
					<source>Paired</source>
				</segment>
			</unit>
			<unit id="settings_terminals_label_not_paired">
				<segment>
					<source>Not paired</source>
				</segment>
			</unit>
			<unit id="settings_terminals_label_terminal_number">
				<segment>
					<source>Terminal number</source>
				</segment>
			</unit>
			<unit id="settings_terminals_label_pairing_status">
				<segment>
					<source>Pairing status</source>
				</segment>
			</unit>
			<unit id="settings_terminals_pairing_terminal_number">
				<segment>
					<source>Pairing Terminal {{terminalNumber}}</source>
				</segment>
			</unit>
			<unit id="settings_terminals_pairing_enter_code">
				<segment>
					<source>Enter the 8-digit code you see on your terminal.</source>
				</segment>
			</unit>
			<unit id="settings_terminals_terminal_code">
				<segment>
					<source>Terminal code</source>
				</segment>
			</unit>
			<unit id="settings_terminals_pairing_in_progress">
				<segment>
					<source>Pairing in progress</source>
				</segment>
			</unit>
			<unit id="settings_terminals_pairing_failed">
				<segment>
					<source>Pairing Failed</source>
				</segment>
			</unit>
			<unit id="settings_terminals_pairing_failed_code_not_valid">
				<segment>
					<source>The code you entered is not valid. Please check the 8-digit code on your terminal and try again.</source>
				</segment>
			</unit>
			<unit id="settings_terminals_pairing_code">
				<segment>
					<source>Pairing code</source>
				</segment>
			</unit>
			<unit id="settings_terminals_modal_add_title">
				<segment>
					<source>Install the app to continue</source>
				</segment>
			</unit>
			<unit id="settings_terminals_modal_add_text_intro">
				<segment>
					<source>Install the Conqueror Hub application by scanning the QR code with your device's camera or by visiting the following link via the Google Chrome browser if you use the Windows operating system, and Safari if you use the macOS operating system.</source>
				</segment>
			</unit>
			<unit id="settings_terminals_modal_add_text_device">
				<segment>
					<source>Once installed, open the application and follow the instructions on your device.</source>
				</segment>
			</unit>
			<unit id="settings_terminals_modal_add_text_browser">
				<segment>
					<source>Note: The app is optimized for Google Chrome on Windows and Safari on macOS. If you use other browser/OS combinations to download the app, proper functionality of the product cannot be guaranteed.</source>
				</segment>
			</unit>
			<unit id="settings_terminals_modal_add_button">
				<segment>
					<source>App installed, continue with pairing</source>
				</segment>
			</unit>
			<unit id="settings_terminals_link_copied">
				<segment>
					<source>Link copied to your clipboard</source>
				</segment>
			</unit>
			<unit id="settings_enable_time_mode">
				<segment>
					<source>Enable Time Mode</source>
				</segment>
			</unit>
			<unit id="settings_enable_game_mode">
				<segment>
					<source>Enable Game Mode</source>
				</segment>
			</unit>
			<unit id="settings_playing_mode_time">
				<segment>
					<source>Playing mode - Time</source>
				</segment>
			</unit>
			<unit id="settings_playing_mode_game">
				<segment>
					<source>Playing mode - Game</source>
				</segment>
			</unit>
			<unit id="settings_playing_mode_time_status">
				<segment>
					<source>Playing mode - Time: {{status}}</source>
				</segment>
			</unit>
			<unit id="settings_playing_mode_game_status">
				<segment>
					<source>Playing mode - Game: {{status}}</source>
				</segment>
			</unit>
		</group>
		<group id="tooltip">
			<unit id="tooltip_businessResults">
				<segment>
					<source>Dashboard to review center financial results, including detail by department, sector, and operator.</source>
				</segment>
			</unit>
			<unit id="tooltip_gameStatistics">
				<segment>
					<source>Dashboard to review usage of BES X and HyperBowling games, environments, and content.</source>
				</segment>
			</unit>
			<unit id="tooltip_performanceMonitoring">
				<segment>
					<source>Dashboard to review pinspotter and other equipment stops, errors, and overall performance.</source>
				</segment>
			</unit>
			<unit id="tooltip_bumperStatistics">
				<segment>
					<source>Dashboard to review HyperBowling bumper events including fouls, tilts, and warnings for rough play.</source>
				</segment>
			</unit>
			<unit id="tooltip_customerAccounts">
				<segment>
					<source>Search and manage the global customer accounts that are part of your Company database.</source>
				</segment>
			</unit>
			<unit id="tooltip_onlineBackups">
				<segment>
					<source>Review the status of securely stored Conqueror X backups.</source>
				</segment>
			</unit>
			<unit id="tooltip_serviceReservations">
				<segment>
					<source>Create service reservations to book lanes for center use and make them unavailable for guest reservations and events.</source>
				</segment>
			</unit>
			<unit id="tooltip_reservationsGrid">
				<segment>
					<source>View the "whiteboard" of center reservations and booked activities.</source>
				</segment>
			</unit>
			<unit id="tooltip_kioskUtilities">
				<segment>
					<source>Wake up, shut down and restart the Kiosk stations</source>
				</segment>
			</unit>
			<unit id="tooltip_webReservationSite">
				<segment>
					<source>Quick access and link to the Conqueror Web booking site for this center.</source>
				</segment>
			</unit>
			<unit id="tooltip_trainingResources">
				<segment>
					<source>Access the Conqueror Maximization Training Resource library featuring how-tos, videos, webinars, and more.</source>
				</segment>
			</unit>
			<unit id="tooltip_liveVirtualTraining">
				<segment>
					<source>View the schedule and sign up for Live Virtual Training sessions on Conqueror X modules and features.</source>
				</segment>
			</unit>
			<unit id="tooltip_marketingResources">
				<segment>
					<source>Access the Conqueror Maximization Marketing Resource library featuring Product Marketing Kits, campaigns and collaterals.</source>
				</segment>
			</unit>
			<unit id="tooltip_techResources">
				<segment>
					<source>Access the Conqueror Maximization Technical Resources Library featuring installation manuals, troubleshooting &amp; how-to guides, videos, and more.</source>
				</segment>
			</unit>
			<unit id="tooltip_eShop">
				<segment>
					<source>Quick access to the QubicaAMF eShop.</source>
				</segment>
			</unit>
			<unit id="tooltip_maxTrainingPlus">
				<segment>
					<source>Quick access to the MaxTraining Plus courses. Requires a separate login.</source>
				</segment>
			</unit>
			<unit id="tooltip_dassleWeb">
				<segment>
					<source>Quick access to the Dassle Web daily accounting software. Requires a separate login.</source>
				</segment>
			</unit>
			<unit id="tooltip_requestServices">
				<segment>
					<source>Request contact from QubicaAMF for various services, including consultations as included with the Conqueror Maximization Program.</source>
				</segment>
			</unit>
			<unit id="tooltip_servicesActivation">
				<segment>
					<source>Manage the categories of Conqueror data to be securely transmitted and stored within the Conqueror Cloud.</source>
				</segment>
			</unit>
			<unit id="tooltip_programsLicenses">
				<segment>
					<source>View and manage Conqueror Maximization Program and other recurring products and licenses.</source>
				</segment>
			</unit>
			<unit id="tooltip_webReservation">
				<segment>
					<source>Manage QPortal and other cloud-based product and module settings.</source>
				</segment>
			</unit>
			<unit id="tooltip_rolesSetup">
				<segment>
					<source>Define roles that will determine which features, services, and privileges users will have access to.</source>
				</segment>
			</unit>
			<unit id="tooltip_companyUsers">
				<segment>
					<source>Create and manage QPortal users, including assigning roles to those users.</source>
				</segment>
			</unit>
			<unit id="tooltip_tipsReporting">
				<segment>
					<source>Report to review tips and gratuity transactions.</source>
				</segment>
			</unit>
			<unit id="tooltip_cloudDataSelection">
				<segment>
					<source>Manage the categories of Conqueror data to be securely transmitted and stored within the Conqueror Cloud.</source>
				</segment>
			</unit>
			<unit id="tooltip_settings">
				<segment>
					<source>Manage QPortal and other cloud-based product and module settings.</source>
				</segment>
			</unit>
			<unit id="tooltip_rolesPrivileges">
				<segment>
					<source>Define roles that will determine which features, services, and privileges users will have access to.</source>
				</segment>
			</unit>
			<unit id="tooltip_users">
				<segment>
					<source>Create and manage QPortal users, including assigning roles to those users.</source>
				</segment>
			</unit>
			<unit id="tooltip_sessionInfo">
				<segment>
					<source>View information about your current session.</source>
				</segment>
			</unit>
			<unit id="tooltip_fnb_orders">
				<segment>
					<source>Search for F&amp;B orders and text updates to customers</source>
				</segment>
			</unit>
			<unit id="tooltip_agent4D">
				<segment>
					<source>Setup your LED walls and manage what to play on them.</source>
				</segment>
			</unit>
			<unit id="tooltip_webTransactions">
				<segment>
					<source>View the transactions of your center.</source>
				</segment>
			</unit>
			<unit id="tooltip_square_integration">
				<segment>
					<source>Manage and configure Square Integration</source>
				</segment>
			</unit>
		</group>
		<group id="bowl_expo">
			<unit id="bowl_expo_disabled_feature_title">
				<segment>
					<source>Bowl Expo 2021</source>
				</segment>
			</unit>
			<unit id="bowl_expo_disabled_feature_message">
				<segment>
					<source>This feature is temporarily disabled</source>
				</segment>
			</unit>
			<unit id="bowl_expo_option_use_default">
				<segment>
					<source>Use Default</source>
				</segment>
			</unit>
			<unit id="bowl_expo_option_not_available">
				<segment>
					<source>Bowl Expo 2021 – option not available</source>
				</segment>
			</unit>
		</group>
		<group id="tips_dashboard">
			<unit id="tips_actions_report">
				<segment>
					<source>Actions Report</source>
				</segment>
			</unit>
			<unit id="label_tips_add_void">
				<segment>
					<source>Add / Void</source>
				</segment>
			</unit>
			<unit id="label_tips_add">
				<segment>
					<source>Add</source>
				</segment>
			</unit>
			<unit id="label_tips_external_key">
				<segment>
					<source>External Key</source>
				</segment>
			</unit>
			<unit id="label_tips_tip_amount">
				<segment>
					<source>Tip Amount</source>
				</segment>
			</unit>
			<unit id="label_tips_pay_out_amount">
				<segment>
					<source>Pay Out Amount</source>
				</segment>
			</unit>
			<unit id="label_tips_tip_actions">
				<segment>
					<source>Tip Actions</source>
				</segment>
			</unit>
		</group>
		<group id="order_updates">
			<unit id="fnb_order_updates">
				<segment>
					<source>F&amp;B order updates</source>
				</segment>
			</unit>
			<unit id="label_send_message">
				<segment>
					<source>Send message</source>
				</segment>
			</unit>
			<unit id="label_order_id">
				<segment>
					<source>Order ID</source>
				</segment>
			</unit>
			<unit id="label_destination">
				<segment>
					<source>Destination</source>
				</segment>
			</unit>
			<unit id="label_last_update">
				<segment>
					<source>Last update</source>
				</segment>
			</unit>
			<unit id="label_date_and_time">
				<segment>
					<source>Date and time</source>
				</segment>
			</unit>
			<unit id="label_sent">
				<segment>
					<source>Sent</source>
				</segment>
			</unit>
			<unit id="label_canceled">
				<segment>
					<source>Canceled</source>
				</segment>
			</unit>
			<unit id="fnb_order_search_order_id_or_name">
				<segment>
					<source>Search Order ID or NAME</source>
				</segment>
			</unit>
			<unit id="fnb_order_no_matching_orders_found">
				<segment>
					<source>No matching orders found</source>
				</segment>
			</unit>
			<unit id="fnb_order_send_message">
				<segment>
					<source>Send message</source>
				</segment>
			</unit>
			<unit id="fnb_order_do_you_want_to_send_update_text">
				<segment>
					<source>Do you want to send a text update?</source>
				</segment>
			</unit>
			<unit id="fnb_order_canceling_order">
				<segment>
					<source>Are you sure you want to cancel the order {{OrderNumber}}?</source>
				</segment>
			</unit>
			<unit id="label_order_details">
				<segment>
					<source>Order Details</source>
				</segment>
			</unit>
			<unit id="label_history">
				<segment>
					<source>History</source>
				</segment>
			</unit>
			<unit id="label_phone_number">
				<segment>
					<source>Phone number</source>
				</segment>
			</unit>
			<unit id="label_cancel_order">
				<segment>
					<source>Cancel order</source>
				</segment>
			</unit>
			<unit id="label_restore">
				<segment>
					<source>Restore</source>
				</segment>
			</unit>
			<unit id="label_table">
				<segment>
					<source>Table</source>
				</segment>
			</unit>
			<unit id="label_canceling_order">
				<segment>
					<source>Canceling order</source>
				</segment>
			</unit>
			<unit id="label_restored">
				<segment>
					<source>Restored</source>
				</segment>
			</unit>
			<unit id="fnb_order_history_update">
				<segment>
					<source>Update {{number}} = {{timeLocalized}}</source>
				</segment>
			</unit>
			<unit id="fnb_order_send_update_order_number">
				<segment>
					<source>Order ID {{OrderNumber}}</source>
				</segment>
			</unit>
		</group>
		<group id="agent4D">
			<unit id="agent4DTitle">
				<segment>
					<source>Neoverse</source>
				</segment>
			</unit>
			<unit id="agent4D_playlists">
				<segment>
					<source>Your Playlists</source>
				</segment>
			</unit>
			<unit id="agent4D_new_playlist">
				<segment>
					<source>New Playlist</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_error_save_duplicate_title">
				<segment>
					<source>Unable to create Playlist</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_error_save_duplicate_message">
				<segment>
					<source>A Playlist with this name already exists. Please rename the Playlist and try again.</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_confirm_delete_title">
				<segment>
					<source>Deleting Playlist</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_confirm_delete_message">
				<segment>
					<source>You are deleting the playlist {{name}}. Do you want to proceed?</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_error_delete_while_playing_title">
				<segment>
					<source>Unable to delete Playlist</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_error_delete_while_playing_message">
				<segment>
					<source>This playlist cannot be deleted because it is currently playing.</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_error_save_while_playing_title">
				<segment>
					<source>Unable to save Playlist</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_error_save_while_playing_message">
				<segment>
					<source>This playlist cannot be edited because it is currently playing.</source>
				</segment>
			</unit>
			<unit id="agent4D_no_playlist_configured">
				<segment>
					<source>No playlist configured</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_empty">
				<segment>
					<source>This playlist is empty, add items</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_add_item">
				<segment>
					<source>Add Item</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_delete_content">
				<segment>
					<source>Delete item</source>
				</segment>
			</unit>
			<unit id="agent4D_playlist_content_minimum_duration">
				<segment>
					<source>Minimum duration is {{duration}}</source>
				</segment>
			</unit>
			<unit id="agent4D_billboards">
				<segment>
					<source>Billboards</source>
				</segment>
			</unit>
			<unit id="agent4D_billboards_your_ledwalls">
				<segment>
					<source>Your Ledwalls</source>
				</segment>
			</unit>
			<unit id="label_ledwall">
				<segment>
					<source>Ledwall</source>
				</segment>
			</unit>
			<unit id="agent4D_no_ledwall_configured">
				<segment>
					<source>No ledwall configured</source>
				</segment>
			</unit>
			<unit id="agent4D_new_template">
				<segment>
					<source>New Template</source>
				</segment>
			</unit>
			<unit id="agent4D_edit_template_subtitle">
				<segment>
					<source>Set up the billboards for this template</source>
				</segment>
			</unit>
			<unit id="label_templates">
				<segment>
					<source>Templates</source>
				</segment>
			</unit>
			<unit id="label_n_templates">
				<segment>
					<source>{{number}} Templates</source>
				</segment>
			</unit>
			<unit id="label_n_template">
				<segment>
					<source>{{number}} Template</source>
				</segment>
			</unit>
			<unit id="label_template">
				<segment>
					<source>Template</source>
				</segment>
			</unit>
			<unit id="agent4D_template_error_save_duplicate_title">
				<segment>
					<source>Unable to create Template</source>
				</segment>
			</unit>
			<unit id="agent4D_template_error_save_duplicate_message">
				<segment>
					<source>A Template with this name already exists. Please rename the Template and try again.</source>
				</segment>
			</unit>
			<unit id="agent4D_template_confirm_delete_title">
				<segment>
					<source>Delete Template</source>
				</segment>
			</unit>
			<unit id="agent4D_template_confirm_delete_message">
				<segment>
					<source>Are you sure you want to delete this template?</source>
				</segment>
			</unit>
			<unit id="agent4D_template_confirm_preview_title">
				<segment>
					<source>Starting Preview</source>
				</segment>
			</unit>
			<unit id="agent4D_template_confirm_preview_message">
				<segment>
					<source>The preview mode will override all other playing contents on your LED walls.</source>
				</segment>
			</unit>
			<unit id="agent4D_add_billboard">
				<segment>
					<source>Add billboard</source>
				</segment>
			</unit>
			<unit id="agent4D_template_error_add_billboards_all_filled_up_title">
				<segment>
					<source>All filled up!</source>
				</segment>
			</unit>
			<unit id="agent4D_template_error_add_billboards_all_filled_up_message">
				<segment>
					<source>You cannot add more billboards since there is no more room on your ledwalls.</source>
				</segment>
			</unit>
			<unit id="agent4D_no_template_configured">
				<segment>
					<source>No template configured</source>
				</segment>
			</unit>
			<unit id="label_n_billboards">
				<segment>
					<source>{{number}} Billboards</source>
				</segment>
			</unit>
			<unit id="label_n_billboard">
				<segment>
					<source>{{number}} Billboard</source>
				</segment>
			</unit>
			<unit id="label_billboards_size">
				<segment>
					<source>Billboards size</source>
				</segment>
			</unit>
			<unit id="label_billboard_n">
				<segment>
					<source>Billboard {{number}}</source>
				</segment>
			</unit>
			<unit id="label_opacity">
				<segment>
					<source>Opacity</source>
				</segment>
			</unit>
			<unit id="label_lane_n">
				<segment>
					<source>Lane {{number}}</source>
				</segment>
			</unit>
			<unit id="agent4D_all_filled_up">
				<segment>
					<source>All Filled Up!</source>
				</segment>
			</unit>
			<unit id="agent4D_you_cannot_add_more_billboards">
				<segment>
					<source>You cannot add more billboards since there is no more room on your ledwalls.</source>
				</segment>
			</unit>
			<unit id="agent4D_start_preview_message">
				<segment>
					<source>The preview could not start. Try again later.</source>
				</segment>
			</unit>
			<unit id="agent4D_start_preview_title">
				<segment>
					<source>Start preview error</source>
				</segment>
			</unit>
			<unit id="agent4D_updated_preview_message">
				<segment>
					<source>The preview could not be updated and it will be stopped.</source>
				</segment>
			</unit>
			<unit id="agent4D_updated_preview_title">
				<segment>
					<source>Update preview error</source>
				</segment>
			</unit>
		</group>
		<group id="transactions">
			<unit id="label_tip">
				<segment>
					<source>Tip</source>
				</segment>
			</unit>
			<unit id="label_card_brand">
				<segment>
					<source>Brand</source>
				</segment>
			</unit>
			<unit id="label_card_number">
				<segment>
					<source>Card number</source>
				</segment>
			</unit>
			<unit id="label_transaction_channel">
				<segment>
					<source>Channel</source>
				</segment>
			</unit>
			<unit id="label_operator">
				<segment>
					<source>Operator</source>
				</segment>
			</unit>
			<unit id="label_terminal">
				<segment>
					<source>Terminal</source>
				</segment>
			</unit>
			<unit id="label_qpad">
				<segment>
					<source>QPad</source>
				</segment>
			</unit>
			<unit id="label_auth_code">
				<segment>
					<source>Auth. Code</source>
				</segment>
			</unit>
			<unit id="error_sms_title">
				<segment>
					<source>Message Delivery Failed</source>
				</segment>
			</unit>
			<unit id="error_sms_details">
				<segment>
					<source>Message delivery failed. Error: *{{code}}* - {{description}}</source>
				</segment>
			</unit>
		</group>
	</file>
</xliff>
