/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-spread */
import { Functionality } from "@app-rest/company/interfaces";
import useUserStore from "@app-store/user";

import QPortalApp from "@/main";

class SecurityPluginFactory {
	installed = false;
	private hasFunctionality(functionalityKey: Functionality) {
		const userStore = useUserStore();
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const func = privs ? privs[functionalityKey] : null;
		return Boolean(func);
	}

	hasFunctionalities(requiredFunctionalityKeys: Functionality[]) {
		const results = requiredFunctionalityKeys.map(pk => this.hasFunctionality(pk));
		const userPrivMatches = results.filter(Boolean);
		return requiredFunctionalityKeys.length === userPrivMatches.length;
	}

	hasFunctionalitiesEnabled(requiredFunctionalityKeys: Functionality[]) {
		const userStore = useUserStore();
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const results = requiredFunctionalityKeys.map(pk => privs ? privs[pk] : null);
		const userPrivMatches = results.filter(r => r?.Enabled);
		return requiredFunctionalityKeys.length === userPrivMatches.length;
	}

	hasAtLeastOneFunctionality(requiredFunctionalityKeys: Functionality[]) {
		const userStore = useUserStore();
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const results = requiredFunctionalityKeys.map(pk => privs ? privs[pk] : null);
		const userPrivMatches = results.filter(Boolean);
		return userPrivMatches.length > 0;
	}

	hasAtLeastOneFunctionalityEnabled(requiredFunctionalityKeys: Functionality[]) {
		const userStore = useUserStore();
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const results = requiredFunctionalityKeys.map(pk => privs ? privs[pk] : null);
		const userPrivMatches = results.filter(r => r?.Enabled);
		return userPrivMatches.length > 0;
	}

	install() {
		if (this.installed) return;
		this.installed = true;
		const that = this;

		QPortalApp.config.globalProperties.$hasFunctionalities = (...args) => that.hasFunctionalities.apply(that, args as any);

		QPortalApp.config.globalProperties.$hasAtLeastOneFunctionality = (...args) => that.hasAtLeastOneFunctionality.apply(that, args as any);

		QPortalApp.config.globalProperties.$hasFunctionalitiesEnabled = (...args) => that.hasFunctionalitiesEnabled.apply(that, args as any);

		QPortalApp.config.globalProperties.$hasAtLeastOneFunctionalityEnabled = (...args) => that.hasAtLeastOneFunctionalityEnabled.apply(that, args as any);
	}
}

const SecurityPlugin = new SecurityPluginFactory();
export default SecurityPlugin;
