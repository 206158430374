import { useSecurityUtilities } from "@app-composables/security";
import type { IRestResponse } from "@app-rest/common.interfaces";
import { Functionalities } from "@app-rest/company/interfaces";
import { ExternalPosRestFactory } from "@app-rest/system/externalPos";
import type { IBowlingItem, ICenterPairingRequest, ICreateAliasPayload, ICreatePairPayload, IGetBowlingItemsQuery, IIntegrationSyncStatusResponse, ISaveBowlingItemDetailsRequest, IUpdateAliasPayload } from "@app-rest/system/externalPos.interfaces";
import { SquareOAuthCodeRequest, SquareOAuthEnvironment, SquareOAuthRestFactory } from "@app-rest/system/squareOAuth";
import useCompanyStore from "@app-store/company";
import store from "@app-store/index";
import useSystemStore from "@app-store/system";
import EventsManager from "@app-utils/events-manager";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

@Module class ExternalPosModuleFactory extends VuexModule {
	restExternalPos: ExternalPosRestFactory | null = null;
	restSquareOAuth: SquareOAuthRestFactory | null = null;
	isCompanyConnected: boolean | null = null;
	isCenterPaired: boolean | null = null;
	pairedLocationName: string | null = null;
	activeSquareEnvironment: SquareOAuthEnvironment | null = null;
	bowlingItems: IBowlingItem[] = [];
	bowlingItemsPerPage = 50;
	hasMoreBowlingItems = false;
	squareSyncStatus: IIntegrationSyncStatusResponse["Status"] | "NotExists" | null = null;

	@Mutation clearData() {
		this.restExternalPos = null;
		this.restSquareOAuth = null;
		this.isCompanyConnected = null;
		this.isCenterPaired = null;
		this.pairedLocationName = null;
		this.activeSquareEnvironment = null;
		this.bowlingItems = [];
		this.hasMoreBowlingItems = false;
		this.squareSyncStatus = null;
	}

	@Mutation setRestExternalPosClient(restClient: ExternalPosRestFactory | null) {
		this.restExternalPos = restClient;
	}

	@Mutation setRestSquareOAuthClient(restClient: SquareOAuthRestFactory | null) {
		this.restSquareOAuth = restClient;
	}

	@Mutation setActiveSquareEnvironment(data: SquareOAuthEnvironment | null) {
		this.activeSquareEnvironment = data;
		this.isCompanyConnected = Boolean(data);
	}

	@Mutation setIsCenterPaired(data: boolean) {
		this.isCenterPaired = data;
	}

	@Mutation setPairedLocationName(data: string | null) {
		this.pairedLocationName = data;
	}

	@Mutation setBowlingItems(data: IRestResponse<IBowlingItem> | null) {
		this.bowlingItems = data?.Elements ?? [];
		this.hasMoreBowlingItems = data?.HasMoreElements ?? false;
	}

	@Mutation setSquareSyncStatus(data?: IIntegrationSyncStatusResponse["Status"]) {
		this.squareSyncStatus = data ?? "NotExists";
	}

	@Mutation appendBowlingItems(data: IRestResponse<IBowlingItem>) {
		const currentElements = this.bowlingItems;
		this.bowlingItems = [...currentElements, ...data.Elements];
		this.hasMoreBowlingItems = data?.HasMoreElements ?? false;
	}

	@Action async ensureSquareSyncStatus() {
		const { hasAtLeastOneFunctionalityEnabled } = useSecurityUtilities();
		if (this.squareSyncStatus || !hasAtLeastOneFunctionalityEnabled([Functionalities.ManageSquarePosIntegration, Functionalities.ManageSquareItemLibrary])) return;
		const response = await this.getIntegrationSyncStatus();
		this.setSquareSyncStatus(response.data?.Status);
	}

	@Action async ensureCenterPairing() {
		if (this.activeSquareEnvironment || (this.isCompanyConnected !== null && this.isCenterPaired !== null)) return;
		const systemId = useSystemStore().info?.Id;
		if (!systemId) {
			EventsManager.emitError(new TypeError(), { message: "External POS: systemId is null or undefined" });
			return;
		}
		const restClient = await this.ensureRestSquareOAuthClient();
		const responses = await Promise.all([restClient.tokenExists("Production"), restClient.tokenExists("Sandbox")]);
		const activeToken = responses.find(r => r.data);
		this.setActiveSquareEnvironment(activeToken?.request.options.query?.environment ?? null);
		const response = await this.getCenterPair(systemId);
		this.setIsCenterPaired(Boolean(response?.data));
		this.setPairedLocationName(response?.data?.PairedLocationName ?? null);
	}

	@Action ensureRestExternalPosClient() {
		if (this.restExternalPos) return Promise.resolve(this.restExternalPos);
		this.setRestExternalPosClient(new ExternalPosRestFactory(useSystemStore().info?.Id));
		return Promise.resolve(this.restExternalPos as unknown as ExternalPosRestFactory);
	}

	@Action ensureRestSquareOAuthClient() {
		if (this.restSquareOAuth) return Promise.resolve(this.restSquareOAuth);
		this.setRestSquareOAuthClient(new SquareOAuthRestFactory(useCompanyStore().company?.Id));
		return Promise.resolve(this.restSquareOAuth as unknown as SquareOAuthRestFactory);
	}

	@Action async getTerminals() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getTerminals();
	}

	@Action async createAlias(payload: ICreateAliasPayload) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.createAlias(payload);
	}

	@Action async deleteAlias(aliasId: number) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.deleteAlias(aliasId);
	}

	@Action async getTerminalAliases() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getTerminalAliases();
	}

	@Action async getAliasDetails(aliasId: number) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getAliasDetails(aliasId);
	}

	@Action async updateAlias({ aliasId, data }: {aliasId: number, data: IUpdateAliasPayload}) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.updateAlias(aliasId, data);
	}

	@Action async createPair({ data }: {data: ICreatePairPayload}) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.createPair(data);
	}

	@Action async getConnectUrl(environment: SquareOAuthEnvironment) {
		const restClient = await this.ensureRestSquareOAuthClient();
		return restClient.getConnectUrl(environment);
	}

	@Action async saveToken(payload: SquareOAuthCodeRequest) {
		const restClient = await this.ensureRestSquareOAuthClient();
		const response = await restClient.saveToken(payload);
		if (!response.error)
			this.setActiveSquareEnvironment(payload.Environment);
	}

	@Action async revokeToken(environment: SquareOAuthEnvironment) {
		const restClient = await this.ensureRestSquareOAuthClient();
		const response = await restClient.revokeToken(environment);
		if (!response.error)
			this.setActiveSquareEnvironment(null);
	}

	@Action async getCenterPair(systemId: number) {
		const companyId = useCompanyStore().company?.Id;
		if (!companyId) {
			EventsManager.emitError(new TypeError(), { message: "External POS: companyId is null or undefined" });
			return;
		}
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getPairById(companyId ?? 0, systemId);
	}

	@Action async getCenterList(companyId: number) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getCenterList(companyId, this.activeSquareEnvironment ?? "Production");
	}

	@Action async getLocationList({ companyId, environment }: {companyId: number, environment: SquareOAuthEnvironment}) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getLocationList(companyId, environment);
	}

	@Action async saveCenterPairing({ companyId, payload }: {companyId: number, payload: ICenterPairingRequest}) {
		const systemId = useSystemStore().info?.Id;
		if (!systemId) {
			EventsManager.emitError(new TypeError(), { message: "External POS: systemId is null or undefined" });
			return;
		}
		const restClient = await this.ensureRestExternalPosClient();
		const response = await restClient.saveCenterPairing(companyId, payload);
		if (systemId === payload.CenterId && !response.error) this.setIsCenterPaired(true);
		return response;
	}

	@Action async deleteCenterPairing(systemId: number) {
		const currentSystemId = useSystemStore().info?.Id;
		const companyId = useCompanyStore().company?.Id;
		if (!currentSystemId || !companyId) {
			EventsManager.emitError(new TypeError(), { message: "External POS: systemId or companyId is null or undefined" });
			return;
		}
		const restClient = await this.ensureRestExternalPosClient();
		const response = await restClient.deleteCenterPairing(companyId, systemId);
		if (currentSystemId === systemId && !response.error) this.setIsCenterPaired(false);
		return response;
	}

	@Action async getIntegrationRequirements() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getIntegrationRequirements();
	}

	@Action async getSquareRequirements() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getSquareRequirements();
	}

	@Action async addItemCustomAttribute() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.addItemCustomAttribute();
	}

	@Action async firstCatalogImport() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.firstCatalogImport();
	}

	@Action async getIntegrationImportStatus() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getIntegrationImportStatus();
	}

	@Action async getImportResults() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getImportResults();
	}

	@Action async getIntegrationSyncStatus() {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getIntegrationSyncStatus();
	}

	@Action async syncBowlingItems(query: IGetBowlingItemsQuery) {
		const restClient = await this.ensureRestExternalPosClient();
		const response = await restClient.getBowlingItems(query);
		if (response.data) {
			if (query.Page === 1)
				this.setBowlingItems(response.data);
			else this.appendBowlingItems(response.data);
		} else this.setBowlingItems(null);
	}

	@Action async getBowlingItemDetails(item: IBowlingItem) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.getBowlingItemDetails(item.Type, item.Id);
	}

	@Action async saveBowlingItemDetails({ item, body }: { item: IBowlingItem, body: ISaveBowlingItemDetailsRequest }) {
		const restClient = await this.ensureRestExternalPosClient();
		return restClient.saveBowlingItemDetails(item.Type, item.Id, body);
	}
}

const moduleName = "system.pos";
let ExternalPosStore: ExternalPosModuleFactory | null;
function useExternalPosStore() {
	if (ExternalPosStore) return ExternalPosStore;
	const mod = ExternalPosStore = new ExternalPosModuleFactory({ name: moduleName, store });
	EventsManager.onSystemChanged(systemId => {
		mod.clearData();
		mod.setRestExternalPosClient(new ExternalPosRestFactory(systemId));
	});
	EventsManager.onCompanyChanged(companyId => {
		mod.clearData();
		mod.setRestSquareOAuthClient(new SquareOAuthRestFactory(companyId));
	});
	return mod;
}

export default useExternalPosStore;
