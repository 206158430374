import languages from "@app-backend/languages.json";
import type { QRoute } from "@app-router/index";
import useAppStore from "@app-store/app";
import type { ITranslation } from "@qamf/xliff-loader";
import { ref } from "vue";

import QPortalApp from "@/main";

export type LanguageIso = keyof typeof languages;
const xlfObjectSource = ref(<ITranslation>{});
const xlfObject = ref(<ITranslation>{});

const usei18n = () => {
	let installed = false;

	function translateKey(key: string, obj?): string {
		if (!key || !xlfObject.value)
			return "";

		let str = ""; let translated = true;

		if (key in xlfObject.value.target && xlfObject.value.target[key])
			str = xlfObject.value.target[key].trim();

		else if (key in xlfObject.value.source && xlfObject.value.source[key])
			str = xlfObject.value.source[key].trim();

		else if (key in xlfObjectSource.value.source && xlfObjectSource.value.source[key])
			str = xlfObjectSource.value.source[key].trim();

		else {
			translated = false;
			console.warn(`[i18n] The Translation-Key '${key}' was not translated`);
			str = `[${key.toUpperCase()}]`;
		}

		if (!str)
			console.warn(`[i18n] The Translation-Key '${key}' is empty.`);

		if (obj && translated) {
			const tokenRegex = /{{[a-zA-Z0-9]+}}/gm;
			const matches = str.matchAll(tokenRegex);
			let fixedString = String(str);
			for (const match of matches) {
				const token = match[0];
				fixedString = fixedString.replace(token, token.toLowerCase());
			}
			for (const prop in obj)
				fixedString = fixedString.replace(`{{${prop.toLowerCase()}}}`, obj[prop]);

			str = fixedString;
		}

		return str;
	}

	function getTranslatedRouteTitle(route: QRoute) {
		const titleKey = typeof route.meta.titleKey === "function" ? route.meta.titleKey() : route.meta.titleKey;
		return titleKey ? translateKey(titleKey) : "";
	}

	function sourceKey(key: string): string {
		return xlfObjectSource.value && xlfObjectSource.value.source && key in xlfObjectSource.value.source && xlfObjectSource.value.source[key]
			? xlfObjectSource.value.source[key].trim()
			: key;
	}

	function install() {
		const appStore = useAppStore();
		if (installed) return;
		installed = true;
		QPortalApp.config.globalProperties.$t = translateKey;

		if (process.env.LAUNCH_MODE === "DevelopSession" && appStore.env === "Develop") {
			import("@app-backend/Locales/qportal.xlf").then(xlf => {
				console.info(`[i18n] XLF Source file loaded, keys found: ${Object.keys(xlf.default.source).length}`);
			});
		}

		QPortalApp.directive("t", {
			mounted: (element, binding) => {
				const key = Object.keys(binding.modifiers)[0];
				const translatedString = translateKey(key, binding.value);
				element.innerHTML = translatedString;
			},
			updated: (element, binding) => {
				const key = Object.keys(binding.modifiers)[0];
				const translatedString = translateKey(key, binding.value);
				element.innerHTML = translatedString;
			}
		});
	}

	return {
		xlfObjectSource,
		xlfObject,
		translateKey,
		getTranslatedRouteTitle,
		sourceKey,
		install
	};
};

export default usei18n;
