import type { IResponseError } from "@app-rest/common.interfaces";
import RestSystemFactory from "@app-rest/system";
import type { ICurrencyDefinitionModel, ISystemBase } from "@app-rest/system/interfaces";
import { RestReservations } from "@app-rest/system/reservations";
import type { IReservationParameters } from "@app-rest/system/reservations.interfaces";
import store from "@app-store/index";
import useMenuItemsStore from "@app-store/menu-items";
import useUserStore from "@app-store/user";
import EventsManager from "@app-utils/events-manager";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

let _restClient: RestSystemFactory | null;
function ensureSystemRestClient() {
	if (_restClient) return _restClient;
	const restClient = _restClient = new RestSystemFactory();
	return restClient;
}

@Module class SystemModuleFactory extends VuexModule {
	loadingInfo = false;
	loadingCurrency = false;
	loadingCalendars = false;
	info: ISystemBase | null = null;
	infoStatusCode = 0;
	currency: ICurrencyDefinitionModel | null = null;
	reservationParams: IReservationParameters | null = null;
	restReservations: RestReservations | null = null;

	get hoursDayChangeTime() {
		if (!this.info || !this.info.DayChangeTime) return 6;
		const timeSep = ":";
		return +(this.info.DayChangeTime.split(timeSep)[0]);
	}

	get minutesDayChangeTime() {
		if (!this.info || !this.info.DayChangeTime) return 0;
		const timeSep = ":";
		return +(this.info.DayChangeTime.split(timeSep)[1]);
	}

	@Mutation clearData() {
		this.info = null;
		this.infoStatusCode = 0;
		this.currency = null;
		this.reservationParams = null;
	}

	@Mutation setLoadingInfo(v: boolean) {
		this.loadingInfo = v;
	}

	@Mutation setLoadingSystemCurrency(v: boolean) {
		this.loadingCurrency = v;
	}

	@Mutation setRestReservationsClient(restClient: RestReservations | null) {
		this.restReservations = restClient;
	}

	@Mutation setInfo(info: ISystemBase) {
		const changed = !this.info || this.info?.Id !== info.Id;
		if (changed) {
			useUserStore().addSystemIdToHistory(info.Id);
			EventsManager.emitSystemChanged(info.Id);
		}
		this.info = info;
	}

	@Mutation setInfoStatusCode(v: number) {
		this.infoStatusCode = v;
	}

	@Mutation setCurrency(curr: ICurrencyDefinitionModel | null) {
		this.currency = curr;
	}

	@Mutation setRestReservation(restClient: RestReservations | null) {
		this.restReservations = restClient;
		this.reservationParams = null;
	}

	@Mutation setReservationParams(data: IReservationParameters | null) {
		this.reservationParams = data;
	}

	@Action async ensureInfo(systemId: number) {
		if (this.info?.Id === +systemId) return;
		this.setLoadingInfo(true);

		const response = await ensureSystemRestClient().center(systemId);

		if (response.status === 404 || response.status === 401) {
			this.setInfoStatusCode(response.status);
			this.setLoadingInfo(false);
			return;
		}

		if (response && response.data) {
			this.setInfo(response.data);
			useMenuItemsStore().initMenu();
		}
		this.setLoadingInfo(false);
	}

	@Action async ensureCurrency(systemId?: number) {
		if (this.currency && this.info && this.info.Id === systemId) return;
		if (!systemId) systemId = this.info?.Id;
		if (!systemId) return;

		this.setLoadingSystemCurrency(true);
		let prom = ensureSystemRestClient().getPromise<ICurrencyDefinitionModel, IResponseError<string>>("ensureCurrency");
		if (!prom) {
			prom = ensureSystemRestClient().mainCurrency(systemId);
			ensureSystemRestClient().setPromise("ensureCurrency", prom);
		}
		const response = await prom;
		if (response) this.setCurrency(response.data);
		this.setLoadingSystemCurrency(false);
	}

	@Action ensureRestReservation() {
		if (!this.restReservations)
			this.setRestReservationsClient(new RestReservations(this.info?.Id));

		return Promise.resolve(this.restReservations as unknown as RestReservations);
	}

	@Action async ensureReservationParams() {
		const restReservations = await this.ensureRestReservation();
		const result = await restReservations.parameters();
		if (result.error?.data)
			return { Success: false, ErrorCode: result.error.data.Error.Code };
		else {
			this.setReservationParams(result.data);
			return { Success: true };
		}
	}
}

let _systemStore: SystemModuleFactory | undefined;
export default function useSystemStore() {
	if (_systemStore) return _systemStore;
	const systemStore = _systemStore = new SystemModuleFactory({ store, name: "system" });
	EventsManager.onSystemChanged(id => {
		systemStore.clearData();
		systemStore.setRestReservation(new RestReservations(id));
	});
	return systemStore;
}
