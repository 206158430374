import { defineComponent, watch } from "vue";

export default defineComponent({
	name: "FakeInput",
	props: {
		value: { type: [String, Number, Boolean, Date, Object, Array], default: undefined },
		size: {
			type: String,
			default: "md",
			validator: (value: string) => ["xs", "sm", "md", "lg", "xl"].indexOf(value) >= 0
		},
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false }
	},
	emits: ["input"],
	setup(props, { attrs, emit }) {
		const instanceAttrs = attrs;
		const onValueChanged = (v: any) => {
			emit("input", v);
		};
		watch(
			() => props.value,
			(v: any) => onValueChanged(v)
		);
		return {
			instanceAttrs
		};
	}
});
