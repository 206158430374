import FakeInput from "@app-components/fake-input/fake-input.vue";
import clickAway from "@app-directives/click-away";
import useLangStore from "@app-store/lang";
import { PopoverPlacement, QFormFieldWrapper, QPopover } from "@qamf/lighthouse";
import { BaseSize } from "bootstrap-vue-next";
import {
	addDays,
	addMinutes,
	differenceInHours,
	eachHourOfInterval,
	format,
	getDay,
	getHours,
	getMinutes,
	isToday,
	isValid,
	roundToNearestMinutes,
	set
} from "date-fns";
import { padStart } from "lodash-es";
import {
	computed,
	defineComponent,
	nextTick,
	onMounted,
	onUnmounted,
	PropType,
	reactive,
	ref,
	watch
} from "vue";

interface PickerButton {
	value: Date | number;
	text: string;
	pressed: boolean;
	disabled: boolean;
}

const pickerProvider = new Map<string, typeof QPopover>();

export default defineComponent({
	name: "QFormFieldTime",
	components: {
		QPopover,
		QFormFieldWrapper,
		FakeInput
	},
	directives: { clickAway },
	props: {
		name: { type: String, default: null, required: true },
		modelValue: { type: Date, default: undefined },
		spinnerSize: { type: String, default: "md" },
		gridFirstTime: { type: Number, default: 0 },
		gridLastTime: { type: Number, default: 23 },
		granularity: { type: Number, default: 15 },
		min: { type: Date, default: null },
		max: { type: Date, default: null },
		maxDateIncluded: { type: Boolean, default: false },
		baseDate: { type: Date, default: () => new Date() },
		format: { type: String, default: "p" },
		preserveMinutes: { type: Boolean, default: false },
		fillHoursRows: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		checkForNow: { type: Boolean, default: false },
		size: {
			type: String as PropType<keyof BaseSize>,
			default: "md",
			validator: (value: string) => ["xs", "sm", "md", "lg", "xl"].indexOf(value) >= 0
		},
		placement: {
			type: String as PropType<PopoverPlacement>,
			default: "bottom",
			validator: (value: string) =>
				[
					"top",
					"bottom",
					"right",
					"left",
					"auto",
					"top-start",
					"top-left",
					"bottom-start",
					"bottom-end",
					"right-start",
					"right-end",
					"left-start",
					"left-end",
					"auto-start",
					"auto-end"
				].indexOf(value) >= 0
		},
		teleportTo: { type: String, default: "body" },
		rules: { type: String, default: null },
		errorMessage: { type: String, default: "" },
		hideValidation: { type: Boolean, default: false },
		hideValidationMessages: { type: Boolean, default: false },
		class: { type: String, default: null }
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		const inputElement = ref<HTMLElement | undefined>();
		const popover = ref<typeof QPopover | null>();
		const isPopoverVisible = ref(false);
		const selectedDate = computed({
			get: () => props.modelValue,
			set: (newValue) => { emit("update:modelValue", newValue) }
		});
		const helpers = reactive({
			loading: false,
			pickerKey: "",
			minimumSelectableHour: null as Date | null,
			maximumSelectableHour: null as Date | null,
			hours: [] as PickerButton[],
			minutes: [] as PickerButton[]
		});
		const elementsPerRow = ref(4);
		const classProp = computed(() => {
			return props.class;
		});
		const endsTomorrow = computed(() => {
			return props.gridLastTime <= props.gridFirstTime;
		});
		const inputValue = computed(() => {
			const langStore = useLangStore();
			const is24h = langStore.language?.is24h;
			const timeSep = langStore.language?.timeSep;
			return selectedDate.value && isValid(selectedDate.value)
				? format(
					selectedDate.value,
					is24h
						? "H" + timeSep + "mm"
						: "h" + timeSep + "mm a"
				)
				: "";
		});
		const setMinimumSelectableHour = () => {
			const now = addMinutes(
				roundToNearestMinutes(new Date(), {
					nearestTo: props.granularity
				}),
				props.granularity
			);
			const baseDate = set(props.baseDate, {
				hours: now.getHours(),
				minutes: now.getMinutes(),
				seconds: 0,
				milliseconds: 0
			});
			const baseDateIsToday = isToday(baseDate);
			helpers.minimumSelectableHour = props.checkForNow
					&& baseDateIsToday
					&& props.min
					&& baseDate >= props.min
					&& props.max
					&& baseDate <= props.max
				? baseDate
				: props.min ?? null;
		};
		const setMaximumSelectableHour = () => {
			const now = addMinutes(
				roundToNearestMinutes(new Date(), {
					nearestTo: props.granularity
				}),
				props.granularity
			);
			const baseDate = set(props.baseDate, {
				hours: now.getHours(),
				minutes: now.getMinutes(),
				seconds: 0,
				milliseconds: 0
			});
			const baseDateIsToday = isToday(baseDate);
			helpers.maximumSelectableHour = props.checkForNow
					&& baseDateIsToday
					&& props.min
					&& props.max
					&& baseDate >= props.min
					&& baseDate <= props.max
				? baseDate
				: props.max ?? null;
		};
		watch(
			() => props.modelValue,
			(val?: Date | null) => {
				selectedDate.value = val ?? undefined;
			}
		);
		const isDateSelectable = (datetime: Date, minimumHour?: Date | null) => {
			if (minimumHour && props.max && props.maxDateIncluded)
				return datetime >= minimumHour && datetime <= props.max;
			if (minimumHour && props.max)
				return datetime >= minimumHour && datetime < props.max;
			if (minimumHour) return datetime >= minimumHour;
			if (props.max) return datetime < props.max;
			return true;
		};
		const submitModelNull = () => {
			selectedDate.value = undefined;
		};
		const submitModel = () => {
			const h = helpers.hours.find((m) => m.pressed);
			if (!h) {
				selectedDate.value = undefined;
				return;
			}
			const minutes = helpers.minutes.find((m) => m.pressed);
			if (typeof minutes?.value === "number")
				selectedDate.value = set(h.value, { minutes: minutes.value });
			else {
				helpers.minutes[0].pressed = true;
				selectedDate.value = h.value as Date;
			}
		};
		const onClickOutside = () => {
			isPopoverVisible.value = false;
		};
		const initTimes = () => {
			helpers.loading = true;
			setMinimumSelectableHour();
			setMaximumSelectableHour();
			if (!props.baseDate) {
				helpers.loading = false;
				return;
			}
			const start = set(props.baseDate, { hours: props.gridFirstTime });
			let end = set(props.baseDate, { hours: props.gridLastTime });
			if (endsTomorrow.value) end = addDays(end, 1);
			const langStore = useLangStore();
			const minimumHHmm = helpers.minimumSelectableHour
				? new Date(helpers.minimumSelectableHour)
				: undefined;
			const minimumHH = minimumHHmm
				? set(minimumHHmm, { minutes: 0, seconds: 0, milliseconds: 0 })
				: undefined;
			const displayedHours = differenceInHours(end, start) + 1;
			const rowFiller = !props.fillHoursRows || displayedHours % elementsPerRow.value === 0
				? 0
				: elementsPerRow.value - (displayedHours % elementsPerRow.value);
			end = set(end, { hours: props.gridLastTime + rowFiller });
			helpers.minutes = [];
			helpers.hours = eachHourOfInterval({ start, end }).map((basedate) => {
				const isSameHour = Boolean(
					selectedDate.value
						&& getDay(basedate) === getDay(selectedDate.value)
						&& getHours(basedate) === getHours(selectedDate.value)
				);
				const is24h = langStore.language?.is24h;
				const hour: PickerButton = {
					text: format(basedate, is24h ? "H" : "h a"),
					value: basedate,
					disabled: !isDateSelectable(basedate, minimumHH),
					pressed: isSameHour
				};
				return hour;
			});
			for (let minutes = 0; minutes < 60; minutes += props.granularity) {
				const basedate = selectedDate.value
					? set(selectedDate.value, { minutes })
					: null;
				const minutesSep = langStore.language?.timeSep;
				helpers.minutes.push({
					text: `${minutesSep}${padStart(minutes + "", 2, "0")}`,
					value: minutes,
					disabled: basedate
						? !isDateSelectable(basedate, minimumHHmm)
						: true,
					pressed: Boolean(
						selectedDate.value
							&& getMinutes(selectedDate.value) === minutes
					)
				});
			}
			helpers.loading = false;
		};
		watch(
			() => [props.min, props.max, props.baseDate, props.checkForNow],
			() => initTimes()
		);
		const onClickWrapper = async() => {
			if (props.disabled) return;
			await nextTick();

			if (!pickerProvider.has(helpers.pickerKey) && popover.value)
				pickerProvider.set(helpers.pickerKey, popover.value);
			pickerProvider.forEach((singlePopover: typeof QPopover) => {
				if (singlePopover !== popover.value)
					singlePopover.hide();
			});
			if (popover.value) isPopoverVisible.value = true;
		};
		const onHoursSelected = (h: PickerButton) => {
			helpers.hours.forEach((hh) => (hh.pressed = false));
			h.pressed = true;
			helpers.minutes.forEach((mm) => {
				mm.pressed = false;
				const datetime = set(h.value, {
					minutes: mm.value as number
				});
				mm.disabled = !isDateSelectable(
					datetime,
					helpers.minimumSelectableHour
				);
			});
			if (props.preserveMinutes) {
				const previousSelectedMm = helpers.minutes.find((m) => m.pressed);
				if (previousSelectedMm && previousSelectedMm.disabled) {
					submitModelNull();
					return onClickOutside();
				} else if (previousSelectedMm) previousSelectedMm.pressed = true;
				else if (helpers.minutes[0].disabled) {
					submitModelNull();
					return onClickOutside();
				} else helpers.minutes[0].pressed = true;
			} else {
				const index = helpers.minutes.findIndex((m) => m.disabled === false);
				const pressedIndex = index !== -1 ? index : 0;
				helpers.minutes[pressedIndex].pressed = true;
			}
			submitModel();
		};
		const onMinutesSelected = (minute: PickerButton) => {
			if (minute.pressed) return onClickOutside();
			helpers.minutes.forEach((mm) => (mm.pressed = false));
			minute.pressed = true;
			submitModel();
			onClickOutside();
		};
		onMounted(async() => {
			helpers.pickerKey = +new Date() + "_" + Math.random() * 1000;
		});
		onUnmounted(() => {
			pickerProvider.delete(helpers.pickerKey);
		});
		return {
			popover,
			helpers,
			elementsPerRow,
			endsTomorrow,
			inputValue,
			inputElement,
			isPopoverVisible,
			classProp,
			onClickOutside,
			onHoursSelected,
			onMinutesSelected,
			initTimes,
			onClickWrapper,
			selectedDate
		};
	}
});
