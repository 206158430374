export interface IResponseAny {
	<TData = any, TError = any>(prom: Promise<any>): Promise<
		[TData | null, TError | null]
	>;
}
const resolveAny: IResponseAny = (prom: Promise<any>) => {
	return new Promise<any>(resolve => {
		prom.then((response: any) =>
			resolve([response, null])
		).catch((err: any) => resolve([null, err]));
	});
};
export default resolveAny;
