<template>
	<div class="group-box">
		<div class="group-box-label">
			<slot name="label" />
		</div>
		<div class="group-box-content">
			<slot />
		</div>
		<div class="group-box-footer">
			<slot name="footer" />
		</div>
	</div>
</template>
<style lang="scss" src="./group-box.scss"></style>
