import RestCompanyFactory from "@app-rest/company";
import type{ ICompanyInfoModel } from "@app-rest/company/interfaces";
import useAppStore from "@app-store/app";
import store from "@app-store/index";
import useMenuItemsStore from "@app-store/menu-items";
import useSystemStore from "@app-store/system";
import EventsManager from "@app-utils/events-manager";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

let _restClient: RestCompanyFactory | null;
function ensureCompanyRestClient() {
	if (_restClient) return _restClient;
	const restClient = _restClient = new RestCompanyFactory();
	return restClient;
}

@Module class CompanyModuleFactory extends VuexModule {
	loading = false;
	company: ICompanyInfoModel | null = null;
	get isMultiSystem() {
		if (!this.company || !this.company.Centers || !this.company.Centers.length) return false;
		return this.company.Centers.length > 1;
	}

	bowlerWebsiteReservationsLink() {
		const system = useSystemStore().info;
		const appStore = useAppStore();
		if (!system || !this.company?.Id) return "";
		const url = appStore.bowlerWebSite;
		return `${url}${!url ? "/" : ""}${this.company?.Id}/${system.Id}/book`;
	}

	@Mutation clearData() {
		this.company = null;
	}

	@Mutation setLoading(state: boolean) {
		this.loading = state;
	}

	@Mutation setCompany(v: ICompanyInfoModel) {
		const changed = v.Id !== this.company?.Id;
		if (changed)
			EventsManager.emitCompanyChanged(v.Id);

		this.company = v;
	}

	@Action async ensureCompanyInfo(companyId: number) {
		if (this.company?.Id === companyId) return;
		const response = await ensureCompanyRestClient().company(companyId);
		if (response.data) this.setCompany(response.data);
		useMenuItemsStore().initMenu();
	}

	@Action getPrivilegesTree() {
		return ensureCompanyRestClient().privilegesTree(this.company?.Id ?? 0);
	}
}

const moduleName = "company";
let CompanyStore: CompanyModuleFactory | null;
function useCompanyStore() {
	if (CompanyStore) return CompanyStore;
	const mod = CompanyStore = new CompanyModuleFactory({ name: moduleName, store });
	return mod;
}

export default useCompanyStore;
