import usei18n from "@app-i18n/index";
import type { QRouteMeta } from "@app-router/index";
import Router from "@app-router/index";
import useCompanyStore from "@app-store/company";
import useGlobalStore from "@app-store/global";
import useSystemStore from "@app-store/system";
import { computed, ref } from "vue";

export function useRouteUtilities() {
	const globalStore = useGlobalStore();
	const companyStore = useCompanyStore();
	const router = ref(Router);
	const route = router.value.currentRoute;
	const { getTranslatedRouteTitle } = usei18n();
	const company = computed(() => {
		return companyStore.company;
	});

	const appLoading = computed(() => {
		return globalStore.appLoading;
	});

	const routeLoading = computed(() => {
		return globalStore.routeLoading;
	});

	const viewLoading = computed(() => {
		return globalStore.viewLoading;
	});

	const routeName = computed(() => {
		return route.name;
	});

	const routeParams = computed(() => {
		return route.params;
	});

	const viewParent = computed(() => {
		return route.meta?.childOf;
	});

	const viewParentTitle = computed(() => {
		const currentRouteParent = (route.meta as QRouteMeta)?.childOf;
		if (!currentRouteParent) return;

		const targetRoute = router.value.getRoutes().find(route => route.name === currentRouteParent);
		if (!targetRoute) return;

		const qRoute = targetRoute;
		return qRoute.meta.title ?? getTranslatedRouteTitle(qRoute);
	});

	const viewTitle = computed(() => {
		return (route.meta.title ?? getTranslatedRouteTitle(route.matched[0])) as string;
	});

	const subtitle = computed(() => {
		const systemStore = useSystemStore();

		if (!companyStore.isMultiSystem || !systemStore.info) return "";
		return systemStore.info.Name;
	});

	return {
		company,
		appLoading,
		routeLoading,
		viewLoading,
		routeName,
		routeParams,
		viewParent,
		viewParentTitle,
		viewTitle,
		subtitle
	};
}
