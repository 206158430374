import type { Functionality } from "@app-rest/company/interfaces";
import useUserStore from "@app-store/user";

export function useSecurityUtilities() {
	const userStore = useUserStore();
	const hasFunctionality = (functionalityKey: Functionality) => {
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const func = privs ? privs[functionalityKey] : null;
		return Boolean(func);
	};

	const hasFunctionalities = (requiredFunctionalityKeys: Functionality[]) => {
		const results = requiredFunctionalityKeys.map(pk => hasFunctionality(pk));
		const userPrivMatches = results.filter(Boolean);
		return requiredFunctionalityKeys.length === userPrivMatches.length;
	};

	const hasFunctionalitiesEnabled = (requiredFunctionalityKeys: Functionality[]) => {
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const results = requiredFunctionalityKeys.map(pk => privs ? privs[pk] : null);
		const userPrivMatches = results.filter(r => r?.Enabled);
		return requiredFunctionalityKeys.length === userPrivMatches.length;
	};

	const hasAtLeastOneFunctionality = (requiredFunctionalityKeys: Functionality[]) => {
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const results = requiredFunctionalityKeys.map(pk => privs ? privs[pk] : null);
		const userPrivMatches = results.filter(Boolean);
		return userPrivMatches.length > 0;
	};

	const hasAtLeastOneFunctionalityEnabled = (requiredFunctionalityKeys: Functionality[]) => {
		const privs = Object.assign({}, userStore.systemFunctionalities(), userStore.companyFunctionalities());
		const results = requiredFunctionalityKeys.map(pk => privs ? privs[pk] : null);
		const userPrivMatches = results.filter(r => r?.Enabled);
		return userPrivMatches.length > 0;
	};

	return {
		hasFunctionalities,
		hasFunctionalitiesEnabled,
		hasAtLeastOneFunctionality,
		hasAtLeastOneFunctionalityEnabled
	};
}
