import { Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

@Module class RestUtilsModelFactory extends VuexModule {
	acceptLanguage = "";

	@Mutation setAcceptLanguage(lang: string) {
		this.acceptLanguage = lang;
	}
}
let _restUtilsStore: RestUtilsModelFactory | undefined;
export default function useRestUtilsStore() {
	if (_restUtilsStore) return _restUtilsStore;
	const restUtils = _restUtilsStore = new RestUtilsModelFactory({ store, name: "rest-utils" });
	return restUtils;
}
