<template>
	<div
		v-if="showAppInfo"
		class="wip-stripes"
	>
		v{{ version }} <i /> Cloud: {{ env }} <i /> Channel: {{ ch }}
	</div>
	<QNavbar
		:hide-toggler="!userIsAuthenticated"
		container="fluid"
	>
		<template
			v-if="userIsAuthenticated"
			#offcanvas
		>
			<Sidebar />
		</template>
		<template #brand-image>
			<span class="logo" />
		</template>
		<template #start>
			<div class="q-breadcrumb">
				<template v-if="breadcrumbRoot">
					<q-link
						class="router-link navbar-brand d-inline-flex"
						:to="{ name: breadcrumbRoot.name, params: breadcrumbRoot.params }"
						:active="breadcrumbRoot.name === routeName"
					>
						{{ getTitle(breadcrumbRoot) }}
					</q-link>
				</template>
				<template v-if="routeData && breadcrumbNoRoot.length">
					<span
						v-for="r in breadcrumbNoRoot"
						:key="r.name"
						class="item"
					>
						<span class="separator"><i class="far fa-angles-right" /></span>
						<q-link
							class="router-link"
							:to="{ name: r.name, params: routeParams }"
							:active="r.name === routeName"
						>
							{{ getTitle(r) }}
						</q-link>
					</span>
				</template>
			</div>
		</template>
		<template #end>
			<div
				v-if="userIsAuthenticated"
				class="context-info-wrapper"
				:class="{ 'show-actions': showActions }"
				@mouseleave="showActionsTooltip(false)"
			>
				<span
					class="help-link"
					title="Help"
					@click.prevent="helpLinkClick"
				><i class="icon-help far fa-fw fa-question-circle" /></span>
				<span
					v-if="userData"
					class="user"
				>
					<span
						class="info"
						@mouseover="showActionsTooltip(true)"
					>
						<i class="icon-user far fa-user-circle" />
						<span>{{ $t("hiTitle") }}, {{ userData.Firstname }}</span>
					</span>
					<div class="actions-wrapper">
						<a
							class="action"
							target="_blank"
							:href="userAccountUri"
						><i class="far fa-fw fa-id-card" />{{ $t("label_personal_data") }}</a>
						<span
							v-b-modal.modal-user-settings
							class="action"
						><i class="far fa-fw fa-user-cog" />{{ $t("label_preferences") }}</span>
						<span
							v-if="canManageRemoteAssistance"
							v-b-modal.modal-remote-assistance
							class="action"
						><i class="far fa-fw fa-mouse-pointer" />{{ $t("remote_assistance_title") }}</span>
						<a
							class="action"
							href="/logout"
						><i class="fas fa-fw fa-sign-out-alt" />{{ $t("label_logout") }}</a>
					</div>
				</span>
				<span
					v-else
					class="user"
				>
					<a
						class="action"
						href="/logout"
					>{{ $t("label_logout") }}</a>
				</span>
			</div>
		</template>
	</QNavbar>
</template>
<script lang="ts" src="./navbar.ts"></script>
<style lang="scss" src="./navbar.scss"></style>
