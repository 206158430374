import CardHeader from "@app-components/card-header/card-header.vue";
import type { QRouteMeta } from "@app-router/index";
import useGlobalStore from "@app-store/global";
import { isAccessGranted } from "@app-utils/router";
import { type BaseButtonVariant } from "@qamf/lighthouse";
import { defineComponent, PropType } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
	name: "AccessDenied",
	components: {
		CardHeader
	},
	props: {
		titleKey: { type: String, default: "accessDeniedTitle" },
		subtitleKey: { type: String, default: "missing_auth" },
		hintTextKey: { type: String, default: "request_auth" },
		showBackButton: { type: Boolean, default: true },
		backButtonVariant: { type: String as PropType<keyof BaseButtonVariant>, default: "outline-secondary" }
	},
	setup() {
		const globalStore = useGlobalStore();
		const router = useRouter();
		const route = useRoute();
		const handleBackButton = async() => {
			let currentChildOf = (route.meta as QRouteMeta).childOf;
			let canGoToParent;
			let parentView = "center-home";
			while (!canGoToParent) {
				const routeParent = globalStore.routes.find((el) => el.name === currentChildOf);
				if (routeParent && routeParent.name !== "center-home") {
					canGoToParent = isAccessGranted(routeParent.meta);
					if (canGoToParent && routeParent.name) parentView = routeParent.name.toString();
					currentChildOf = routeParent.meta.childOf;
				} else break;
			}
			await router.push({
				name: parentView,
				params: route.params,
				query: route.query
			}).catch(() => {});
		};
		return { handleBackButton };
	}
});
