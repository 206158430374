<template>
	<div class="view-walker">
		<div
			v-for="(group, groupName) in linksGrouped"
			:key="groupName"
			class="group-wrapper"
		>
			<span class="group-name">{{ getGroupTitle(group) }}</span>
			<b-button-group
				:vertical="vertical"
				class="walker-group-buttons"
			>
				<NavigationButton
					v-for="route in group"
					:key="route.name"
					:variant="buttonVariant"
					:to="getLocation(route)"
					:disabled="route.meta.disabled"
					:active="routeName === route.name"
				>
					<span class="name">{{ getTitle(route) }}</span>
					<template #status>
						<slot :name="'status-' + route.name?.toString()" />
					</template>
				</NavigationButton>
			</b-button-group>
		</div>
	</div>
</template>

<script lang="ts" src="./view-walker.ts"></script>
<style lang="scss" src="./view-walker.scss"></style>
