<template>
	<QModal
		:id="id"
		ref="modalUserSettings"
		v-model="modalState"
		centered
		hide-footer
		no-close-on-backdrop
		static
		:title="$t('label_preferences')"
		modal-class="modal-user-settings"
		@show="onModalOpen"
		@shown="scrollActive"
	>
		<QSpinner
			v-if="isLoading"
		/>
		<QFormFieldRadio
			v-model="showLanguagesList"
			name="show-languages-list"
			:options="showLanguagesOptions"
			stacked
		>
			{{ $t('label_language') }}
		</QFormFieldRadio>
		<div
			:class="{'notShowLanguagesList overflow-hidden': !showLanguagesList}"
			class="wrap-languages position-relative overflow-auto my-3"
		>
			<div
				v-for="lang in languages"
				:key="lang.code"
				:class="{active : lang.code === selectedLang}"
				class="py-3 px-2"
				@click="showLanguagesList ? selectLang(lang.code) : ''"
			>
				{{ lang.label }}
			</div>
		</div>
		<div class="d-flex justify-content-end">
			<q-button
				:disabled="isLoading"
				@click="saveLanguage"
			>
				{{ $t("button_save") }}
			</q-button>
		</div>
	</QModal>
</template>
<script lang="ts" src="./modal-preferences.ts"></script>
<style lang="scss" src="./modal-preferences.scss"></style>
