const appStorageKey = "qportal_data";
export function getAppStorage<T = any>(moduleName?: "app" | "user"): T | undefined {
	const store = JSON.parse(localStorage.getItem(appStorageKey) ?? "{}");
	if (!moduleName) return store;
	if (!store[moduleName]) return undefined;
	return store[moduleName];
}
export function writeAppStorage(obj: object) {
	localStorage.setItem(appStorageKey, JSON.stringify(obj));
}
export function clearAppStorage() {
	localStorage.removeItem(appStorageKey);
}
