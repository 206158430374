import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";
import SecurityPlugin from "@app-utils/security";

import { settingsGraphicThemes } from ".";

const settingsGraphicThemesAssign = "graphic-themes-assign";
export const settingsGraphicThemesManage = "graphic-themes-manage";
export const settingsGraphicThemesDetail = "graphic-themes-manage-theme-theme";
export const settingsGraphicThemesName = "graphic-themes-manage-theme-name";
export const settingsGraphicThemesFonts = "graphic-themes-manage-theme-fonts";
export const settingsGraphicThemesColors = "graphic-themes-manage-theme-colors";
export const settingsGraphicThemesWebsite = "graphic-themes-manage-theme-website";
export const settingsGraphicThemesKiosk = "graphic-themes-manage-theme-kiosk";
export const settingsGraphicThemesEmail = "graphic-themes-manage-theme-email";
export const settingsGraphicThemesPDF = "graphic-themes-manage-theme-confirmation-pdf";

export default [
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage",
		name: settingsGraphicThemesManage,
		component: () => import("@app-views/settings/graphic-themes/manage/manage-themes.vue"),
		meta: {
			titleKey: "settings_graphic_themes_manage",
			childOf: settingsGraphicThemes
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/assign",
		name: settingsGraphicThemesAssign,
		component: () => import("@app-views/settings/graphic-themes/assign/assign-themes.vue"),
		meta: {
			titleKey: "settings_graphic_themes_assign",
			childOf: settingsGraphicThemes
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name",
		name: settingsGraphicThemesDetail,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/theme.vue"),
		meta: {
			childOf: settingsGraphicThemesManage
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/name",
		name: settingsGraphicThemesName,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/name/name.vue"),
		meta: {
			titleKey: "label_name",
			childOf: settingsGraphicThemesDetail
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/fonts",
		name: settingsGraphicThemesFonts,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/fonts/fonts.vue"),
		meta: {
			titleKey: "label_fonts",
			childOf: settingsGraphicThemesDetail,
			groupName: "fonts"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/colors",
		name: settingsGraphicThemesColors,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/colors/colors.vue"),
		meta: {
			titleKey: "label_colors",
			childOf: settingsGraphicThemesDetail,
			groupName: "colors"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/colors/website",
		name: settingsGraphicThemesWebsite,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/website/website.vue"),
		meta: {
			titleKey: "settings_website",
			childOf: settingsGraphicThemesColors,
			groupName: "primary"
		},
		showIf: () => SecurityPlugin.hasFunctionalitiesEnabled([
			Functionalities.WebReservations
		])
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/colors/kiosk",
		name: settingsGraphicThemesKiosk,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/kiosk/kiosk.vue"),
		meta: {
			titleKey: "label_kiosk",
			childOf: settingsGraphicThemesColors,
			groupName: "primary"
		},
		showIf: () => SecurityPlugin.hasFunctionalitiesEnabled([
			Functionalities.Kiosks
		])
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/colors/email",
		name: settingsGraphicThemesEmail,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/document/document.vue"),
		meta: {
			titleKey: "label_email",
			childOf: settingsGraphicThemesColors,
			groupName: "secondary"
		},
		showIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
			Functionalities.WebReservations,
			Functionalities.Kiosks
		])
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes/manage/:name/colors/confirmation-pdf",
		name: settingsGraphicThemesPDF,
		component: () => import("@app-views/settings/graphic-themes/manage/theme/document/document.vue"),
		meta: {
			titleKey: "label_confirmation_pdf",
			childOf: settingsGraphicThemesColors,
			groupName: "secondary"
		},
		showIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
			Functionalities.WebReservations,
			Functionalities.Kiosks
		])
	}
] as QRoute[];
