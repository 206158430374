<template>
	<div
		class="q-spinner"
		:size="size"
		:class="{
			'inline': inline,
			'disabled' : disabled
		}"
	>
		<div
			class="spinner-element"
			:theme="theme"
		>
			<div
				v-if="theme === 'dotted'"
				class="circles-wrapper"
			>
				<div class="qs-circle1 qs-child" />
				<div class="qs-circle2 qs-child" />
				<div class="qs-circle3 qs-child" />
				<div class="qs-circle4 qs-child" />
				<div class="qs-circle5 qs-child" />
				<div class="qs-circle6 qs-child" />
				<div class="qs-circle7 qs-child" />
				<div class="qs-circle8 qs-child" />
				<div class="qs-circle9 qs-child" />
				<div class="qs-circle10 qs-child" />
				<div class="qs-circle11 qs-child" />
				<div class="qs-circle12 qs-child" />
			</div>
		</div>
	</div>
</template>
<script lang="ts" src="./q-spinner.ts"></script>
<style lang="scss" src="./q-spinner.scss"></style>
