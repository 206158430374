import type { IResponseError } from "@app-rest/common.interfaces";
import PortalRestClientFactory from "@app-rest/index";

import type { IClaim, ICompanyUserActivationStatusModel, IToken, IUser, IUserSettings } from "./user.interfaces";

export default class RestUserFactory extends PortalRestClientFactory {
	info() {
		return this.get<IUser>("/api-user/info", { basePath: "/", throw: false });
	}

	settings() {
		return this.get<IUserSettings>("/user-settings", { throw: false });
	}

	settingsUpdate(data: IUserSettings) {
		return this.put<IUserSettings>("/user-settings", { body: data });
	}

	getToken() {
		return this.get<IToken>("/api-user/tokens", { basePath: "/", throw: false });
	}

	refreshToken() {
		return this.post<IToken>("/api-user/tokens", { basePath: "/", throw: false });
	}

	getClaims() {
		return this.get<IClaim[]>("/api-user/claims", { basePath: "/", throw: false });
	}

	activationUser(companyId: number, userId: number, activationCode: string) {
		return this.get<ICompanyUserActivationStatusModel>(`/api/user-activations/${companyId}/${userId}/${activationCode}`, {
			basePath: "/"
		});
	}

	finalizeActivationUser(companyId: number, userId: number, activationCode: string) {
		return this.post<null, IResponseError<string>>(`/api/user-activations/${companyId}/${userId}/${activationCode}`,
			{
				basePath: "/",
				// FIXME: Check throwExcluding without Error Codes - TechStory 42933
				throwExcluding: [{ statusCode: 400 }]
			}
		);
	}
}
