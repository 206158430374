import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";
import SecurityPlugin from "@app-utils/security";

export const invoicesRoute = "invoices";
export const programsLicenseRoute = "programs-licenses";

export default [
	{
		path: "/companies/:companyId(\\d+)/programs-licenses",
		name: programsLicenseRoute,
		component: () =>
			import("@app-views/cmp/list/list.vue"),
		meta: {
			titleKey: "programsLicensesTitle",
			childOf: "company",
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.AccessToCmp,
				Functionalities.AccessToInvoices,
				Functionalities.ManageCmpSubscription
			]),
			helpSiteAnchor: "management-configurations/programs-licences"
		}
	},
	{
		path: "/companies/:companyId(\\d+)/invoices",
		name: invoicesRoute,
		component: () =>
			import("@app-views/cmp/list/list.vue"),
		meta: {
			titleKey: "programsLicensesTitle",
			childOf: "company",
			accessIf: [Functionalities.AccessToInvoices],
			helpSiteAnchor: "management-configurations/programs-licences"
		}
	},
	{
		path: "/:centerId(\\d{4,})/cmp",
		name: "cmp",
		component: () => import("@app-views/cmp/detail/detail.vue"),
		meta: {
			titleKey: "cmpProgramsServicesTitle",
			childOf: "center-home",
			inheritShowAccessIfs: false,
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.AccessToCmp,
				Functionalities.ManageCmpSubscription
			]),
			helpSiteAnchor: "management-configurations/programs-licences"
		}
	},
	{
		path: "/:centerId(\\d{4,})/cmp/payment/:mode?",
		name: "cmp-payment",
		component: () => import("@app-views/cmp/payment/payment.vue"),
		meta: {
			titleKey: "cmpPaymentServicesTitle",
			childOf: "cmp",
			inheritShowAccessIfs: false,
			accessIf: [Functionalities.ManageCmpSubscription],
			helpSiteAnchor: "management-configurations/programs-licences"
		}
	},
	{
		path: "/:centerId(\\d{4,})/cmp/management",
		name: "cmp-management",
		component: () => import("@app-views/cmp/landing/landing.vue"),
		meta: {
			childOf: "cmp",
			inheritShowAccessIfs: false,
			accessIf: [Functionalities.ManageCmpSubscription]
		}
	},
	{
		path: "/:centerId(\\d{4,})/cmp/unsubscribe",
		name: "cmp-unsubscribe",
		component: () => import("@app-views/cmp/landing/landing.vue"),
		meta: {
			childOf: "cmp",
			inheritShowAccessIfs: false,
			accessIf: [Functionalities.ManageCmpSubscription]
		}
	},
	{
		path: "/:centerId(\\d{4,})/cmp/extend",
		name: "cmp-extend",
		component: () => import("@app-views/cmp/landing/landing.vue"),
		meta: {
			childOf: "cmp",
			inheritShowAccessIfs: false,
			accessIf: [Functionalities.ManageCmpSubscription]
		}
	},
	{
		path: "/:centerId(\\d{4,})/cmp/rejoin",
		name: "cmp-rejoin",
		component: () => import("@app-views/cmp/landing/landing.vue"),
		meta: {
			childOf: "cmp",
			inheritShowAccessIfs: false,
			accessIf: [Functionalities.ManageCmpSubscription]
		}
	}
] as QRoute[];
