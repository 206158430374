import PortalRestClientFactory from "@app-rest/index";

import type { ICenterBackup, ICenterBackupDetails } from "./onlineBackups.interfaces";

export class OnlineBackupsRestFactory extends PortalRestClientFactory {
	constructor(systemId?: number) {
		super();
		this.options.set("basePath", `/api/${systemId}`);
	}

	backups() {
		return this.get<ICenterBackup[]>("backups");
	}

	backupDetails(name: string) {
		return this.get<ICenterBackupDetails>(`backups/${name}`);
	}
}
