import FakeInput from "@app-components/fake-input/fake-input.vue";
import QFormFieldSelect from "@app-components/q-form-field-select/q-form-field-select.vue";
import clickAway from "@app-directives/click-away";
import {
	dateKey,
	endOfWeek,
	format,
	IMonthOption,
	startOfWeek
} from "@app-utils/dates";
import { BaseButtonVariant, PopoverPlacement, QFormFieldWrapper, QPopover } from "@qamf/lighthouse";
import {
	addDays,
	addMonths,
	addYears,
	eachMonthOfInterval,
	eachYearOfInterval,
	endOfMonth,
	endOfYear,
	getMonth,
	getYear,
	isAfter,
	isBefore,
	isSameDay,
	isSaturday,
	isSunday,
	isToday,
	isValid,
	lastDayOfMonth,
	set,
	startOfDay,
	startOfMonth,
	startOfYear,
	subMonths,
	subYears
} from "date-fns";
import { flow, map, uniqBy } from "lodash-es";
import {
	computed,
	defineComponent,
	nextTick,
	onMounted,
	onUnmounted,
	PropType,
	reactive,
	ref,
	watch
} from "vue";

interface PickerButton {
	value: Date;
	dateKey: string;
	dayOfWeek: number;
	text: string;
	disabled: boolean;
	isToday: boolean;
	isSaturday: boolean;
	isSunday: boolean;
}

const pickerProvider = new Map<string, typeof QPopover>();
const datesMatrixProvider = new Map<string, PickerButton[]>();

export default defineComponent({
	name: "QFormFieldDate",
	components: {
		QPopover,
		QFormFieldSelect,
		QFormFieldWrapper,
		FakeInput
	},
	directives: { clickAway },
	props: {
		modelValue: { type: Date, default: undefined },
		rules: { type: String, default: null },
		errorMessage: { type: String, default: "" },
		hideValidation: { type: Boolean, default: false },
		hideValidationMessages: { type: Boolean, default: false },
		name: { type: String, default: null, required: true },
		spinnerSize: { type: String, default: "md" },
		min: { type: Date, default: null },
		max: { type: Date, default: null },
		maxDateIncluded: { type: Boolean, default: true },
		showControlsHeader: { type: Boolean, default: false },
		format: { type: String, default: "P" },
		loading: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		variant: { type: String as PropType<keyof BaseButtonVariant>, default: "date-pick" },
		buttonVariant: { type: String as PropType<keyof BaseButtonVariant>, default: "date-nav" },
		size: {
			type: String,
			default: "md",
			validator: (value: string) => ["xs", "sm", "md", "lg", "xl"].indexOf(value) >= 0
		},
		placement: {
			type: String as PropType<PopoverPlacement>,
			default: "bottom",
			validator: (value: string) =>
				[
					"top",
					"bottom",
					"right",
					"left",
					"auto",
					"top-start",
					"top-left",
					"bottom-start",
					"bottom-end",
					"right-start",
					"right-end",
					"left-start",
					"left-end",
					"auto-start",
					"auto-end"
				].indexOf(value) >= 0
		},
		teleportTo: { type: String, default: "body" },
		class: { type: String, default: null }
	},
	emits: ["update:modelValue", "navigation"],
	setup(props, { emit }) {
		const bPopover = ref<typeof QPopover | null>();
		const inputElement = ref<HTMLElement | null>();
		const isPopoverVisible = ref(false);
		const selectedDate = computed({
			get: () => props.modelValue,
			set: (value) => emit("update:modelValue", value)
		});
		const helpers = reactive({
			loading: false,
			pickerKey: "",
			datePage: startOfMonth(new Date()),
			dates: [] as PickerButton[],
			datesFirstWeek: [] as PickerButton[],
			listOfMonthsOptions: [] as IMonthOption[],
			listOfYearsOptions: [] as number[],
			monthSelected: 0,
			yearSelected: getYear(new Date())
		});
		const classProp = computed(() => {
			return props.class;
		});
		const inputValue = computed(() => {
			return selectedDate.value && isValid(selectedDate.value)
				? format(selectedDate.value, props.format)
				: "";
		});
		const pageHeaderLabel = computed(() => {
			return format(helpers.datePage, "LLLL yyyy");
		});
		const dayOfWeekLabel = (date: Date) => {
			return format(date, "EEE");
		};
		const submitModel = (date: Date | undefined = undefined) => {
			selectedDate.value = date;
		};
		const syncControlsWithModel = () => {
			if (!props.showControlsHeader || !selectedDate.value) return;
			helpers.yearSelected = getYear(selectedDate.value);
			helpers.monthSelected = getMonth(selectedDate.value);
		};
		const getMonthsOptions = () => {
			const startRange = startOfYear(
				set(new Date(), { year: helpers.yearSelected })
			);
			const start = props.min
				? new Date(Math.max(+startRange, +props.min))
				: startRange;
			const endRange = endOfYear(
				set(new Date(), { year: helpers.yearSelected })
			);
			const end = props.max
				? new Date(Math.min(+endRange, +props.max))
				: endRange;
			let range: Date[] = [];
			if (
				isBefore(end, start)
				|| (props.max && selectedDate.value && isBefore(props.max, selectedDate.value))
				|| (props.min && selectedDate.value && isBefore(selectedDate.value, props.min))
			) {
				const today = new Date();
				range = eachMonthOfInterval({
					start: startOfYear(today),
					end: endOfYear(today)
				});
			} else range = eachMonthOfInterval({ start, end });
			const months = flow(
				(dates) =>
					map(dates, (el) => {
						const option: IMonthOption = {
							name: format(el, "LLLL"),
							value: getMonth(el)
						};
						return option;
					}),
				(options) => uniqBy(options, "value")
			)(range);
			return months;
		};
		const getYearsOptions = () => {
			return eachYearOfInterval({
				start: props.min ?? subYears(new Date(), 10),
				end: props.max ?? addYears(new Date(), 10)
			}).reverse().map((el) => Number(format(el, "uu")));
		};
		const onControlsChanged = () => {
			helpers.datePage = set(new Date(), {
				month: helpers.monthSelected,
				year: helpers.yearSelected
			});
		};
		watch(
			() => [helpers.monthSelected, helpers.yearSelected],
			() => onControlsChanged()
		);
		const isDateSelectable = (datetime: Date) => {
			if (props.min && props.max && props.maxDateIncluded) {
				return (
					(isSameDay(datetime, props.min) || isAfter(datetime, props.min))
					&& (isSameDay(datetime, props.max) || isBefore(datetime, props.max))
				);
			}
			if (props.min)
				return isSameDay(datetime, props.min) || isAfter(datetime, props.min);
			if (props.max) {
				return props.maxDateIncluded
					? isSameDay(datetime, props.max) || isBefore(datetime, props.max)
					: isBefore(datetime, props.max);
			}
			return true;
		};
		const isSelected = (date: Date) => {
			return selectedDate.value ? isSameDay(date, selectedDate.value) : false;
		};

		const onClickWrapper = async() => {
			if (props.disabled) return;
			await nextTick();
			if (!pickerProvider.has(helpers.pickerKey) && bPopover.value)
				pickerProvider.set(helpers.pickerKey, bPopover.value);
			pickerProvider.forEach((popover: typeof QPopover) => {
				if (popover !== bPopover.value)
					popover.hide();
			});
			if (bPopover.value) isPopoverVisible.value = true;
		};

		const onClickOutside = () => {
			if (!bPopover.value) return;
			isPopoverVisible.value = false;
		};
		const goPrevMonth = () => {
			helpers.datePage = subMonths(helpers.datePage, 1);
		};
		const goNextMonth = () => {
			helpers.datePage = addMonths(helpers.datePage, 1);
		};
		const isPrevMonthSelectable = computed(() => {
			const prev = lastDayOfMonth(subMonths(helpers.datePage, 1));
			return isDateSelectable(prev);
		});
		const isNextMonthSelectable = computed(() => {
			const next = startOfMonth(addMonths(helpers.datePage, 1));
			return isDateSelectable(next);
		});
		const updateControlsData = () => {
			if (!props.showControlsHeader) return;
			helpers.listOfYearsOptions = getYearsOptions();
			helpers.yearSelected = getYear(helpers.datePage);
			helpers.listOfMonthsOptions = getMonthsOptions();
			const month = getMonth(helpers.datePage);
			const monthOption = helpers.listOfMonthsOptions.find(
				(o) => o.value === month
			);
			helpers.monthSelected = monthOption
				? month
				: helpers.listOfMonthsOptions[0].value;
		};
		const onNavigationPageChanged = () => {
			helpers.loading = true;
			const key = dateKey(helpers.datePage);
			const monthStart = startOfMonth(helpers.datePage);
			const monthEnd = endOfMonth(helpers.datePage);
			let datesMatrix = datesMatrixProvider.get(key);
			if (!datesMatrix) {
				datesMatrix = [];
				const from = startOfDay(startOfWeek(startOfMonth(helpers.datePage)));
				const to = startOfDay(endOfWeek(endOfMonth(helpers.datePage)));
				let curr = new Date(from);
				do {
					const isOut = curr < monthStart || curr > monthEnd;
					datesMatrix.push({
						value: new Date(curr),
						dateKey: dateKey(curr),
						dayOfWeek: curr.getDay(),
						text: curr.getDate() + "",
						disabled: isOut ? true : !isDateSelectable(curr),
						isToday: isToday(curr),
						isSaturday: isSaturday(curr),
						isSunday: isSunday(curr)
					});
					curr = addDays(curr, 1);
				} while (curr <= to);
				datesMatrixProvider.set(key, datesMatrix);
			}
			helpers.dates = datesMatrix;
			helpers.datesFirstWeek = datesMatrix.slice(0, 7);
			updateControlsData();
			helpers.loading = false;
			emit("navigation", helpers.datePage);
		};
		watch(
			() => [helpers.datePage, props.min, props.max],
			() => onNavigationPageChanged()
		);
		const syncDatePage = () => {
			helpers.datePage = selectedDate.value
				? new Date(selectedDate.value)
				: startOfMonth(new Date());
			onNavigationPageChanged();
			syncControlsWithModel();
		};
		watch(selectedDate, () =>
			syncDatePage()
		);
		const onSelected = (date: Date) => {
			submitModel(date);
			onClickOutside();
		};
		onMounted(() => {
			helpers.pickerKey = +new Date() + "_" + Math.random() * 1000;
			syncDatePage();
			updateControlsData();
			syncControlsWithModel();
		});
		onUnmounted(() => {
			datesMatrixProvider.clear();
			pickerProvider.delete(helpers.pickerKey);
		});
		return {
			bPopover,
			helpers,
			dayOfWeekLabel,
			inputValue,
			pageHeaderLabel,
			isSelected,
			onClickOutside,
			goPrevMonth,
			goNextMonth,
			isPrevMonthSelectable,
			isNextMonthSelectable,
			onSelected,
			inputElement,
			isPopoverVisible,
			selectedDate,
			onClickWrapper,
			classProp
		};
	}
});
