import type { QRoute } from "@app-router/index";

export default [
	{
		path: "/",
		name: "root",
		meta: { title: "QPortal" }
	},
	{
		path: "/select-center",
		name: "select-center",
		component: () => import("@app-views/select-center/select-center.vue"),
		meta: {
			titleKey: "com_select_center",
			helpSiteAnchor: "select-center"
		}
	},
	{
		path: "/public/empty",
		name: "public-empty",
		component: () => import("@app-views/public-empty.vue"),
		meta: { allowAnonymous: true }
	},
	{
		path: "/access-denied",
		name: "access-denied",
		component: () => import("@app-views/access-denied/access-denied.vue"),
		meta: {
			titleKey: "accessDeniedTitle",
			allowAnonymous: true
		}
	},
	{
		path: "/public/access-denied",
		name: "public-access-denied",
		component: () => import("@app-views/access-denied/access-denied.vue"),
		meta: {
			titleKey: "accessDeniedTitle",
			allowAnonymous: true
		}
	},
	{
		path: "/public/error-page",
		name: "error-page",
		component: () => import("@app-views/error-page/error-page.vue"),
		meta: {
			titleKey: "errorPageTitle",
			allowAnonymous: true
		}
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/not-found"
	},
	{
		path: "/not-found",
		name: "not-found",
		component: () => import("@app-views/not-found/not-found.vue"),
		meta: {
			titleKey: "not_found_title",
			allowAnonymous: true
		}
	},
	{
		path: "/session-info",
		name: "session-info",
		component: () => import("@app-views/session-info/session-info.vue"),
		meta: {
			titleKey: "openid_token_page"
		}
	},
	{
		path: "/style-guide",
		name: "style-guide",
		component: () => import("@app-views/style-guide/style-guide.vue"),
		meta: {
			titleKey: "styleguideTitle"
		}
	}
] as QRoute[];
