import Layout from "@app-components/layout.vue";
import QOverlay from "@app-components/q-overlay/q-overlay.vue";
import QSpinner from "@app-components/q-spinner/q-spinner.vue";
import ViewWalker from "@app-components/view-walker/view-walker.vue";
import ViewWrapper from "@app-components/view-wrapper/view-wrapper.vue";
import i18nPlugin from "@app-i18n/plugin";
import router, { Routes } from "@app-router/.";
import useGlobalStore from "@app-store/global";
import store from "@app-store/index";
import Security from "@app-utils/security";
import SettingsCardHeader from "@app-views/settings/components/settings-card-header/settings-card-header.vue";
import { createDsy, QButton } from "@qamf/lighthouse";
import createBootstrap from "bootstrap-vue-next";
import { createApp } from "vue";

import AppEntryPoint from "./app.vue";
import boot from "./boot";

const QPortalApp = createApp(AppEntryPoint);

Routes.forEach(el => router.addRoute(el));
useGlobalStore().setRoutes(Routes);

if (!window.structuredClone)
	window.structuredClone = (value: any) => JSON.parse(JSON.stringify(value));

window.addEventListener("load", async() => {
	QPortalApp.component("QSpinner", QSpinner);
	QPortalApp.component("QOverlay", QOverlay);
	QPortalApp.component("Layout", Layout);
	QPortalApp.component("ViewWrapper", ViewWrapper);
	QPortalApp.component("SettingsCardHeader", SettingsCardHeader);
	QPortalApp.component("ViewWalker", ViewWalker);
	QPortalApp.component("QButton", QButton);
	await boot();
	QPortalApp.use(router);
	QPortalApp.use(store);
	QPortalApp.use(createBootstrap({
		directives: {
			all: false,
			vBToggle: true,
			vBModal: true,
			vBTooltip: true
		},
		components: {
			all: false,
			BCard: true,
			BModal: true
		}
	}));
	QPortalApp.use(createDsy({
		plugins: {
			modalManager: true,
			modalController: true,
			toast: true
		}
	}));
	QPortalApp.use(Security);
	QPortalApp.use(i18nPlugin);
	QPortalApp.mount("#app-main");
});

export default QPortalApp;
