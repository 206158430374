import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";

import { settingsKiosks } from ".";

export const settingsKioskStationsList = "kiosks-stations";
export const settingsKioskStationDetails = "kiosks-stations-details";
export const settingsKioskStationDetailsServices = "kiosks-stations-details-services";
export const settingsKioskStationDetailsPinPad = "kiosks-stations-details-pinpad";
export const settingsKioskTextsAndImages = "kiosk-texts";
const settingsKioskTextsAndImagesPdf = "kiosk-texts-pdf";
const settingsKioskTextsAndImagesEmail = "kiosk-texts-email";
export const settingsKioskTextsAndImagesCommonParts = "kiosk-text-common-parts";
const settingsKioskTextsAndImagesMainPageTiles = "main-page-tiles";
export const settingsKioskTextsAndImagesMainPage = "main-page";
export const settingsKioskTextsAndImagesTilePlayNow = "kiosk-text-tile-play-now";
export const settingsKioskTextsAndImagesTileCheckIn = "kiosk-text-tile-check-in";
export const settingsKioskTextsAndImagesTileMenu = "kiosk-text-tile-menu";
export const settingsKioskTextsAndImagesTileWaitingList = "kiosk-text-tile-waiting-list";
export const settingsKioskTextsAndImagesPlayNow = "kiosk-text-play-now";
export const settingsKioskTextsAndImagesYourReservation = "kiosk-text-your-reservation";
export const settingsKioskTextsAndImagesYourReservationFind = "kiosk-text-your-reservation-find";
export const settingsKioskTextsAndImagesYourReservationBowlerLogged = "kiosk-text-your-reservation-bowler-logged";
export const settingsKioskTextsAndImagesYourReservationBowlerGuest = "kiosk-text-your-reservation-bowler-guest";
export const settingsKioskTextsAndImagesYourReservationDetails = "kiosk-text-your-reservation-details";
export const settingsKioskTextsAndImagesYourReservationThankYou = "kiosk-text-your-reservation-thank-you-page";
export const settingsKioskTextsAndImagesYourReservationInstructions = "kiosk-text-your-reservation-instructions";
export const settingsKioskTextsAndImagesYourReservationInstructionsCheckIn = "kiosk-text-your-reservation-instructions-check-in";
export const settingsKioskTextsAndImagesYourReservationInstructionsGoToFrontDesk = "kiosk-text-your-reservation-instructions-go-to-front-desk";
export const settingsKioskTextsAndImagesYourReservationInstructionsGoToLane = "kiosk-text-your-reservation-instructions-go-to-lane";
export const settingsKioskTextsAndImagesMenu = "kiosk-text-menu";
export const settingsKioskTextsAndImagesMenuDeliveryPickup = "kiosk-text-menu-delivery-pickup";
export const settingsKioskTextsAndImagesMenuDeliveryAreasTables = "kiosk-text-menu-delivery-areas-tables";
export const settingsKioskTextsAndImagesMenuCategoriesItems = "kiosk-text-menu-categories-items";
export const settingsKioskTextsAndImagesMenuReviewOrder = "kiosk-text-menu-review-order";
export const settingsKioskTextsAndImagesMenuContactInfo = "kiosk-text-menu-contact-info";
export const settingsKioskTextsAndImagesMenuTips = "kiosk-text-menu-tips";
const settingsKioskTextsAndImagesMenuThankYou = "kiosk-text-menu-thank-you";
export const settingsKioskTextsAndImagesMenuPickupThankYou = "kiosk-text-menu-pickup-thank-you";
export const settingsKioskTextsAndImagesMenuDeliveryThankYou = "kiosk-text-menu-delivery-thank-you";
export const settingsKioskTextsAndImagesMenuPickupThankYouOrderUpdate = "kiosk-text-menu-pickup-thank-you-order-update";
export const settingsKioskTextsAndImagesMenuDeliveryThankYouOrderUpdate = "kiosk-text-menu-delivery-thank-you-order-update";
export const settingsKioskTextsAndImagesWaitingList = "kiosk-text-waiting-list";
export const settingsKioskTextsAndImagesWaitingListPlayers = "kiosk-text-waiting-list-players";
export const settingsKioskTextsAndImagesWaitingListPlayingOptions = "kiosk-text-waiting-list-playing-options";
export const settingsKioskTextsAndImagesWaitingListContactInfo = "kiosk-text-waiting-list-contact-info";
export const settingsKioskTextsAndImagesWaitingListThankyou = "kiosk-text-waiting-list-thankyou";
export const settingsKioskTextsAndImagesWaitingListInstructions = "kiosk-text-waiting-list-instructions";
export const settingsKioskTextsAndImagesPlayNowPlayers = "kiosk-text-play-now-players";
export const settingsKioskTextsAndImagesPlayNowOffers = "kiosk-text-play-now-offers";
export const settingsKioskTextsAndImagesPlayNowShoes = "kiosk-text-play-now-shoes";
export const settingsKioskTextsAndImagesPlayNowGames = "kiosk-text-play-now-games";
export const settingsKioskTextsAndImagesPlayNowFood = "kiosk-text-play-now-food";
export const settingsKioskTextsAndImagesPlayNowOrder = "kiosk-text-play-now-order";
export const settingsKioskTextsAndImagesPlayNowPay = "kiosk-text-play-now-pay";
export const settingsKioskTextsAndImagesPlayNowTips = "kiosk-text-play-now-tips";
export const settingsKioskTextsAndImagesPlayNowThankYou = "kiosk-text-play-now-thank-you-page";
const settingsKioskTextsAndImagesPlayNowInstructions = "kiosk-text-play-now-instructions";
export const settingsKioskTextsAndImagesPlayNowInstructionsFrontDesk = "kiosk-text-play-now-instructions-go-frontdesk";
export const settingsKioskTextsAndImagesPlayNowInstructionsFrontDeskLater = "kiosk-text-play-now-instructions-go-frontdesk-later";
export const settingsKioskTextsAndImagesPlayNowInstructionsStraightToLane = "kiosk-text-play-now-instructions-go-straight-to-lane";
export const settingsKioskTextsAndImagesUnavailableServices = "unavailable-services";
const settingsKioskUnlinkCpu = "kiosks-unlink-cpu";
const settingsKioskPlaylists = "kiosks-playlists";
const settingsKioskManagePlaylists = "kiosks-playlists-manage";
const settingsKioskAssignPlaylists = "kiosks-playlists-assign";
export const settingsKioskManagePlaylistDetail = "kiosks-playlists-manage-detail";
const settingsKioskTextsAndImagesHeaderLogoBackground = "kiosk-header-logo-background";

export default [
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/stations",
		name: settingsKioskStationsList,
		component: () => import("@app-views/settings/kiosk/stations/stations.vue"),
		meta: {
			titleKey: "settings_kiosk_stations",
			childOf: settingsKiosks,
			accessIf: [Functionalities.DefineKioskStations]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/stations/:id(\\d+)",
		name: settingsKioskStationDetails,
		component: () => import("@app-views/settings/kiosk/stations/station/station.vue"),
		meta: {
			childOf: settingsKioskStationsList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/stations/:id(\\d+)/services",
		name: settingsKioskStationDetailsServices,
		component: () => import("@app-views/settings/kiosk/stations/station/services/services.vue"),
		meta: {
			childOf: settingsKioskStationDetails
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/stations/:id(\\d+)/pin-pad",
		name: settingsKioskStationDetailsPinPad,
		component: () => import("@app-views/settings/kiosk/stations/station/station-pinpad.vue"),
		meta: {
			childOf: settingsKioskStationDetails
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images",
		name: settingsKioskTextsAndImages,
		component: () => import("@app-views/settings/kiosk/texts-and-images/texts-and-images.vue"),
		meta: {
			titleKey: "settings_kiosk_section_title_texts_and_images",
			childOf: settingsKiosks,
			accessIf: [Functionalities.DefineKioskTextAndImages]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/common-parts",
		name: settingsKioskTextsAndImagesCommonParts,
		component: () => import("@app-views/settings/kiosk/texts-and-images/texts-and-images.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_common_parts",
			childOf: settingsKioskTextsAndImages
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/common-parts/header-logo-background",
		name: settingsKioskTextsAndImagesHeaderLogoBackground,
		component: () => import("@app-views/settings/kiosk/texts-and-images/common-parts/header-logo-background.vue"),
		meta: {
			childOf: settingsKioskTextsAndImagesCommonParts,
			titleKey: "settings_kiosk_common_parts_header_logo_background"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/common-parts/messages-kiosk-disabled",
		name: settingsKioskTextsAndImagesUnavailableServices,
		component: () => import("@app-views/settings/kiosk/texts-and-images/common-parts/common-parts.vue"),
		meta: {
			groupName: "messages",
			childOf: settingsKioskTextsAndImagesCommonParts,
			titleKey: "settings_kiosk_common_parts_messages_disabled"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/main-page",
		name: settingsKioskTextsAndImagesMainPageTiles,
		component: () => import("@app-views/settings/kiosk/texts-and-images/texts-and-images.vue"),
		meta: {
			titleKey: "label_mainpage",
			childOf: settingsKioskTextsAndImages
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/main-page/welcome-message",
		name: settingsKioskTextsAndImagesMainPage,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_main_page_welcome_message",
			childOf: settingsKioskTextsAndImagesMainPageTiles
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/main-page/tile-play-now",
		name: settingsKioskTextsAndImagesTilePlayNow,
		component: () => import("@app-views/settings/kiosk/texts-and-images/main-page/tiles.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_main_page_tile_playnow",
			childOf: settingsKioskTextsAndImagesMainPageTiles
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/main-page/tile-check-in",
		name: settingsKioskTextsAndImagesTileCheckIn,
		component: () => import("@app-views/settings/kiosk/texts-and-images/main-page/tiles.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_main_page_tile_checkin",
			childOf: settingsKioskTextsAndImagesMainPageTiles
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/main-page/tile-menu",
		name: settingsKioskTextsAndImagesTileMenu,
		component: () => import("@app-views/settings/kiosk/texts-and-images/main-page/tiles.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_main_page_tile_menu",
			childOf: settingsKioskTextsAndImagesMainPageTiles,
			showIf: [Functionalities.DeliveryAndPickup],
			accessIf: [Functionalities.DeliveryAndPickup]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/main-page/tile-waiting-list",
		name: settingsKioskTextsAndImagesTileWaitingList,
		component: () => import("@app-views/settings/kiosk/texts-and-images/main-page/tiles.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_main_page_tile_waiting_list",
			childOf: settingsKioskTextsAndImagesMainPageTiles,
			showIf: [Functionalities.KioskBowlingWaitingList],
			accessIf: [Functionalities.KioskBowlingWaitingList]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now",
		name: settingsKioskTextsAndImagesPlayNow,
		component: () => import("@app-views/settings/kiosk/texts-and-images/play-now/play-now.vue"),
		meta: {
			titleKey: "label_play_now",
			childOf: settingsKioskTextsAndImages
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/players",
		name: settingsKioskTextsAndImagesPlayNowPlayers,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_players",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/offers",
		name: settingsKioskTextsAndImagesPlayNowOffers,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_offers",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/shoes-and-socks",
		name: settingsKioskTextsAndImagesPlayNowShoes,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_shoes_socks",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/games-and-extras",
		name: settingsKioskTextsAndImagesPlayNowGames,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_games_extras",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/food-and-beverage",
		name: settingsKioskTextsAndImagesPlayNowFood,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_food_beverage",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/your-order",
		name: settingsKioskTextsAndImagesPlayNowOrder,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_your_order",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/pay",
		name: settingsKioskTextsAndImagesPlayNowPay,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "common_finalize_your_order",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/tips",
		name: settingsKioskTextsAndImagesPlayNowTips,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_tips_payments",
			childOf: settingsKioskTextsAndImagesPlayNow,
			showIf: [Functionalities.RealIncomePlayNow],
			accessIf: [Functionalities.RealIncomePlayNow]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/thank-you",
		name: settingsKioskTextsAndImagesPlayNowThankYou,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_thank_you",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/instructions",
		name: settingsKioskTextsAndImagesPlayNowInstructions,
		component: () => import("@app-views/settings/kiosk/texts-and-images/play-now/play-now.vue"),
		meta: {
			groupName: "instructions",
			titleKey: "settings_kiosk_play_now_instructions",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/instructions/go-frontdesk",
		name: settingsKioskTextsAndImagesPlayNowInstructionsFrontDesk,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_play_now_instructions_go_frontdesk_now",
			childOf: settingsKioskTextsAndImagesPlayNowInstructions
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/instructions/go-frontdesk-later",
		name: settingsKioskTextsAndImagesPlayNowInstructionsFrontDeskLater,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_play_now_instructions_go_frontdesk_later",
			childOf: settingsKioskTextsAndImagesPlayNowInstructions
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/instructions/go-straight-to-lane",
		name: settingsKioskTextsAndImagesPlayNowInstructionsStraightToLane,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_play_now_instructions_go_straight_to_lane",
			childOf: settingsKioskTextsAndImagesPlayNowInstructions
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/order-pdf",
		name: settingsKioskTextsAndImagesPdf,
		component: () => import("@app-views/settings/kiosk/texts-and-images/order-details-pdf/order-details-pdf.vue"),
		meta: {
			groupName: "email-pdf",
			titleKey: "settings_web_lookfeel_text_images_order_details_pdf",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/play-now/confirmation-email",
		name: settingsKioskTextsAndImagesEmail,
		component: () => import("@app-views/settings/kiosk/texts-and-images/confirmation-email/confirmation-email.vue"),
		meta: {
			groupName: "email-pdf",
			titleKey: "settings_web_lookfeel_text_images_confirmation_email",
			childOf: settingsKioskTextsAndImagesPlayNow
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation",
		name: settingsKioskTextsAndImagesYourReservation,
		component: () => import("@app-views/settings/kiosk/texts-and-images/texts-and-images.vue"),
		meta: {
			titleKey: "com_your_reservation",
			childOf: settingsKioskTextsAndImages
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/find-reservation",
		name: settingsKioskTextsAndImagesYourReservationFind,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_find_reservation",
			childOf: settingsKioskTextsAndImagesYourReservation
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/bowler-info-logged",
		name: settingsKioskTextsAndImagesYourReservationBowlerLogged,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_bowler_info_logged",
			childOf: settingsKioskTextsAndImagesYourReservation
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/bowler-info-guest",
		name: settingsKioskTextsAndImagesYourReservationBowlerGuest,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_bowler_info_guest",
			childOf: settingsKioskTextsAndImagesYourReservation
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/reservation-details",
		name: settingsKioskTextsAndImagesYourReservationDetails,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_reservation_details",
			childOf: settingsKioskTextsAndImagesYourReservation
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/thank-you",
		name: settingsKioskTextsAndImagesYourReservationThankYou,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_thank_you",
			childOf: settingsKioskTextsAndImagesYourReservation
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/instructions",
		name: settingsKioskTextsAndImagesYourReservationInstructions,
		component: () => import("@app-views/settings/kiosk/texts-and-images/texts-and-images.vue"),
		meta: {
			titleKey: "settings_kiosk_play_now_instructions",
			childOf: settingsKioskTextsAndImagesYourReservation,
			groupName: "instructions",
			showIf: Functionalities.CheckInSections,
			accessIf: Functionalities.CheckInSections
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/instructions/early-check-in",
		name: settingsKioskTextsAndImagesYourReservationInstructionsCheckIn,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_early_check_in",
			childOf: settingsKioskTextsAndImagesYourReservationInstructions
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/instructions/go-to-front-desk",
		name: settingsKioskTextsAndImagesYourReservationInstructionsGoToFrontDesk,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_go_to_front_desk",
			childOf: settingsKioskTextsAndImagesYourReservationInstructions
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/your-reservation/instructions/go-to-lane",
		name: settingsKioskTextsAndImagesYourReservationInstructionsGoToLane,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_go_to_lane",
			childOf: settingsKioskTextsAndImagesYourReservationInstructions
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/playlists",
		name: settingsKioskPlaylists,
		component: () => import("@app-views/settings/kiosk/playlists/playlists.vue"),
		meta: {
			titleKey: "settings_kiosk_playlists",
			childOf: settingsKiosks,
			accessIf: [
				Functionalities.AccessToKiosks,
				Functionalities.ManageKioskPlayLists
			]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/playlists/manage",
		name: settingsKioskManagePlaylists,
		component: () => import("@app-views/settings/kiosk/playlists/manage/manage-playlists.vue"),
		meta: {
			titleKey: "settings_kiosk_manage_playlists",
			childOf: settingsKioskPlaylists
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/playlists/manage/:id",
		name: settingsKioskManagePlaylistDetail,
		component: () => import("@app-views/settings/kiosk/playlists/manage/detail/detail-playlist.vue"),
		meta: {
			childOf: settingsKioskManagePlaylists
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/playlists/assign",
		name: settingsKioskAssignPlaylists,
		component: () => import("@app-views/settings/kiosk/playlists/assign/assign-playlists.vue"),
		meta: {
			titleKey: "settings_kiosk_assign_playlists",
			childOf: settingsKioskPlaylists
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/unlink-cpu",
		name: settingsKioskUnlinkCpu,
		component: () => import("@app-views/settings/kiosk/unlink-cpu/unlink-cpu.vue"),
		meta: {
			titleKey: "settings_kiosk_unlink_cpu",
			childOf: settingsKiosks,
			groupName: "unlink-cpu",
			accessIf: [Functionalities.DefineKioskStations]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu",
		name: settingsKioskTextsAndImagesMenu,
		component: () => import("@app-views/settings/kiosk/texts-and-images/menu/menu.vue"),
		meta: {
			titleKey: "label_menu",
			childOf: settingsKioskTextsAndImages,
			showIf: [Functionalities.DeliveryAndPickup],
			accessIf: [Functionalities.DeliveryAndPickup]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/table-service-pickup",
		name: settingsKioskTextsAndImagesMenuDeliveryPickup,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_table_service_pickup",
			childOf: settingsKioskTextsAndImagesMenu
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/table-service-areas-tables",
		name: settingsKioskTextsAndImagesMenuDeliveryAreasTables,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_table_service_areas_tables",
			childOf: settingsKioskTextsAndImagesMenu
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/categories-items",
		name: settingsKioskTextsAndImagesMenuCategoriesItems,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_categories_and_items",
			childOf: settingsKioskTextsAndImagesMenu
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/review-order",
		name: settingsKioskTextsAndImagesMenuReviewOrder,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_review_order",
			childOf: settingsKioskTextsAndImagesMenu
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/contact-info",
		name: settingsKioskTextsAndImagesMenuContactInfo,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_contact_info",
			childOf: settingsKioskTextsAndImagesMenu,
			showIf: [Functionalities.ManageFBOrdersUpdates],
			accessIf: [Functionalities.ManageFBOrdersUpdates]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/tips",
		name: settingsKioskTextsAndImagesMenuTips,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_tips_payments",
			childOf: settingsKioskTextsAndImagesMenu,
			showIf: [Functionalities.ManageTips]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/thank-you",
		name: settingsKioskTextsAndImagesMenuThankYou,
		component: () => import("@app-views/settings/kiosk/texts-and-images/menu/menu.vue"),
		meta: {
			titleKey: "settings_kiosk_texts_thank_you",
			childOf: settingsKioskTextsAndImagesMenu
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/thank-you-pickup",
		name: settingsKioskTextsAndImagesMenuPickupThankYou,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_pickup",
			childOf: settingsKioskTextsAndImagesMenuThankYou
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/thank-you-table-service",
		name: settingsKioskTextsAndImagesMenuDeliveryThankYou,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_web_foodbeverage_table_service",
			childOf: settingsKioskTextsAndImagesMenuThankYou
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/thank-you-pickup-order-update",
		name: settingsKioskTextsAndImagesMenuPickupThankYouOrderUpdate,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_pickup_order_update",
			childOf: settingsKioskTextsAndImagesMenuThankYou,
			showIf: [Functionalities.ManageFBOrdersUpdates],
			accessIf: [Functionalities.ManageFBOrdersUpdates]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/menu/thank-you-table-service-order-update",
		name: settingsKioskTextsAndImagesMenuDeliveryThankYouOrderUpdate,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_table_service_order_update",
			childOf: settingsKioskTextsAndImagesMenuThankYou,
			showIf: [Functionalities.ManageFBOrdersUpdates],
			accessIf: [Functionalities.ManageFBOrdersUpdates]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/waiting-list",
		name: settingsKioskTextsAndImagesWaitingList,
		component: () => import("@app-views/settings/kiosk/texts-and-images/waiting-list/waiting-list.vue"),
		meta: {
			titleKey: "label_bowling_waiting_list",
			childOf: settingsKioskTextsAndImages,
			showIf: [Functionalities.KioskBowlingWaitingList],
			accessIf: [Functionalities.KioskBowlingWaitingList]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/waiting-list/players",
		name: settingsKioskTextsAndImagesWaitingListPlayers,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_players",
			childOf: settingsKioskTextsAndImagesWaitingList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/waiting-list/playing-options",
		name: settingsKioskTextsAndImagesWaitingListPlayingOptions,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_playing_options",
			childOf: settingsKioskTextsAndImagesWaitingList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/waiting-list/contact-info",
		name: settingsKioskTextsAndImagesWaitingListContactInfo,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "label_contact_info",
			childOf: settingsKioskTextsAndImagesWaitingList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/waiting-list/thankyou",
		name: settingsKioskTextsAndImagesWaitingListThankyou,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "com_thank_you",
			childOf: settingsKioskTextsAndImagesWaitingList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks/texts-and-images/waiting-list/instructions",
		name: settingsKioskTextsAndImagesWaitingListInstructions,
		component: () => import("@app-views/settings/kiosk/texts-and-images/text-details/text-details.vue"),
		meta: {
			titleKey: "settings_kiosk_play_now_instructions",
			childOf: settingsKioskTextsAndImagesWaitingList,
			groupName: "instructions"
		}
	}
] as QRoute[];
