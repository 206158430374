import { Functionalities } from "@app-rest/company/interfaces";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useSystemStore from "@app-store/system";
import useUserStore from "@app-store/user";
import SecurityPlugin from "@app-utils/security";

const chmln = (window as any).chmln;
export function identifyChameleonUser() {
	const appStore = useAppStore();
	if (appStore.isDevelopSession || appStore.isTestingSession) return;
	const userStore = useUserStore();
	if (!userStore.info) return;
	const user = userStore.info;
	const companyStore = useCompanyStore();
	const company = companyStore.company;
	const system = useSystemStore().info;
	const trackingObject: any = {
		uid_hash: appStore.currentUserChameleonHash,
		name: user.Firstname + " " + user.Lastname,
		username: user.Username,
		environment: appStore.env,
		channel: appStore.channel,
		companyRole: userStore.companyRole,
		functionalities: {
			accessToBusinessDashboard: SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.AccessToBusinessDashboard]),
			assignRolesToUsers: SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.AssignRolesToUsers]),
			defineWebOffers: SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.DefineWebOffers]),
			defineFoodAndBeverage: SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.DefineFoodAndBeverage])
		}
	};
	if (company) {
		trackingObject.company = {
			uid: String(company.Id),
			name: company.Name,
			crmNumber: company.CrmNumber,
			distributorType: company.DistributorType,
			language: company.Language,
			mainCenter: company.MasterCenterId,
			centersCount: company.Centers.length
		};
	}
	if (system) {
		trackingObject.system = {
			id: system.Id,
			name: system.Name,
			country: system.Country,
			state: system.State,
			conquerorVersionActivated: system.ActivatedVersion,
			conquerorVersionInstalled: system.LastAccessVersion
		};
	}
	chmln.identify(user.Email, trackingObject);
}
