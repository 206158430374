import GroupBox from "@app-components/group-box/group-box.vue";
import QFormFieldDate from "@app-components/q-form-field-date/q-form-field-date.vue";
import QFormFieldTime from "@app-components/q-form-field-time/q-form-field-time.vue";
import useRemoteAssistanceStore from "@app-store/system/remoteAssistance";
import { QCol, QModal, QRow, useForm } from "@qamf/lighthouse";
import {
	addDays,
	addHours,
	isSameDay,
	roundToNearestMinutes,
	subMinutes
} from "date-fns";
import { computed, defineComponent, onBeforeMount, reactive, ref } from "vue";

export default defineComponent({
	name: "RemoteAssistance",
	components: {
		QRow,
		QCol,
		GroupBox,
		QFormFieldDate,
		QFormFieldTime,
		QModal
	},
	props: {
		id: { required: true, type: String }
	},
	setup() {
		const remoteAssistanceStore = useRemoteAssistanceStore();
		const expireAtIso = computed(() => {
			return remoteAssistanceStore.expireAtIso;
		});
		const expireAt = computed(() => {
			return expireAtIso.value ? new Date(expireAtIso.value) : null;
		});
		const isOff = computed(() => {
			return Boolean(!expireAt.value || new Date() > expireAt.value);
		});
		const { meta, resetForm } = useForm();
		const granularity = 5;
		const isLoading = ref(false);
		const modalState = ref(false);
		const supportStatus = ref(false);
		const helpers = reactive({
			supportEndsAt: null as Date | null,
			supportMin: null as Date | null,
			supportMax: null as Date | null
		});
		const play = async(date: Date) => {
			await remoteAssistanceStore.startRemoteAssistance(date);
		};
		const stop = async() => {
			await remoteAssistanceStore.stopRemoteAssistance();
		};
		const supportMinTime = computed(() => {
			return helpers.supportEndsAt
				&& helpers.supportMin
				&& isSameDay(helpers.supportEndsAt, helpers.supportMin)
				? helpers.supportMin
				: null;
		});
		const supportMaxTime = computed(() => {
			return helpers.supportEndsAt
				&& helpers.supportMax
				&& isSameDay(helpers.supportEndsAt, helpers.supportMax)
				? helpers.supportMax
				: null;
		});
		const onShow = async() => {
			isLoading.value = true;
			await remoteAssistanceStore.syncAssistanceStatus();
			helpers.supportMin = addHours(new Date(), 2);
			helpers.supportMax = addDays(new Date(), 7);
			helpers.supportEndsAt = expireAt.value && !isOff.value ? new Date(expireAt.value) : null;
			supportStatus.value = Boolean(!isOff.value);
			isLoading.value = false;
		};
		const setModalState = (state: boolean) => {
			modalState.value = state;
		};
		const turnOn = async() => {
			isLoading.value = true;
			supportStatus.value = true;
			helpers.supportEndsAt = addHours(new Date(), 48);
			const roundedExpire = roundToNearestMinutes(
				subMinutes(helpers.supportEndsAt, 3),
				{ nearestTo: granularity }
			);
			await play(roundedExpire);
			setModalState(false);
		};
		const save = async(supportChange: boolean) => {
			isLoading.value = true;
			supportStatus.value = supportChange;
			if (supportStatus.value && helpers.supportEndsAt)
				await play(helpers.supportEndsAt);
			else {
				helpers.supportEndsAt = null;
				await stop();
			}
			setModalState(false);
		};
		onBeforeMount(() => {
			resetForm();
		});
		return {
			granularity,
			isLoading,
			modalState,
			supportStatus,
			helpers,
			supportMinTime,
			supportMaxTime,
			meta,
			onShow,
			setModalState,
			turnOn,
			save
		};
	}
});
