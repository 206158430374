import NavigationButton from "@app-components/navigation-button/navigation-button.vue";
import { useRouteUtilities } from "@app-composables/route-utilities";
import usei18n from "@app-i18n/index";
import type { QRoute } from "@app-router/index";
import useGlobalStore from "@app-store/global";
import { isVisible } from "@app-utils/router";
import { BButtonGroup } from "bootstrap-vue-next";
import { groupBy } from "lodash-es";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
	name: "ViewWalker",
	components: {
		NavigationButton,
		BButtonGroup
	},
	props: {
		childOf: { type: String, default: "" },
		groupByMeta: { type: String, default: "groupName" },
		printTitleGroup: { type: Boolean, default: false },
		vertical: { type: Boolean, default: true },
		buttonVariant: { type: String, default: "navigation" }
	},
	setup(props) {
		const globalStore = useGlobalStore();
		const routeName = computed(() => useRouteUtilities().routeName.value);
		const route = useRoute();
		const { translateKey, getTranslatedRouteTitle } = usei18n();
		const getLocation = (routeParam: QRoute) => {
			return {
				name: routeParam.name,
				params: route.params,
				query: route.query
			};
		};
		const getTitle = (route: QRoute) => {
			return route.meta.title ?? getTranslatedRouteTitle(route);
		};
		const getGroupLinks = () => {
			return globalStore.routes.filter((el) => {
				if (!el.meta) return false;
				return el.meta.childOf === props.childOf && isVisible(el.meta);
			});
		};
		const getGroupTitle = (group: QRoute[]) => {
			if (!group.length || !props.printTitleGroup) return "";
			const groupTitleKey = group.find((el) => el.meta.groupTitleKey)?.meta.groupTitleKey;
			if (!groupTitleKey) return "";
			return translateKey(groupTitleKey);
		};
		const linksGrouped = computed(() => {
			const routes = getGroupLinks();
			return props.groupByMeta
				? groupBy(routes, (link) => link.meta[props.groupByMeta])
				: groupBy(routes, (link) => link);
		});
		return {
			routeName,
			getLocation,
			getTitle,
			getGroupTitle,
			linksGrouped
		};
	}
});
