import { ConstructorOptions, EventEmitter2, OnOptions } from "eventemitter2";

import { ICompanyChangedHandler, IEmptyHandler, IErrorEventHandler, IEventScroll, IEventWindowResize, ISystemChangedHandler } from "./events.definitions";

export class EventsManagerFactory extends EventEmitter2 {
	constructor(options?: ConstructorOptions) {
		super(options);
		this.setMaxListeners(0);
	}

	emitWindowResize() {
		return super.emit("window-resize", window.innerWidth, window.innerHeight);
	}

	onWindowResize(handler: IEventWindowResize, onOptions?: OnOptions) {
		const listener = super.on("window-resize", handler, onOptions);
		return () => {
			listener.off("window-resize", handler);
		};
	}

	emitErrorModalClose() {
		return this.emit("error-modal-close");
	}

	onErrorModalClose(handler: IEmptyHandler) {
		const listener = this.on("error-modal-close", handler);
		return () => listener.off("error-modal-close", handler);
	}

	emitScrollWindow() {
		return super.emit("window-on-scroll", window.scrollX, window.scrollY);
	}

	onScrollWindow(handler: IEventScroll, onOptions?: OnOptions) {
		const listener = super.on("window-on-scroll", handler, onOptions);
		return () => {
			listener.off("window-on-scroll", handler);
		};
	}

	emitHelpLinkClicked() {
		return this.emit("help-link");
	}

	onHelpLinkClicked(handler: IEmptyHandler) {
		const listener = this.on("help-link", handler);
		return () => listener.off("help-link", handler);
	}

	emitCompanyChanged(id: number) {
		return this.emit("company-changed", id);
	}

	onCompanyChanged(handler: ICompanyChangedHandler) {
		const listener = this.on("company-changed", handler);
		return () => listener.off("company-changed", handler);
	}

	emitSystemChanged(id: number) {
		return this.emit("system-changed", id);
	}

	onSystemChanged(handler: ISystemChangedHandler) {
		const listener = this.on("system-changed", handler);
		return () => listener.off("system-changed", handler);
	}

	emitError(error: Parameters<IErrorEventHandler>[0], extra?: Parameters<IErrorEventHandler>[1]) {
		return this.emit("fatal-error", error, extra);
	}

	onError(handler: IErrorEventHandler) {
		this.on("fatal-error", handler);
	}
}
export default new EventsManagerFactory();
