import { useResponsiveUtilities } from "@app-composables/responsive";
import { backToParentView } from "@app-utils/router";
import { BCardSubtitle, BCardTitle } from "bootstrap-vue-next";
import { computed, defineComponent, onMounted, onUnmounted } from "vue";

export default defineComponent({
	name: "SettingsCardHeader",
	components: {
		BCardTitle,
		BCardSubtitle
	},
	props: {
		title: { type: String, default: "" },
		subtitle: { type: String, default: "" },
		hasBackIcon: { type: Boolean, default: null }
	},
	setup(props) {
		const { isMedium, isSmall, init: initResponsiveUtils, destroy: destroyResponsiveUtils } = useResponsiveUtilities();
		const showBackArrow = computed(() => {
			return props.hasBackIcon !== null ? props.hasBackIcon : (isMedium.value || isSmall.value);
		});
		const handleBackArrow = async() => {
			await backToParentView();
		};
		onMounted(() => {
			initResponsiveUtils();
		});
		onUnmounted(() => {
			destroyResponsiveUtils();
		});
		return {
			isMedium,
			isSmall,
			showBackArrow,
			handleBackArrow
		};
	}
});
