import { Functionalities } from "@app-rest/company/interfaces";
import type { ISystemBase } from "@app-rest/system/interfaces";
import router from "@app-router/index";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import store from "@app-store/index";
import useSystemStore from "@app-store/system";
import useUserStore from "@app-store/user";
import { isVisible } from "@app-utils/router";
import SecurityPlugin from "@app-utils/security";
import { Module, Mutation, VuexModule } from "vuex-class-modules";

import useGlobalStore from "./global";

interface IMenuItemBase {
	showIf: {() : boolean},
	label?: string,
	translateKey?: string,
}
export interface IMenuItem extends IMenuItemBase {
	iconImg?: string,
	classes: { [prop: string]: boolean },
	links?: Partial<IMenuLink>[],
	collapsable: boolean,
	collapseVisible: boolean,
	submenuId: string,
	submenus: Partial<IMenuItem>[],
}
export interface IMenuLink extends IMenuItemBase {
	viewName: string,
	viewParams: { [key: string]: string },
	exact: boolean,
	active: boolean,
	classes: { [prop: string]: boolean },
	tooltipTranslateKey?: string,
	external: boolean,
	experimental: boolean,
	href: string,
	itemId: string,
}

@Module class MenuItemsFactory extends VuexModule {
	menuItems: IMenuItem[] | null = null;
	@Mutation initMenu() {
		const systemStore = useSystemStore();
		const appStore = useAppStore();
		const globalStore = useGlobalStore();
		const userStore = useUserStore();
		const companyStore = useCompanyStore();
		function getMenuSystems() {
			const userSystems = userStore.systems;
			const systemIdsHistory = userStore.systemIdsHistory;
			const sidebarSystems: ISystemBase[] = [];
			const currentRouteName = router.currentRoute.value.name ?? "";

			systemIdsHistory.forEach(systemId => {
				const ct = userSystems.find(c => c.Id === systemId);
				if (ct) sidebarSystems.push(ct);
			});
			userSystems.some(sys => {
				if (sidebarSystems.length > 5)
					return true;

				const isIn = sidebarSystems.findIndex(system => system.Id === sys.Id) > -1;
				if (!isIn)
					sidebarSystems.push(sys);

				return false;
			});
			const menuItems: Partial<IMenuLink>[] = !sidebarSystems
				? []
				: sidebarSystems.map(c => ({
					label: c.Name,
					viewName: "center-home",
					viewParams: { centerId: c.Id.toString() },
					active: currentRouteName === "center-home" && systemStore.info?.Id === c.Id
				}));
			if (menuItems.length > 5) {
				menuItems.push({
					translateKey: "com_all_center",
					viewName: "select-center"
				});
			}

			return menuItems;
		}
		function canShowMenuItem(name: string) {
			const currentRoute = globalStore.routes.find(r => r.name === name);
			return currentRoute?.meta ? isVisible(currentRoute.meta) : true;
		}

		const isAppDevStage = () => appStore.env === "Staging" || appStore.env === "Develop" || appStore.env === "Local";
		const companyId = () => systemStore.info && systemStore.info.CompanyId
			? systemStore.info.CompanyId.toString()
			: companyStore.company
				? companyStore.company.Id.toString()
				: "";
		const systemId = () => systemStore.info?.Id ? systemStore.info?.Id.toString() : "";
		const menuSystems = getMenuSystems();

		this.menuItems = [
			{
				showIf: () => Boolean(menuSystems?.length),
				translateKey: "com_select_center",
				collapsable: false,
				collapseVisible: true,
				classes: {
					section: true,
					"select-center": true
				},
				submenuId: "centers",
				submenus: [{ links: menuSystems }]
			},
			{
				showIf: () => Boolean(systemStore.info) && SecurityPlugin.hasAtLeastOneFunctionality([
					Functionalities.AccessToBusinessDashboard,
					Functionalities.AccessToGameStatistics,
					Functionalities.AccessToPerformanceMonitoring,
					Functionalities.AccessToBumperProtectionStatistics,
					Functionalities.AccessToTipsReporting
				]),
				translateKey: "dashboardsReportingTitle",
				classes: { section: true, "dashboard-reporting": true, collapsable: true },
				collapsable: true,
				collapseVisible: true,
				submenuId: "dashboardReporting",
				submenus: [
					{
						links: [
							{
								showIf: () => canShowMenuItem("business-results"),
								translateKey: "businessResultsTitle",
								viewName: "business-results",
								viewParams: { centerId: systemId() },
								tooltipTranslateKey: "tooltip_businessResults"
							},
							{
								showIf: () => canShowMenuItem("game-statistics"),
								translateKey: "gameStatisticsTitle",
								viewName: "game-statistics",
								viewParams: { centerId: systemId() },
								tooltipTranslateKey: "tooltip_gameStatistics"
							},
							{
								showIf: () => canShowMenuItem("performance-monitoring"),
								translateKey: "performanceMonitoringTitle",
								viewName: "performance-monitoring",
								viewParams: { centerId: systemId() },
								tooltipTranslateKey: "tooltip_performanceMonitoring"
							},
							{
								showIf: () => canShowMenuItem("bumper-statistics"),
								translateKey: "bumperStatisticsTitle",
								viewName: "bumper-statistics",
								viewParams: { centerId: systemId() },
								tooltipTranslateKey: "tooltip_bumperStatistics"
							},
							{
								showIf: () => canShowMenuItem("tips-reporting"),
								translateKey: "tipsReportingTitle",
								viewName: "tips-reporting",
								viewParams: { centerId: systemId() },
								tooltipTranslateKey: "tooltip_tipsReporting"
							}
						]
					}
				]
			},
			{
				showIf: () => Boolean(systemStore.info),
				translateKey: "servicesResourcesTitle",
				classes: { section: true, "services-resources": true, collapsable: true },
				collapsable: true,
				collapseVisible: true,
				submenuId: "servicesResources",
				submenus: [{
					links: [
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.AccessToTrainingLibrary]);
							},
							external: true,
							href: isAppDevStage()
								? "https://conqmaxstaging.qubicaamf.com/training-resources"
								: "https://conqmax.qubicaamf.com/training-resources",
							translateKey: "trainingResourcesTitle",
							itemId: "training-resources",
							tooltipTranslateKey: "tooltip_trainingResources"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.AccessToTrainingLibrary]);
							},
							external: true,
							href: isAppDevStage()
								? "https://conqmaxstaging.qubicaamf.com/virtual-training"
								: "https://conqmax.qubicaamf.com/virtual-training",
							translateKey: "liveVirtualTrainingTitle",
							itemId: "live-virtual-training",
							tooltipTranslateKey: "tooltip_liveVirtualTraining"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.AccessToTrainingLibrary]);
							},
							external: true,
							href: isAppDevStage()
								? "https://conqmaxstaging.qubicaamf.com/marketing-resources"
								: "https://conqmax.qubicaamf.com/marketing-resources",
							translateKey: "marketingResourcesTitle",
							itemId: "marketing-resources",
							tooltipTranslateKey: "tooltip_marketingResources"
						},
						{
							external: true,
							href: isAppDevStage()
								? "https://conqmaxstaging.qubicaamf.com/technical-resources"
								: "https://conqmax.qubicaamf.com/technical-resources",
							translateKey: "technicalResourcesTitle",
							itemId: "technical-resources",
							tooltipTranslateKey: "tooltip_techResources"
						},
						{
							external: true,
							href: "https://eshop.qubicaamf.com/",
							translateKey: "eShopTitle",
							itemId: "e-shop",
							tooltipTranslateKey: "tooltip_eShop"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.AccessToTrainingLibrary]);
							},
							external: true,
							href: "https://qubicaamf.litmos.com/account/login",
							translateKey: "maxTrainingPlusTitle",
							itemId: "max-training-plus",
							tooltipTranslateKey: "tooltip_maxTrainingPlus"
						},
						{
							external: true,
							href: "https://web.dassle.us/" + systemId(),
							translateKey: "dassleWebTitle",
							itemId: "dassle-web",
							tooltipTranslateKey: "tooltip_dassleWeb"
						}
					]
				}]
			},
			{
				showIf: () => Boolean(systemStore.info)
					&& (SecurityPlugin.hasAtLeastOneFunctionality([
						Functionalities.AccessToCustomerAccounts,
						Functionalities.CreateServiceReservations,
						Functionalities.AccessToReservationsGrid,
						Functionalities.WebReservations,
						Functionalities.Kiosks,
						Functionalities.Agent4DExperimentalFeature,
						Functionalities.AccessNeoverse
					])),
				translateKey: "toolsFeaturesTitle",
				classes: { section: true, "tools-features": true, collapsable: true },
				collapsable: true,
				collapseVisible: true,
				submenuId: "toolsFeatures",
				submenus: [{
					links: [
						{
							showIf: () => canShowMenuItem("customer-accounts"),
							translateKey: "customerAccountsTitle",
							viewName: "customer-accounts",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_customerAccounts"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.WebReservations]);
							},
							external: true,
							href: companyStore.bowlerWebsiteReservationsLink(),
							translateKey: "webReservationSite",
							itemId: "web-site",
							tooltipTranslateKey: "tooltip_webReservationSite"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.WebReservations]);
							},
							translateKey: "serviceReservationsTitle",
							viewName: "service-reservations",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_serviceReservations"
						},
						{
							showIf: () => canShowMenuItem("center-reservations"),
							translateKey: "reservationsGridTitle",
							viewName: "center-reservations",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_reservationsGrid"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasFunctionalities([Functionalities.Kiosks]);
							},
							translateKey: "kioskUtilities",
							viewName: "kiosk-utilities",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_kioskUtilities"
						},
						{
							showIf: () => {
								return canShowMenuItem("fnb-order-updates");
							},
							translateKey: "fnb_order_updates",
							viewName: "fnb-order-updates",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_fnb_orders"
						},
						{
							showIf: () => {
								return SecurityPlugin.hasAtLeastOneFunctionality([Functionalities.Agent4DExperimentalFeature, Functionalities.AccessNeoverse]);
							},
							experimental: true,
							translateKey: "agent4DTitle",
							viewName: "agent4D",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_agent4D"
						}
					]
				}]
			},
			{
				showIf: () => Boolean(systemStore.info)
					&& SecurityPlugin.hasAtLeastOneFunctionality([
						Functionalities.AccessToDataAndServiceSetup,
						Functionalities.AccessToCmp,
						Functionalities.AccessToInvoices,
						Functionalities.AccessToOnlineBackup,
						Functionalities.AccessToUserSetup,
						Functionalities.AccessToRolesAndPrivileges,
						Functionalities.AccessToTermsAndConditions,
						Functionalities.DefineBookableDaysHours,
						Functionalities.DefineBowlerTypes,
						Functionalities.DefineExtras,
						Functionalities.DefineFoodAndBeverage,
						Functionalities.DefineGraphicResources,
						Functionalities.DefineLaneGroups,
						Functionalities.DefinePayments,
						Functionalities.DefinePlayNowRulesAndPreferences,
						Functionalities.DefineRulesAndPreferences,
						Functionalities.DefineShoesAndSocks,
						Functionalities.DefineTextAndImages,
						Functionalities.DefineWebsiteMaintenance,
						Functionalities.DefineWebOffers,
						Functionalities.AccessToSquare
					]),
				translateKey: "ManagementConfigurationTitle",
				classes: { section: true, "management-configuration": true, collapsable: true },
				collapsable: true,
				collapseVisible: true,
				submenuId: "managementConfiguration",
				submenus: [{
					links: [
						{
							showIf: () => canShowMenuItem("cloud-data-selection"),
							translateKey: "cloudDataSelectionTitle",
							viewName: "cloud-data-selection",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_cloudDataSelection"
						},
						{
							showIf: () => canShowMenuItem("programs-licenses"),
							translateKey: "programsLicensesTitle",
							viewName: "programs-licenses",
							viewParams: { companyId: companyId() },
							itemId: "programs-licenses",
							tooltipTranslateKey: "tooltip_programsLicenses"
						},
						{
							showIf: () => canShowMenuItem("settings"),
							translateKey: "settingsTitle",
							viewName: "settings",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_settings"
						},
						{
							showIf: () => canShowMenuItem("roles-privileges"),
							translateKey: "rolesPrivilegesTitle",
							viewName: "roles-privileges",
							viewParams: { companyId: companyId() },
							tooltipTranslateKey: "tooltip_rolesPrivileges"
						},
						{
							showIf: () => canShowMenuItem("company-users"),
							translateKey: "usersTitle",
							viewName: "company-users",
							viewParams: { companyId: companyId() },
							tooltipTranslateKey: "tooltip_users"
						},
						{
							showIf: () => canShowMenuItem("online-backups"),
							translateKey: "onlineBackupTitle",
							viewName: "online-backups",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_onlineBackups"
						},
						{
							showIf: () => canShowMenuItem("pos-solution"),
							itemId: "square-logo",
							get experimental() {
								return SecurityPlugin.hasFunctionalitiesEnabled([Functionalities.TestPOSIntegrationPreview]);
							},
							translateKey: "posSolutionTitle",
							viewName: "pos-solution",
							viewParams: { centerId: systemId() },
							tooltipTranslateKey: "tooltip_square_integration"
						}
					]
				}]
			},
			{
				showIf: () => Boolean(userStore.isAuthenticated && userStore.isStaffUser),
				classes: { section: true, "staff-menu": true, collapsable: true },
				label: "Advanced",
				collapsable: true,
				collapseVisible: true,
				submenuId: "qamfStaffMenu",
				submenus: [{
					links: [
						{
							itemId: "qubica_logo",
							translateKey: "openid_token_page",
							viewName: "session-info",
							tooltipTranslateKey: "tooltip_sessionInfo"
						},
						{
							showIf: () => canShowMenuItem("web-transactions"),
							itemId: "qubica_logo",
							tooltipTranslateKey: "tooltip_webTransactions",
							translateKey: "web_transactions_title",
							viewName: "web-transactions",
							viewParams: { centerId: systemId() }
						}
					]
				}]
			}
		];
	}
}

const moduleName = "system.menuItems";
let MenuItemsStore: MenuItemsFactory | null;
function useMenuItemsStore() {
	if (MenuItemsStore) return MenuItemsStore;
	const mod = MenuItemsStore = new MenuItemsFactory({ name: moduleName, store });
	return mod;
}

export default useMenuItemsStore;
