<template>
	<div
		v-click-away="onClickOutside"
		class="q-form-field q-form-field-time"
		:class="classProp"
	>
		<QPopover
			ref="popover"
			v-model="isPopoverVisible"
			manual
			no-flip
			custom-class="q-form-field-time-wrapper"
			:teleport-to="teleportTo"
			:placement="placement"
			:reference="inputElement"
			:delay="0"
			:offset="0"
			:class="{
				'd-none': disabled
			}"
			@show="initTimes"
		>
			<template #target>
				<slot />
				<QFormFieldWrapper
					v-model="selectedDate"
					:name="name"
					:rules="rules"
					:error-message="errorMessage"
					:hide-validation="hideValidation"
					:hide-validation-messages="hideValidationMessages"
					:size="size"
					@click.stop="onClickWrapper"
				>
					<template #default="defaultSlotProps">
						<FakeInput
							ref="inputElement"
							:value="selectedDate"
							v-bind="defaultSlotProps.field"
							:disabled="disabled"
							:readonly="readonly"
							class="the-control content-editable"
							:size="size"
							:class="{
								'is-valid': defaultSlotProps.state === true,
								'is-invalid': defaultSlotProps.state === false,
							}"
						>
							{{ inputValue }}
						</FakeInput>
					</template>
					<template #appendIcon>
						<i
							class="bi bi-clock"
							:class="{'disabled': disabled }"
							aria-hidden="true"
						/>
					</template>
				</QFormFieldWrapper>
			</template>
			<div
				class="time-picker-body"
				@click.stop
			>
				<QOverlay
					:show="helpers.loading || loading"
					:size="spinnerSize"
				>
					<div class="hours">
						<div
							v-for="(h, index) in helpers.hours"
							:key="index"
						>
							<q-button
								variant="time-pick"
								:pressed="h.pressed"
								:disabled="h.disabled || readonly"
								@click="onHoursSelected(h)"
							>
								{{ h.text }}
							</q-button>
						</div>
					</div>
					<div class="minutes">
						<div
							v-for="(m, index) in helpers.minutes"
							:key="index"
						>
							<q-button
								variant="time-pick"
								:pressed="m.pressed"
								:disabled="m.disabled || readonly"
								@click="onMinutesSelected(m)"
							>
								{{ m.text }}
							</q-button>
						</div>
					</div>
				</QOverlay>
			</div>
		</QPopover>
	</div>
</template>
<script lang="ts" src="./q-form-field-time.ts"></script>
<style lang="scss" src="./q-form-field-time.scss"></style>
