import type { IResponseError, IRestResponse } from "@app-rest/common.interfaces";
import PortalRestClientFactory from "@app-rest/index";

import type { AddCustomAttributeErrorCodes, BowlingDepartmentType, IAliasDetailsResponse, IBowlingItem, IBowlingItemDetails, ICenterListResponse, ICenterPairingRequest, ICenterPairingResponse, ICreateAliasPayload, ICreateAliasResponse, ICreatePairPayload, ICreatePairResponse, IGetBowlingItemsQuery, IImportResultsResponse, IIntegrationImportStatusResponse, IIntegrationRequirementsResponse, IIntegrationSyncStatusResponse, IListAliasesResponse, ILocationListResponse, ISaveBowlingItemDetailsRequest, ISquareRequirementsResponse, ITerminalsResponse, IUpdateAliasPayload, IUpdateAliasResponse, TerminalAliasError } from "./externalPos.interfaces";
import { SquareOAuthEnvironment } from "./squareOAuth";

const pairingBasePath = (systemId: number) => `/api/${systemId}/pair-device`;
const centerPairingBasePath = (companyId: number) => `/api/${companyId}/center-pairings`;

export class ExternalPosRestFactory extends PortalRestClientFactory {
	systemId: number;
	constructor(systemId?: number) {
		super();
		this.systemId = systemId ?? 0;
		this.options.set("basePath", `/api/${systemId}/pos-solution`);
	}

	getTerminals() {
		return this.get<ITerminalsResponse>("/terminals", { basePath: pairingBasePath(this.systemId) });
	}

	async createAlias(body: ICreateAliasPayload) {
		const handledErrorCodes: TerminalAliasError[] = ["AliasWithSameNameAlreadyExists", "AliasWithSameTerminalIdAlreadyExists"];
		const response = await this.post<ICreateAliasResponse>("/create-alias", { body, basePath: pairingBasePath(this.systemId) });
		if (response.data && !response.data.Success && !handledErrorCodes.includes(response.data.ErrorCode)) throw new Error(response.data.ErrorCode);
		return response;
	}

	deleteAlias(aliasId: number) {
		return this.delete(`/delete-alias/${aliasId}`, { basePath: pairingBasePath(this.systemId) });
	}

	async getTerminalAliases() {
		const response = await this.get<IListAliasesResponse>("/list-alias", { basePath: pairingBasePath(this.systemId) });
		if (response.data && !response.data.Success) throw new Error(response.data.ErrorCode ?? "ListAlias failed");
		return response;
	}

	getAliasDetails(aliasId: number) {
		return this.get<IAliasDetailsResponse>(`/alias-details/${aliasId}`, { basePath: pairingBasePath(this.systemId) });
	}

	async updateAlias(aliasId: number, body: IUpdateAliasPayload) {
		const handledErrorCodes: TerminalAliasError[] = ["AliasWithSameNameAlreadyExists", "AliasWithSameTerminalIdAlreadyExists"];
		const response = await this.put<IUpdateAliasResponse>(`update-alias/${aliasId}`, { body, basePath: pairingBasePath(this.systemId) });
		if (response.data && !response.data.Success && !handledErrorCodes.includes(response.data.ErrorCode)) throw new Error(response.data.ErrorCode);
		return response;
	}

	createPair(body: ICreatePairPayload) {
		return this.post<ICreatePairResponse>("create-pair", { body, basePath: pairingBasePath(this.systemId) });
	}

	// #region center pairing
	saveCenterPairing(companyId: number, body: ICenterPairingRequest) {
		return this.post(`/pair/${body.CenterId}`, {
			basePath: centerPairingBasePath(companyId),
			body,
			headers: new Headers({
				"Content-Type": "application/json-patch+json"
			})
		});
	}

	deleteCenterPairing(companyId: number, systemId: number) {
		return this.delete(`/pair/${systemId}`, {
			basePath: centerPairingBasePath(companyId)
		});
	}

	getCenterList(companyId: number, squareEnvironment: SquareOAuthEnvironment) {
		return this.get<ICenterListResponse>(`/${squareEnvironment}`, {
			basePath: centerPairingBasePath(companyId)
		});
	}

	getPairById(companyId: number, systemId: number) {
		return this.get<ICenterPairingResponse>(`/pair/${systemId}`, {
			basePath: centerPairingBasePath(companyId),
			throwExcluding: [error => (error.statusCode === 404 || error.statusCode === 401) && typeof error.data?.Error.Code === "string"]
		});
	}

	getLocationList(companyId: number, environment: SquareOAuthEnvironment) {
		return this.get<ILocationListResponse>(`/${environment}/pairable-location-list`, {
			basePath: centerPairingBasePath(companyId)
		});
	}
	// #end region

	getIntegrationRequirements() {
		return this.get<IIntegrationRequirementsResponse>("/integration-requirements");
	}

	getSquareRequirements() {
		return this.get<ISquareRequirementsResponse>("/square/integration-requirements");
	}

	addItemCustomAttribute() {
		return this.post<null, IResponseError<AddCustomAttributeErrorCodes>>("/square/item-custom-attribute", {
			responseType: "json",
			throwExcluding: [error => (error.statusCode === 424 || error.statusCode === 500) && typeof error.data?.Error.Code === "string"]
		});
	}

	firstCatalogImport() {
		return this.post<null, IResponseError<"RequirementsNotSatisfied", IIntegrationRequirementsResponse>>("/first-import/start", {
			throwExcluding: [
				error => error.statusCode === 422
						&& typeof error.data?.Error.Code === "string"
						&& error.data.Error.Code === "RequirementsNotSatisfied"
			]
		});
	}

	getIntegrationImportStatus() {
		return this.get<IIntegrationImportStatusResponse>("/first-import/status", {
			throwExcluding: [error => error.statusCode === 404 && typeof error.data?.Error.Code === "string"]
		});
	}

	getImportResults() {
		return this.get<IImportResultsResponse>("/first-import/results", {
			throwExcluding: [error => error.statusCode === 404 && typeof error.data?.Error.Code === "string"]
		});
	}

	getIntegrationSyncStatus() {
		return this.get<IIntegrationSyncStatusResponse>("/sync-status", {
			throwExcluding: [error => error.statusCode === 404 && typeof error.data?.Error.Code === "string"]
		});
	}

	getBowlingItems(query: IGetBowlingItemsQuery) {
		return this.get<IRestResponse<IBowlingItem>>("/bowling-items", {
			query
		});
	}

	getBowlingItemDetails<T extends BowlingDepartmentType>(type: T, id: number) {
		return this.get<IBowlingItemDetails<T>>(`/bowling-items/${type}/${id}`);
	}

	saveBowlingItemDetails(type: BowlingDepartmentType, id: number, body: ISaveBowlingItemDetailsRequest) {
		return this.put(`/bowling-items/${type}/${id}`, {
			body
		});
	}
}
