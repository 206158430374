import PortalRestClientFactory from "@app-rest/index";
import { formatISO } from "date-fns";

interface IRemoteAssistanceModel { Exp: string | null }

export class RestRemoteAssistance extends PortalRestClientFactory {
	constructor(systemId?: number) {
		super();
		this.options.set("basePath", `/api/${systemId}`);
	}

	getAssistanceExpiration() {
		return this.get<IRemoteAssistanceModel>("/remote-assistance");
	}

	setAssistanceExpiration(date: Date | null) {
		const body: IRemoteAssistanceModel = { Exp: date ? formatISO(date) : null };
		return this.put("remote-assistance", { body });
	}
}
