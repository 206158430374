<template>
	<template v-for="{ links } in items">
		<template
			v-for="(link, index) in links"
			:key="index"
		>
			<template v-if="typeof link.showIf == 'undefined' || link.showIf()">
				<q-link
					v-if="link.external"
					class="route external"
					:href="link.href"
					target="_blank"
					:class="link.classes"
				>
					{{ label(link) }}
				</q-link>
				<q-link
					v-else
					class="route"
					:active="link.active ? link.viewName == routeName : false"
					:to="{ name: link.viewName, params: link.viewParams }"
					:exact="link.exact"
					:class="link.classes"
				>
					{{ label(link) }}
				</q-link>
			</template>
		</template>
	</template>
</template>
<script lang="ts" src="./menu-items.ts"></script>
