import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/.";

import { settingsFoodAndBeverage } from ".";

export const foodAndBeverageAvailability = "food-and-beverage-availability";
export const foodAndBeverageAvailabilityCalendar = "food-and-beverage-availability-calendar";
export const foodAndBeverageCategories = "food-and-beverage-categories";
export const foodAndBeverageItems = "food-and-beverage-items";

export const foodAndBeverageItem = "food-and-beverage-item";
export const foodAndBeverageItemCategories = "food-and-beverage-item-categories";
export const foodAndBeverageItemCategory = "food-and-beverage-category";
export const foodAndBeverageCategoryOffers = "food-and-beverage-category-offers";

export const foodAndBeverageDelivery = "food-and-beverage-delivery";
export const foodAndBeverageDeliveryAreas = "food-and-beverage-delivery-areas";
export const foodAndBeverageDeliveryTables = "food-and-beverage-delivery-tables";
export const foodAndBeverageDeliveryArea = "food-and-beverage-delivery-area";
export const foodAndBeverageDeliveryTable = "food-and-beverage-delivery-table";

export const foodAndBeverageModifierGroupList = "food-and-beverage-modifier-group-list";
export const foodAndBeverageModifierGroupDetail = "food-and-beverage-modifiers-group-detail";
export const foodAndBeverageModifierList = "food-and-beverage-modifiers-list";
export const foodAndBeverageModifierDetail = "food-and-beverage-modifiers-detail";

export const foodAndBeverageItemInPackageModifierGroupList = "food-and-beverage-item-package-modifier-group-list";
export const foodAndBeverageItemInPackageModifierGroupDetail = "food-and-beverage-item-package-modifiers-group-detail";
export const foodAndBeverageItemInPackageModifierList = "food-and-beverage-item-package-modifiers-list";
export const foodAndBeverageItemInPackageModifierDetail = "food-and-beverage-item-package-modifiers-detail";

export const foodAndBeverageOrderUpdates = "food-and-beverage-order-updates";
export const foodAndBeverageOrderUpdatesPickup = "food-and-beverage-order-updates-pickup";
export const foodAndBeverageOrderUpdatesTableService = "food-and-beverage-order-updates-table-service";

export default [
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/availability",
		name: foodAndBeverageAvailability,
		component: () => import("@app-views/settings/food-and-beverage/availability/availability.vue"),
		meta: {
			groupName: "availability",
			titleKey: "label_availability",
			childOf: settingsFoodAndBeverage
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/availability/:idCalendar",
		name: foodAndBeverageAvailabilityCalendar,
		component: () => import("@app-views/settings/food-and-beverage/availability/calendar-view/calendar-view.vue"),
		meta: {
			childOf: foodAndBeverageAvailability
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/categories",
		name: foodAndBeverageCategories,
		component: () => import("@app-views/settings/food-and-beverage/categories/categories.vue"),
		meta: {
			titleKey: "label_categories",
			childOf: settingsFoodAndBeverage
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items",
		name: foodAndBeverageItems,
		component: () => import("@app-views/settings/food-and-beverage/items/items.vue"),
		meta: {
			titleKey: "label_items",
			childOf: settingsFoodAndBeverage
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/table-service",
		name: foodAndBeverageDelivery,
		component: () => import("@app-views/settings/food-and-beverage/table-service/table-service.vue"),
		meta: {
			titleKey: "settings_web_foodbeverage_table_service",
			childOf: settingsFoodAndBeverage,
			groupName: "table-service",
			showIf: [Functionalities.DeliveryAndPickup],
			accessIf: [Functionalities.DeliveryAndPickup]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/table-service/areas",
		name: foodAndBeverageDeliveryAreas,
		component: () => import("@app-views/settings/food-and-beverage/table-service/areas/areas.vue"),
		meta: {
			titleKey: "settings_web_foodbeverage_table_service_areas",
			childOf: foodAndBeverageDelivery
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/table-service/areas/:name",
		name: foodAndBeverageDeliveryArea,
		component: () => import("@app-views/settings/food-and-beverage/table-service/areas/area/area.vue"),
		meta: {
			childOf: foodAndBeverageDeliveryAreas
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/table-service/tables",
		name: foodAndBeverageDeliveryTables,
		component: () => import("@app-views/settings/food-and-beverage/table-service/tables/tables.vue"),
		meta: {
			titleKey: "settings_web_foodbeverage_table_service_tables",
			childOf: foodAndBeverageDelivery
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/table-service/tables/:name",
		name: foodAndBeverageDeliveryTable,
		component: () => import("@app-views/settings/food-and-beverage/table-service/tables/table/table.vue"),
		meta: {
			childOf: foodAndBeverageDeliveryTables
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name",
		name: foodAndBeverageItem,
		component: () => import("@app-views/settings/food-and-beverage/items/item/item.vue"),
		meta: {
			childOf: foodAndBeverageItems
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/groups",
		name: foodAndBeverageModifierGroupList,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group-list/item-modifier-group-list.vue"),
		meta: {
			childOf: foodAndBeverageItem,
			accessIf: [Functionalities.ManageFoodAndBeverageModifiers],
			titleKey: "settings_modifier_groups"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/packageItems/:itemInPackage/groups",
		name: foodAndBeverageItemInPackageModifierGroupList,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group-list/item-modifier-group-list.vue"),
		meta: {
			childOf: foodAndBeverageItem,
			accessIf: [Functionalities.ManageFoodAndBeverageModifiers],
			titleKey: "settings_modifier_groups"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/groups/:group",
		name: foodAndBeverageModifierGroupDetail,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group/item-modifier-group.vue"),
		meta: {
			childOf: foodAndBeverageModifierGroupList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/packageItems/:itemInPackage/groups/:group",
		name: foodAndBeverageItemInPackageModifierGroupDetail,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group/item-modifier-group.vue"),
		meta: {
			childOf: foodAndBeverageItemInPackageModifierGroupList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/groups/:group/modifiers",
		name: foodAndBeverageModifierList,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group/item-modifiers-list/item-modifier-list.vue"),
		meta: {
			childOf: foodAndBeverageModifierGroupDetail,
			titleKey: "label_modifiers"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/packageItems/:itemInPackage/groups/:group/modifiers",
		name: foodAndBeverageItemInPackageModifierList,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group/item-modifiers-list/item-modifier-list.vue"),
		meta: {
			childOf: foodAndBeverageItemInPackageModifierGroupDetail,
			titleKey: "label_modifiers"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/groups/:group/modifiers/:modifier",
		name: foodAndBeverageModifierDetail,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group/item-modifier/item-modifier.vue"),
		meta: {
			childOf: foodAndBeverageModifierList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/packageItems/:itemInPackage/groups/:group/modifiers/:modifier",
		name: foodAndBeverageItemInPackageModifierDetail,
		component: () => import("@app-views/settings/food-and-beverage/items/item-modifier-group/item-modifier/item-modifier.vue"),
		meta: {
			childOf: foodAndBeverageItemInPackageModifierList
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/items/:name/categories",
		name: foodAndBeverageItemCategories,
		component: () => import("@app-views/settings/food-and-beverage/items/item-categories/item-categories.vue"),
		meta: {
			childOf: foodAndBeverageItem
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/categories/:name",
		name: foodAndBeverageItemCategory,
		component: () => import("@app-views/settings/food-and-beverage/categories/category/category.vue"),
		meta: {
			childOf: foodAndBeverageCategories
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/categories/:name/add-web-offer",
		name: foodAndBeverageCategoryOffers,
		component: () => import("@app-views/settings/food-and-beverage/categories/category/category-offers.vue"),
		meta: {
			childOf: foodAndBeverageItemCategory
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/order-updates/",
		name: foodAndBeverageOrderUpdates,
		component: () => import("@app-views/settings/food-and-beverage/order-updates/order-updates.vue"),
		meta: {
			childOf: settingsFoodAndBeverage,
			titleKey: "settings_order_updates",
			groupName: "order-updates",
			showIf: [Functionalities.ManageFBOrdersUpdates],
			accessIf: [Functionalities.ManageFBOrdersUpdates]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/order-updates/pickup",
		name: foodAndBeverageOrderUpdatesPickup,
		component: () => import("@app-views/settings/food-and-beverage/order-updates/order-updates-configuration/order-updates-configuration.vue"),
		meta: {
			childOf: foodAndBeverageOrderUpdates,
			titleKey: "settings_order_updates_pickup"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage/order-updates/table-service",
		name: foodAndBeverageOrderUpdatesTableService,
		component: () => import("@app-views/settings/food-and-beverage/order-updates/order-updates-configuration/order-updates-configuration.vue"),
		meta: {
			childOf: foodAndBeverageOrderUpdates,
			titleKey: "settings_order_updates_table_service"
		}
	}
] as QRoute[];
