import type { IMediaFolderRequestData, IMediaFolderResponseData, IMediaFolderSearchRequestData } from "@app-rest/company/media.interfaces";
import PortalRestClientFactory from "@app-rest/index";

import { IPaymentCurrency } from "./common.interfaces";

export class RestGlobalFactory extends PortalRestClientFactory {
	currencies() {
		return this.get<IPaymentCurrency[]>("/dictionaries/currencies");
	}

	getMedia(params: IMediaFolderRequestData) {
		return this.get<IMediaFolderResponseData>("/filefolders", { query: params });
	}

	searchMediaFolders(params: IMediaFolderSearchRequestData) {
		return this.get<IMediaFolderResponseData>("/filefolders/search", { query: params });
	}
}

export default new RestGlobalFactory();
