<template>
	<div
		v-for="(item, index) in menu"
		:key="index"
		class="menu-group"
		:class="item.classes"
	>
		<template v-if="typeof item.showIf == 'undefined' || item.showIf()">
			<div
				v-if="!item.collapsable"
				class="label"
			>
				<span class="item-text">{{ label(item) }}</span>
			</div>
			<a
				v-else
				v-b-toggle
				class="label label-bordered"
				:href="'#' + item.submenuId"
				@click.prevent
			>
				<span class="item-text">{{ label(item) }}</span>
				<i class="fas fa-caret-right icon right" />
				<i class="fas fa-caret-down icon down" />
			</a>
			<template v-if="item.submenus && item.submenus.length">
				<q-collapse
					v-if="item.collapsable"
					:id="item.submenuId"
					:visible="item.collapseVisible"
					class="menu-group-links"
				>
					<MenuItems
						:items="item.submenus"
						:label="label"
					/>
				</q-collapse>
				<div
					v-else
					class="menu-group-links"
				>
					<MenuItems
						:items="item.submenus"
						:label="label"
					/>
				</div>
			</template>
		</template>
	</div>
</template>
<script lang="ts" src="./sidebar.ts"></script>
