import Layer from "@app-components/layer.vue";
import ModalPreferences from "@app-components/modal-preferences/modal-preferences.vue";
import Navbar from "@app-components/navbar/navbar.vue";
import QFooter from "@app-components/q-footer/q-footer.vue";
import RemoteAssistance from "@app-components/remote-assistance/remote-assistance.vue";
import { useRouteUtilities } from "@app-composables/route-utilities";
import { useSecurityUtilities } from "@app-composables/security";
import usei18n from "@app-i18n/index";
import type { IResponseError } from "@app-rest/common.interfaces";
import { Functionalities } from "@app-rest/company/interfaces";
import { setRouteTitle } from "@app-router/index";
import { settings } from "@app-router/routes/settings";
import useAppStore from "@app-store/app";
import useCompanyStore from "@app-store/company";
import useLangStore from "@app-store/lang";
import useSystemStore from "@app-store/system";
import useUserStore from "@app-store/user";
import syncSettingsFromBE from "@app-utils/api-app";
import {
	EventAI,
	setupAppInsights,
	type TrackedException,
	trackException,
	trackUserEvent
} from "@app-utils/app-insights";
import EventsManager from "@app-utils/events-manager";
import { initModalUtil } from "@app-utils/modal";
import { popupWindowFocus } from "@app-utils/popup";
import { QModal, QOverlay } from "@qamf/lighthouse";
import { BModalOrchestrator, BToastOrchestrator, useModalController } from "bootstrap-vue-next";
import Bowser from "bowser";
import type { AppDriver } from "cypress/support/commands";
import { debounce } from "lodash-es";
import { RestError } from "scarlett";
import { computed, defineComponent, getCurrentInstance, nextTick, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import QPortalApp from "./main";

const browser = Bowser.getParser(window.navigator.userAgent);
const engine = browser.getEngine();

if (engine.name && engine.name.toLowerCase() === "webkit") {
	document.addEventListener("touchmove", (e) => e.preventDefault());
	const body = document.querySelector("body");
	body?.addEventListener("touchstart", (e) => {
		if (!e.currentTarget) return;
		const target = e.currentTarget as HTMLElement;
		if (
			target
            && (target.matches(".scrollable-area")
                || target.matches(".table-responsive"))
		) {
			if (target.scrollTop === 0) target.scrollTop = 1;
			else if (target.scrollHeight === target.scrollTop + target.offsetHeight)
				target.scrollTop -= 1;
		}
	});
	body?.addEventListener("touchmove", (e) => {
		if (!e.currentTarget) return;
		const target = e.currentTarget as HTMLElement;
		if (
			target
            && (target.matches(".scrollable-area")
                || target.matches(".table-responsive"))
		)
			e.stopPropagation();
	});
}

interface IErrorOccurrence {
    error: Error | RestError<IResponseError> | null;
	extras: any;
	datetime: Date;
}

export default defineComponent({
	name: "QPortal",
	components: {
		Layer,
		Navbar,
		ModalPreferences,
		QFooter,
		QModal,
		QOverlay,
		RemoteAssistance,
		BModalOrchestrator,
		BToastOrchestrator
	},
	props: {},
	setup() {
		const appStore = useAppStore();
		const userStore = useUserStore();
		const companyStore = useCompanyStore();
		const router = useRouter();
		const route = useRoute();
		const modalController = useModalController();
		const env = computed(() => {
			return appStore.env;
		});
		const helpSiteLink = computed(() => {
			return appStore.helpSiteLink;
		});
		const center = computed(() => {
			return useSystemStore().info;
		});
		const isCompanyMultiSystem = computed(() => {
			return companyStore.isMultiSystem;
		});
		const userSettings = computed(() => {
			return userStore.settings;
		});
		const userInfo = computed(() => {
			return userStore.info;
		});
		const isStaffUser = computed(() => {
			return userStore.isStaffUser;
		});
		const isPopupOpen = computed(() => {
			return appStore.popupWindowIsOpen;
		});
		const language = computed(() => {
			return useLangStore().isoCode;
		});
		const locationHref = computed(() => {
			return location.href;
		});
		const canManageRemoteAssistance = computed(() => {
			return useSecurityUtilities().hasFunctionalitiesEnabled([
				Functionalities.ManageRemoteAssistance
			]);
		});
		const isDevelopSession = computed(() => {
			return appStore.isDevelopSession;
		});
		const currentRouteName = computed(() => {
			return router.currentRoute.value.name;
		});
		const errorModal = ref<typeof QModal>();
		const currentView = ref<any>();
		const currentViewComponent = ref<any>();
		const navbar = ref<typeof Navbar>();
		const user = ref<typeof ModalPreferences>();
		const remote = ref<typeof RemoteAssistance>();
		const fatalErrors = ref<IErrorOccurrence[]>([]);
		const hamburgerVisible = ref(false);
		const showErrorModal = ref(false);
		const { appLoading, routeLoading } = useRouteUtilities();
		const viewParent = computed(() => useRouteUtilities().viewParent.value);
		const reload = () => {
			location.reload();
		};
		const onResize = async() => {
			EventsManager.emitWindowResize();
			await nextTick();
		};
		const isChildOfSettings = computed(() => {
			return viewParent.value === settings;
		});
		const isErrorPage = computed(() => router.currentRoute.value.name === "error-page");
		const isInsideSettingsSection = computed(() => {
			return router.currentRoute.value.fullPath.includes("/settings/");
		});
		const errorModalClose = () => {
			EventsManager.emitErrorModalClose();
		};
		const initKeyboardEvents = () => {
			window.addEventListener("keydown", (event) => {
				if (event.key && event.key.toLowerCase() === "f1") {
					event.preventDefault();
					EventsManager.emitHelpLinkClicked();
				}
			});
		};
		const mainOverlayClicked = () => {
			popupWindowFocus();
		};
		const errorFirstOccurrence = computed(() => {
			return fatalErrors.value.length ? fatalErrors.value[0] : null;
		});
		const errorIsRest = computed(() => {
			return fatalErrors.value[0] && fatalErrors.value[0].error instanceof RestError;
		});
		const errorStatusCode = computed(() => {
			return fatalErrors.value[0] && fatalErrors.value[0].error instanceof RestError
				? (fatalErrors.value[0].error.statusCode as number) ?? 0
				: 0;
		});
		const errorRestObject = computed(() => {
			return fatalErrors.value[0] && fatalErrors.value[0].error instanceof RestError
				? fatalErrors.value[0].error.data ?? null
				: null;
		});
		const helpURL = computed(() => {
			return `${helpSiteLink.value}${language.value}/qportal#${route.meta?.helpSiteAnchor ?? ""}`;
		});
		const goToSystemSelection = () => {
			showErrorModal.value = false;
			router.push({ name: "select-center" }).catch(() => {});
		};
		const initErrorEvents = () => {
			const logErrors = debounce(() => {
				const fatalErrorsList = fatalErrors.value;
				const printErrorEl = (el: IErrorOccurrence, title?: string) => {
					console.groupCollapsed(
						"\x1b[31m%s\x1b[0m",
						title ?? el.error?.message ?? "FatalError"
					);
					console.info(el.datetime);
					console.error(el.error);
					if (el.extras) console.info(el.extras);
					console.groupEnd();
				};
				if (fatalErrorsList.length === 1)
					printErrorEl(fatalErrorsList[0], "A fatal error occurred");
				else {
					console.groupCollapsed(
						"\x1b[31m%s\x1b[0m",
						`Intercepted fatal errors: ${fatalErrorsList.length}`
					);
					fatalErrorsList.forEach((er) => printErrorEl(er));
					console.groupEnd();
				}
			}, 1000);
			function isSessionExpired<TRestErr extends RestError<IResponseError<"SessionExpired">>>(err: Error | TRestErr): err is Required<TRestErr> {
				return err instanceof RestError && (err as TRestErr).data?.Error.Code === "SessionExpired";
			}
			window.onerror = (message, source, lineno, colno, error) => {
				EventsManager.emitError(error as Error, {
					message,
					source,
					lineno,
					colno
				});
				return true;
			};
			window.onunhandledrejection = (event: PromiseRejectionEvent) => {
				event.preventDefault();
				if (event.reason instanceof Error || event.reason instanceof RestError)
					EventsManager.emitError(event.reason);
				else {
					let message = "";
					try {
						message = JSON.stringify(event.reason);
					} catch (e) { }
					EventsManager.emitError({
						name: "UnhandledPromiseRejection",
						message
					});
				}
			};
			QPortalApp.config.errorHandler = (err, vm, info) => {
				EventsManager.emitError(err as Error, { info });
			};
			EventsManager.onError(async(error, extras) => {
				fatalErrors.value.push({ error, extras, datetime: new Date() });
				logErrors();

				let tracked: TrackedException | undefined;
				if (!isSessionExpired(error))
					tracked = await trackException(error, extras);

				if (showErrorModal.value)
					return;

				trackUserEvent(EventAI.FatalError, {
					description: "Unhandled exception feedback to user",
					error: tracked?.exception,
					errorName: tracked?.exception.name,
					errorStack: tracked?.exception.stack,
					errorMessage: tracked?.exception.message,
					errorExtras: tracked?.properties
				});

				const firstError = fatalErrors.value[0];
				const isUnauthorized = Boolean(
					firstError.error
        && firstError.error instanceof RestError
        && firstError.error.statusCode === 401
				);
				if (!isErrorPage.value && errorModal.value && !isUnauthorized)
					showErrorModal.value = true;
			});
		};
		const errorRestCode = computed(() => {
			return errorRestObject.value && errorRestObject.value.Error
				? errorRestObject.value.Error.Code
				: "";
		});
		const errorRestMessage = computed(() => {
			return errorRestObject.value && errorRestObject.value.Error
				? errorRestObject.value.Error.Message
				: "";
		});
		const titleErrorModal = computed(() => {
			const langUtils = usei18n();
			if (errorRestCode.value === "QCenterNotFound")
				return langUtils.translateKey("com_center_not_found");
			else {
				switch (errorStatusCode.value) {
					case 500:
						return langUtils.translateKey("com_generic_error");
					case 503:
						return langUtils.translateKey("com_service_unavailable");
					case 401:
						return langUtils.translateKey("com_unauthorized_access");
					case 404:
						return langUtils.translateKey("com_resource_not_found");
					case 440:
						return langUtils.translateKey("com_session_expired_title");
					default:
						return langUtils.translateKey("com_error_occurred");
				}
			}
		});
		const initUtilityEvents = () => {
			EventsManager.onSystemChanged((id) => {
				trackUserEvent(EventAI.CenterChanged, { centerId: "" + id });
				if (!center.value) return;
				setRouteTitle("center-home", center.value.Name);
				userStore.addSystemIdToHistory(center.value.Id);
			});
			EventsManager.onHelpLinkClicked(() => {
				trackUserEvent(EventAI.GoToHelpSite, { url: helpURL.value });
				window.open(helpURL.value, "_blank");
			});
		};
		const getCurrentViewProxyInfo = async() => {
			await nextTick();
			if (currentView.value) {
				const currentComponent = currentView.value.$.subTree.component;
				currentViewComponent.value = currentComponent.proxy;
			}
		};
		watch(route, async() => {
			await getCurrentViewProxyInfo();
		});
		onMounted(async() => {
			initErrorEvents();
			initModalUtil(modalController);
			setupAppInsights({
				connectionString: appStore.connectionString,
				maxAjaxCallsPerView: -1,
				disableExceptionTracking: true,
				disableTelemetry: appStore.launchMode === "DevelopAndTestingSession"
			});
			await getCurrentViewProxyInfo();
			await syncSettingsFromBE(); // FIXME: #50429 remove after upgrade Vue3
			if (appStore.isTestingSession || appStore.isDevelopSession) {
				const appInstance = getCurrentInstance();
				const app = {
					getInstance: () => appInstance?.root,
					getView: () => currentViewComponent.value,
					getTopbar: () => navbar.value,
					getRef: (refName) => appInstance?.refs[refName] || null,
					getRouter: () => router,
					getStore: () => useStore()
				} as AppDriver;
				(window as any).app = app;
			}
			window.onresize = debounce(onResize, 250);
			onResize();
			initKeyboardEvents();
			initUtilityEvents();
		});
		return {
			appLoading,
			routeLoading,
			errorRestObject,
			currentView,
			errorModal,
			showErrorModal,
			errorFirstOccurrence,
			locationHref,
			errorStatusCode,
			errorRestCode,
			errorIsRest,
			titleErrorModal,
			isDevelopSession,
			router,
			currentRouteName,
			isCompanyMultiSystem,
			env,
			canManageRemoteAssistance,
			isPopupOpen,
			language,
			errorRestMessage,
			userSettings,
			userInfo,
			isStaffUser,
			isChildOfSettings,
			hamburgerVisible,
			user,
			remote,
			isInsideSettingsSection,
			isErrorPage,
			reload,
			errorModalClose,
			mainOverlayClicked,
			goToSystemSelection
		};
	}
});
