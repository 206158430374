<template>
	<div
		v-bind="instanceAttrs"
		class="field-control form-control fake-input"
		:class="{
			['form-control-' + size]: true,
			'isDisabled': disabled,
			'isReadonly': readonly
		}"
		readonly="readonly"
	>
		<slot>{{ value }}</slot>
	</div>
</template>
<script lang="ts" src="./fake-input.ts"></script>
<style lang="scss">
@import "@/style/variables.scss";
.fake-input {
	cursor: pointer;
	&.the-control {
		&.isReadonly, &.isDisabled {
			background-color: $input-disabled-bg;
			color: $disabled-color;
			pointer-events: none;
		}
		&.isDisabled {
			user-select: none;
			opacity: $opacity-50;
		}
	}
}
</style>
