import type { CmpCustomerType, CmpStatus, ICmpCenterBaseModel, InvoicingType } from "@app-rest/system/cmp.interfaces";

export interface IPrivilegeCategoryModel {
	Id: string;
	Categories: IPrivilegeCategoryModel[] | null;
	Privileges: IPrivilege[];
}
export interface IPrivilege {
	Id: Functionalities;
	Scope: string;
}
export enum Functionalities {
	AccessToTermsAndConditions = "AccessToTermsAndConditions",
	AccessToUserSetup = "AccessToUserSetup",
	AddUser = "AddUser",
	BanAndDeleteUsers = "BanAndDeleteUsers",
	AccessToRolesAndPrivileges = "AccessToRolesAndPrivileges",
	AssignRolesToUsers = "AssignRolesToUsers",
	DefineReservationOptions = "DefineReservationOptions",
	DefineRolesAndPrivileges = "DefineRolesAndPrivileges",
	AccessToDataAndServiceSetup = "AccessToDataAndServiceSetup",
	ActivateDeactivateServices = "ActivateDeactivateServices",
	AccessToBusinessDashboard = "AccessToBusinessDashboard",
	AccessToGameStatistics = "AccessToGameStatistics",
	AccessToBumperProtectionStatistics = "AccessToBumperProtectionStatistics",
	AccessToPerformanceMonitoring = "AccessToPerformanceMonitoring",
	AccessToOnlineBackup = "AccessToOnlineBackup",
	AccessToCustomerAccounts = "AccessToCustomerAccounts",
	AccessToReservationsGrid = "AccessToReservationsGrid",
	Agent4DExperimentalFeature = "Agent4DExperimentalFeature",
	AsynchronousPayments = "AsynchronousPayments",
	ManageCustomerAccounts = "ManageCustomerAccounts",
	ManageKioskUtilities = "ManageKioskUtilities",
	AccessToCmp = "AccessToCmp",
	AccessToInvoices = "AccessToInvoices",
	ManageCmpSubscription = "ManageCmpSubscription",
	AccessToTrainingLibrary = "AccessToTrainingLibrary",
	AccessToTipsReporting = "AccessToTipsReporting",
	ManageRemoteAssistance = "ManageRemoteAssistance",
	DefinePlayNowRulesAndPreferences = "DefinePlayNowRulesAndPreferences",
	DefineRulesAndPreferences = "DefineRulesAndPreferences",
	DefineBookableDaysHours = "DefineBookableDaysHours",
	DefineBowlerTypes = "DefineBowlerTypes",
	DefineWebOffers = "DefineWebOffers",
	DefineShoesAndSocks = "DefineShoesAndSocks",
	DefineFoodAndBeverage = "DefineFoodAndBeverage",
	DefineExtras = "DefineExtras",
	DefineTextAndImages = "DefineTextAndImages",
	DefineWebsiteMaintenance = "DefineWebsiteMaintenance",
	DefineLaneGroups = "DefineLaneGroups",
	LaneOptionsSets = "LaneOptionsSets",
	DefineLaneOptionsSets = "DefineLaneOptionsSets",
	DefinePayments = "DefinePayments",
	DefineGraphicResources = "DefineGraphicResources",
	DefineGraphicalThemes = "DefineGraphicalThemes",
	DefineEmailAddresses = "DefineEmailAddresses",
	DefinePrivacyAndTerms = "DefinePrivacyAndTerms",
	DefineWebsiteMenu = "DefineWebsiteMenu",
	DeliveryAndPickup = "DeliveryAndPickup",
	DefineKioskWaitingList = "DefineKioskWaitingList",
	AccessToKiosks = "AccessToKiosks",
	DefineKioskStations = "DefineKioskStations",
	DefineKioskTextAndImages = "DefineKioskTextAndImages",
	ManageKioskPlayLists = "ManageKioskPlayLists",
	KioskTempusPayment = "KioskTempusPayment",
	KioskTrancloudPayment = "KioskTrancloudPayment",
	KioskFreedomPayPayment = "KioskFreedomPayPayment",
	KioskSquarePayment = "KioskSquarePayment",
	DefineAPIsSettings = "DefineAPIsSettings",
	RealTimeScoringEvents = "RealTimeScoringEvents",
	EventQueueScoringEvents = "EventQueueScoringEvents",
	EventQueueResEvents = "EventQueueResEvents",
	EventQueueLaneStatus = "EventQueueLaneStatus",
	CreateServiceReservations = "CreateServiceReservations",
	ManageFoodAndBeverageModifiers = "ManageFoodAndBeverageModifiers",
	ManageFBOrdersUpdates = "ManageFBOrdersUpdates",
	KioskFnBSMS = "KioskFnBSMS", // FIXME: remove exp feature when deleted from Jarvis
	KioskBowlingWaitingList = "KioskBowlingWaitingList",
	ManageTips = "ManageTips",
	WebReservations = "WebReservations",
	GuestReservations = "GuestReservations",
	Kiosks = "Kiosks",
	RealIncomePlayNow = "RealIncomePlayNow",
	TwilioSetup = "TwilioSetup",
	CheckInManagement = "CheckInManagement",
	CheckInSections = "CheckInSections",
	MMS = "MMS",
	LaneOrder = "LaneOrder",
	WebResFixedStartingTimeAt00 = "WebResFixedStartingTimeAt00",
	TestPOSIntegrationPreview = "TestPOSIntegration-preview",
	AccessToSquare = "AccessToSquare",
	SquarePayment = "SquarePayment",
	DefineTerminals = "DefineTerminals",
	WebSquarePayment = "WebSquarePayment",
	ManageSquarePosIntegration = "ManageSquarePosIntegration",
	ManageSquareItemLibrary = "ManageSquareItemLibrary",
	ManageSquareConnection = "ManageSquareConnection",
	ManageSquareTeamMembers = "ManageSquareTeamMembers",
	Neoverse = "Neoverse",
	AccessNeoverse = "AccessNeoverse",
	BowlingReservationApi = "BowlingReservationApi",
	ManageBowlingReservationApi = "ManageBowlingReservationApi"
}
export interface IFunctionalityDetails {
	Enabled: boolean,
	Experimental: boolean
}
export type IFunctionalities = Record<Functionalities, IFunctionalityDetails>
export type Functionality = keyof IFunctionalities;

interface ICompanyBase {
	Id: number;
	Name: string;
}
export interface ICompanyCenter extends ICompanyBase {
	Channel: string;
	Region: string;
	Address: string;
	CmpStatus: CmpStatus;
	CmpOnHold: boolean;
	AccountId: string | null;
}
export interface IInvoice {
	InvoiceId: number;
	Invoice: string;
	Description: string;
	Date: string;
	Amount: number;
	Currency: string;
	CenterId: number;
	CenterName: string;
}
export interface ICompanyInfoModel extends ICompanyBase {
	Language: string;
	CrmNumber: string;
	MasterCenterId: number | null;
	Centers: ICompanyCenter[];
	DistributorType: InvoicingType;
}

export interface ICompanyCmpListCenterModel extends ICmpCenterBaseModel {}
export interface ICompanyCmpListModel extends ICompanyBase {
	Centers: ICompanyCmpListCenterModel[];
	CmpCustomerType: CmpCustomerType;
}

export type CompanyUserRole = "Owner" | "Admin" | "QStaff" | "User";
export interface ICompanyRoleModel {
	Id?: number;
	RoleName: string;
	Privileges?: Functionalities[];
}
export interface ICompanyUserModel {
	Id?: number;
	SquareTeamMemberId?: string;
	Username: string;
	FirstName: string;
	LastName: string;
	Email: string;
	Banned: boolean;
	Owner: boolean;
	Admin: boolean;
}
export interface ICompanyUserAuthorizationModel {
	Id: number;
	CenterId?: number;
	RoleIds: number[];
}
export interface ISquareTeamMember {
	Id: string;
	GivenName: string;
	FamilyName: string;
}
