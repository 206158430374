import Spinner from "@app-components/q-spinner/q-spinner.vue";
import { QOverlay as Overlay } from "@qamf/lighthouse";
import { computed, defineComponent } from "vue";

export default defineComponent({
	name: "QOverlay",
	components: {
		Overlay,
		Spinner
	},
	props: {
		show: { type: Boolean, required: true, default: false },
		spinnerSize: { type: String, default: "md" },
		opacity: {
			type: Number,
			default: 1,
			validator: (value: number) => value >= 0 && value <= 1
		},
		hideSpinner: { default: false, type: Boolean }
	},
	setup(props, { attrs }) {
		const attributes = computed(() => {
			return attrs;
		});
		return {
			attributes
		};
	}
});
