import useAppStore from "@app-store/app";

export default async function syncSettingsFromBE() {
	const appStore = useAppStore();

	// FIXME: #63540: Ensure fetch api-app call only once
	await fetch("/api-app", { method: "GET", cache: "no-cache" })
		.then(async(data) => {
			const config = await data.json();
			appStore.updateData(config);
		})
		.catch((error) => {
			console.warn("[WARN] /api-app not working");
			console.error(error);
		});
}
