<template>
	<div
		v-click-away="onClickOutside"
		class="q-form-field q-form-field-date"
		:class="classProp"
		@click.stop="onClickWrapper"
	>
		<QPopover
			ref="bPopover"
			v-model="isPopoverVisible"
			manual
			no-flip
			:reference="inputElement"
			:teleport-to="teleportTo"
			:placement="placement"
			:offset="0"
			:delay="0"
			custom-class="q-form-field-date-wrapper no-print"
			:class="{
				'd-none': disabled
			}"
		>
			<template #target>
				<slot />
				<QFormFieldWrapper
					ref="wrapper"
					v-model="selectedDate"
					:name="name"
					:rules="rules"
					:error-message="errorMessage"
					:hide-validation="hideValidation"
					:hide-validation-messages="hideValidationMessages"
				>
					<template #default="defaultSlotProps">
						<FakeInput
							ref="inputElement"
							v-bind="defaultSlotProps.field"
							class="fake-input the-control content-editable"
							:size="size"
							:disabled="disabled"
							:class="{
								'is-valid': defaultSlotProps.state === true,
								'is-invalid': defaultSlotProps.state === false,
							}"
							:readonly="readonly"
							:value="selectedDate"
						>
							{{ inputValue }}
						</FakeInput>
					</template>
					<template #appendIcon>
						<i
							class="bi bi-calendar"
							:class="{'disabled': disabled }"
							aria-hidden="true"
						/>
					</template>
				</QFormFieldWrapper>
			</template>
			<div
				class="date-picker-body"
				@click.stop
			>
				<QOverlay
					:show="helpers.loading || loading"
					:size="spinnerSize"
				>
					<div class="date-nav">
						<div
							class="go-prev"
							:class="{ disabled: !isPrevMonthSelectable }"
						>
							<q-button
								:variant="buttonVariant"
								:disabled="!isPrevMonthSelectable"
								@click="goPrevMonth"
							>
								<slot name="nav-go-prev">
									<i class="far fa-chevron-left" />
								</slot>
							</q-button>
						</div>
						<div
							v-if="showControlsHeader"
							class="header head-controls"
						>
							<QFormFieldSelect
								id="months_select"
								v-model="helpers.monthSelected"
								class="month-select"
								options-class="month-select-option-wrapper"
								name="month"
								size="sm"
								:options="helpers.listOfMonthsOptions"
								text-field="name"
								value-field="value"
							/>
							<QFormFieldSelect
								id="years_select"
								v-model="helpers.yearSelected"
								class="years-select"
								name="year"
								size="sm"
								:options="helpers.listOfYearsOptions"
							/>
						</div>
						<div
							v-else
							class="header head-label"
						>
							<slot name="nav-header-label">
								{{ pageHeaderLabel }}
							</slot>
						</div>
						<div
							class="go-next"
							:class="{ disabled: !isNextMonthSelectable }"
						>
							<q-button
								:variant="buttonVariant"
								:disabled="!isNextMonthSelectable"
								@click="goNextMonth"
							>
								<slot name="nav-go-next">
									<i class="far fa-chevron-right" />
								</slot>
							</q-button>
						</div>
					</div>
					<div class="week-days">
						<div
							v-for="d in helpers.datesFirstWeek"
							:key="d.dateKey"
							class="week-day"
							:week-day="d.dayOfWeek"
							:class="{
								'is-sunday': d.isSunday,
								'is-saturday': d.isSaturday
							}"
						>
							<slot
								:name="'header-week-day-' + d.dayOfWeek"
								v-bind="d"
							>
								{{ dayOfWeekLabel(d.value) }}
							</slot>
						</div>
					</div>
					<div class="month-days">
						<div
							v-for="d in helpers.dates"
							:key="d.dateKey"
							class="month-day"
							:day="d.dateKey"
							:class="{
								'is-sunday': d.isSunday,
								'is-saturday': d.isSaturday
							}"
						>
							<slot
								:name="'month-day-' + d.dateKey"
								v-bind="d"
							>
								<q-button
									:variant="variant"
									:class="{ selected: isSelected(d.value) }"
									:disabled="d.disabled || readonly"
									@click="onSelected(d.value)"
								>
									{{ d.text }}
								</q-button>
							</slot>
						</div>
					</div>
				</QOverlay>
			</div>
		</QPopover>
	</div>
</template>
<script lang="ts" src="./q-form-field-date.ts"></script>
<style lang="scss" src="./q-form-field-date.scss"></style>
