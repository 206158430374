import { OnlineBackupsRestFactory } from "@app-rest/system/onlineBackups";
import type { ICenterBackup, ICenterBackupDetails, IStatsArchive } from "@app-rest/system/onlineBackups.interfaces";
import store from "@app-store/index";
import useSystemStore from "@app-store/system";
import EventsManager from "@app-utils/events-manager";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

@Module class OnlineBackupsModuleFactory extends VuexModule {
	loadingArchive = false;
	modalArchive = false;
	dataArchive: ICenterBackupDetails | null = null;
	controllerArchive: AbortController | null = null;
	statsArchive: IStatsArchive | null = null;
	storedDownloadedArchive = 0;
	isDownloadAborted = false;
	downloadInProgress = false;
	restClient: OnlineBackupsRestFactory | null = null;
	selectedBackup: ICenterBackup = {
		Name: "",
		Date: "",
		Completed: false,
		Size: 0,
		Info: []
	};

	@Mutation clearData() {
		this.restClient = null;
		this.loadingArchive = false;
		this.modalArchive = false;
		this.dataArchive = null;
		this.controllerArchive = null;
		this.statsArchive = null;
		this.storedDownloadedArchive = 0;
		this.isDownloadAborted = false;
		this.downloadInProgress = false;
		this.selectedBackup = {
			Name: "",
			Date: "",
			Completed: false,
			Size: 0,
			Info: []
		};
	}

	@Mutation setRestClient(restClient: OnlineBackupsRestFactory) {
		this.restClient = restClient;
	}

	@Action ensureRestClient() {
		if (!this.restClient)
			this.setRestClient(new OnlineBackupsRestFactory(useSystemStore().info?.Id));
		return Promise.resolve(this.restClient as unknown as OnlineBackupsRestFactory);
	}

	@Mutation setLoadingArchive(v: boolean) {
		this.loadingArchive = v;
	}

	@Mutation setModalArchive(v: boolean) {
		this.modalArchive = v;
	}

	@Mutation setDataArchive(v: ICenterBackupDetails | null) {
		this.dataArchive = v;
	}

	@Mutation setControllerArchive(v: AbortController) {
		this.controllerArchive = v;
	}

	@Mutation setStatsArchive(v: IStatsArchive | null) {
		this.statsArchive = v;
	}

	@Mutation setStoredDownloadedArchive(v: number) {
		this.storedDownloadedArchive = v;
	}

	@Mutation setIsDownloadAborted(v: boolean) {
		this.isDownloadAborted = v;
	}

	@Mutation setDownloadInProgress(v: boolean) {
		this.downloadInProgress = v;
	}

	@Mutation setSelectedBackup(v: ICenterBackup) {
		this.selectedBackup = v;
	}

	@Action async getBackups() {
		const restClient = await this.ensureRestClient();

		const response = await restClient.backups();

		return response.data ?? null;
	}

	@Action async getBackupDetail(name: string) {
		const restClient = await this.ensureRestClient();
		return restClient.backupDetails(name);
	}
}

const moduleName = "system.onlineBackups";
let OnlineBackupsStore: OnlineBackupsModuleFactory | null;
function useOnlineBackupsStore() {
	if (OnlineBackupsStore) return OnlineBackupsStore;
	const mod = OnlineBackupsStore = new OnlineBackupsModuleFactory({ name: moduleName, store });
	EventsManager.onSystemChanged(systemId => {
		mod.clearData();
		mod.setRestClient(new OnlineBackupsRestFactory(systemId));
	});
	return mod;
}

export default useOnlineBackupsStore;
