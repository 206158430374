import { useRouteUtilities } from "@app-composables/route-utilities";
import { IMenuItem, IMenuLink } from "@app-store/menu-items";
import { QLink } from "@qamf/lighthouse";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "MenuItems",
	components: { QLink },
	props: {
		items: { type: Array as PropType<Partial<IMenuItem>[]>, required: true },
		label: { type: Function as PropType<(item: Partial<IMenuLink>) => string>, required: true }
	},
	setup() {
		const { routeName } = useRouteUtilities();
		return {
			routeName
		};
	}
});
