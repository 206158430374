export interface IReservationParams {
	dateFrom: Date, // DateTimeOffset,
	dateTo: Date, // DateTimeOffset,
	status?: string[],
	resourceClass?: string[],
}
export interface IReservation extends IReservationBase {
	ReservationKey: string,
	ReservationKeyLength: number,
	UserNameLength: number,
	NotesLength: number,
	DescriptionLength: number,
	StaffRequirementsNotesLength: number,
	StaffMemberLength: number,
	EnumTypesLength: number,
}
export interface IReservationItem {
	ItemGuid: string,
	ResourceClass: string,
	ResourceTypeGuid: string,
	ResourceId: number,
	StartTime: string,
	EndTime: string,
	OpenType: string,
	IsPair: boolean,
	GamesXPlayer: number,
	TotalPlayers: number,
	AutomaticOpen: boolean,
	AsSoonAsPossible: boolean,
	AccessFacilities: string,
	SmokerArea: string,
	Status: string,
	Bumpers: string,
	BookedDurationMins: number,
	ActualDurationMins: number,
	Team1Name: string,
	Team2Name: string,
	FiveFrames: boolean,
}
export interface IReservationService {
	type: string;
	title: string;
	day: Date;
	time: Date;
	duration: number;
	lane?: number;
	lanePairs?: number[];
	laneNumbers?: number;
}
export interface IReservationBase {
	ArrivalTime: string;
	BowlingQty: number;
	CenterReferentId: number;
	CompletionDate: string;
	CustomerCenterId: number;
	CustomerId: number;
	DateOfCreation: string;
	DelayOnArrival: number;
	Deposit: number;
	DepositTimeLimit: string;
	Description: string;
	FullPaymentTimeLimit: string;
	ItemGamesDescription?: string;
	MinutesBeforeTime: number;
	Notes: string;
	OpenType: string;
	OwnerType: string;
	ReferentId: number;
	ReservationItems: IReservationServiceItem[];
	ReservationType?: string;
	ReservationTypeGuid: string;
	StaffMember?: string;
	StaffRequirementsNotes?: string;
	Status: string;
	TimedUnlimitedType?: string;
	UserName: string;
}
export interface IReservationServiceItem {
	AccessFacilities: string;
	ActualDurationMins: number;
	AsSoonAsPossible: boolean;
	AutomaticOpen: boolean;
	BookedDurationMins: number;
	Bumpers: string;
	EndTime: string;
	FiveFrames: boolean;
	GamesXPlayer: number;
	IsPair: boolean;
	ItemGuid: string;
	OpenType: string;
	ResourceClass: string;
	ResourceId: number;
	ResourceType?: string;
	ResourceTypeGuid: string;
	SmokerArea: string;
	StartTime: string;
	Status: string;
	Team1Name?: string;
	Team2Name?: string;
	TotalPlayers: number;
	IsOverbookingArea: boolean;
}
interface IReservationAvailabilityBase {
	resourceIds?: any;
	laneNumbers?: number;
	reservationType: string;
	description: string;
	notes: string;
}
export interface IReservationAvailability extends IReservationAvailabilityBase {
	dateFrom: Date;
	dateTo: Date;
}

export interface IReservationAvailabilityPayload extends IReservationAvailabilityBase {
	dateFrom: string;
	dateTo: string;
}
export interface IReservationType {
	ReservationTypeId: number;
	RsrvTypeGuid: string;
	Description: string;
	IsService: boolean;
	IsDeleted: boolean;
}
export interface IResourceType {
	ResourceTypeGuid: string;
	Description: string;
}
export interface IReservationParameters {
	BookableEndTime: string;
	BookableStartTime: string;
	Granularity: number;
	LanesPairs: ILanePairs[];
	TotalLanes: number;
	MaxNumberOfPlayerPerLane: number;
}
export interface ILanePairs {
	Left: number;
	Right: number;
}
export interface IReservationPriceKey {
	Id: number;
	Name: string;
	Multiplier?: number;
}
interface IReservationOffer {
	Quantity : number,
	OpenType : OpenType,
}
export interface IReservationExperience {
	BgColor: string;
	DefIdPkGame: number;
	DefIdPkTime: number;
	DefIdPkUnlimited: number;
	DefaultEnvironment: number;
	Description: string;
	DuckpinMode: string;
	Environments: [];
	ExperienceGuid: string;
	GameDuration: string;
	Id: number;
	LaneSet: string;
	Name: string;
	OpenTypes: OpenType[];
	Position: number;
	PriceKeys: number[];
	TextColor: string;
	UnlimitedConfig : {
		Type : UnlimitedConfigType,
		Value : string
	}
}
export enum UnlimitedConfigType {
	Duration = "Duration",
	Unlimited = "Unlimited",
	ClosingTime = "ClosingTime"
}
export enum OpenType {
	Time = "Time",
	Game = "Game",
	Unlimited = "Unlimited"
}
export interface IReservationOfferPriceKey extends IReservationOffer {
	PriceKeyId : number
}
