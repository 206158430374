import { settingsReservationsShoesSocks } from "./reservation";

const settingsShoeSizeSystem = "shoe-size-system";
export const settingsShoeSizeSystemCategoryDetail = "shoe-size-system-categories-detail";

export default [
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/status",
		name: "shoes-and-socks-status",
		component: () => import("@app-views/settings/reservations/rental-shoes/status/status.vue"),
		meta: {
			titleKey: "settings_web_shoessocks_status",
			childOf: settingsReservationsShoesSocks
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/shoes",
		name: "shoes-and-socks-shoes",
		component: () => import("@app-views/settings/reservations/rental-shoes/shoes/shoes.vue"),
		meta: {
			titleKey: "settings_web_shoessocks_shoes",
			childOf: settingsReservationsShoesSocks
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/socks",
		name: "shoes-and-socks-socks",
		component: () => import("@app-views/settings/reservations/rental-shoes/socks/socks.vue"),
		meta: {
			titleKey: "settings_web_shoessocks_socks",
			childOf: settingsReservationsShoesSocks
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/socks/:name",
		name: "shoes-and-socks-socks-detail",
		component: () => import("@app-views/settings/reservations/rental-shoes/socks/detail/detail.vue"),
		meta: {
			childOf: "shoes-and-socks-socks"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/shoe-size-system",
		name: "shoe-size-system",
		component: () => import("@app-views/settings/reservations/rental-shoes/shoe-size-system/shoe-size-system.vue"),
		meta: {
			titleKey: "settings_web_shoe_size_system",
			childOf: settingsReservationsShoesSocks,
			groupName: "shoe-size-system"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/shoe-size-system/categories",
		name: "shoe-size-system-categories",
		component: () => import("@app-views/settings/reservations/rental-shoes/shoe-size-system/categories/shoe-size-categories.vue"),
		meta: {
			titleKey: "label_categories",
			childOf: settingsShoeSizeSystem
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/shoe-size-system/categories/:name",
		name: settingsShoeSizeSystemCategoryDetail,
		component: () => import("@app-views/settings/reservations/rental-shoes/shoe-size-system/categories/detail/detail.vue"),
		meta: {
			childOf: "shoe-size-system-categories"
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations/rental-shoes/shoe-size-system/chart",
		name: "shoe-size-system-chart",
		component: () => import("@app-views/settings/reservations/rental-shoes/shoe-size-system/shoe-size-chart/shoe-size-chart.vue"),
		meta: {
			titleKey: "settings_web_shoe_size_chart",
			childOf: settingsShoeSizeSystem
		}
	}
];
