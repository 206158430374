import useAppStore from "@app-store/app";
const appStore = useAppStore();

interface IPopupParams<T> {
	target: string,
	name: string,
	width: number,
	height: number,
	window?: Window,
	onMessage?: (evt: MessageEvent<T>) => void,
	readySignal?: (evt: MessageEvent<any>) => boolean,
	shouldResolveWhen: (evt: MessageEvent<T>) => boolean,
	eventTypeGuard: (evtData: MessageEvent<any>) => boolean
}
type PopupInstance<OutboundPayload, InboundPayload> = {
	target: string,
	createdAt: Date,
	win: Window,
	resultAsync: Promise<MessageEvent<InboundPayload> | null>
	sendMessage: (payload: OutboundPayload) => boolean,
}

let globalPopup: Window | null = null;

function serializePopupParams(params: Record<string, string | number>): string {
	return Object.keys(params).map((prop) => `${prop}=${params[prop]}`).join(",");
}

export function popupWindowFocus() {
	globalPopup?.focus();
}
export function popupWindowClose() {
	globalPopup?.close();
	globalPopup = null;
	appStore.setPopupWindowIsOpen(false);
}
export function setPopupWindow(win: Window) {
	if (globalPopup && win.location.href !== globalPopup.location.href)
		popupWindowClose();

	globalPopup = win;
	appStore.setPopupWindowIsOpen(true);
	const checker = {
		id: setTimeout(() => { }),
		next: () => {
			checker.id = setTimeout(() => {
				if (!win.closed) return checker.next();
				clearTimeout(checker.id);
				popupWindowClose();
			}, 200);
		}
	};
	checker.next();
}
export function createPopup<OutboundPayload, InboundPayload>(params: IPopupParams<InboundPayload>) {
	// eslint-disable-next-line promise/param-names
	return new Promise<PopupInstance<OutboundPayload, InboundPayload> | null>(resolvePopupInstance => {
		let isInstanceReady = false;
		let isResolvedWithResult = false;
		const { target, name, width, height, onMessage, shouldResolveWhen, eventTypeGuard } = params;
		const defaultReadySignal = (evt) => evt.data === "ready";
		const readySignal = params.readySignal ?? defaultReadySignal;
		const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
		const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
		const left = (screen.availWidth - width) / 2 + dualScreenLeft;
		const top = (screen.availHeight - height) / 2 + dualScreenTop;
		let initPopup: Window | null = null;
		if (!params.window) {
			const windowParams = "resizable," + serializePopupParams({ width, height, top, left });
			initPopup = window.open(target, name, windowParams);
		} else {
			initPopup = params.window;
			initPopup.name = name;
			initPopup.resizeTo(width, height);
			initPopup.moveTo(left, top);
			initPopup.location.href = target;
		}

		const popupWindow = initPopup;

		if (!popupWindow)
			return resolvePopupInstance(null);

		const popupInstanceReady = () => {
			isInstanceReady = true;
			const sendMessage = (payload: OutboundPayload): boolean => {
				if (popupWindow.closed)
					return false;

				popupWindow.postMessage(payload);
				return true;
			};
			resolvePopupInstance({
				createdAt: new Date(),
				win: popupWindow,
				target,
				sendMessage,
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				resultAsync
			});
		};
		const resultAsync = new Promise<MessageEvent<InboundPayload> | null>((resolve) => {
			const resolveWithResult = (result: MessageEvent<InboundPayload> | null) => {
				isResolvedWithResult = true;
				resolve(result);
			};
			function onMessageHandler(evt: MessageEvent<any>) {
				if (isResolvedWithResult) return;

				if (!isInstanceReady && readySignal(evt))
					return popupInstanceReady();

				if (!eventTypeGuard(evt))
					return;

				if (typeof onMessage === "function")
					onMessage(evt);

				if (shouldResolveWhen(evt)) {
					popupWindow?.close();
					resolveWithResult(evt);
				}
			}
			window.addEventListener("message", onMessageHandler);
			const checker = {
				id: setTimeout(() => { }),
				next: () => {
					checker.id = setTimeout(() => {
						if (!popupWindow.closed) return checker.next();
						clearTimeout(checker.id);
						window.removeEventListener("message", onMessageHandler);
						if (!isResolvedWithResult)
							resolveWithResult(null);
					}, 200);
				}
			};
			checker.next();
		});
	});
}
