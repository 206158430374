<template>
	<transition
		enter-active-class="animate__animated animate__slideInUp"
		leave-active-class="animate__animated animate__slideOutDown"
		:duration="400"
	>
		<div
			v-if="stateDownloadInProgress || stateIsDownloadAborted"
			class="q-footer"
		>
			<QSpinner
				size="sm"
				theme="dotted"
				:disabled="stateIsDownloadAborted"
				inline
			/>
			<span v-t.obck_progessbar_downloaded="{ count: stateStoredDownloadedArchive, totals: countSelectedBackup }" />
			<div
				class="btn btn-action"
				:class="{'disabled' : stateIsDownloadAborted}"
				@click="abort"
			>
				<i class="far fa-stop-circle" /><span>{{ $t("label_stop") }}</span>
			</div>
		</div>
	</transition>
</template>
<script lang="ts" src="./q-footer.ts"></script>
<style lang="scss" src="./q-footer.scss"></style>
