import { Functionalities } from "@app-rest/company/interfaces";
import type { QRoute } from "@app-router/index";
import useCompanyStore from "@app-store/company";
import SecurityPlugin from "@app-utils/security";
export const settings = "settings";
export const webOfferCalendar = "web-offer-details-availability-calendar";
export const settingsExtras = "extras";
export const settingsExtra = "extra";
export const settingsKiosks = "kiosks";
export const settingsReservations = "web-reservation";
export const settingsLaneGroups = "lane-groups";
export const settingsLaneOptionsSets = "lane-options-sets";
export const settingsPayments = "payments";
export const settingsAdminSettings = "administrative-settings";
export const settingsGraphicThemes = "graphic-themes";
export const settingsMediaLibrary = "media-library";
export const settingsThirdPartyApi = "third-party-api";
export const settingsFoodAndBeverage = "food-and-beverage";
export const settingsWebsite = "website";
export const settingsPictureLibraryContext = "picture-library-context";
export const settingsTerminals = "terminals";
export const settingsTerminalsDetails = "terminals-details";

function canShowKioskPaymentsRoutes(): boolean {
	return SecurityPlugin.hasFunctionalities([Functionalities.Kiosks])
		&& (SecurityPlugin.hasAtLeastOneFunctionalityEnabled([Functionalities.KioskTempusPayment, Functionalities.KioskTrancloudPayment, Functionalities.KioskFreedomPayPayment])
		|| SecurityPlugin.hasFunctionalities([Functionalities.KioskSquarePayment, Functionalities.SquarePayment]));
}

export default [
	{
		path: "/:centerId(\\d{4,})/settings",
		name: settings,
		component: () => import("@app-views/settings/settings.vue"),
		meta: {
			titleKey: "label_settings",
			childOf: "center-home",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks,
				Functionalities.Neoverse,
				Functionalities.DefineAPIsSettings,
				Functionalities.BowlingReservationApi
			]),
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineWebOffers,
				Functionalities.DefineShoesAndSocks,
				Functionalities.DefineBowlerTypes,
				Functionalities.DefinePlayNowRulesAndPreferences,
				Functionalities.DefineRulesAndPreferences,
				Functionalities.DefineBookableDaysHours,
				Functionalities.DefineFoodAndBeverage,
				Functionalities.DefineExtras,
				Functionalities.DefineWebsiteMenu,
				Functionalities.DefineTextAndImages,
				Functionalities.DefineWebsiteMaintenance,
				Functionalities.DefineLaneGroups,
				Functionalities.DefineLaneOptionsSets,
				Functionalities.DefinePayments,
				Functionalities.DefinePrivacyAndTerms,
				Functionalities.DefineEmailAddresses,
				Functionalities.DefineAPIsSettings,
				Functionalities.DefineGraphicalThemes,
				Functionalities.DefineGraphicResources,
				Functionalities.AccessToKiosks,
				Functionalities.DefineKioskStations,
				Functionalities.DefineKioskTextAndImages,
				Functionalities.ManageKioskPlayLists,
				Functionalities.DefineTerminals,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/reservations",
		name: settingsReservations,
		component: () => import("@app-views/settings/reservations/reservations.vue"),
		meta: {
			titleKey: "settings_reservation",
			childOf: "settings",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks,
				Functionalities.BowlingReservationApi
			]),
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineWebOffers,
				Functionalities.DefineShoesAndSocks,
				Functionalities.DefineBowlerTypes,
				Functionalities.DefineRulesAndPreferences,
				Functionalities.DefinePlayNowRulesAndPreferences,
				Functionalities.DefineBookableDaysHours,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/food-and-beverage",
		name: settingsFoodAndBeverage,
		component: () => import("@app-views/settings/food-and-beverage/food-and-beverage.vue"),
		meta: {
			titleKey: "settings_web_lookfeel_text_images_food_beverage",
			childOf: "settings",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks
			]),
			accessIf: [Functionalities.DefineFoodAndBeverage]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/extras",
		name: settingsExtras,
		component: () => import("@app-views/settings/extras/extras.vue"),
		meta: {
			titleKey: "settings_extras",
			childOf: "settings",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks
			]),
			accessIf: [Functionalities.DefineExtras]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/extras/:name",
		name: settingsExtra,
		component: () => import("@app-views/settings/extras/extra/extra.vue"),
		meta: {
			childOf: settingsExtras
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/website",
		name: settingsWebsite,
		component: () => import("@app-views/settings/website/website.vue"),
		meta: {
			titleKey: "settings_website",
			childOf: "settings",
			groupName: "maintenance",
			showIf: [Functionalities.WebReservations],
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineWebsiteMenu,
				Functionalities.DefineTextAndImages,
				Functionalities.DefineWebsiteMaintenance
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/kiosks",
		name: settingsKiosks,
		component: () => import("@app-views/settings/kiosk/kiosk.vue"),
		meta: {
			titleKey: "settings_kiosks",
			childOf: "settings",
			groupName: "maintenance",
			showIf: [Functionalities.Kiosks],
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.AccessToKiosks,
				Functionalities.DefineKioskStations,
				Functionalities.DefineKioskTextAndImages,
				Functionalities.ManageKioskPlayLists
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/lane-groups",
		name: settingsLaneGroups,
		component: () => import("@app-views/settings/lanes-group/lanes-group.vue"),
		meta: {
			titleKey: "settings_web_offer_lanegroups",
			childOf: "settings",
			groupName: settingsAdminSettings,
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks,
				Functionalities.BowlingReservationApi
			]),
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineLaneGroups,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/lane-groups/:name",
		name: "lane-groups-details",
		component: () => import("@app-views/settings/lanes-group/details/details.vue"),
		meta: {
			childOf: settingsLaneGroups
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/lane-options-sets",
		name: settingsLaneOptionsSets,
		component: () => import("@app-views/settings/lane-options-sets/lane-options-sets.vue"),
		meta: {
			titleKey: "settings_lane_options_sets",
			childOf: "settings",
			groupName: settingsAdminSettings,
			showIf: [Functionalities.LaneOptionsSets], // FIXME: #38523 onWait to fix privilege
			accessIf: [Functionalities.LaneOptionsSets, Functionalities.DefineLaneOptionsSets]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/payments",
		name: settingsPayments,
		component: () => import("@app-views/settings/payments/payments.vue"),
		meta: {
			titleKey: "settings_payments",
			childOf: "settings",
			groupName: settingsAdminSettings,
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks
			]),
			accessIf: [Functionalities.DefinePayments]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/payments/web-payments",
		name: "web-payments",
		component: () => import("@app-views/settings/payments/web-payments/web-payments.vue"),
		meta: {
			titleKey: "settings_payments_web_payments",
			groupName: "platform-payments",
			childOf: settingsPayments,
			showIf: [Functionalities.WebReservations]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/payments/kiosk-payments",
		name: "kiosk-payments",
		component: () => import("@app-views/settings/payments/kiosk-payments/kiosk-payments.vue"),
		meta: {
			titleKey: "settings_payments_kiosk_payments",
			groupName: "platform-payments",
			childOf: settingsPayments,
			showIf: () => canShowKioskPaymentsRoutes()
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/payments/sales-receipt-kiosk",
		name: "sales-receipt-kiosk",
		component: () => import("@app-views/settings/payments/kiosk-receipt/kiosk-receipt.vue"),
		meta: {
			titleKey: "settings_payments_sales_receipt_kiosk",
			groupName: "platform-receipts",
			childOf: "payments",
			showIf: [Functionalities.Kiosks]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/payments/credit-card-receipt-kiosk",
		name: "credit-card-receipt-kiosk",
		component: () => import("@app-views/settings/payments/kiosk-credit-card-receipt/kiosk-credit-card-receipt.vue"),
		meta: {
			titleKey: "settings_payments_credit_card_receipt_kiosk",
			groupName: "platform-receipts",
			childOf: "payments",
			showIf: () => canShowKioskPaymentsRoutes()
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/payments/tips",
		name: "tips",
		component: () => import("@app-views/settings/payments/tips/tips.vue"),
		meta: {
			titleKey: "settings_tips_payments",
			groupName: "tips-payments",
			childOf: "payments",
			showIf: [Functionalities.ManageTips],
			accessIf: [Functionalities.DefinePayments]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings",
		name: settingsAdminSettings,
		component: () => import("@app-views/settings/administrative-settings/administrative-settings.vue"),
		meta: {
			titleKey: "settings_administative_settings",
			childOf: "settings",
			groupName: settingsAdminSettings,
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks
			]),
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefinePrivacyAndTerms,
				Functionalities.DefineEmailAddresses
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/administrative-settings/email-addresses",
		name: "administrative-settings-email-addresses",
		component: () => import("@app-views/settings/administrative-settings/email-addresses/email-addresses.vue"),
		meta: {
			titleKey: "settings_website_management_email_addresses",
			childOf: settingsAdminSettings,
			accessIf: [Functionalities.DefineEmailAddresses]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/terminals",
		name: settingsTerminals,
		component: () => import("@app-views/settings/terminals/terminals.vue"),
		meta: {
			titleKey: "settings_terminals",
			childOf: "settings",
			groupName: settingsAdminSettings,
			showIf: [Functionalities.Neoverse],
			accessIf: [Functionalities.DefineTerminals]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/terminals/:terminalId(\\d+)",
		name: settingsTerminalsDetails,
		component: () => import("@app-views/settings/terminals/details/terminal.vue"),
		meta: {
			childOf: settingsTerminals
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/graphic-themes",
		name: settingsGraphicThemes,
		component: () => import("@app-views/settings/graphic-themes/graphic-themes.vue"),
		meta: {
			titleKey: () => {
				return useCompanyStore().isMultiSystem
					? "settings_graphic_themes_all_centers"
					: "settings_graphic_themes";
			},
			childOf: "settings",
			groupName: "third-party-api-media-group",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks
			]),
			accessIf: [Functionalities.DefineGraphicalThemes]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/media-library",
		name: settingsMediaLibrary,
		component: () => import("@app-views/settings/media-library/media-library.vue"),
		meta: {
			titleKey: () => {
				return useCompanyStore().isMultiSystem
					? "settings_media_library_all_centers"
					: "settings_media_library";
			},
			childOf: "settings",
			groupName: "third-party-api-media-group",
			showIf: () => SecurityPlugin.hasAtLeastOneFunctionality([
				Functionalities.WebReservations,
				Functionalities.Kiosks,
				Functionalities.BowlingReservationApi
			]),
			accessIf: () => SecurityPlugin.hasAtLeastOneFunctionalityEnabled([
				Functionalities.DefineGraphicResources,
				Functionalities.ManageBowlingReservationApi
			])
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/media-library/:name",
		name: settingsPictureLibraryContext,
		component: () => import("@app-views/settings/media-library/library-context/library-context.vue"),
		meta: {
			childOf: settingsMediaLibrary
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/media-library/:name/:context",
		name: "picture-library-detail",
		component: () => import("@app-views/settings/media-library/library-detail/library-detail.vue"),
		meta: {
			childOf: settingsPictureLibraryContext
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/external-third-party-api",
		name: settingsThirdPartyApi,
		component: () => import("@app-views/settings/api/api.vue"),
		meta: {
			titleKey: "label_apis",
			groupName: "third-party-api-media-group",
			childOf: "settings",
			accessIf: [Functionalities.DefineAPIsSettings],
			showIf: [Functionalities.DefineAPIsSettings],
			inheritShowAccessIfs: false
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/external-third-party-api/realtime-scoring-events",
		name: "realtime-scoring-events",
		component: () => import("@app-views/settings/api/real-time-scoring-events/real-time-scoring-events.vue"),
		meta: {
			titleKey: "RealTimeScoringEvents",
			childOf: settingsThirdPartyApi,
			accessIf: [Functionalities.RealTimeScoringEvents],
			showIf: [Functionalities.RealTimeScoringEvents]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/external-third-party-api/event-queue-lane-status",
		name: "event-queue-lane-status",
		component: () => import("@app-views/settings/api/event-queue-lane-status/event-queue-lane-status.vue"),
		meta: {
			titleKey: "EventQueueLaneStatus",
			childOf: settingsThirdPartyApi,
			accessIf: [Functionalities.EventQueueLaneStatus],
			showIf: [Functionalities.EventQueueLaneStatus]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/external-third-party-api/event-queue-scoring-events",
		name: "event-queue-scoring-events",
		component: () => import("@app-views/settings/api/event-queue-scoring-events/event-queue-scoring-events.vue"),
		meta: {
			titleKey: "EventQueueScoringEvents",
			childOf: settingsThirdPartyApi,
			accessIf: [Functionalities.EventQueueScoringEvents],
			showIf: [Functionalities.EventQueueScoringEvents]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/external-third-party-api/twilio-setup",
		name: "twilio-setup",
		component: () => import("@app-views/settings/api/twilio-setup/twilio-setup.vue"),
		meta: {
			titleKey: "settings_apis_twilio_setup",
			childOf: settingsThirdPartyApi,
			groupName: "twilio-setup",
			accessIf: [Functionalities.TwilioSetup],
			showIf: [Functionalities.TwilioSetup]
		}
	},
	{
		path: "/:centerId(\\d{4,})/settings/external-third-party-api/reservation-events",
		name: "reservation-events",
		component: () => import("@app-views/settings/api/reservation-events/reservation-events.vue"),
		meta: {
			titleKey: "EventQueueLocalReservationEvents",
			childOf: settingsThirdPartyApi,
			accessIf: [Functionalities.EventQueueResEvents],
			showIf: [Functionalities.EventQueueResEvents]
		}
	}
] as QRoute[];
