import useOnlineBackupsStore from "@app-store/system/onlineBackups";
import { computed, defineComponent } from "vue";

export default defineComponent({
	name: "QFooter",
	props: {},
	setup() {
		const onlineBackupsStore = useOnlineBackupsStore();
		const stateControllerArchive = computed(() => {
			return onlineBackupsStore.controllerArchive;
		});
		const stateIsDownloadAborted = computed(() => {
			return onlineBackupsStore.isDownloadAborted;
		});
		const stateSelectedBackup = computed(() => {
			return onlineBackupsStore.selectedBackup;
		});
		const stateDownloadInProgress = computed(() => {
			return onlineBackupsStore.downloadInProgress;
		});
		const stateStoredDownloadedArchive = computed(() => {
			return onlineBackupsStore.storedDownloadedArchive;
		});
		const abort = () => {
			onlineBackupsStore.controllerArchive?.abort();
			onlineBackupsStore.setStatsArchive(null);
			onlineBackupsStore.setDownloadInProgress(false);
			onlineBackupsStore.setIsDownloadAborted(true);
			setTimeout(() => {
				onlineBackupsStore.setIsDownloadAborted(false);
				onlineBackupsStore.setStoredDownloadedArchive(0);
			}, 1500);
		};
		const countSelectedBackup = computed(() => {
			return stateSelectedBackup.value.Info.length > 0
				? stateSelectedBackup.value.Info[0].Count
				: "0";
		});
		return {
			stateControllerArchive,
			stateIsDownloadAborted,
			stateSelectedBackup,
			stateDownloadInProgress,
			stateStoredDownloadedArchive,
			abort,
			countSelectedBackup
		};
	}
});
