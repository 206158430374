import { useRouteUtilities } from "@app-composables/route-utilities";
import usei18n from "@app-i18n/index";
import useMenuItemsStore, { IMenuItem, IMenuLink } from "@app-store/menu-items";
import { QCollapse } from "@qamf/lighthouse";
import { computed, defineComponent } from "vue";

import MenuItems from "./menu-items.vue";

export default defineComponent({
	name: "Sidebar",
	components: {
		QCollapse,
		MenuItems
	},
	props: {},
	setup() {
		const menuItemsStore = useMenuItemsStore();
		const { translateKey } = usei18n();
		const { routeName } = useRouteUtilities();
		const menu = computed(() => {
			return menuItemsStore.menuItems ?? [];
		});
		const label = (item: IMenuItem | Partial<IMenuLink>) => {
			return item.translateKey
				? translateKey(item.translateKey)
				: item.label ?? "";
		};
		return {
			routeName,
			menu,
			label
		};
	}
});
