<template>
	<div
		class="layout"
		:slots="slots"
		v-bind="{ fluid: fluid || null }"
	>
		<div
			v-if="Number(slots) === 1"
			class="slot"
		>
			<slot />
		</div>
		<template v-else>
			<div
				v-for="(col, index) in Number(slots)"
				:key="index"
				:class="`slot slot-${index + 1}`"
			>
				<slot :name="(index + 1)" />
			</div>
		</template>
	</div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
	name: "Layout",
	props: {
		slots: { default: 1, type: [String, Number] },
		fluid: { default: false, type: Boolean }
	},
	setup() {
		return {};
	}
});
</script>
